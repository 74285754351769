import styled from '@emotion/styled/macro';
import { em, margin } from 'polished';

export const PaginationListItem = styled.li({
  ...margin(em(5.5), em(3)),
  display: 'flex',
  '&:first-of-type': {
    ...margin(0, em(3), 0, 0)
  },
  '&:last-of-type': {
    ...margin(0, 0, 0, em(3))
  }
});
