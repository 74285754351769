import injector from '../../injector';
import { REPOS_DIRECTORY_PATH, REPOS_SELECTED_FILENAME } from './constants';
import { getProcess } from '../process/queries';
import { readFile } from '../core/queries';

const { inject } = injector;

const _getSelectedProjectPath = inject(
  ['path'],
  function _getSelectedProjectPath() {
    return this.path.join(REPOS_DIRECTORY_PATH, REPOS_SELECTED_FILENAME);
  }
);

const getSelectedProjectPath = inject(
  ['path'],
  function getSelectedProjectPath() {
    const projId = getSelectedProjectId();
    if (projId) return getProjectPath(projId);
  }
);

const getSelectedProjectId = () =>
  readFile({
    path: _getSelectedProjectPath()
  });

const getProjectIdFromPath = inject(['path'], function getProjectIdFromPath(
  path
) {
  if (path.startsWith(REPOS_DIRECTORY_PATH)) {
    return this.path.basename(path.split(REPOS_DIRECTORY_PATH)[1]);
  }
});

const getProjectIdFromPid = inject(['path'], function getProjectIdFromPath(
  pid
) {
  const proc = getProcess(pid);
  return getProjectIdFromPath(proc.cwd);
});

const getProjectPath = inject(['path'], function getProjectPath(projId) {
  return this.path.join(REPOS_DIRECTORY_PATH, projId);
});

export {
  getProjectIdFromPath,
  getProjectIdFromPid,
  getProjectPath,
  _getSelectedProjectPath,
  getSelectedProjectPath,
  getSelectedProjectId
};
