import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var MenuIcon = function MenuIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M3.61 2h10.78c.13 0 .235.126.235.281V3.97c0 .155-.105.281-.234.281H3.609c-.13 0-.234-.126-.234-.281V2.28c0-.155.105-.281.234-.281zM.25 7.625h17.5c.138 0 .25.126.25.281v1.688c0 .155-.112.281-.25.281H.25c-.138 0-.25-.126-.25-.281V7.906c0-.155.112-.281.25-.281zm3.36 5.625h10.78c.13 0 .235.126.235.281v1.688c0 .155-.105.281-.234.281H3.609c-.13 0-.234-.126-.234-.281V13.53c0-.155.105-.281.234-.281z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

MenuIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(MenuIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'MenuIcon';
export default ForwardRef;