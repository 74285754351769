import gql from 'graphql-tag';
import { ContentFeedFragment } from '../fragments';

export const ContentsQuery = gql`
  query ContentsQuery(
    $first: Int
    $offset: Int
    $orderBy: [ContentsOrderBy!]
    $condition: ContentCondition
  ) {
    contents(
      first: $first
      offset: $offset
      orderBy: $orderBy
      condition: $condition
    ) {
      edges {
        cursor
        node {
          ...ContentFeedFragment
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${ContentFeedFragment}
`;
