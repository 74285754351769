import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ColorIcon = function ColorIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M10.832 2.06l7.139 7.13a.096.096 0 010 .138l-7.871 7.86a.096.096 0 01-.138 0l-7.14-7.13-.734.734a.095.095 0 01-.135-.002l-.664-.664a.093.093 0 01-.002-.134L10.765.527A.095.095 0 0110.9.53l.664.663c.038.038.041.096.002.135l-.734.733zm-7.17 7.033h12.555L9.94 2.824 3.662 9.093zM1.75 17.5C.784 17.5 0 16.83 0 16.002c0-.827 1.794-2.996 1.794-2.996S3.5 15.175 3.5 16.002 2.716 17.5 1.75 17.5z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ColorIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ColorIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ColorIcon';
export default ForwardRef;