export const rangeTrackSelectors = [
  '&::-ms-track',
  '&::-moz-range-track',
  '&::-webkit-slider-runnable-track'
];

export const rangeThumbSelectors = [
  '&::-ms-thumb',
  '&::-moz-range-thumb',
  '&::-webkit-slider-thumb'
];
