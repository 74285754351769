import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { margin } from 'polished';

export const AccordionPanel = styled.dd({
  ...margin(0),
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto',
  '&[hidden]': {
    display: 'none'
  }
});

AccordionPanel.defaultProps = {
  role: 'region'
};

AccordionPanel.propTypes = {
  'aria-labelledby': PropTypes.string.isRequired,
  hidden: PropTypes.bool
};
