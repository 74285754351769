import React from 'react';

import { Box, Flex, ExternalLink } from './';

import styled from '@emotion/styled';
import css from '@styled-system/css';
import themeGet from '@styled-system/theme-get';
import { TERMS_URL, PRIVACY_URL, SECURITY_URL } from '../urls';
export const FooterBottom = styled(Box)(props =>
  css({
    bg: 'backgroundAlt',
    py: '6',
    px: [0, 150, 150, 200, 370],
    textAlign: 'center',
    display: ['block', 'block', 'flex'],
    flexDirection: ['column', 'column', 'column', 'row'],
    justifyContent: 'space-between',
    alignItems: 'center',
    color: themeGet('colors.gray')(props)
  })
);

export const Footer = () => (
  <FooterBottom>
    <ExternalLink href={process.env.REACT_APP_WEBSITE_URL}>
      <small>© AirPage, Inc. All rights reserved</small>
    </ExternalLink>
    <Flex
      flexDirection={['column', 'column', 'column', 'row']}
      justifyContent="space-between"
    >
      <ExternalLink href={TERMS_URL} mt={['3', '3', '3', '0']}>
        <div>Terms</div>
      </ExternalLink>
      <ExternalLink
        href={PRIVACY_URL}
        mt={['3', '3', '3', '0']}
        ml={[0, 0, 0, '48px']}
      >
        <div>Privacy Policy</div>
      </ExternalLink>
      <ExternalLink
        href={SECURITY_URL}
        mt={['3', '3', '3', '0']}
        ml={[0, 0, 0, '48px']}
      >
        <div>Security</div>
      </ExternalLink>
    </Flex>
  </FooterBottom>
);
