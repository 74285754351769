import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { em } from 'polished';
import { layout, border } from 'styled-system';
import themeGet from '@styled-system/theme-get';
import { List, ListItem, ListItemLink } from '../List';

const variant = props => ({
  backgroundColor: themeGet('colors.background.default')(props),
  borderColor: themeGet('colors.border')(props),
  boxShadow: `rgba(0,0,0,0.05) ${em(6)} 0 ${em(6)}`
});

export const SideNavMenu = styled.div(
  {
    borderStyle: 'solid',
    borderWidth: 18 / 16,
    boxSizing: 'border-box',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    flexDirection: 'column',
    position: 'relative',
    width: em(300),
    padding: em(24),
    [`${List}`]: {
      flexGrow: 1,
      marginLeft: -24,
      marginRight: -24
    },
    [`${ListItem}`]: {
      marginLeft: -12,
      marginRight: -12
    },
    [`${ListItemLink}`]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  },
  variant,
  layout,
  border
);

SideNavMenu.defaultProps = {
  role: 'tablist'
};

SideNavMenu.propTypes = {
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string
};
