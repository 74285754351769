import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CloneIcon = function CloneIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M12.857 0v4.286H4.286v8.571H0V0h12.857zm-12 1.372l2.914 2.914.515-.515L1.372.857l-.515.515zM12 2.572V.856h-1.714L12 2.571zm6 2.57v12.75c0 .06-.048.108-.107.108H5.25a.107.107 0 01-.107-.107V5.25c0-.06.048-.107.107-.107H18zm-.857 3.43V6H14.57l2.572 2.571zm-3.429-7.2l.515-.515 2.914 2.914-.515.515-2.914-2.914zM.857 14.23l.515-.515 2.914 2.914-.515.515-2.914-2.914z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CloneIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CloneIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CloneIcon';
export default ForwardRef;