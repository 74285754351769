import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { em } from 'polished';

const variant = css({
  backgroundColor: 'rgb(24,24,24)'
});

export const PanelTabsNav = styled.nav(
  {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: em(6)
  },
  variant
);
