export const removeSelections = (document, window) => {
  if (document.selection) {
    document.selection.empty();
  } else {
    try {
      window.getSelection().removeAllRanges();
    } catch {}
  }
};

export const getOwnerDocument = node => {
  return (node && node.ownerDocument) || document;
};

export const isWindow = node => {
  return node === node.window
    ? node
    : node.nodeType === 9
    ? node.defaultView || node.parentWindow
    : false;
};

const bodyIsOverflowing = node => {
  let doc = getOwnerDocument(node);
  return doc.body.clientWidth < window.innerWidth;
};

const isBody = node => {
  return node && node.tagName.toLowerCase() === 'body';
};

export const isOverflowing = container => {
  return isWindow(container) || isBody(container)
    ? bodyIsOverflowing(container)
    : container.scrollHeight > container.clientHeight;
};

export const getScrollbarSize = () => {
  let size = 0;
  let scrollDiv = document.createElement('div');

  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  scrollDiv.style.width = '50px';
  scrollDiv.style.height = '50px';
  scrollDiv.style.overflow = 'scroll';

  document.body.appendChild(scrollDiv);
  size = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return size;
};
