import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { padding, em, margin } from 'polished';
import {
  color,
  position,
  space,
  layout,
  border,
  shadow,
  buttonStyle
} from 'styled-system';
import { Icon } from '../Icon';

export const Button = styled.button(
  props => ({
    ...margin(0),
    ...padding(em(6 - 0.5), em(12 - 0.5)),
    textAlign: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 0.5,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: props.fluid ? 'block' : 'inline-block',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 500,
    lineHeight: 24 / 16,
    textDecoration: 'none',
    verticalAlign: props.fluid ? undefined : 'middle',
    width: props.fluid ? '100%' : undefined,
    '& > *': {
      verticalAlign: 'middle'
    },
    "&:disabled, &[aria-disabled='true']": {
      cursor: 'not-allowed'
    },
    [`${Icon}`]: {
      '&:not(:first-child)': {
        marginLeft: em(6)
      },

      '&:not(:last-child)': {
        marginRight: em(6)
      }
    }
  }),
  buttonStyle,
  props => ({
    borderRadius: props.round ? '100%' : em(3)
  }),
  color,
  position,
  space,
  layout,
  border,
  shadow,
  color
);

Button.defaultProps = {
  variant: 'default'
};

Button.propTypes = {
  'aria-pressed': PropTypes.bool,
  'aria-disabled': PropTypes.bool,
  fluid: PropTypes.bool
};
