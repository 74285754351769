import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PageIcon = function PageIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2.25 0h10.646a.25.25 0 01.177.073l2.854 2.854a.25.25 0 01.073.177V17.75a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V.25A.25.25 0 012.25 0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PageIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PageIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PageIcon';
export default ForwardRef;