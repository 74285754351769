export default function(theme) {
  // Currently both them have same colors
  const dark = {
    textColors: {
      primary: 'rgb(255, 255, 255)',
      secondary: 'rgb(186, 186, 186)',
      header: 'rgb(250, 250, 250)',
      disabled: 'rgba(186, 186, 186, 0.3)',
      active: 'rgb(6, 45, 99)'
    }
  };
  const light = {
    textColors: {
      primary: 'rgb(128, 128, 128)',
      secondary: 'rgb(186, 186, 186)',
      header: 'rgb(77, 77, 77)',
      disabled: 'rgba(186, 186, 186, 0.3)',
      active: 'rgb(1, 161, 255)'
    }
  };
  return { dark, light }[theme] || dark;
}
