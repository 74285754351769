import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SplitIcon = function SplitIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9.429 8.571h8.465c.059 0 .106.047.106.106v.646a.108.108 0 01-.106.106H9.43v8.465a.105.105 0 01-.106.106h-.646a.108.108 0 01-.106-.106V9.43H.106A.105.105 0 010 9.323v-.646c0-.059.052-.106.106-.106H8.57V.106c0-.059.047-.106.106-.106h.646c.059 0 .106.052.106.106V8.57zM.857.965c0-.06.044-.108.108-.108h6.642c.06 0 .107.044.107.108v6.642c0 .06-.044.107-.107.107H.965a.105.105 0 01-.108-.107V.965zm0 9.428c0-.06.044-.107.108-.107h6.642c.06 0 .107.044.107.107v6.642c0 .06-.044.108-.107.108H.965a.105.105 0 01-.108-.108v-6.642zM10.286.965c0-.06.044-.108.107-.108h6.642c.06 0 .108.044.108.108v6.642c0 .06-.044.107-.108.107h-6.642a.105.105 0 01-.107-.107V.965zm0 9.428c0-.06.044-.107.107-.107h6.642c.06 0 .108.044.108.107v6.642c0 .06-.044.108-.108.108h-6.642a.105.105 0 01-.107-.108v-6.642z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SplitIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SplitIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SplitIcon';
export default ForwardRef;