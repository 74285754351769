import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PluckIcon = function PluckIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M10.968 5.8A2.66 2.66 0 019 6.667 2.66 2.66 0 017.032 5.8L2.8 7.854l1.269 3.695L2.672 12 1 7.13 6.384 4.52A2.666 2.666 0 017.667 1.69V0h2.666v1.69a2.666 2.666 0 011.283 2.829L17 7.13 15.328 12l-1.396-.451 1.27-3.695L10.967 5.8zM6 12h6v6H6v-6z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PluckIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PluckIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PluckIcon';
export default ForwardRef;