import React, { memo } from 'react';
import { AuthForm, AuthHeading, Button } from '../../ui';
import { propsAreEqual } from '../../utils/react';

export const ProfileIsComplete = memo(function ProfileIsComplete(props) {
  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        const location = window.location;
        window.location = location;
      }}
    >
      <AuthHeading>Congratulations</AuthHeading>
      <Button fluid={true} type="submit" variant="primary">
        Go to the app
      </Button>
    </AuthForm>
  );
}, propsAreEqual);
