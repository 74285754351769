import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';

import { RegisterOrganizationMutation } from '../mutations';

import {
  Box,
  Button,
  Alert,
  AlertClose,
  Icon,
  Field,
  Label,
  Input
} from '../ui';
import { NotificationPortal, Dialog } from '../components';

const OrgNewForm = ({ onSubmit, onChange }) => {
  const [displayName, setDisplayName] = useState('');
  const handleNameChange = useCallback(event => {
    setDisplayName(event.target.value);
  }, []);

  useEffect(() => {
    typeof onChange === 'function' && onChange({ displayName });
  }, [displayName, onChange]);

  return (
    <Box as="form" onSubmit={onSubmit}>
      <Field fluid={true}>
        <Label fluid={true} htmlFor="org-name" id="org-name-label">
          Name
        </Label>
        <Input
          id="org-name"
          aria-label="Organization name"
          fluid={true}
          name="name"
          placeholder="Organization name"
          type="text"
          value={displayName}
          onChange={handleNameChange}
        />
      </Field>
    </Box>
  );
};

export default function CreateOrg() {
  const [notification, setNotification] = useState(null);
  const form = useRef({ displayName: '' });
  const onFormChange = useCallback(({ displayName }) => {
    form.current.displayName = displayName;
  }, []);

  const onSubmit = createOrg => event => {
    event.preventDefault();
    createOrg({
      variables: {
        displayName: form.current.displayName
      }
    });
  };

  return (
    <React.Fragment>
      <Dialog
        renderTarget={open => (
          <Button onClick={open} variant="primary">
            <Icon name="plus" size={18} color="inherit" marginRight="2" />
            <span>New organization</span>
          </Button>
        )}
        title="Create organization"
        footer={close => (
          <Mutation
            mutation={RegisterOrganizationMutation}
            onCompleted={close}
            onError={error => {
              console.log('err', error);
              setNotification({
                text: 'There was an error creating your organization',
                variant: 'danger'
              });
            }}
          >
            {(createOrg, { loading }) => (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="default"
                  marginRight="3"
                  as={Link}
                  to={`/dashboard/projects`}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={onSubmit(createOrg)}
                  disabled={loading}
                >
                  Confirm
                </Button>
              </Box>
            )}
          </Mutation>
        )}
      >
        {close => (
          <Mutation
            mutation={RegisterOrganizationMutation}
            onCompleted={close}
            onError={error => {
              console.log('err', error);
              setNotification({
                text: 'There was an error creating your organization',
                variant: 'danger'
              });
            }}
          >
            {(createOrg, { loading }) => (
              <OrgNewForm
                loading={loading}
                onSubmit={onSubmit(createOrg)}
                onChange={onFormChange}
              />
            )}
          </Mutation>
        )}
      </Dialog>
      {notification && (
        <NotificationPortal>
          <Alert variant={notification.variant}>
            {notification.text}
            <AlertClose onClick={() => setNotification(null)}>
              <Icon name="delete" size={18} title="Close Alert" />
            </AlertClose>
          </Alert>
        </NotificationPortal>
      )}
    </React.Fragment>
  );
}
