import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var GradientIcon = function GradientIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.857 17.143h16.286V.857H.857v16.286zM17.893 18H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107zm-5.501-9.429c.194-.978 1.039-1.714 2.051-1.714 1.022 0 2.057.974 2.057 2.143 0 1.169-1.035 2.143-2.057 2.143-1.012 0-1.857-.736-2.051-1.714H5.608c-.194.978-1.039 1.714-2.051 1.714C2.535 11.143 1.5 10.178 1.5 9s1.035-2.143 2.057-2.143c1.012 0 1.857.736 2.051 1.714h6.784z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

GradientIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(GradientIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'GradientIcon';
export default ForwardRef;