import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DateIcon = function DateIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.179.857h9.642a.107.107 0 01-.107-.107V.107c0-.059.048-.107.107-.107h1.5c.06 0 .108.048.108.107V.75c0 .06-.048.107-.108.107h2.572c.059 0 .107.048.107.107v1.072c0 .059-.048.107-.107.107H.107A.107.107 0 010 2.036V.964C0 .905.048.857.107.857H2.68A.107.107 0 012.57.75V.107C2.571.048 2.62 0 2.68 0h1.5c.059 0 .107.048.107.107V.75c0 .06-.048.107-.107.107zM.107 2.571h17.786c.059 0 .107.048.107.108v15.214c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V2.679c0-.06.048-.108.107-.108zm1.286 2.572a.107.107 0 00-.107.107v10.071c0 .06.048.108.107.108h15.214c.06 0 .107-.048.107-.108V5.25a.107.107 0 00-.107-.107H1.393zm2.464 2.143H6.43v2.571H3.857V7.286zm0 3.428H6.43v2.572H3.857v-2.572zm3.857-3.428h2.572v2.571H7.714V7.286zm0 3.428h2.572v2.572H7.714v-2.572zm3.857-3.428h2.572v2.571H11.57V7.286zm0 3.428h2.572v2.572H11.57v-2.572z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DateIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DateIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DateIcon';
export default ForwardRef;