import 'typeface-hind';
import 'typeface-source-code-pro';

import { margin, padding } from 'polished';
import themeGet from '@styled-system/theme-get';

const globalStyles = props => ({
  'html, body, #root': {
    height: '100%'
  },

  html: {
    backgroundColor: themeGet('colors.background.default')({ theme: props })
  },

  body: {
    ...margin(0),
    ...padding(0),
    fontFamily: `'Hind', sans-serif`,
    hyphens: 'auto',
    maxWidth: '100%',
    orphans: 2,
    overflowWrap: 'break-word',
    overflow: 'auto',
    widows: 2,
    wordWrap: 'break-word'
  },

  '#root': {
    minHeight: '100%'
  },

  a: {
    color: 'inherit'
  }
});

export { globalStyles };
