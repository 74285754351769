import React, { useState, memo } from 'react';
import { propsAreEqual } from '../../utils/react';
import { useMutation } from '@apollo/react-hooks';
import { AuthForm, AuthHeading, Button, Field, Input, Label } from '../../ui';
import { SetPasswordMutation } from '../../mutations';

const SetPasswordForm = memo(function SetPasswordForm({ step, setStep }) {
  const [disabled, setDisabled] = useState(true);
  const [pass, setPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [setPassword] = useMutation(SetPasswordMutation, {
    onCompleted: () => {
      setStep(step + 1);
    }
  });

  const changePassword = event => {
    const ps = event.currentTarget.value;
    if (ps.length > 7 && ps === confirmPass) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setPass(ps);
  };

  const changeConfirmPassword = event => {
    const ps = event.currentTarget.value;
    if (ps.length > 7 && ps === pass) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setConfirmPass(ps);
  };

  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        const variables = Array.from(event.currentTarget.elements).reduce(
          (variables, { name, value }) =>
            name && value ? { ...variables, [name]: value } : variables,
          {}
        );

        setPassword({ variables: { newPassword: variables.password } })
          .then(() => {
            console.log('something good happened');
          })
          .catch(() => {
            console.error('something bad happened');
          });
      }}
    >
      <AuthHeading>Set Password</AuthHeading>
      <Field fluid={true}>
        <Label htmlFor="password" id="password-label">
          Password
        </Label>
        <Input
          aria-labelledby="password-label"
          autoComplete="password"
          fluid={true}
          id="password"
          name="password"
          type="password"
          onChange={changePassword}
        />
      </Field>
      <Field fluid={true}>
        <Label htmlFor="confirm-password" id="confirm-password-label">
          Confirm Password
        </Label>
        <Input
          aria-labelledby="confirm-password-label"
          autoComplete="confirm-password"
          fluid={true}
          id="confirm-password"
          name="confirm-password"
          type="password"
          onChange={changeConfirmPassword}
        />
      </Field>
      <Button fluid={true} disabled={disabled} type="submit" variant="primary">
        Set Password
      </Button>
    </AuthForm>
  );
}, propsAreEqual);

const PasswordRules = memo(function PasswordRules(props) {
  return (
    <AuthForm>
      Your password must:
      <ul style={{ textAlign: 'left' }}>
        <li>Be at least 7 characters</li>
        <li>
          Not exist in the{' '}
          <a
            href={'https://haveibeenpwned.com/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            have i been pwned?
          </a>{' '}
          database
        </li>
      </ul>
    </AuthForm>
  );
}, propsAreEqual);

const SetPassword = props => {
  return (
    <>
      <SetPasswordForm {...props} />
      <PasswordRules />
    </>
  );
};

export { SetPassword };
