import './commands';
import { commands } from '../registry';

import { getTerminalPrompt, readState } from '../queries';
import {
  appendTerminalHistory,
  appendTerminalOutput,
  readIntoBuffer
} from '../commands';

import {
  getMinimistOpts,
  processArgs,
  validateArgs,
  processArgPaths
} from './args';

import { minimist } from './minimist';
import { TERMINAL_DATA_FILENAME, TERMINAL_DIRECTORY_PATH } from '../constants';
import { emitFileChanged } from '../events';
import { BFSRequire } from 'browserfs';

import color from 'ansi-colors';
import { getActiveProcessId } from '../app/process/queries';

const path = BFSRequire('path');

const parseShellString = str => {
  return str
    .trim()
    .match(/\\?.|^$/g)
    .reduce(
      (p, c) => {
        if (c === '"' || c === "'") {
          p.quote ^= 1;
        } else if (!p.quote && c === ' ') {
          p.a.push('');
        } else {
          p.a[p.a.length - 1] += c.replace(/\\(.)/, '$1');
        }
        return p;
      },
      { a: [''] }
    ).a;
};

const addChanges = (hash, array) => {
  array.forEach(item => {
    hash[item] = true;
  });
  return hash;
};

class CommandProcessor {
  static instance;
  constructor() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = this;
  }

  async exec(pid, command, ...payload) {
    const prompt = getTerminalPrompt();
    const changes = {};
    if (!commands.has(command)) {
      appendTerminalOutput(prompt);
      if (!command) {
        return addChanges(changes, appendTerminalOutput(color.white('$')));
      }
      return addChanges(
        changes,
        appendTerminalOutput(`command ${command} is not found.`)
      );
    }
    addChanges(changes, appendTerminalOutput(prompt));
    appendTerminalOutput(color.white('$ ') + [command, ...payload].join(' '));
    addChanges(changes, appendTerminalHistory([command, ...payload].join(' ')));

    const cmd = commands.get(command);

    let result = [];
    if (cmd.hasOwnProperty('args')) {
      const opts = getMinimistOpts(cmd.args);
      let argv = processArgs(cmd.args, minimist(payload || [], opts));
      processArgPaths(cmd.args, argv);
      validateArgs(cmd.args, argv);

      // try {
      result = await cmd(argv);
      // } catch (e) {
      // appendTerminalOutput(e.message);
      // }
    } else {
      // try {
      result = await cmd(...payload);
      // } catch (e) {
      // appendTerminalOutput(e.message);
      // }
    }
    if (result instanceof Array) addChanges(changes, result);
    return changes;
  }
  async parse(string, pid = getActiveProcessId()) {
    const state = readState(
      path.join(TERMINAL_DIRECTORY_PATH, pid, TERMINAL_DATA_FILENAME),
      {}
    );
    if (state.read) {
      readIntoBuffer(string);
    } else {
      const promises = string
        .trim()
        .split(/\n|;/)
        .filter(Boolean)
        .map(str => {
          const [command, ...params] = parseShellString(str);
          return this.exec(pid, command, ...params);
        });
      const changesArray = await Promise.all(promises);
      const changes = Object.keys(
        changesArray.reduce((m, hash) => {
          for (var key in hash) {
            m[key] = hash[key];
          }
          return m;
        }, {})
      );

      emitFileChanged(...changes);
    }
  }
}

export { CommandProcessor };
