import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var RootIcon = function RootIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 0c4.963 0 9 4.037 9 9s-4.037 9-9 9-9-4.037-9-9 4.037-9 9-9zm3.29 16.588a8.306 8.306 0 002.917-2.129l-.142-.012-.193.177-.513.005-.383-.455.346-.497.291-.96v-.606l.683-.91-.197-.304.212-.728-.03-.425-.273-.455-.546.121-.619-.121-.959-.121-.637-.516-.85-.425-.303-.572.358-.41-.176-.504-.273-.456.273-.758.667-.546.638-.304.637-.333.97-.03 1.047.06.653.607v-.182l-.653-.425-.834-.122.152-.424-.425-.273-.285.424-.534.182-.399.15-.785-.271-.09-.395.303-.303.516-.243.254-.349-.618-.106-.759-.485-.06-.607-.33-.218-.634-.127-.57-.224.335-.121A8.274 8.274 0 009 .728h-.015L9 1.005l-.667.213-.273.334h-.546v-.425l.161-.291a8.177 8.177 0 00-.867.19.313.313 0 01-.044.04c-.278.213-.304.638-.373.698-.068.06.078.382.078.382l-.108.346-.668.152-.364.182v.546l-.379.637-.349.546v.456l.273.728-.424-.152-.29-.303-.166-.607-1.092.03s-1.092 1.517-.88 1.76c.213.243.516.728.516.728h.607l.161.245-.282.18-.182.425.06.273-.06.394.424-.03.395-.364 2.033.138.788.544.88 1.047.357.104.098.382-.204.51-.281.127s-.577.273-.546.455c.03.182-.364.94-.364.94l.273.698-.971.455-.162.607-.05 1.001.272.577-.182.303-.667-.273-.775-.758-.378-1.032-.88-1.578-.334-.485-.565-.334-.133-.637.03-1.426 1.063-.455L2.477 9l-.273-.544-.152-.32-.485-.304-.717-.239A8.295 8.295 0 00.728 9c0 3.484 2.166 6.469 5.22 7.686l.261-.176.82.424.242-.379-.051-.349.324.091.273.364.44.198.41-.016h.667l.364-.242 1.32-.304.728.122.543.169z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

RootIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(RootIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'RootIcon';
export default ForwardRef;