import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { padding, em } from 'polished';

const variant = css({
  backgroundImage: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6))'
});

export const ProjectFeedItemHeaderForeground = styled.div(
  {
    ...padding(em(12)),
    alignItems: 'flex-end',
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  variant
);
