import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { linearGradient } from 'polished';

import themeGet from '@styled-system/theme-get';
import { layout, space, position } from 'styled-system';
import { clamp } from '../../utils/math';
import { ControlThumb, thumbSize } from '../../ui/ControlThumb';
import { Color } from './Color';

const stacking = {
  thumb: 3,
  boardValue: 2,
  boardSaturation: 1,
  boardHsb: 1
};

const BoardHSB = styled.div(props => ({
  position: 'relative',
  background: props.background,
  zIndex: stacking.boardHsb,
  height: '100%',
  [`${BoardValue}, ${BoardSaturation}`]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0
  }
}));

const BoardValue = styled.div(props => ({
  zIndex: stacking.boardValue,
  ...linearGradient({
    colorStops: ['transparent 0%', `${themeGet('colors.black')(props)} 100%`],
    fallback: 'transparent',
    toDirection: 'to bottom'
  })
}));

const BoardSaturation = styled.div(props => ({
  zIndex: stacking.boardSaturation,
  ...linearGradient({
    colorStops: [`${themeGet('colors.white')(props)} 0%`, 'transparent 100%'],
    fallback: 'transparent',
    toDirection: 'to right'
  })
}));

const EventListener = styled.div({
  zIndex: stacking.thumb
});

const BoardWrapper = styled.div({
  width: '100%',
  height: 180,
  position: 'relative',
  userSelect: 'none',
  [`${EventListener}`]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0
  },
  [`${ControlThumb}`]: {
    transform: `translate(-${thumbSize / 2}px, -${thumbSize / 2}px)`
  }
});

export const Board = styled(({ color, onChange, className, ...rest }) => {
  const boardRef = useRef();

  const goToPoint = useCallback(
    ({ clientX: x, clientY: y }) => {
      const boardRect = boardRef.current
        ? boardRef.current.getBoundingClientRect()
        : null;
      if (!boardRect) return;
      const { width, height, left, top } = boardRect;
      const xOffset = clamp(x - left, 0, width);
      const yOffset = clamp(y - top, 0, height);
      const newColor = color;
      newColor.saturation = xOffset / width;
      newColor.brightness = 1 - yOffset / height;
      onChange(newColor);
    },
    [color, onChange]
  );

  const onDrag = useCallback(
    event => {
      goToPoint(event);
    },
    [goToPoint]
  );

  const onDragEnd = useCallback(
    event => {
      goToPoint(event);
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', onDragEnd);
    },
    [goToPoint, onDrag]
  );

  const onMouseDown = useCallback(
    event => {
      goToPoint(event);
      window.addEventListener('mousemove', onDrag);
      window.addEventListener('mouseup', onDragEnd);
    },
    [goToPoint, onDrag, onDragEnd]
  );

  const hueColor = new Color({
    h: color.hue,
    s: 1,
    v: 1
  });

  const thumbCoords = {
    x: color.saturation * 100,
    y: (1 - color.brightness) * 100
  };

  return (
    <BoardWrapper ref={boardRef} className={className} {...rest}>
      <BoardHSB background={hueColor.hexString}>
        <BoardValue />
        <BoardSaturation />
      </BoardHSB>
      <ControlThumb
        position="absolute"
        zIndex={stacking.thumb}
        left={`${thumbCoords.x}%`}
        top={`${thumbCoords.y}%`}
      />
      <EventListener onMouseDown={onMouseDown} />
    </BoardWrapper>
  );
})(layout, space, position);

Board.propTypes = {
  color: PropTypes.object,
  onChange: PropTypes.func
};

Board.defaultProps = {
  onChange: () => {}
};
