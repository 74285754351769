import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DesktopIcon = function DesktopIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.25 15h9.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-9.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25zm-4-14h17.5a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25V1.25A.25.25 0 01.25 1zM1 2.129V4.87c0 .077.055.129.123.129H2V3.125C2 3.058 2.056 3 2.125 3H4v-.877A.125.125 0 003.871 2H1.13A.125.125 0 001 2.129z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DesktopIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DesktopIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DesktopIcon';
export default ForwardRef;