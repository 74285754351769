import React from 'react';
import {
  AuthContainer,
  AuthContainerWrapper,
  Icon,
  VisuallyHidden
} from '../ui';
import { Footer, ExternalLink, Link } from '../imported';

export const AuthLayout = props => {
  return (
    <AuthContainer style={{ display: 'flex', flexDirection: 'column' }}>
      <AuthContainerWrapper>
        <VisuallyHidden as="h1">AirPage</VisuallyHidden>
        {props.linkLocal ? (
          <Link href={'/'}>
            <Icon name="airpage-logo-text" title="AirPage" size={120} />
          </Link>
        ) : (
          <ExternalLink href={process.env.REACT_APP_WEBSITE_URL}>
            <Icon name="airpage-logo-text" title="AirPage" size={120} />
          </ExternalLink>
        )}
      </AuthContainerWrapper>
      <div style={{ flex: '1 0 auto' }}>{props.children}</div>
      <div style={{ flexShrink: '0' }}>
        <Footer />
      </div>
    </AuthContainer>
  );
};
