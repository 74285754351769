import { mkdirpSync } from '../../utils';
import { BFSRequire } from 'browserfs';

import injector from '../../injector';

import { getCurrentWorkingDirectory, getUniqLocalId } from '../../queries';

import {
  PROCESSES_DIRECTORY_PATH,
  PROCESSES_ACTIVE_PROCESS_FILENAME,
  PROCESSES_FOLLOWER_EXTENSIONS
} from './constants';

import {
  getProcessPath,
  getActiveProcessPath,
  isPinnedProcess
} from './queries';

import { TERMINAL_DIRECTORY_PATH } from '../terminal/constants';

import { writeState } from '../core/commands';
const { inject } = injector;

// const fs = BFSRequire('fs');
const path = BFSRequire('path');

const initializeRootProcess = inject(['fs'], function initializeRootProcess() {
  if (!this.fs.existsSync(getProcessPath('root'))) {
    createProcess({ title: 'root', pid: 'root', cwd: '/' });
    setActiveProcess('root');
  }
});

const createProcess = ({
  title,
  pid = getUniqLocalId(PROCESSES_DIRECTORY_PATH),
  cwd = getCurrentWorkingDirectory()
} = {}) => {
  mkdirpSync(path.join(TERMINAL_DIRECTORY_PATH, pid));
  writeState(getProcessPath(pid), {
    pid,
    cwd,
    title
  });
  return pid;
};

const setActiveProcess = inject(['fs'], function setActiveProcess(pid) {
  if (pid === PROCESSES_ACTIVE_PROCESS_FILENAME) return [];
  if (pid.endsWith(PROCESSES_FOLLOWER_EXTENSIONS))
    pid = pid.split(PROCESSES_FOLLOWER_EXTENSIONS)[0];
  const activePidPath = getActiveProcessPath();
  this.fs.writeFileSync(activePidPath, pid);
  return [activePidPath];
});

const kill = inject(['fs'], function kill(...args) {
  // TODO cleanup the terminal stdout/stdin/history paths...
  for (let pid of args) {
    if (isPinnedProcess(pid)) continue;
    const processPath = getProcessPath(pid);
    if (this.fs.existsSync(processPath)) {
      this.fs.unlinkSync(processPath);
    }
  }
});

const detach = () => {
  return setActiveProcess('root');
};

export { createProcess, detach, initializeRootProcess, kill, setActiveProcess };
