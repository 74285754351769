import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DuplicateIcon = function DuplicateIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M14.172.172l3.656 3.656a.702.702 0 01.172.42v9.505a.25.25 0 01-.25.247h-3.5a.252.252 0 00-.25.25v3.5c0 .135-.109.25-.243.25H.243A.251.251 0 010 17.757V4.243C0 4.121.112 4 .25 4h3.5c.135 0 .25-.112.25-.25V.25c0-.135.11-.25.247-.25h9.506c.135 0 .324.077.419.172zM5.904 12.927l2.9-2.915c.094-.094.174-.06.174.082v1.73c0 .137.115.258.257.258h.506a.253.253 0 00.257-.255V8.255A.258.258 0 009.743 8H6.172a.259.259 0 00-.256.258v.505c0 .148.116.257.258.257h1.73c.137 0 .179.079.082.175l-2.914 2.899a.25.25 0 000 .351l.48.48c.1.1.256.099.352.002zM14 1.256v2.488c0 .142.115.256.256.256h2.488c.142 0 .176-.08.077-.179L14.18 1.18c-.105-.105-.179-.064-.179.077zM1.25 17h11.5c.146 0 .25-.115.25-.256v-2.488a.257.257 0 00-.26-.256H4.26a.258.258 0 01-.26-.26V5.26A.256.256 0 003.744 5H1.256A.253.253 0 001 5.25v11.5c0 .146.112.25.25.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DuplicateIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DuplicateIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DuplicateIcon';
export default ForwardRef;