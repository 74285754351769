import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ReferenceIcon = function ReferenceIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M16.868 4.133l.55 1.162L9 9.283.582 5.295l.55-1.162L9 7.86l7.868-3.727zm-8.51 4.01h1.285v9H8.357v-9zM9 0l9 4.286v9L9 18l-9-4.714v-9L9 0zm0 1.424L1.286 5.097v7.41L9 16.55l7.714-4.042v-7.41L9 1.424z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ReferenceIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ReferenceIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ReferenceIcon';
export default ForwardRef;