import styled from '@emotion/styled/macro';
import { em } from 'polished';
import { ProjectFeedItem } from './ProjectFeedItem';

export const ProjectFeedItemTopRightAction = styled.a({
  position: 'absolute',
  top: em(12),
  right: em(12),
  opacity: 0,
  [`&:focus, ${ProjectFeedItem}:hover > &`]: {
    opacity: 1
  }
});
