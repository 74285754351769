import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var TileViewIcon = function TileViewIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 .124C0 .056.057 0 .124 0h3.752C3.944 0 4 .057 4 .124v3.752A.125.125 0 013.876 4H.124A.125.125 0 010 3.876V.124zm0 7C0 7.056.057 7 .124 7h3.752c.068 0 .124.057.124.124v3.752a.125.125 0 01-.124.124H.124A.125.125 0 010 10.876V7.124zm7-7C7 .056 7.057 0 7.124 0h3.752c.068 0 .124.057.124.124v3.752a.125.125 0 01-.124.124H7.124A.125.125 0 017 3.876V.124zm0 7C7 7.056 7.057 7 7.124 7h3.752c.068 0 .124.057.124.124v3.752a.125.125 0 01-.124.124H7.124A.125.125 0 017 10.876V7.124zm7-7c0-.068.057-.124.124-.124h3.752c.068 0 .124.057.124.124v3.752a.125.125 0 01-.124.124h-3.752A.125.125 0 0114 3.876V.124zm0 7c0-.068.057-.124.124-.124h3.752c.068 0 .124.057.124.124v3.752a.125.125 0 01-.124.124h-3.752a.125.125 0 01-.124-.124V7.124zm-14 7C0 14.056.057 14 .124 14h3.752c.068 0 .124.057.124.124v3.752a.125.125 0 01-.124.124H.124A.125.125 0 010 17.876v-3.752zm7 0c0-.068.057-.124.124-.124h3.752c.068 0 .124.057.124.124v3.752a.125.125 0 01-.124.124H7.124A.125.125 0 017 17.876v-3.752zm7 0c0-.068.057-.124.124-.124h3.752c.068 0 .124.057.124.124v3.752a.125.125 0 01-.124.124h-3.752a.125.125 0 01-.124-.124v-3.752z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

TileViewIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(TileViewIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'TileViewIcon';
export default ForwardRef;