import styled from '@emotion/styled/macro';

export const TreeItem = styled.li({
  display: 'flex',
  flexDirection: 'column'
});

TreeItem.defaultProps = {
  role: 'treeitem'
};
