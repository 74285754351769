import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { Input } from '../Input';
import { InputGroupPrepend, InputGroupAppend } from './InputGroupAdornment';

export const InputGroup = styled.div(({ fluid, theme }) => ({
  alignItems: 'stretch',
  display: 'flex',
  flexWrap: 'wrap',
  position: 'relative',
  width: fluid ? '100%' : undefined,
  [`${Input}`]: {
    position: 'relative',
    width: '0%',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto'
  },
  [`${InputGroupPrepend}`]: {
    left: 1,
    [`& + ${Input}`]: {
      paddingLeft:
        themeGet('space.6')({ theme }) + themeGet('space.3')({ theme })
    }
  },
  [`${InputGroupAppend}`]: {
    right: 1
  }
}));
