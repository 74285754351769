import styled from '@emotion/styled/macro';
import { em } from 'polished';

export const ProjectFeedItemHeaderTitle = styled.div({
  fontSize: em(18),
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});
