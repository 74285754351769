import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

const variant = css({
  color: 'rgb(250,250,250)'
});

export const TerminalScreenPrompt = styled.form(
  {
    display: 'flex',
    flexDirection: 'row'
  },
  variant
);
