import styled from '@emotion/styled/macro';
import { em } from 'polished';

export const PanelTabs = styled.div({
  borderRadius: em(3),
  display: 'flex',
  flexBasis: 'auto',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 0,
  overflow: 'hidden'
});
