import gql from 'graphql-tag';

export const UserEmailsQuery = gql`
  query UserEmailsQuery {
    userEmails {
      nodes {
        id
        email
        isVerified
      }
    }
  }
`;
