const TERMINAL_DIRECTORY_PATH = '/etc/terminal';
const TERMINAL_STDIN_FILENAME = '.stdin';
const TERMINAL_STDOUT_FILENAME = '.stdout';
const TERMINAL_DATA_FILENAME = '.data';
const TERMINAL_HISTORY_FILENAME = '.bash_history';
const TERMINAL_PROMPT_FILENAME = '.bash_prompt';

export {
  TERMINAL_STDIN_FILENAME,
  TERMINAL_DATA_FILENAME,
  TERMINAL_DIRECTORY_PATH,
  TERMINAL_HISTORY_FILENAME,
  TERMINAL_STDOUT_FILENAME,
  TERMINAL_PROMPT_FILENAME
};
