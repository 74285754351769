import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ColumnViewIcon = function ColumnViewIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 .106C0 .047.048 0 .106 0h3.216c.059 0 .107.052.107.106v17.788a.107.107 0 01-.107.106H.106A.109.109 0 010 17.894V.106zm7.286 0c0-.059.048-.106.106-.106h3.216c.059 0 .106.052.106.106v17.788a.107.107 0 01-.106.106H7.392a.109.109 0 01-.106-.106V.106zm7.285 0c0-.059.049-.106.107-.106h3.216c.058 0 .106.052.106.106v17.788a.107.107 0 01-.106.106h-3.216a.109.109 0 01-.107-.106V.106z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ColumnViewIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ColumnViewIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ColumnViewIcon';
export default ForwardRef;