import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getOwnerDocument } from '../utils/dom';
import { Box } from '../ui/Box';

export const ClickAwaySensor = ({ onClickAway, children }) => {
  const sensorRef = useRef(null);
  const doc = getOwnerDocument(sensorRef);
  const handleClickAway = useCallback(
    event => {
      if (sensorRef.current && !sensorRef.current.contains(event.target)) {
        onClickAway(event, 'clickaway');
      }
    },
    [onClickAway]
  );

  useEffect(() => {
    doc.addEventListener('mousedown', handleClickAway);
    return () => {
      doc.removeEventListener('mousedown', handleClickAway);
    };
  });

  return (
    <Box ref={sensorRef} margin="0 !important" padding="0 !important">
      {children}
    </Box>
  );
};

ClickAwaySensor.propTypes = {
  // Called when clicked outside of the children
  // optionally called with the originated event and reason
  onClickAway: PropTypes.func
};

ClickAwaySensor.defaultProps = {
  onClickAway: () => {}
};
