import styled from '@emotion/styled/macro';
import { space, layout, flexbox, position, typography } from 'styled-system';
import themeGet from '@styled-system/theme-get';

export const CardBody = styled.div(
  props => ({
    padding: themeGet('space.3')(props)
  }),
  space,
  layout,
  flexbox,
  position,
  typography
);
