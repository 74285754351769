import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var StyleIcon = function StyleIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.678 9.586L2.559 6.533a.619.619 0 01-.145-.613.579.579 0 01.46-.406l4.704-.717L9.682.333A.57.57 0 0110.193 0a.57.57 0 01.512.333l2.103 4.464 4.704.717a.579.579 0 01.46.406.62.62 0 01-.144.613l-3.404 3.475.804 4.906a.614.614 0 01-.227.585.556.556 0 01-.6.046l-4.208-2.317-4.207 2.317a.55.55 0 01-.6-.046c-.176-.133 4.564-4.675 4.6-4.898 0 0-1.894-2.005-2.582-2.663-.048-.046-1.726 1.648-1.726 1.648zm1.714-.665l1.687 1.687a.108.108 0 010 .153l-7.207 7.207a.108.108 0 01-.154 0L.032 16.282a.108.108 0 010-.154l7.207-7.207a.108.108 0 01.153 0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

StyleIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(StyleIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'StyleIcon';
export default ForwardRef;