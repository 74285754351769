import { Button } from './Button';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { em } from 'polished';

export const RoundButton = styled(Button)({
  borderRadius: '50%',
  display: 'inline-flex',
  height: em(48),
  justifyContent: 'center',
  width: em(48)
});

RoundButton.propTypes = {
  fluid: PropTypes.oneOf([false])
};
