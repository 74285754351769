import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ObjectIcon = function ObjectIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M8.955.028a.107.107 0 01.09 0l8.75 3.977a.108.108 0 01.004.194l-8.75 4.396a.107.107 0 01-.097 0L.41 4.198a.108.108 0 01.004-.193L8.955.027zm-.047 1.896l5.143 2.454a.214.214 0 00.285-.102.216.216 0 00-.101-.286L9.092 1.535a.214.214 0 00-.286.102.216.216 0 00.102.287zm-1.714.861l5.143 2.455a.214.214 0 00.285-.102.216.216 0 00-.101-.287L7.378 2.396a.214.214 0 00-.286.102.216.216 0 00.102.287zm-1.715.861l5.143 2.455a.214.214 0 00.286-.102.216.216 0 00-.102-.287L5.663 3.258a.214.214 0 00-.285.102.216.216 0 00.101.286zm-1.714.862l5.143 2.454a.214.214 0 00.286-.102.216.216 0 00-.102-.287L3.949 4.12a.214.214 0 00-.285.102.216.216 0 00.101.287zM0 5.133a.107.107 0 01.155-.096l8.357 4.198c.036.019.06.056.06.097v8.578a.107.107 0 01-.159.094L.056 13.387A.108.108 0 010 13.292V5.133zM1.19 7.52l5.571 2.799c.106.053.235.01.288-.096a.216.216 0 00-.096-.29L1.382 7.136a.214.214 0 00-.288.096.216.216 0 00.096.289zm0 1.722l5.571 2.8a.216.216 0 00.192-.385l-5.571-2.8a.214.214 0 00-.288.096.216.216 0 00.096.29zm0 1.723l2.571 1.292a.216.216 0 00.192-.385L1.382 10.58a.214.214 0 00-.288.096.216.216 0 00.096.289zm8.239-1.633c0-.041.023-.078.059-.097l8.357-4.198a.107.107 0 01.155.096v8.16c0 .038-.021.075-.056.093l-8.357 4.619a.107.107 0 01-.158-.095V9.332zm1.81.987l5.573-2.799a.216.216 0 00.096-.289.214.214 0 00-.287-.096l-5.574 2.799a.216.216 0 00-.096.289c.053.106.182.149.288.096zm0 1.722l3.857-1.937a.216.216 0 00.096-.29.214.214 0 00-.288-.096l-3.857 1.938a.216.216 0 00-.096.289c.053.106.182.15.288.096z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ObjectIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ObjectIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ObjectIcon';
export default ForwardRef;