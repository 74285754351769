import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DateTimeIcon = function DateTimeIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.179.857h7.071a.107.107 0 01-.107-.107V.107c0-.059.048-.107.107-.107h1.5c.06 0 .107.048.107.107V.75c0 .06-.048.107-.107.107h2.571c.06 0 .108.048.108.107v1.072c0 .059-.048.107-.108.107H.107A.107.107 0 010 2.036V.964C0 .905.048.857.107.857H2.68A.107.107 0 012.57.75V.107C2.571.048 2.62 0 2.68 0h1.5c.059 0 .107.048.107.107V.75c0 .06-.048.107-.107.107zM.107 2.571h15.214c.06 0 .108.048.108.107v4.608c-2.337-.806-5.112-.484-6.858 1.285-1.745 1.77-2.24 4.49-1.285 6.858H.107A.107.107 0 010 15.32V2.678c0-.059.048-.107.107-.107zM12.857 18a5.143 5.143 0 110-10.286 5.143 5.143 0 010 10.286zm.429-5.571V8.57h-.857v4.715h3.857v-.857h-3z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DateTimeIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DateTimeIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DateTimeIcon';
export default ForwardRef;