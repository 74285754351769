import { join, dirname } from '../../utils';
const isModule = string => !string.startsWith('/') && !string.startsWith('.');

const getRequireContext = (registry = { require: () => {} }) => {
  const localModules = {};
  const req = modulename =>
    isModule(modulename)
      ? registry.require(modulename)
      : localModules[modulename].exports;

  req.resolve = modulename =>
    isModule(modulename) ? registry.require.resolve(modulename) : modulename;

  return {
    register: (modulename, amdFn) => {
      localModules[modulename] = { exports: {} };
      const _module = localModules[modulename];
      const _exports = localModules[modulename].exports;
      amdFn(_module, _exports, path => {
        if (isModule(path)) {
          return registry.require(path);
        }
        const reqModule = join(dirname(modulename), path);
        return localModules[reqModule].exports;
      });
    },
    require: req
  };
};

export { getRequireContext };
