import React, { useCallback } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { uniqueId } from '../../../utils/uniqueId';
import { ACTIONS } from '../reducer';

const Wrapper = styled.div({
  width: '100%',
  height: '100%',
  position: 'relative',
  cursor: 'copy'
});

const Svg = styled.svg({
  position: 'absolute'
});

export const GradientBand = styled(
  ({ stops, gradientRect, dispatch, className }) => {
    const gradientId = uniqueId();
    const gradientUrl = `url(#${gradientId})`;
    const sortedStops = stops.sort((a, b) => a.offset - b.offset);

    const handleMouseDown = useCallback(
      event => {
        const addColorStop = offset => {
          dispatch({ type: ACTIONS.addStop, offset });
        };
        // Add another color stop on left/primary click
        if (event.button !== 0) return;
        const offset =
          (event.clientX - event.target.getBoundingClientRect().left) /
          gradientRect.width;
        addColorStop(offset);
      },
      [dispatch, gradientRect.width]
    );

    return (
      <Wrapper className={className} onMouseDown={handleMouseDown}>
        <Svg width="100%" height="100%">
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0.5" x2="1" y2="0.5">
              {' '}
              {sortedStops.map(c => (
                <stop
                  key={c.id}
                  offset={c.offset}
                  style={{ stopColor: c.color, stopOpacity: 1 }}
                />
              ))}
            </linearGradient>
          </defs>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            rx="3"
            ry="3"
            fill={gradientUrl}
          />
        </Svg>
      </Wrapper>
    );
  }
)();

GradientBand.propTypes = {
  stops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      offset: PropTypes.number,
      color: PropTypes.string
    })
  )
};
