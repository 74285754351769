import React, { useState, memo } from 'react';

import { AuthLayout } from '../../layouts/AuthLayout';
import { propsAreEqual } from '../../utils/react';

import { AgreeToTerms } from './AgreeToTerms';
import { ProfileIsComplete } from './ProfileIsComplete';
import { CreateDisplayName } from './CreateDisplayName';
import { CreateUsername } from './CreateUsername';
import { EnterInviteCode } from './EnterInviteCode';
import { VerifyEmail } from './VerifyEmail';
import { SetPassword } from './SetPassword';
import { useQuery } from '@apollo/react-hooks';
import { Query } from '@apollo/react-components';

import {
  TasksRequiredForQuery,
  CompleteProfileTasksQuery
} from '../../queries';

import { AuthLoader } from '../../ui';

const _HASH_ = {
  accept_terms: AgreeToTerms,
  set_password: SetPassword,
  verify_email: VerifyEmail,
  create_display_name: CreateDisplayName,
  create_username: CreateUsername,
  invite_code: EnterInviteCode
};

const ProfileCompleteWizard = memo(function ProfileCompleteWizard(props) {
  const { tasks } = props;
  const [step, setStep] = useState(0);
  const { data, loading, error } = useQuery(CompleteProfileTasksQuery);
  if (loading) return <AuthLoader />;
  if (error) return <div>Error querying tasks.</div>;

  const totalSteps =
    data.userAchievements.edges[0].node.achievementUserTasks.edges.length;

  const needToComplete = tasks.reduce((m, v) => {
    return m.concat(v.name);
  }, []);

  const all = data.userAchievements.edges[0].node.achievementUserTasks.edges.reduce(
    (m, v) => {
      return m.concat(v.node.name);
    },
    []
  );

  const completedMap = all.reduce(
    (m, v, i) => {
      let current = m.current;
      if (current < 0 && needToComplete.includes(v)) {
        current = i;
      }
      return {
        current,
        completed: m.completed.concat(!needToComplete.includes(v))
      };
    },
    { current: -1, completed: [] }
  );

  if (tasks.length && step < tasks.length) {
    const currentTask = tasks[step].name;
    if (_HASH_.hasOwnProperty(currentTask)) {
      return React.createElement(_HASH_[currentTask], {
        step,
        setStep,
        completedMap,
        totalSteps
      });
    }
    return <div>Cannot find task {currentTask}. Please contact support.</div>;
  } else {
    return <ProfileIsComplete />;
  }
}, propsAreEqual);

export const CompleteProfile = memo(function CompleteProfile(props) {
  return (
    <AuthLayout>
      <Query
        query={TasksRequiredForQuery}
        variables={{ achievement: 'profile_complete' }}
      >
        {({ loading, error, data }) => {
          if (loading) return <AuthLoader />;
          if (error) return <div>Error querying profile info.</div>;
          return (
            data &&
            data.tasksRequiredFor && (
              <ProfileCompleteWizard
                tasks={data.tasksRequiredFor.nodes}
                {...props}
              />
            )
          );
        }}
      </Query>
    </AuthLayout>
  );
}, propsAreEqual);
