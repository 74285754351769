import gql from 'graphql-tag';
import { ProjectFeedProjectFragment } from '../fragments';

export const ProjectsQuery = gql`
  query ProjectsQuery($first: Int, $offset: Int, $orderBy: [ProjectsOrderBy!]) {
    projects(first: $first, offset: $offset, orderBy: $orderBy) {
      edges {
        cursor
        node {
          ...ProjectFeedProjectFragment
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${ProjectFeedProjectFragment}
`;
