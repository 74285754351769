import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { em } from 'polished';
import themeGet from '@styled-system/theme-get';

const variant = props => ({
  backgroundColor: themeGet('colors.background.default')(props),
  borderColor: themeGet('colors.border')(props),
  boxShadow: `rgba(0,0,0,0.15) ${em(6)} 0 ${em(6)}`
});

export const TileTabsList = styled.div(
  {
    borderStyle: 'solid',
    borderWidth: 18 / 16,
    boxSizing: 'border-box',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    flexDirection: 'column',
    position: 'relative',
    width: em(120)
  },
  variant
);

TileTabsList.defaultProps = {
  role: 'tablist'
};

TileTabsList.propTypes = {
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string
};
