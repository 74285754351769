import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var TabletIcon = function TabletIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2 16.125c0-.069.056-.125.121-.125h13.758c.067 0 .121.058.121.125v1.75c0 .069-.056.125-.121.125H2.121A.124.124 0 012 17.875v-1.75zM9 17.5a.5.5 0 100-1 .5.5 0 000 1zM2 0h14v15H2V0zm1 1.129V3.87c0 .077.055.129.123.129H4V2.125C4 2.058 4.056 2 4.125 2H6v-.877A.125.125 0 005.871 1H3.13A.125.125 0 003 1.129z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

TabletIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(TabletIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'TabletIcon';
export default ForwardRef;