import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PropertiesIcon = function PropertiesIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M12.214 12.96c1.638 0 3.123.884 4.219 2.321.09.118.09.282 0 .4C15.337 17.116 13.852 18 12.214 18c-1.637 0-3.122-.884-4.218-2.321a.331.331 0 010-.4c1.096-1.435 2.58-2.319 4.218-2.319zm.117 4.316a1.803 1.803 0 001.665-1.679 1.794 1.794 0 00-1.898-1.913c-.89.056-1.61.782-1.666 1.679a1.794 1.794 0 001.899 1.913zM15.07.09v10.62c0 .05-.04.09-.089.09h-.893a.09.09 0 01-.089-.09V1.08H4.446a.09.09 0 01-.089-.09v-.9c0-.05.04-.09.09-.09h10.535c.05 0 .09.04.09.09zM3.018 1.44h10.536c.049 0 .089.04.089.09v10.62c0 .05-.04.09-.09.09h-.892a.09.09 0 01-.09-.09V2.52H3.018a.09.09 0 01-.09-.09v-.9c0-.05.04-.09.09-.09zm9.265 15.118a1.074 1.074 0 01-1.138-1.147 1.08 1.08 0 011-1.009 1.074 1.074 0 011.138 1.147 1.08 1.08 0 01-1 1.009zM1.5 2.97a.09.09 0 01.093-.091h10.529c.05 0 .092.043.092.09v9.63c-2.043-.118-3.717.96-4.643 2.52H1.586c-.048 0-.086-.04-.086-.091V2.97zm1.071 1.797v.544c0 .05.04.088.09.088h4.107c.052 0 .09-.04.09-.088v-.544c0-.05-.04-.088-.09-.088H2.66c-.053 0-.09.04-.09.088zm0 1.44v.544c0 .05.04.088.09.088h8.391c.053 0 .09-.04.09-.088v-.544c0-.05-.04-.088-.09-.088h-8.39c-.052 0-.09.04-.09.088zm0 1.44v.544c0 .05.04.088.089.088h3.395c.048 0 .088-.04.088-.088v-.544c0-.05-.04-.088-.088-.088H2.66a.089.089 0 00-.089.088zm4.286 0v.544c0 .05.04.088.09.088h4.106c.053 0 .09-.04.09-.088v-.544c0-.05-.04-.088-.09-.088H6.947c-.053 0-.09.04-.09.088zm-4.286 1.44v.544c0 .05.04.088.09.088h4.107c.052 0 .09-.04.09-.088v-.544c0-.05-.04-.088-.09-.088H2.66c-.053 0-.09.04-.09.088zm0 1.44v.544c0 .05.04.088.089.088h5.18c.049 0 .089-.04.089-.088v-.544c0-.05-.04-.088-.089-.088H2.66a.089.089 0 00-.089.088zm6.072 0v.544c0 .05.04.088.089.088h2.322c.045 0 .089-.04.089-.088v-.544c0-.05-.04-.088-.09-.088H8.733a.091.091 0 00-.09.088zm-6.072 1.44v.544c0 .05.04.088.09.088H5.34c.048 0 .089-.04.089-.088v-.544c0-.05-.04-.088-.09-.088H2.66a.089.089 0 00-.089.088zm3.572 0v.544c0 .05.04.088.09.088h1.25a.09.09 0 00.088-.088v-.544c0-.05-.04-.088-.089-.088h-1.25a.09.09 0 00-.09.088z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PropertiesIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PropertiesIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PropertiesIcon';
export default ForwardRef;