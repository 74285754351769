import styled from '@emotion/styled';
import { compose, layout, space } from 'styled-system';
import css from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';

export const Link = styled('a', { shouldForwardProp })(
  css({
    display: 'inline-block',
    textDecoration: 'none'
  }),
  compose(
    layout,
    space
  )
);

export const ExternalLink = props => {
  return <Link target="_blank" rel="noopener noreferrer" {...props} />;
};
