import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { margin, padding } from 'polished';
import { TabsNavListItem } from './TabsNavListItem';

const variant = css({
  color: 'rgb(180,180,180)',
  [`${TabsNavListItem}:hover > &`]: {
    color: 'rgb(250,250,250)'
  }
});

export const TabsNavListItemButton = styled.button(
  {
    ...margin(0),
    ...padding(0),
    appearance: 'none',
    backgroundColor: 'transparent',
    borderWidth: 0,
    cursor: 'pointer',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    textDecoration: 'none',
    lineHeight: 18 / 16,
    opacity: 0,
    [`${TabsNavListItem}:hover > &, ${TabsNavListItem}[aria-selected="true"] > &`]: {
      opacity: 1
    }
  },
  variant
);
