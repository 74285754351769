import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AlignCenterIcon = function AlignCenterIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M3 .327C3 .147 3.152 0 3.333 0h11.334c.184 0 .333.152.333.327v3.346c0 .18-.152.327-.333.327H3.333A.334.334 0 013 3.673V.327zm-3 7C0 7.147.135 7 .297 7h17.406c.164 0 .297.152.297.327v3.346c0 .18-.135.327-.297.327H.297C.133 11 0 10.848 0 10.673V7.327zm3 7c0-.18.152-.327.333-.327h11.334c.184 0 .333.152.333.327v3.346c0 .18-.152.327-.333.327H3.333A.334.334 0 013 17.673v-3.346z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AlignCenterIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AlignCenterIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AlignCenterIcon';
export default ForwardRef;