import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { transparentize, transitions, lighten } from 'polished';
import { space, layout, flexbox, color, shadow } from 'styled-system';
import themeGet from '@styled-system/theme-get';
import systemTypes from '@styled-system/prop-types';
import { Text } from '../Text';
import { CardBody } from './CardBody';
import { CardCover } from './CardCover';
import { CardAction } from './CardAction';

const elevation = props => {
  switch (props.elevation) {
    case 0:
      return {
        boxShadow: 'none'
      };
    case 1:
      return {
        boxShadow: `0px 2px 8px 1px
          ${transparentize(0.6, themeGet('colors.black')(props))}`
      };
    case 2:
      return {
        boxShadow: `0px 4px 10px 2px
          ${transparentize(0.6, themeGet('colors.black')(props))}`
      };
    case 3:
      return {
        boxShadow: `0px 6px 12px 3px
          ${transparentize(0.6, themeGet('colors.black')(props))}`
      };
    case 4:
      return {
        boxShadow: `0px 8px 14px 4px ${transparentize(
          0.6,
          themeGet('colors.black')(props)
        )};`
      };
    default:
      return {
        boxShadow: 'none'
      };
  }
};

const interactive = props =>
  props.interactive && {
    ...transitions(
      ['transform', 'box-shadow', 'background-color'],
      '.2s cubic-bezier(.4,1,.75,.9)'
    ),
    cursor: 'pointer',
    '&:hover': {
      borderColor:
        props.theme.mode === 'dark'
          ? transparentize(0.2, themeGet('colors.black')(props))
          : transparentize(0.8, themeGet('colors.black')(props)),
      backgroundColor:
        props.theme.mode === 'dark'
          ? themeGet('colors.gray.4')(props)
          : transparentize(0.96, themeGet('colors.black')(props))
    },
    "&:active, &[aria-pressed='true']": {
      backgroundColor:
        props.theme.mode === 'dark'
          ? transparentize(0.5, themeGet('colors.blue.1')(props))
          : 'rgb(235, 247, 255)',
      boxShadow:
        props.theme.mode === 'dark'
          ? `0px 6px 12px 3px ${transparentize(
              0.6,
              themeGet('colors.black')(props)
            )}`
          : 'none'
    },
    '&:focus': {
      borderColor:
        props.theme.mode === 'dark'
          ? transparentize(0.2, themeGet('colors.black')(props))
          : transparentize(0.8, themeGet('colors.black')(props)),
      backgroundColor: transparentize(0.97, themeGet('colors.white')(props))
    },
    "&:disabled, &[aria-disabled='true']": {
      borderColor:
        props.theme.mode === 'light' ? 'rgba(128, 128, 128, 0.2) ' : undefined,
      backgroundColor: transparentize(1, themeGet('colors.black')(props))
    }
  };

const textVariant = props => {
  const mode = props.theme.mode;
  const base = {
    light: {
      color: 'rgba(128, 128, 128, 1)'
    },
    dark: {
      color: 'rgba(186, 186, 186, 1)'
    }
  };
  const interactive = {
    "&:hover:not([aria-disabled='true'])": {
      [`${CardBody} ${Text}`]: {
        fontWeight: 600
      }
    },
    '&:hover, &:focus': {
      [`${CardBody} ${Text}`]: {
        color:
          mode === 'dark'
            ? themeGet('colors.white')(props)
            : 'rgba(77, 77, 77, 1)'
      }
    },
    "&:active, &[aria-pressed='true']": {
      [`${CardBody} ${Text}`]: {
        color:
          mode === 'dark'
            ? themeGet('colors.white')(props)
            : themeGet('colors.blue.1')(props)
      }
    },
    "&:disabled, &[aria-disabled='true']": {
      cursor: 'not-allowed',
      [`${CardBody} ${Text}`]: {
        color: transparentize(0.7, base.dark.color)
      }
    }
  };

  return {
    [`${CardBody} ${Text}`]: {
      ...base[props.theme.mode]
    },
    ...(props.interactive ? interactive : {})
  };
};

const cardAction = props => ({
  [`&:hover ${CardAction}`]: {
    opacity: '1',
    pointerEvents: 'auto',
    color:
      props.theme.mode === 'dark'
        ? themeGet('colors.white')(props)
        : 'rgba(77, 77, 77, 1)'
  },
  [`&:active, &[aria-pressed='true'] ${CardAction}`]: {
    color:
      props.theme.mode === 'dark'
        ? themeGet('colors.white')(props)
        : themeGet('colors.blue.1')(props)
  },
  [`&:disabled, &[aria-disabled='true'] ${CardAction}`]: {
    opacity: '0'
  }
});

export const Card = styled.div(
  props => {
    const light = {
      backgroundColor: themeGet('colors.white')(props),
      borderColor: lighten(0.8, themeGet('colors.black')(props)),
      [`${CardCover}`]: {
        backgroundColor: 'rgb(235, 235, 235)'
      }
    };
    const dark = {
      backgroundColor: themeGet('colors.background.default')(props),
      borderColor: transparentize(0.5, themeGet('colors.black')(props)),
      [`${CardCover}`]: {
        backgroundColor: themeGet('colors.gray.5')(props)
      }
    };
    return {
      borderRadius: themeGet('radii.1')(props) + 1,
      borderStyle: 'solid',
      borderWidth: 0.5,
      ...{ light, dark }[props.theme.mode]
    };
  },
  elevation,
  interactive,
  textVariant,
  cardAction,
  space,
  layout,
  flexbox,
  color,
  shadow
);

Card.propType = {
  ...systemTypes.space,
  ...systemTypes.layout,
  ...systemTypes.flexbox,
  elevation: PropTypes.number,
  interactive: PropTypes.bool
};

Card.defaultProps = {
  elevation: 0,
  interactive: false
};
