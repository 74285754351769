import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PlusIcon = function PlusIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M10.5 7.5h7.25a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H10.5v7.25a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V10.5H.25a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25H7.5V.25A.25.25 0 017.75 0h2.5a.25.25 0 01.25.25V7.5z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PlusIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PlusIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PlusIcon';
export default ForwardRef;