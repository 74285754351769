import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ShareIcon = function ShareIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M15.75 13.5a2.25 2.25 0 11-2.095 1.427l-9.408-4.89a2.25 2.25 0 110-2.075l9.408-4.89a2.25 2.25 0 11.718.957L4.812 9.001l9.563 4.97a2.24 2.24 0 011.376-.47z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ShareIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ShareIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ShareIcon';
export default ForwardRef;