import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { em } from 'polished';

export const WindowSplitterSeparator = styled.div(props => ({
  alignContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgb(50,50,50)',
  borderColor: 'rgba(0,0,0,0.8)',
  borderStyle: 'solid',
  borderWidth: 1.5,
  boxSizing: 'border-box',
  color: 'rgb(90,90,90)',
  cursor:
    props.direction === 'column'
      ? 'row-resize'
      : props.direction === 'row'
      ? 'col-resize'
      : undefined,
  display: 'flex',
  flexBasis: em(3),
  flexGrow: 0,
  flexShrink: 0,
  justifyContent: 'center',
  overflow: 'visible',
  '&::before, &::after': {
    content: '""',
    display: 'flex',
    flexBasis: em(2.75),
    flexGrow: 0,
    flexShrink: 0
  }
}));

WindowSplitterSeparator.propTypes = {
  direction: PropTypes.oneOf(['row', 'column']).isRequired
};
