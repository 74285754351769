import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var EyeIcon = function EyeIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 4C5.56 4 2.442 5.754.14 8.602a.628.628 0 000 .793C2.443 12.246 5.56 14 9 14c3.44 0 6.558-1.754 8.86-4.602a.628.628 0 000-.793C15.557 5.754 12.44 4 9 4zm.26 8.992a4.003 4.003 0 01-4.252-4.253A4.01 4.01 0 018.74 5.01a4.003 4.003 0 014.253 4.252 4.022 4.022 0 01-3.731 3.73zm-.068-.998a2.995 2.995 0 01-3.185-3.186 3.012 3.012 0 012.8-2.802 2.995 2.995 0 013.186 3.186 3.012 3.012 0 01-2.8 2.802z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

EyeIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(EyeIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'EyeIcon';
export default ForwardRef;