import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var TelephoneIcon = function TelephoneIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 4.111c-3.013-.033-4.693.944-5.143 4H0C0 2.236 4.55 1 9 1s9 1.236 9 7.111h-3.857c-.439-3.106-2.105-3.966-5.143-4zM1.929 9.666C.864 9.666 0 9.742 0 8.333h3.857c0 .17-.013.315-.037.444-.173.947-.954.889-1.891.889zm12.214-1.333H18c0 1.41-.863 1.333-1.929 1.333-.93 0-1.705.057-1.888-.865a2.424 2.424 0 01-.04-.468zm-1.375-1.738v1.03c.9.958 3.84 5.034 3.946 5.513v3.31a.546.546 0 01-.538.552H1.826a.546.546 0 01-.539-.552l-.001-3.294c.107-.493 3.049-4.57 3.948-5.528V6.595c0-.246.276-.262.617-.262h.559c.34 0 .617.016.617.262v.842h3.947v-.842c0-.246.277-.262.618-.262h.558c.341 0 .618.016.618.262zm-5.935 7.003a.53.53 0 00.523-.537.53.53 0 00-.523-.536.53.53 0 00-.523.536.53.53 0 00.523.537zm0-1.84a.53.53 0 00.523-.536.53.53 0 00-.523-.536.53.53 0 00-.523.536.53.53 0 00.523.536zm0-1.838a.53.53 0 00.523-.537.53.53 0 00-.523-.536.53.53 0 00-.523.536.53.53 0 00.523.537zm2.153 3.678a.53.53 0 00.523-.537.53.53 0 00-.523-.536.53.53 0 00-.523.536.53.53 0 00.523.537zm0-1.84a.53.53 0 00.523-.536.53.53 0 00-.523-.536.53.53 0 00-.523.536.53.53 0 00.523.536zm0-1.838a.53.53 0 00.523-.537.53.53 0 00-.523-.536.53.53 0 00-.523.536.53.53 0 00.523.537zm2.152 3.678a.53.53 0 00.523-.537.53.53 0 00-.523-.536.53.53 0 00-.523.536.53.53 0 00.523.537zm0-1.84a.53.53 0 00.523-.536.53.53 0 00-.523-.536.53.53 0 00-.523.536.53.53 0 00.523.536zm0-1.838a.53.53 0 00.523-.537.53.53 0 00-.523-.536.53.53 0 00-.523.536.53.53 0 00.523.537z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

TelephoneIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(TelephoneIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'TelephoneIcon';
export default ForwardRef;