import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ImageIcon = function ImageIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zM4.5 6.429a1.929 1.929 0 100-3.858 1.929 1.929 0 000 3.858zM1.286 16.714h15.428L11.141 5.571 6.43 13.286l-1.715-2.572-3.428 6z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ImageIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ImageIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ImageIcon';
export default ForwardRef;