import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CircleChangeIcon = function CircleChangeIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 18A9 9 0 119 0a9 9 0 010 18zm2.25-3.375a3.375 3.375 0 110-6.75 3.375 3.375 0 010 6.75zm0 1.125a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM3.375 4.5v6.469c0 .155.126.281.281.281H4.5V4.5H3.375zm6.75 0v1.125h1.125V4.5h-1.125zM4.5 10.125v1.125h1.125v-1.125H4.5zm-.844-6.75a.281.281 0 00-.281.281V4.5h7.875v-.844a.281.281 0 00-.281-.281H3.656z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CircleChangeIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CircleChangeIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CircleChangeIcon';
export default ForwardRef;