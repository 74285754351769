import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Menu } from '../../ui';
import { Popup } from '../../components/Popup';

export const ListItemActions = ({ children, PopupProps, label, id }) => {
  return (
    <Popup
      popup={
        <Menu id={id} aria-label={`${label}-menu`}>
          {children}
        </Menu>
      }
      placement="bottom-end"
      closeOnClickAway
      {...PopupProps}
    >
      {({ setExpanded }) => (
        <Button
          aria-label="More"
          aria-controls={id}
          aria-haspopup="true"
          border="none"
          boxShadow="none"
          backgroundColor="transparent"
          px="1"
          py="0"
          onClick={() => setExpanded(true)}
        >
          <Icon
            name="more"
            title="Open action menu"
            size={16}
            role="presentation"
          />
        </Button>
      )}
    </Popup>
  );
};

ListItemActions.propTypes = {
  children: PropTypes.oneOfType(
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  )
};
