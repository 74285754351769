import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';
import { base } from '../ui/Theme/modes/base';
import { ClickAwaySensor } from './ClickAwaySensor';
import { GenericPortal } from './GenericPortal';

export const Popup = props => {
  const isControlled = typeof props.expanded !== 'undefined';
  const [expandedState, setExpandedState] = useState(props.defaultExpanded);

  const expanded = isControlled ? props.expanded : expandedState;
  const setExpanded = isControlled ? props.onExpanded : setExpandedState;
  const child =
    typeof props.children === 'function'
      ? React.Children.only(props.children({ expanded, setExpanded }))
      : React.Children.only(props.children);
  const popup =
    typeof props.popup === 'function'
      ? props.popup({ expanded, setExpanded })
      : React.Children.only(props.popup);

  return (
    <Manager>
      <Reference>
        {({ ref }) =>
          React.cloneElement(child, {
            ref,
            'aria-haspopup': true,
            'aria-expanded': expanded
          })
        }
      </Reference>
      {expanded && (
        <Popper
          placement={props.placement}
          modifiers={props.modifiers}
          {...props.PopperProps}
        >
          {({ ref, style, placement }) => {
            const popupNode = React.cloneElement(popup, {
              ref,
              style: Object.assign(
                {
                  zIndex: base.zIndex.tooltip
                },
                style,
                popup.props.style
              ),
              'data-placement': placement
            });
            return props.closeOnClickAway ? (
              <GenericPortal open={expanded}>
                <ClickAwaySensor onClickAway={() => setExpanded(false)}>
                  {popupNode}
                </ClickAwaySensor>
              </GenericPortal>
            ) : (
              <GenericPortal open={expanded}>{popupNode}</GenericPortal>
            );
          }}
        </Popper>
      )}
    </Manager>
  );
};

Popup.defaultProps = {
  defaultExpanded: false,
  closeOnClickAway: false,
  PopperProps: {},
  onChange: () => undefined,
  onExpanded: () => undefined,
  onClick: () => undefined
};

Popup.propTypes = {
  closeOnClickAway: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  popup: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  PopperProps: PropTypes.object
};
