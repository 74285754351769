import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var H4Icon = function H4Icon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm8.137 6.086v3.096H4.685V6.086h-.977v7.2h.977v-3.158h3.559v3.158h.977v-7.2h-.977zm6.655 5.02h-.761v-5.04H13.13l-3.014 5.265v.7h3.055v1.255h.967V12.03h.76v-.926zm-3.6 0L13.17 7.69v3.415h-1.872z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

H4Icon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(H4Icon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'H4Icon';
export default ForwardRef;