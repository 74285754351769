import styled from '@emotion/styled/macro';
import { Text } from '../Text';

export const AuthParagraph = styled(Text)({
  textAlign: 'center',
  fontWeight: 400,
  '& > a': {
    color: 'inherit'
  }
});

AuthParagraph.defaultProps = {
  as: 'p'
};
