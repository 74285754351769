import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AddFolderIcon = function AddFolderIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 1.988c0-.055.051-.1.107-.1h11.286c.06 0 .14-.032.179-.071l.856-.856a.297.297 0 01.183-.072h4.278a.3.3 0 01.183.072l.856.856c.04.04.072.115.072.171v.9H0v-.9zm0 1.9h18v13H.106c-.059 0-.106-.046-.106-.111V3.889zm10 5V6.016a.122.122 0 00-.125-.126h-1.75A.126.126 0 008 6.015v2.874H5.126A.122.122 0 005 9.014v1.75c0 .067.056.125.126.125H8v2.874c0 .075.056.126.125.126h1.75a.126.126 0 00.125-.126V10.89h2.874a.122.122 0 00.126-.125v-1.75a.126.126 0 00-.126-.125H10z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AddFolderIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AddFolderIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AddFolderIcon';
export default ForwardRef;