import styled from '@emotion/styled/macro';
import { Icon } from '../Icon';
import { em } from 'polished';

export const ProjectCardHeaderActions = styled.div({
  display: 'flex',
  alignItems: 'center',
  [`${Icon}`]: {
    marginLeft: em(12),
    cursor: 'pointer'
  }
});
