const AIRPAGE_DATA_FILENAME = 'data.json';
const AIRPAGE_DIRECTORY_NAME = '.airpage';
const AIRPAGE_EDGES_FILENAME = 'edges.json';
const AIRPAGE_GRAPH_FILENAME = 'graph.json';
const AIRPAGE_GROUP_FILENAME = 'groups.json';
const AIRPAGE_META_FILENAME = 'meta.json';
const AIRPAGE_NODE_EXTENSION = '.json';

export {
  AIRPAGE_DATA_FILENAME,
  AIRPAGE_DIRECTORY_NAME,
  AIRPAGE_EDGES_FILENAME,
  AIRPAGE_GRAPH_FILENAME,
  AIRPAGE_GROUP_FILENAME,
  AIRPAGE_META_FILENAME,
  AIRPAGE_NODE_EXTENSION
};
