import React, { memo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CurrentRoleQuery } from '../../queries';

import {
  AuthForm,
  AuthHeading,
  AuthParagraph,
  Button,
  Field,
  Input,
  Label,
  Loader
} from '../../ui';
import { propsAreEqual } from '../../utils/react';
import { UpdateRoleMutation } from '../../mutations';

const CreateUsername = memo(function CreateUsername({ step, setStep }) {
  const [disabled, setDisabled] = useState(true);
  const [username, setUsername] = useState(false);
  const [updateRole] = useMutation(UpdateRoleMutation, {
    onCompleted: () => {
      setStep(step + 1);
    }
  });

  const curRoleResult = useQuery(CurrentRoleQuery);
  const currentRole =
    curRoleResult && curRoleResult.data && curRoleResult.data.currentRole;

  if (!currentRole) {
    return (
      <AuthForm>
        <Loader />
      </AuthForm>
    );
  }

  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        console.log({ username });

        updateRole({
          variables: {
            id: currentRole.id,
            patch: {
              username
            }
          }
        })
          .then(data => {
            console.log('something good happened');
            console.log(data);
          })
          .catch(() => {
            console.error('something bad happened');
          });
      }}
    >
      <AuthHeading>Set Up URL</AuthHeading>
      <AuthParagraph>What URL would you like?</AuthParagraph>
      <Field fluid={true}>
        <Label htmlFor="username" id="username-label">
          Username
        </Label>
        <Input
          aria-labelledby="username-label"
          fluid={true}
          id="username"
          name="username"
          type="input"
          placeholder="username"
          onChange={event => {
            event.preventDefault();
            const value = event.currentTarget.value;
            if (value && value.trim().length >= 3) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
            setUsername(value);
          }}
        />
      </Field>
      <Field fluid={true}>
        <Label>Your Profile URL</Label>
        <AuthParagraph>
          {`${process.env.REACT_APP_WEBSITE_URL}/@${username || 'username'}`}
        </AuthParagraph>
      </Field>
      <Button fluid={true} disabled={disabled} type="submit" variant="primary">
        Choose URL
      </Button>
    </AuthForm>
  );
}, propsAreEqual);

export { CreateUsername };
