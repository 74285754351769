import styled from '@emotion/styled/macro';
import { Card } from '../Card';

export const ProjectCard = styled(Card)({
  transform: 'translate(0,0)',
  position: 'absolute',
  top: 0,
  right: 0,
  width: 600,
  bottom: 0,
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out'
});

ProjectCard.defaultProps = {
  role: 'dialog',
  'aria-modal': true,
  tabIndex: 0
};
