import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { borderRadius, em, margin, mix, padding } from 'polished';
import { TabsNavList } from './TabsNavList';

const variant = css({
  backgroundColor: 'transparent',
  borderColor: 'rgba(0,0,0,0.8)',
  color: 'rgb(180,180,180)',
  outline: 0,

  '&:hover, &:focus': {
    backgroundColor: mix(0.15, 'rgb(255,255,255)', 'rgb(50,50,50)'),
    borderColor: 'rgba(0,0,0,0.8)',
    color: 'rgb(250,250,250)',
    boxShadow: 'rgb(0,0,0) 0 -1px 1px 1px'
  },

  '&:active, &[aria-selected="true"]': {
    backgroundColor: mix(0.15, 'rgb(0,0,0)', 'rgb(50,50,50)'),
    color: 'rgb(250,250,250)',
    boxShadow: 'rgb(0,0,0) 0 -1px 1px 1px'
  },

  '&[aria-selected="true"]::before': {
    borderLeftColor: 'rgb(186, 186, 186)'
  },

  [`${TabsNavList}[aria-activedescendant] > &[aria-selected="true"]::before`]: {
    borderLeftColor: 'rgb(0,128,255)'
  }
});

export const TabsNavListItem = styled.li(
  {
    ...borderRadius('top', em(3)),
    ...margin(0),
    ...padding(em(12 - 0.5)),
    appearance: 'none',
    backgroundColor: 'transparent',
    borderWidth: 0,
    cursor: 'pointer',
    display: 'flex',
    flexBasis: em(228),
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 18 / 16,
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&[draggable="true"]': {
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing'
      }
    },
    '&[aria-selected="true"]::before': {
      borderLeftStyle: 'solid',
      borderLeftWidth: 3,
      borderTopLeftRadius: em(3),
      bottom: 0.5,
      content: '""',
      left: 0.5,
      position: 'absolute',
      top: 0.5
    }
  },
  variant
);
