import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var JustifyLeftIcon = function JustifyLeftIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.25 0h17.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H.25A.25.25 0 010 3.75V.25A.25.25 0 01.25 0zm0 7h17.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25v-3.5A.25.25 0 01.25 7zm0 7h9.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25v-3.5A.25.25 0 01.25 14z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

JustifyLeftIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(JustifyLeftIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'JustifyLeftIcon';
export default ForwardRef;