import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SecurityIcon = function SecurityIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M17 3c0 5.039.508 12.069-8 15C.492 15.069 1 8.039 1 3c3.09 0 5.998-1.065 8-3 2.002 1.935 4.91 3 8 3zM8.994 14a5 5 0 100-10 5 5 0 000 10zM9 13a4 4 0 110-8 4 4 0 010 8zm-.677-2.184a.25.25 0 00.358 0l2.65-2.72a.25.25 0 000-.35l-.548-.562a.25.25 0 00-.358 0L8.502 9.159l-.926-.952a.25.25 0 00-.359 0l-.547.562a.25.25 0 000 .349l1.653 1.698z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SecurityIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SecurityIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SecurityIcon';
export default ForwardRef;