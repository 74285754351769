import injector from '../../injector';
import { GIT_DIRECTORY_PATH, REPOS_DIRECTORY_PATH } from './constants';
import {
  _getSelectedProjectPath,
  getProjectPath,
  getSelectedProjectId
} from './queries';
import { appendTerminalOutput } from '../terminal/commands';
import { mkdirpSync } from '../../utils';
const { inject } = injector;

const setSelectedProject = inject(['fs'], function setSelectedProject(projId) {
  const { fs } = this;
  const fullPath = _getSelectedProjectPath();
  const projectPath = getProjectPath(projId);

  if (!fs.existsSync(projectPath)) {
    // empty, wait until iso-morphic git has support for empty repos...
    mkdirpSync(projectPath);
  }

  fs.writeFileSync(fullPath, projId);
  return [projectPath];
});

async function init({ gitdir }) {
  const { git } = this;
  await git.init({ gitdir });
}

const gitInit = inject(['git'], init);
gitInit.description =
  'Create an empty Git repository or reinitialize an existing one';
gitInit.args = [
  {
    _: true,
    name: 'gitdir',
    type: 'path',
    required: true
  }
];

async function add({ gitdir, dir, filepath }) {
  const { git, path } = this;
  filepath = path.relative(dir, filepath);
  await git.add({ gitdir, dir, filepath });
}

const gitAdd = inject(['git', 'path'], add);
gitAdd.description = 'Add file contents to the index';
gitAdd.args = [
  {
    name: 'gitdir',
    type: 'path',
    description: 'git directory path',
    required: true
  },
  {
    name: 'dir',
    type: 'path',
    description: 'project path',
    required: true
  },
  {
    _: true,
    name: 'filepath',
    type: 'path',
    description: 'filepath to add',
    required: true
  }
];

async function commit({ gitdir, dir, message }) {
  const { git } = this;
  let author = {
    name: 'Mr. Test',
    email: 'mrtest@example.com',
    timestamp: 1262356920,
    timezoneOffset: -0
  };
  await git.commit({ gitdir, dir, message, author });
  return appendTerminalOutput(JSON.stringify({ message }));
}

const gitCommit = inject(['git'], commit);
gitCommit.description = 'Record changes to the project';
gitCommit.args = [
  {
    name: 'gitdir',
    type: 'path',
    description: 'git directory path',
    required: true
  },
  {
    name: 'dir',
    type: 'path',
    description: 'project path',
    required: true
  },
  {
    _: true,
    name: 'message',
    type: 'string',
    description: 'message',
    required: true
  }
];

async function tree({ gitdir, dir, filepath }) {
  const { git, path } = this;
  filepath = path.relative(dir, filepath);
  let sha = await git.resolveRef({ dir, gitdir, ref: 'HEAD' });
  let commitObj = await git.readObject({
    dir,
    gitdir,
    oid: sha,
    format: 'parsed'
  });
  appendTerminalOutput(JSON.stringify(commitObj, null, 2));

  let treeObj = await git.readObject({
    dir,
    gitdir,
    oid: commitObj.object.tree,
    format: 'parsed'
  });
  return appendTerminalOutput(JSON.stringify(treeObj, null, 2));
}

const checkTree = inject(['git', 'path'], tree);
checkTree.description = 'Add file contents to the index';
checkTree.args = [
  {
    name: 'gitdir',
    type: 'path',
    description: 'git directory path',
    required: true
  },
  {
    name: 'dir',
    type: 'path',
    description: 'project path',
    required: true
  },
  {
    _: true,
    name: 'filepath',
    type: 'path',
    description: 'filepath to add',
    required: true
  }
];

const cloneProject = inject(['fs', 'git', 'path'], async function cloneProject(
  projId
) {
  const { fs, path, git } = this;
  const REPO_GIT_DIRECTORY_PATH = path.join(GIT_DIRECTORY_PATH, projId);

  if (fs.existsSync(REPO_GIT_DIRECTORY_PATH)) {
    throw new Error(
      `fatal: destination path '${REPO_GIT_DIRECTORY_PATH}' already exists and is not an empty directory.`
    );
  }

  mkdirpSync(REPO_GIT_DIRECTORY_PATH);

  // TODO find a good way to get the URLs
  await git.clone({
    dir: path.join(REPOS_DIRECTORY_PATH, projId),
    gitdir: REPO_GIT_DIRECTORY_PATH,
    corsProxy: process.env.REACT_APP_GIT_CORS_URL,
    url: `${process.env.REACT_APP_GIT_REPO_URL}/${projId}`
  });
});

const saveProject = inject(['fs', 'git', 'path'], async function saveProject({
  projId = getSelectedProjectId()
} = {}) {
  const REPO_GIT_DIRECTORY_PATH = this.path.join(GIT_DIRECTORY_PATH, projId);
  const REPO_DIRECTORY_PATH = this.path.join(REPOS_DIRECTORY_PATH, projId);

  // TODO put in state
  let author = {
    name: 'pyramation',
    email: 'pyramation@gmail.com'
  };

  if (!this.fs.existsSync(REPO_GIT_DIRECTORY_PATH)) {
    throw new Error('Not initialized');
  }

  const dir = REPO_DIRECTORY_PATH;
  const gitdir = REPO_GIT_DIRECTORY_PATH;
  const { git } = this;

  await Promise.all([
    git.statusMatrix({ dir, gitdir }).then(status =>
      Promise.all(
        status.map(([filepath, , worktreeStatus]) => {
          // isomorphic-git may report a changed file as unmodified, so always add if not removing
          console.log(filepath);
          return worktreeStatus
            ? git.add({ dir, gitdir, filepath })
            : git.remove({ dir, gitdir, filepath });
        })
      )
    ),
    git.commit({
      dir,
      gitdir,
      author,
      message: '😎 From a browser!'
    }),
    git.push({
      dir,
      gitdir,
      remote: 'origin',
      ref: 'master'
    })
  ]);
});

saveProject.description = 'save all; git add, git commit and git push';
saveProject.args = [
  {
    name: 'projId',
    type: 'string',
    description: 'projectId'
  }
];

export {
  cloneProject,
  saveProject,
  setSelectedProject,
  checkTree,
  gitCommit,
  gitInit,
  gitAdd
};
