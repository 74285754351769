import React from 'react';
import { Transition } from 'react-transition-group';
import { base } from '../Theme/modes/base';
import { useForkRef } from '../../utils/react';
import { createTransition } from './utils';

const styles = {
  entering: {
    opacity: 1
  },
  entered: {
    opacity: 1
  }
};

const defaultTimeout = {
  enter: base.duration.enteringScreen,
  exit: base.duration.leavingScreen
};

export const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: inProp,
    onEnter,
    onExit,
    style,
    timeout = defaultTimeout,
    ...other
  } = props;
  const handleRef = useForkRef(children.ref, ref);

  const handleEnter = node => {
    const transition = createTransition(['opacity'], {
      duration: timeout.enter,
      easing: timeout.easing || base.easing.easeOutQuart
    });
    node.style.webkitTransition = transition;
    node.style.transition = transition;
    if (onEnter) {
      onEnter(node);
    }
  };

  const handleExit = node => {
    const transition = createTransition(['opacity'], {
      duration: timeout.exit,
      easing: timeout.easing || base.easing.easeOutQuart
    });
    node.style.webkitTransition = transition;
    node.style.transition = transition;
    if (onExit) {
      onExit(node);
    }
  };

  return (
    <Transition
      appear
      in={inProp}
      onEnter={handleEnter}
      onExit={handleExit}
      timeout={timeout}
      {...other}
    >
      {(state, childProps) => {
        return React.cloneElement(children, {
          style: {
            opacity: 0,
            visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
            ...styles[state],
            ...style,
            ...children.props.style
          },
          ref: handleRef,
          ...childProps
        });
      }}
    </Transition>
  );
});
