import React, { useRef } from 'react';

const DraggableContext = React.createContext();

const { Consumer: DraggableConsumer } = DraggableContext;

const DraggableProvider = props => {
  const draggedRef = useRef();
  return (
    <DraggableContext.Provider value={draggedRef}>
      {props.children}
    </DraggableContext.Provider>
  );
};
export { DraggableContext, DraggableConsumer, DraggableProvider };
