import React from 'react';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import PropTypes from 'prop-types';
import { Modal } from '../Modal';
import { Box, Card, Slide } from '../../ui';
import { uniqueId } from '../../utils/uniqueId';
import { DrawerContext } from './DrawerContext';

const SLIDE_DIRECTION = {
  left: 'right',
  right: 'left'
};

const StyledDrawer = styled(Card)(
  props => ({
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1 0 auto',
    zIndex: themeGet('zIndex.drawer')(props),
    WebkitOverflowScrolling: 'touch',
    position: 'fixed',
    top: 0,
    outline: 'none',
    borderRadius: 0,
    border: `0.5px solid rgba(0, 0, 0, 0.15)`,
    boxShadow: `0px 2px 6px 0px rgba(0, 0, 0, 0.2)`
  }),
  props =>
    props.placement === 'left' && {
      left: 0,
      right: 'auto'
    },
  props =>
    props.placement === 'right' && {
      left: 'auto',
      right: 0
    }
);

const PersistentDrawer = styled(Box)(props => ({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  zIndex: themeGet('zIndex.drawer')(props),
  WebkitOverflowScrolling: 'touch',
  position: 'fixed',
  top: 0,
  outline: 'none',
  flex: '0 0 auto'
}));

export const Drawer = props => {
  const {
    id,
    open: openProp,
    defaultOpen,
    persistent,
    backdrop,
    keyboard,
    disableBackdropClick,
    disableScrollLock,
    placement,
    onClose,
    container,
    renderTarget,
    children: childrenProp,
    pushOffset,
    TransitionProps,
    ...rest
  } = props;
  const parentDrawer = React.useContext(DrawerContext);
  const [push, setPush] = React.useState(false);
  const drawerId = React.useRef(uniqueId('drawer-'));

  const [openState, setOpenState] = React.useState(false);
  const isControlled = typeof openProp !== 'undefined';
  const open = isControlled ? defaultOpen || openProp : openState;

  const handleClose = event => {
    if (!isControlled) setOpenState(false);
    if (onClose) onClose(event);
  };

  const handleOpen = () => {
    setOpenState(true);
  };

  React.useEffect(() => {
    if (!parentDrawer) return;
    const { push, pull, id } = parentDrawer;
    if (open && id) {
      push();
    }
    return () => pull();
  }, [parentDrawer, open]);

  const children =
    typeof childrenProp === 'function'
      ? childrenProp(handleClose)
      : childrenProp;

  let drawer = (
    <StyledDrawer
      placement={placement}
      data-drawerid={drawerId.current}
      {...rest}
    >
      <DrawerContext.Provider
        value={{
          push: () => setPush(true),
          pull: () => setPush(false),
          id: drawerId.current
        }}
      >
        {children}
      </DrawerContext.Provider>
    </StyledDrawer>
  );

  if (persistent) {
    return <PersistentDrawer {...rest}>{drawer}</PersistentDrawer>;
  }

  return (
    <DrawerContext.Consumer>
      {() => (
        <React.Fragment>
          {!isControlled && renderTarget(handleOpen)}
          <Modal
            id={id}
            open={open}
            onClose={handleClose}
            backdrop={backdrop}
            disableBackdropClick={disableBackdropClick}
            disableScrollLock={disableScrollLock}
            keyboard={keyboard}
            container={container}
          >
            <Slide
              {...TransitionProps}
              in={open}
              direction={SLIDE_DIRECTION[placement]}
              initialOffset={push ? pushOffset : undefined}
            >
              {drawer}
            </Slide>
          </Modal>
        </React.Fragment>
      )}
    </DrawerContext.Consumer>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool,
  renderTarget: PropTypes.func,
  defaultOpen: PropTypes.bool,
  persistent: PropTypes.bool,
  placement: PropTypes.oneOf(['left', 'right']),
  // Where to render the drawer, could be a func to return the DOM node,
  // or 'body' tag, or a valid HTML DOM element
  container: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ]),
  backdrop: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  onChange: PropTypes.func,
  afterVisibleChange: PropTypes.func,
  onClose: PropTypes.func,
  keyboard: PropTypes.bool,
  // The translate offset to push the content further in case of multiple layers drawer
  pushOffset: PropTypes.number,
  ModalProps: PropTypes.object,
  TransitionProps: PropTypes.object
};

Drawer.defaultProps = {
  placement: 'left',
  defaultOpen: false,
  backdrop: true,
  persistent: false,
  pushOffset: 140,
  disableBackdropClick: false,
  keyboard: true,
  onChange: () => {},
  onClose: () => {}
};
