import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PackageIcon = function PackageIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 5.143h17.786c.059 0 .107.048.107.107v12.643c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V5.25c0-.06.048-.107.107-.107zM2.632 0h12.736c.038 0 .072.02.092.052l2.443 4.071a.107.107 0 01-.092.163H.189a.107.107 0 01-.092-.163L2.54.052A.107.107 0 012.632 0zM5.25 7.714a.107.107 0 00-.107.107v1.072c0 .059.048.107.107.107h7.5c.06 0 .107-.048.107-.107V7.82a.107.107 0 00-.107-.107h-7.5zM8.217.43a.107.107 0 00-.1.07L6.91 3.711a.107.107 0 00.1.145h3.977a.107.107 0 00.1-.145L9.884.498a.107.107 0 00-.1-.07H8.217z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PackageIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PackageIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PackageIcon';
export default ForwardRef;