import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SitemapIcon = function SitemapIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M13.714 9.857H4.286v1.607c0 .06-.048.107-.107.107h-.643a.107.107 0 01-.107-.107V9.107c0-.059.048-.107.107-.107H8.57V6.536c0-.06.048-.107.108-.107h.642c.06 0 .108.048.108.107V9h5.035c.06 0 .107.048.107.107v2.357c0 .06-.048.107-.107.107h-.643a.107.107 0 01-.107-.107V9.857zM5.25 0h7.5c.06 0 .107.048.107.107V1.18c0 .059-.048.107-.107.107h-7.5a.107.107 0 01-.107-.107V.107C5.143.048 5.19 0 5.25 0zm.964.857a.214.214 0 100-.428.214.214 0 000 .428zm.857 0a.214.214 0 100-.428.214.214 0 000 .428zm.858 0a.214.214 0 100-.428.214.214 0 000 .428zM.107 12h7.5c.06 0 .107.048.107.107v1.072c0 .059-.048.107-.107.107h-7.5A.107.107 0 010 13.179v-1.072C0 12.048.048 12 .107 12zm.964.857a.214.214 0 100-.428.214.214 0 000 .428zm.858 0a.214.214 0 100-.428.214.214 0 000 .428zm.857 0a.214.214 0 100-.428.214.214 0 000 .428zM10.393 12h7.5c.059 0 .107.048.107.107v1.072c0 .059-.048.107-.107.107h-7.5a.107.107 0 01-.107-.107v-1.072c0-.059.048-.107.107-.107zm.964.857a.214.214 0 100-.428.214.214 0 000 .428zm.857 0a.214.214 0 100-.428.214.214 0 000 .428zm.857 0a.214.214 0 100-.428.214.214 0 000 .428zM5.25 1.714h7.5c.06 0 .107.048.107.107v4.072c0 .059-.048.107-.107.107h-7.5a.107.107 0 01-.107-.107V1.82c0-.059.048-.107.107-.107zm-5.143 12h7.5c.06 0 .107.048.107.107v4.072c0 .059-.048.107-.107.107h-7.5A.107.107 0 010 17.893V13.82c0-.059.048-.107.107-.107zm10.286 0h7.5c.059 0 .107.048.107.107v4.072c0 .059-.048.107-.107.107h-7.5a.107.107 0 01-.107-.107V13.82c0-.059.048-.107.107-.107z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SitemapIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SitemapIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SitemapIcon';
export default ForwardRef;