import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PublicProfileIcon = function PublicProfileIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M10.908 9.2c-2.723.032-5.215 1.455-7.164 3.8H0l.304-1.822A5 5 0 015.236 7h1.528a5 5 0 014.144 2.2zM6 6a3 3 0 110-6 3 3 0 010 6zm5 4c2.675 0 5.1 1.403 6.89 3.684a.514.514 0 010 .634C16.1 16.598 13.675 18 11 18s-5.1-1.403-6.89-3.684a.514.514 0 010-.634C5.9 11.402 8.325 10 11 10zm.196 6.994a3.016 3.016 0 002.798-2.798 3.002 3.002 0 00-3.19-3.19 3.007 3.007 0 00-2.798 2.798 3.002 3.002 0 003.19 3.19zm-.068-.998a1.997 1.997 0 01-2.124-2.124 2.008 2.008 0 011.868-1.868 1.997 1.997 0 012.124 2.124 2.008 2.008 0 01-1.868 1.868z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PublicProfileIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PublicProfileIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PublicProfileIcon';
export default ForwardRef;