import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DoubleUnderlineIcon = function DoubleUnderlineIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M17.016 11.57a.422.422 0 010 .844h-1.407a.979.979 0 01-.77-.38c-.508.26-.979.38-1.48.38h-.562c-.923 0-1.629-.374-1.937-1.026-.273-.578-.164-1.278.286-1.826.58-.707 1.38-.837 2.228-.976a8.605 8.605 0 001.251-.274v-.26c0-.656-.366-1.549-.984-1.549-.619 0-.985.893-.985 1.548a.422.422 0 01-.422.423H11.11a.422.422 0 01-.421-.423 2.394 2.394 0 012.39-2.392h1.125a2.394 2.394 0 012.39 2.392v3.378c0 .077.064.14.141.14h.282zm-2.391-.38V9.235c-.646.203-1.333.464-1.707.879a.996.996 0 00-.175 1.053c.067.151.236.404.616.404.413 0 .81-.118 1.266-.38zm-4.64.38a.422.422 0 010 .844H7.452a.422.422 0 01-.065-.838L6.575 9.6H2.988l-.814 1.977a.421.421 0 01-.065.838H.984a.422.422 0 010-.844h.28l3.83-9.309a.422.422 0 01.78 0l3.83 9.309h.28zm-6.65-2.815h2.893L4.78 5.24 3.335 8.755zM0 14.372v-1h18v1H0zm0 1h18v1H0v-1z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DoubleUnderlineIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DoubleUnderlineIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DoubleUnderlineIcon';
export default ForwardRef;