import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CollaboratorIcon = function CollaboratorIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2.5 13.95c0 .59.34 1.06.9 1.43-1.9-.22-3.4-.77-3.4-1.64 0-1.79 1.37-3.31 3.43-3.93A3.507 3.507 0 012 7c0-1.85 1.44-3.37 3.26-3.49-.48.71-.76 1.57-.76 2.49 0 1.2.48 2.34 1.31 3.17-2.03.94-3.31 2.74-3.31 4.78zm12.075-4.14c2.06.62 3.43 2.14 3.43 3.93 0 .87-1.5 1.42-3.4 1.64.56-.37.9-.84.9-1.43 0-2.04-1.28-3.84-3.31-4.78.83-.83 1.31-1.97 1.31-3.17 0-.92-.28-1.78-.76-2.49 1.82.12 3.26 1.64 3.26 3.49 0 1.15-.57 2.17-1.43 2.81zM15 13.95c0 2.734-12 2.734-12 0 0-2.107 1.521-3.902 3.794-4.616A3.996 3.996 0 015 6a4 4 0 018 0 3.996 3.996 0 01-1.794 3.334C13.479 10.048 15 11.843 15 13.95z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CollaboratorIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CollaboratorIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CollaboratorIcon';
export default ForwardRef;