import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PhoneNumberIcon = function PhoneNumberIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M13 1.719V.28c0-.155.126-.281.281-.281h.438c.155 0 .281.126.281.281V17.72a.281.281 0 01-.281.281H4.28A.281.281 0 014 17.719V2.28C4 2.126 4.126 2 4.281 2h8.438A.281.281 0 0013 1.719zM5 3.114v4.772c0 .065.05.114.113.114h7.774A.114.114 0 0013 7.886V3.114A.113.113 0 0012.887 3H5.113A.114.114 0 005 3.114zm0 6.011v1.75c0 .067.056.125.125.125h1.75A.126.126 0 007 10.875v-1.75A.126.126 0 006.875 9h-1.75A.126.126 0 005 9.125zm3 3v1.75c0 .067.056.125.125.125h1.75a.126.126 0 00.125-.125v-1.75A.126.126 0 009.875 12h-1.75a.126.126 0 00-.125.125zm0 3v1.75c0 .067.056.125.125.125h1.75a.126.126 0 00.125-.125v-1.75A.126.126 0 009.875 15h-1.75a.126.126 0 00-.125.125zm0-6v1.75c0 .067.056.125.125.125h1.75a.126.126 0 00.125-.125v-1.75A.126.126 0 009.875 9h-1.75A.126.126 0 008 9.125zm3 3v1.75c0 .067.056.125.125.125h1.75a.126.126 0 00.125-.125v-1.75a.126.126 0 00-.125-.125h-1.75a.126.126 0 00-.125.125zm0 3v1.75c0 .067.056.125.125.125h1.75a.126.126 0 00.125-.125v-1.75a.126.126 0 00-.125-.125h-1.75a.126.126 0 00-.125.125zm0-6v1.75c0 .067.056.125.125.125h1.75a.126.126 0 00.125-.125v-1.75A.126.126 0 0012.875 9h-1.75a.126.126 0 00-.125.125zm-6 3v1.75c0 .067.056.125.125.125h1.75A.126.126 0 007 13.875v-1.75A.126.126 0 006.875 12h-1.75a.126.126 0 00-.125.125zm0 3v1.75c0 .067.056.125.125.125h1.75A.126.126 0 007 16.875v-1.75A.126.126 0 006.875 15h-1.75a.126.126 0 00-.125.125z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PhoneNumberIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PhoneNumberIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PhoneNumberIcon';
export default ForwardRef;