import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PressIcon = function PressIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M11.571 2.571h5.143v1.286H15.43v9h-1.286v-1.286H3.857v1.286H2.571v-9H1.286V2.571h5.143V0h5.142v2.571zM3.857 3.857v6.429h3.857V6.429H5.143V3.857H3.857zm9 0V6.43h-2.571v3.857h3.857V3.857h-1.286zm3.857 12.857H1.286V18H0v-2.571h18V18h-1.286v-1.286z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PressIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PressIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PressIcon';
export default ForwardRef;