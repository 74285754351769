import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { layout, space, position } from 'styled-system';
import { transparentize, linearGradient } from 'polished';

import { ControlThumb, thumbSize } from '../../ui/ControlThumb';
import { clamp } from '../../utils/math';

const HueWrapper = styled.div(props => ({
  position: 'relative',
  borderRadius: themeGet('radii.1')(props),
  width: '100%',
  height: themeGet('space.2')(props),
  userSelect: 'none',
  border: `${themeGet('borders.1')(props)} ${transparentize(
    0.5,
    themeGet('colors.black')(props)
  )}`,
  ...linearGradient({
    colorStops: [
      '#ff0000 0%',
      '#ff9900 10%',
      '#cdff00 20%',
      '#35ff00 30%',
      '#00ff66 40%',
      '#00fffd 50%',
      '#0066ff 60%',
      '#3200ff 70%',
      '#cd00ff 80%',
      '#ff0099 90%',
      '#ff0000 100%'
    ],
    toDirection: 'to right',
    fallback: 'transparent'
  }),
  [`${ControlThumb}`]: {
    position: 'absolute',
    transform: 'translateY(-25%)',
    marginLeft: -thumbSize / 2
  },
  [`${EventListener}`]: {
    position: 'absolute'
  }
}));

const EventListener = styled.div({
  cursor: 'pointer',
  width: '104%',
  height: '100%',
  left: '-2%'
});

export const Hue = styled(({ value: hueValue, onChange, className }) => {
  const hueRef = useRef();

  const goToPoint = useCallback(
    ({ clientX: x }) => {
      if (!hueRef.current) return;
      const rect = hueRef.current.getBoundingClientRect();
      const left = clamp(x - rect.left, 0, rect.width);
      const huePercent = left / rect.width;
      const hueValue = huePercent * 360;
      onChange(hueValue);
    },
    [onChange]
  );

  const onDrag = useCallback(
    event => {
      goToPoint(event);
    },
    [goToPoint]
  );

  const onDragEnd = useCallback(
    event => {
      goToPoint(event);
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', onDragEnd);
    },
    [goToPoint, onDrag]
  );

  const onMouseDown = useCallback(
    event => {
      goToPoint(event);
      window.addEventListener('mousemove', onDrag);
      window.addEventListener('mouseup', onDragEnd);
    },
    [goToPoint, onDrag, onDragEnd]
  );

  const position = (hueValue / 360) * 100;

  return (
    <HueWrapper ref={hueRef} className={className}>
      <ControlThumb left={`${position}%`} />
      <EventListener onMouseDown={onMouseDown} />
    </HueWrapper>
  );
})(space, layout, position);

Hue.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
};

Hue.defaultProps = {
  onChange: () => {}
};
