import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var RefreshIcon = function RefreshIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M15.34 6.917l1.343-1.607L18 6.467l-3.087 3.692-4.1-2.627.919-1.508 1.895 1.214c-.742-2.578-3.068-4.46-5.823-4.46-3.352 0-6.07 2.786-6.07 6.222 0 3.436 2.718 6.222 6.07 6.222V17C3.494 17 0 13.418 0 9s3.494-8 7.804-8c3.607 0 6.642 2.509 7.536 5.917z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

RefreshIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(RefreshIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'RefreshIcon';
export default ForwardRef;