import styled from '@emotion/styled/macro';
import { margin, padding, em } from 'polished';
import themeGet from '@styled-system/theme-get';
import { Card } from '../Card';

export const AuthForm = styled(Card)(props => ({
  ...margin(0, 'auto'),
  ...padding(em(24 - 0.5)),
  backgroundColor: themeGet('colors.background.bar')(props),
  borderStyle: 'solid',
  borderWidth: 0.5,
  boxSizing: 'border-box',
  maxWidth: em(360),
  width: '100%',
  borderRadius: 0,
  '&:first-of-type': {
    borderRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  '&:last-of-type': {
    borderRadius: 4,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  }
}));

AuthForm.defaultProps = {
  as: 'form',
  elevation: 0
};
