import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PropertyIcon = function PropertyIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M12.857 12c1.965 0 3.747 1.052 5.063 2.763a.392.392 0 010 .476C16.604 16.948 14.822 18 12.857 18c-1.965 0-3.747-1.052-5.062-2.763a.392.392 0 010-.476C9.11 13.052 10.892 12 12.857 12zm.14 5.138a2.155 2.155 0 001.999-1.998 2.144 2.144 0 00-2.279-2.278 2.148 2.148 0 00-1.998 1.998 2.144 2.144 0 002.278 2.278zm-.057-.855a1.284 1.284 0 01-1.366-1.365c.04-.641.56-1.16 1.2-1.201a1.284 1.284 0 011.366 1.365 1.291 1.291 0 01-1.2 1.201zM0 .11C0 .049.047 0 .111 0h12.635c.061 0 .111.052.111.107v11.464c-2.452-.14-4.461 1.143-5.571 3H.103c-.057 0-.103-.047-.103-.108V.109zm1.286 2.139v.647c0 .059.048.105.107.105h4.928a.104.104 0 00.108-.105v-.647a.106.106 0 00-.108-.105H1.393a.104.104 0 00-.107.105zm0 1.714v.647c0 .06.048.105.108.105h10.069a.105.105 0 00.108-.105v-.647a.106.106 0 00-.108-.105H1.394a.105.105 0 00-.108.105zm0 1.715v.646c0 .06.047.106.106.106h4.073a.106.106 0 00.106-.106v-.646a.105.105 0 00-.106-.106H1.392a.106.106 0 00-.106.106zm5.143 0v.646c0 .06.048.106.107.106h4.928a.104.104 0 00.107-.106v-.646a.106.106 0 00-.107-.106H6.536a.104.104 0 00-.107.106zM1.286 7.39v.647c0 .059.048.105.107.105h4.928a.104.104 0 00.108-.105V7.39a.106.106 0 00-.108-.105H1.393a.104.104 0 00-.107.105zm0 1.714v.647c0 .06.047.105.106.105h6.216a.106.106 0 00.106-.105v-.647A.105.105 0 007.608 9H1.392a.106.106 0 00-.106.105zm7.285 0v.647c0 .06.048.105.107.105h2.787a.109.109 0 00.106-.105v-.647A.105.105 0 0011.465 9H8.678a.109.109 0 00-.107.105zM1.286 10.82v.647c0 .06.047.105.106.105h3.216a.106.106 0 00.106-.105v-.647a.105.105 0 00-.106-.105H1.392a.106.106 0 00-.106.105zm4.285 0v.647c0 .06.048.105.108.105h1.5a.107.107 0 00.107-.105v-.647a.106.106 0 00-.108-.105h-1.5a.107.107 0 00-.107.105z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PropertyIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PropertyIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PropertyIcon';
export default ForwardRef;