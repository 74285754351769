import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CustomizeFieldIcon = function CustomizeFieldIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M1.493 10.766h-.8c-.573 0-.677-.35-.693-.535v-2.53c0-.374.323-.453.528-.467h.977a.814.814 0 00.764-.53l.005-.012c.09-.262.195-.517.313-.765h-.003s.28-.564-.106-.95l-.668-.668-.001-.002c-.367-.367-.072-.76.056-.9l1.572-1.572c.18-.149.554-.341 1.01.114v.001l.469.469c.104.1.47.395.996.172l.024-.007c.253-.121.512-.229.778-.318l.009-.005c.393-.152.49-.381.51-.525V.982c.002-.028.003-.05.003-.083 0-.702.566-.866.944-.899h1.643c.377.033.944.197.944.899v.704s-.01.498.63.7c.04.014.066.024.078.034.253.093.5.198.74.319 0 0 .417.215.722-.09v-.001l.685-.685h.001l.04-.04c.122-.113.507-.397.944.04l1.541 1.541c.131.145.392.536-.06.99l-.46.458s-.002.004-.004.004l-.03.03a.918.918 0 00-.178 1.026c.003.006.002.01.004.015.111.236.211.477.296.724h.003s.001.009.004.017c.009.028.02.054.028.081.07.162.279.469.877.469h.755c.209.018.617.146.617.866v1.756c0 .825-.581.904-.752.91h-.647s-.652-.014-.883.57l-.01.018a6.96 6.96 0 01-.286.696l.004.001-.011.015-.085.175c-.065.191-.088.503.237.828l.693.692c.127.175.226.48-.16.866l-1.494 1.494c0 .001-.001.002-.003.002-.504.505-1.028-.051-1.028-.051l-.52-.519s-.438-.363-1.016-.121l-.037.013c-.216.1-.435.19-.66.268l-.014.008c-.467.192-.55.552-.561.727v.901s.002.65-.713.65H7.688c-.409-.08-.452-.454-.454-.563v-.942c0-.459-.257-.653-.436-.733-.05-.016-.1-.034-.149-.051l-.02-.004.003-.003a7.077 7.077 0 01-.565-.227.393.393 0 01-.102-.043c-.453-.247-.791-.076-.969.069l-.633.633c-.452.45-.815.14-.815.14l-1.82-1.82c-.27-.27-.125-.533-.008-.674l.665-.665h.001c.401-.402.332-.749.27-.902a3.026 3.026 0 01-.042-.082 7.047 7.047 0 01-.367-.903v-.002c-.157-.424-.63-.46-.754-.462zM8.999 4.5A4.505 4.505 0 004.5 9c0 2.481 2.018 4.5 4.499 4.5A4.506 4.506 0 0013.5 9c0-2.481-2.02-4.5-4.501-4.5z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CustomizeFieldIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CustomizeFieldIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CustomizeFieldIcon';
export default ForwardRef;