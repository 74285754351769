import { useState, useEffect } from 'react';
import json2mq from 'json2mq';
import { base } from './modes/base';

// Some default queries based on the theme
const QUERIES = ['sm', 'md', 'lg', 'xl', 'xxl'].reduce((obj, bp) => {
  obj[bp] = { minWidth: base.breakpoints[bp] };
  return obj;
}, {});

/**
 * useMedia
 * Usage: const small = useMedia({ minWidth: 800 })
 * @param {*} query Media string or object
 * @param {*} defaultMatches
 */
export const useMedia = (query, defaultMatches = true) => {
  const [matches, setMatches] = useState(defaultMatches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(
      typeof query === 'string'
        ? QUERIES[query]
          ? json2mq(QUERIES[query])
          : query
        : json2mq(query)
    );
    let active = true;

    const listener = () => {
      if (!active) {
        return;
      }

      if (mediaQueryList.matches) {
        setMatches(true);
      } else {
        setMatches(false);
      }
    };

    mediaQueryList.addListener(listener);
    setMatches(mediaQueryList.matches);

    return () => {
      active = false;
      mediaQueryList.removeListener(listener);
    };
  }, [query]);
  return matches;
};
