import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var FloatIcon = function FloatIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm4.115 8.657L5.89 6.816v-.73H1.867v.967h2.612L2.433 9.357l.452.483c.319-.175.617-.298 1.029-.298.833 0 1.47.607 1.47 1.43 0 .812-.606 1.45-1.45 1.45-.792 0-1.337-.576-1.512-1.265l-.915.4c.267.947 1.07 1.832 2.427 1.832 1.481 0 2.448-1.163 2.448-2.418 0-1.172-.823-2.18-2.16-2.314zm3.497 4.7c.36 0 .648-.287.648-.647a.652.652 0 00-.648-.648.643.643 0 00-.637.648c0 .36.277.648.637.648zm3.271-7.384L8.727 7.392l.443.802.956-.586v5.678h.967V5.973h-.103zm5.699 5.132h-.762v-5.04H14.92l-3.013 5.266v.7h3.055v1.255h.966V12.03h.762v-.926zm-1.72-3.379v3.417h-1.875l1.875-3.417z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

FloatIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(FloatIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'FloatIcon';
export default ForwardRef;