import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var TerminalIcon = function TerminalIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.68 9.167L0 3.818l1.169-1.151 5.688 6.5-5.688 6.5L0 14.515l4.68-5.348zm3.034 4.766H18v1.734H7.714v-1.734z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

TerminalIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(TerminalIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'TerminalIcon';
export default ForwardRef;