import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

export const ScrollViewContent = styled.div(props => ({
  bottom: 0,
  left: 0,
  overflowX: props.direction === 'horizontal' ? 'scroll' : 'hidden',
  overflowY: props.direction === 'horizontal' ? 'hidden' : 'scroll',
  position: 'absolute',
  right: 0,
  top: 0,
  whiteSpace: 'nowrap'
}));

ScrollViewContent.defaultProps = {
  direction: 'vertical'
};

ScrollViewContent.propTypes = {
  direction: PropTypes.oneOf(['vertical', 'horizontal'])
};
