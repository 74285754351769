import styled from '@emotion/styled/macro';
import { em } from 'polished';

export const ListItem = styled.li({
  display: 'block',
  boxSizing: 'border-box',
  '&:not(:first-of-type)': {
    marginTop: em(3)
  },
  'a&': {
    textDecoration: 'none'
  }
});
