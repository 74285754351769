import EventEmitter from 'eventemitter3';

const store = new EventEmitter();
const commands = new EventEmitter();

const emitFileChanged = (...paths) => {
  store.emit('updateFiles', { changes: paths.filter(Boolean) });
};

export { store, commands, emitFileChanged };
