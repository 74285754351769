import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ColumnsIcon = function ColumnsIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm1.286 2.571a.107.107 0 00-.107.108V15.32c0 .06.048.108.107.108h15.214c.06 0 .107-.048.107-.108V2.68a.107.107 0 00-.107-.108H1.393zM2.57 3.857H6v10.286H2.571V3.857zm4.715 0h3.428v10.286H7.286V3.857zm4.714 0h3.429v10.286H12V3.857z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ColumnsIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ColumnsIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ColumnsIcon';
export default ForwardRef;