import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { Card } from '../Card';

export const ModalContent = styled(Card)(props => {
  const mq = themeGet('mediaQueries')(props);
  return {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    margin: '0 auto',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.15)`,
    [mq[2]]: {
      position: 'relative',
      width: '100vw'
    }
  };
});

ModalContent.defaultProps = {
  role: 'document',
  tabIndex: -1
};
