import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ColorPickerIcon = function ColorPickerIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M15.791 5.496l1.067 1.084-2.133 2.167-1.066-1.083-8.682 8.824s-3.35 2.013-4.874 1.394c-.609-1.549 1.676-4.645 1.676-4.645l8.682-8.825L9.394 3.33l2.133-2.168 1.066 1.084L14.116.697c.914-.93 2.285-.93 3.199 0 .913.929.913 2.322 0 3.25l-1.524 1.55zm-10.01 5.84l1.95.186 4.862-4.942-1.066-1.084-5.746 5.84z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ColorPickerIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ColorPickerIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ColorPickerIcon';
export default ForwardRef;