import { ApolloConsumer } from '@apollo/react-hooks';
import React from 'react';
import { signOut } from '../state/commands';

export const SignOutLink = ({ children, ...props }) => {
  return (
    <ApolloConsumer>
      {client => (
        <a
          href="/signout"
          {...props}
          onClick={event => {
            event.preventDefault();
            signOut();
          }}
        >
          {children}
        </a>
      )}
    </ApolloConsumer>
  );
};
