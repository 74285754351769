import { Color } from './Color';

export const ACTIONS = {
  setAlpha: 'SET_ALPHA',
  setColor: 'SET_COLOR',
  setColorMode: 'SET_COLOR_MODE',
  setColorChannel: 'SET_COLOR_CHANNEL',
  // Gradient actions
  setGradient: 'SET_GRADIENT',
  activateStop: 'ACTIVATE_STOP',
  deactivateStop: 'DEACTIVATE_STOP',
  addStop: 'ADD_STOP',
  removeStop: 'REMOVE_STOP',
  changeStopOffset: 'CHANGE_STOP_OFFSET'
};

export const colorReducer = (state, action) => {
  const setGradientStopColor = newColor => {
    if (action.colorPath !== 'activeStopColor') return state.gradient;
    return state.gradient.map(stop => {
      if (!state.activeStop || state.activeStop.id !== stop.id) return stop;
      return { ...stop, color: newColor.rgbString };
    });
  };

  switch (action.type) {
    case ACTIONS.setAlpha: {
      const newColor = state[action.colorPath];
      newColor.alpha = action.alpha;
      return {
        ...state,
        [action.colorPath]: newColor,
        gradient: setGradientStopColor(newColor)
      };
    }
    case ACTIONS.setGradient: {
      return {
        ...state,
        gradient: action.gradient
      };
    }
    case ACTIONS.setColorMode: {
      return {
        ...state,
        mode: action.mode
      };
    }
    case ACTIONS.setColorChannel: {
      const newColor = state[action.colorPath];
      newColor[action.channel] = action.value;

      return {
        ...state,
        [action.colorPath]: newColor,
        gradient: setGradientStopColor(newColor)
      };
    }
    case ACTIONS.setColor: {
      if (!action.color.isValid) return state;
      return {
        ...state,
        [action.colorPath]: action.color,
        gradient: setGradientStopColor(action.color)
      };
    }
    // Actions when interacted with gradient picker
    case ACTIONS.activateStop: {
      if (!action.id) return state;
      const activeStop = state.gradient.find(s => s.id === action.id);
      return {
        ...state,
        activeStop,
        activeStopColor: new Color(activeStop ? activeStop.color : 'white')
      };
    }
    case ACTIONS.deactivateStop: {
      return {
        ...state,
        activeStop: null,
        activeStopColor: null
      };
    }
    case ACTIONS.addStop: {
      const { gradient, activeStop } = state;
      const newColor = {
        id: gradient.length + 1,
        color: activeStop ? activeStop.color : gradient[0].color,
        offset: action.offset
      };
      return {
        ...state,
        gradient: [...gradient, newColor],
        activeStop: newColor
      };
    }
    case ACTIONS.removeStop: {
      const gradient = state.gradient.filter(s => s.id !== action.id);
      return {
        ...state,
        gradient,
        activeStop: gradient.reduce(
          (stop, i) => (i.pos < stop.pos ? i : stop),
          gradient[0]
        )
      };
    }
    case ACTIONS.changeStopOffset: {
      return {
        ...state,
        gradient: state.gradient.map(stop =>
          stop.id !== action.id
            ? { ...stop }
            : {
                ...stop,
                offset: action.offset
              }
        )
      };
    }
    default:
      return state;
  }
};
