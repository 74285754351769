import React, { memo, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SubmitInviteCodeMutation } from '../../mutations';
import { getFormVariables } from '../../utils/form';
import {
  AuthForm,
  AuthHeading,
  AuthParagraph,
  Button,
  Field,
  Input
} from '../../ui';
import { propsAreEqual } from '../../utils/react';

export const EnterInviteCode = memo(function EnterInviteCode({
  step,
  setStep
}) {
  const [disabled, setDisabled] = useState(true);
  const [submitInviteCode] = useMutation(SubmitInviteCodeMutation, {
    onCompleted: () => {
      setStep(step + 1);
    },
    onError: e => {
      console.log({ e });
    }
  });

  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        const variables = getFormVariables(event);
        submitInviteCode({ variables });
      }}
    >
      <AuthHeading>Invite Code</AuthHeading>
      <AuthParagraph>
        Enter your invite code. We're working with a select few select customers
        and creatives to ensure the best possible experience for everyone.{' '}
      </AuthParagraph>

      <Field fluid={true}>
        <Input
          aria-labelledby="token-label"
          fluid={true}
          id="token"
          name="token"
          type="input"
          onChange={event => {
            event.preventDefault();
            const value = event.currentTarget.value;
            if (value && value.trim().length === 64) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }}
        />
      </Field>
      <Button fluid={true} disabled={disabled} type="submit" variant="primary">
        Submit
      </Button>
      <AuthParagraph variant={'caption'}>
        If you don't have a code, invite some friends and get bumped up the
        waitlist! Invite friends &nbsp;
        <a href={'/invites'}>here</a>
      </AuthParagraph>
    </AuthForm>
  );
},
propsAreEqual);
