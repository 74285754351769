import gql from 'graphql-tag';

export const ContentFeedFragment = gql`
  fragment ContentFeedFragment on Content {
    id
    title
    slug
    project {
      id
      name
    }
    publishedAt
    publishedBy
    updatedAt
    createdAt
  }
`;
