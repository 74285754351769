import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ContactIcon = function ContactIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2 .106C2 .047 2.04 0 2.097 0h13.806c.054 0 .097.052.097.106v17.788c0 .059-.04.106-.097.106H2.097C2.043 18 2 17.948 2 17.894V.106zM9 9a3 3 0 100-6 3 3 0 000 6zm0 7c2.577 0 6-.179 6-2.25S12.47 10 9 10s-6 1.679-6 3.75C3 15.821 6.423 16 9 16z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ContactIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ContactIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ContactIcon';
export default ForwardRef;