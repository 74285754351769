import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ConstrainIcon = function ConstrainIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M11 11v7h-.875v-7.875H18V11h-7zm7-3.125h-7.875V0H11v7h7v.875zM7 11H0v-.875h7.875V18H7v-7zm.875-11v7.875H0V7h7V0h.875z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ConstrainIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ConstrainIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ConstrainIcon';
export default ForwardRef;