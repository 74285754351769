import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DownloadIcon = function DownloadIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M11.012 7.091h2.726c.145 0 .262.114.262.253a.247.247 0 01-.049.148l-4.737 6.402a.268.268 0 01-.427 0L4.049 7.492a.248.248 0 01.06-.354.269.269 0 01.153-.047h2.726a.258.258 0 00.263-.253V.253c0-.14.117-.253.262-.253h2.974c.145 0 .262.113.262.253v6.585c0 .14.118.253.263.253zM.282 16h17.437c.155 0 .281.126.281.281v1.438a.281.281 0 01-.281.281H.28A.281.281 0 010 17.719V16.28C0 16.126.126 16 .281 16z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DownloadIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DownloadIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DownloadIcon';
export default ForwardRef;