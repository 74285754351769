import React, { memo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { getFormVariables } from '../../utils/form';
import {
  AuthForm,
  AuthHeading,
  AuthParagraph,
  Button,
  Field,
  Input,
  Label,
  Loader
} from '../../ui';
import { propsAreEqual } from '../../utils/react';
import { CurrentRoleQuery } from '../../queries';
import { UpdateRoleProfileByRoleIdMutation } from '../../mutations';

export const CreateDisplayName = memo(function CreateDisplayName({
  step,
  setStep
}) {
  const [disabled, setDisabled] = useState(true);
  const [updateRoleProfileByRoleId] = useMutation(
    UpdateRoleProfileByRoleIdMutation,
    {
      onCompleted: () => {
        setStep(step + 1);
      }
    }
  );

  const curRoleResult = useQuery(CurrentRoleQuery);
  const currentRole =
    curRoleResult && curRoleResult.data && curRoleResult.data.currentRole;

  if (!currentRole) {
    return (
      <AuthForm>
        <Loader />
      </AuthForm>
    );
  }

  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        const variables = getFormVariables(event);

        updateRoleProfileByRoleId({
          variables: {
            roleId: currentRole.id,
            patch: {
              displayName: variables.displayName
            }
          }
        });
      }}
    >
      <AuthHeading>Your Name</AuthHeading>
      <AuthParagraph>What should we call you?</AuthParagraph>
      <Field fluid={true}>
        <Label htmlFor="displayName" id="displayName-label">
          Your Name
        </Label>
        <Input
          aria-labelledby="displayName-label"
          fluid={true}
          id="displayName"
          name="displayName"
          type="input"
          placeholder="First Last"
          onChange={event => {
            event.preventDefault();
            const value = event.currentTarget.value;
            if (value && value.trim().split(' ').length >= 2) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }}
        />
      </Field>
      <Button fluid={true} disabled={disabled} type="submit" variant="primary">
        Submit
      </Button>
    </AuthForm>
  );
},
propsAreEqual);
