import injector from '../../../injector';
import { join, dirname, extname } from '../../../utils';

const { inject } = injector;

const register = (template, types, filename, body) => {
  const airpageWrap = template(`
    airpage.register(MODULE_NAME, function(module, exports, require) {
        BODY
    })
  `);
  return airpageWrap({
    MODULE_NAME: types.stringLiteral(filename),
    BODY: body
  });
};

const exportString = (template, types, str) => {
  return template(`exports.default = CONTENTS`)({
    CONTENTS: types.stringLiteral(str)
  });
};

const getModulePath = inject(['fs'], function(path) {
  const { existsSync } = this.fs;
  if (!existsSync(path)) {
    if (existsSync(`${path}.js`)) {
      return `${path}.js`;
    } else if (existsSync(`${path}/index.js`)) {
      return `${path}/index.js`;
    } else {
      throw new Error(path + ' could not be found!');
    }
  }
  return path;
});

const isModule = string => !string.startsWith('/') && !string.startsWith('.');

const storeAndRecur = ({
  deps,
  body,
  code,
  compiled,
  path,
  transform,
  template,
  types
}) => {
  compiled[path] = {
    code,
    body,
    deps
  };

  deps.forEach(({ dep, filename }) => {
    recurCompile({
      path: join(dirname(filename), dep),
      compiled,
      transform,
      template,
      types
    });
  });
};

const recurCompile = inject(['fs'], function({
  path,
  compiled = {},
  transform,
  template,
  types
}) {
  if (compiled[path]) return compiled[path];

  const { fs } = this;
  const modulePath = getModulePath(path);
  const code = fs.readFileSync(modulePath).toString();

  if (extname(path) === '.svg') {
    compiled[path] = {
      code,
      body: exportString(template, types, code),
      deps: []
    };
    return compiled;
  }

  let { body, deps } = transform(modulePath, code);

  const onlyDeps = deps.filter(({ dep }) => !isModule(dep));

  storeAndRecur({
    code,
    body,
    deps: onlyDeps,
    path,
    compiled,
    transform,
    template,
    types
  });

  return compiled;
});

const getModuleDeps = (manifest, path) => {
  let edges = manifest[path];
  if (!edges) {
    throw new Error(`no module ${path}`);
  }
  return edges;
};

// https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
const depResolve = (manifest, modulepath, resolved = [], unresolved = []) => {
  unresolved.push(modulepath);

  const edges = getModuleDeps(manifest, modulepath);
  for (var i = 0; i < edges.length; i++) {
    var dep = edges[i].dep;
    if (!resolved.includes(dep)) {
      if (unresolved.includes(dep)) {
        throw new Error(`Circular reference detected ${modulepath}, ${dep}`);
      }
      depResolve(manifest, dep, resolved, unresolved);
    }
  }

  resolved.push(modulepath);
  var index = unresolved.indexOf(modulepath);
  unresolved.splice(index);
  return resolved;
};

const createLookup = compiled => {
  return Object.keys(compiled).reduce((m, v) => {
    if (v.endsWith('index.js')) {
      m[v] = v;
      m[v.replace(/\.js$/, '')] = v;
      m[v.replace(/\/index\.js$/, '')] = v;
    } else if (v.endsWith('.js')) {
      m[v] = v;
      m[v.replace(/\.js$/, '')] = v;
    } else if (v.endsWith('index')) {
      m[v] = v;
      m[v + '.js'] = v;
      m[v.replace(/\/index$/, '')] = v;
    } else {
      m[v] = v;
      m[v + '.js'] = v;
    }
    return m;
  }, {});
};

const cannonicalizeManifest = (compiled, lookup) => {
  return Object.keys(compiled).reduce((m, v) => {
    const deps = compiled[v].deps;
    m[v] = deps.map(({ dep, filename }) => {
      return {
        dep: lookup[join(dirname(filename), dep)],
        filename,
        ref: dep
      };
    });
    return m;
  }, {});
};

const orderCompileByDeps = (path, compiled) => {
  const lookup = createLookup(compiled);
  const manifest = cannonicalizeManifest(compiled, lookup);
  return depResolve(manifest, path);
};

const compile = inject(['fs'], function({
  path,
  transform,
  generate,
  types,
  template,
  files = []
}) {
  // get assets, mainly svg icons right now...
  const assets = files.reduce((assets, path) => {
    return {
      ...assets,
      ...recurCompile({
        path,
        transform,
        template,
        types
      })
    };
  }, {});

  const assetCode = Object.entries(assets).map(([k, v]) =>
    register(template, types, k, v.body)
  );

  const compiled = recurCompile({
    path,
    transform,
    template,
    types
  });

  const body = orderCompileByDeps(path, compiled).reduce(
    (m, key) => m.concat(register(template, types, key, compiled[key].body)),
    assetCode
  );

  const programAst = {
    sourceType: 'module',
    type: 'Program',
    body
  };

  // SOURCE MAPS
  const codeMap = Object.keys(compiled).reduce((m, key) => {
    m[key] = compiled[key].code;
    return m;
  }, {});

  return generate(
    programAst,
    {
      sourceMaps: true
    },
    codeMap
  );
});
export { register, compile };
