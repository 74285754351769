import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ScssIcon = function ScssIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm5.276 8.246c1.399 0 2.232-.875 2.242-1.955.031-2.427-3.363-2.026-3.353-3.59 0-.534.453-.925 1.11-.925.567 0 1.08.36 1.43.75l.638-.657C7.008 1.334 6.278.84 5.362.84c-1.203 0-2.077.813-2.088 1.851 0 2.356 3.364 1.862 3.364 3.59 0 .576-.473 1.018-1.193 1.018-.648 0-1.255-.4-1.656-.977l-.71.669c.463.71 1.327 1.255 2.304 1.255zm0 8.571c1.399 0 2.232-.874 2.242-1.954.031-2.428-3.363-2.026-3.353-3.59 0-.535.453-.926 1.11-.926.567 0 1.08.36 1.43.751l.638-.658c-.442-.535-1.172-1.029-2.088-1.029-1.203 0-2.077.813-2.088 1.852 0 2.355 3.364 1.862 3.364 3.59 0 .576-.473 1.018-1.193 1.018-.648 0-1.255-.401-1.656-.977l-.71.668c.463.71 1.327 1.255 2.304 1.255zm7.906-8.55c.658 0 1.214-.134 1.697-.381v-1.1c-.411.328-.956.534-1.687.534-1.614 0-2.694-1.296-2.694-2.777s1.08-2.777 2.694-2.777c.73 0 1.276.205 1.687.535V1.2C14.503.953 13.947.82 13.29.82c-2.242 0-3.682 1.727-3.682 3.723 0 1.995 1.44 3.723 3.682 3.723zm-1.049 8.55c1.399 0 2.232-.874 2.242-1.954.031-2.428-3.363-2.026-3.353-3.59 0-.535.453-.926 1.111-.926.566 0 1.08.36 1.43.751l.637-.658c-.442-.535-1.172-1.029-2.088-1.029-1.203 0-2.077.813-2.088 1.852 0 2.355 3.364 1.862 3.364 3.59 0 .576-.473 1.018-1.193 1.018-.648 0-1.255-.401-1.656-.977l-.71.668c.463.71 1.327 1.255 2.304 1.255z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ScssIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ScssIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ScssIcon';
export default ForwardRef;