import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var JsIcon = function JsIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm6.205 12.97c1.368 0 1.944-.874 1.944-2.129V5.657h-.967v5.194c0 .7-.257 1.173-.987 1.173-.36 0-.74-.206-1.05-.535l-.606.73c.411.443 1.028.751 1.666.751zm5.359-.01c1.399 0 2.232-.874 2.242-1.954.031-2.428-3.363-2.027-3.353-3.59 0-.535.453-.926 1.11-.926.567 0 1.08.36 1.43.751l.638-.658c-.442-.535-1.172-1.029-2.088-1.029-1.203 0-2.077.813-2.088 1.852 0 2.355 3.364 1.861 3.364 3.59 0 .575-.473 1.018-1.193 1.018-.648 0-1.255-.401-1.656-.977l-.71.668c.463.71 1.327 1.255 2.304 1.255z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

JsIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(JsIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'JsIcon';
export default ForwardRef;