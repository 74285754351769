import styled from '@emotion/styled/macro';
import { padding, em } from 'polished';

export const AuthContainer = styled.div({
  ...padding(em(18)),
  boxSizing: 'border-box',
  display: 'flex',
  flex: '0 0 auto',
  flexDirection: 'column',
  minHeight: '100%',
  minWidth: em(366), // due to recaptcha
  width: '100%'
});
