import styled from '@emotion/styled/macro';
import { Icon } from '../Icon';
import { em, mix } from 'polished';

const baseBorderWidth = 0.5;
const baseLineHeight = 18 / 16;

export const TreeItemButton = styled.button({
  alignItems: 'center',
  appearance: 'none',
  backgroundColor: 'rgb(50,50,50)',
  border: 0,
  borderRadius: em(3),
  color: 'rgb(180,180,180)',
  display: 'flex',
  flex: '1 0 auto',
  font: 'inherit',
  lineHeight: baseLineHeight,
  padding: em(12 - baseBorderWidth),

  '&:hover, &:focus': {
    outline: 0,
    color: 'rgb(250,250,250)',
    position: 'relative'
  },
  '&:active, &[aria-selected="true"]': {
    backgroundColor: mix(0.15, 'rgb(0,0,0)', 'rgb(50,50,50)'),
    color: 'rgb(250,250,250)'
  },
  '&[aria-current="true"]': {
    backgroundColor: 'rgb(0,128,255)',
    color: 'rgb(250,250,250)'
  },

  [`${Icon}`]: {
    marginRight: em(12)
  }
});
