import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { borderWidth, em } from 'polished';

const variant = css({
  backgroundColor: 'rgb(24,24,24)',
  borderColor: 'rgb(50,50,50)'
});

export const TabsNav = styled.nav(
  {
    ...borderWidth(0, 0, 3),
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: em(42),
    borderStyle: 'solid'
  },
  variant
);
