import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var Arrow_2HeadIcon = function Arrow_2HeadIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.111 6c-.118 0-.148-.078-.06-.183L8.848.077c.084-.1.217-.105.305 0l4.798 5.74c.084.101.058.183-.06.183H4.11zm0 6h9.78c.118 0 .144.082.06.183l-4.798 5.74c-.088.105-.22.1-.305 0l-4.797-5.74c-.088-.105-.058-.182.06-.182z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

Arrow_2HeadIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(Arrow_2HeadIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'Arrow_2HeadIcon';
export default ForwardRef;