import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { space, position, layout, flexbox } from 'styled-system';
import { Input } from '../Input';
import { InputGroup, InputGroupPrepend } from '../InputGroup';

const ColorInputWrapper = styled.div(props => ({
  [`${Input}[type="color"]`]: {
    visibility: 'hidden',
    padding: 0,
    width: themeGet('space.6')(props),
    height: themeGet('space.6')(props)
  }
}));

const ColorSwatch = styled.label(props => ({
  backgroundColor: props.color || themeGet('color.black')(props),
  borderRadius: themeGet('radii.1')(props),
  width: 20,
  height: 20,
  margin: themeGet('space.2')(props) + 1
}));

export const ColorInput = styled(
  ({ value, onChange, className, onBlur, onFocus }) => {
    return (
      <ColorInputWrapper className={className}>
        <InputGroup>
          <InputGroupPrepend>
            <ColorSwatch color={value}>
              <Input type="color" value={value} onChange={onChange} />
            </ColorSwatch>
          </InputGroupPrepend>
          <Input
            type="text"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </InputGroup>
      </ColorInputWrapper>
    );
  }
)(space, position, layout, flexbox);

ColorInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
};

ColorInput.defaultProps = {
  onChange: () => {},
  value: '#212121'
};
