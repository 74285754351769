import color from 'ansi-colors';

import {
  appendTerminalOutput,
  changeDirectory,
  clearTerminalOutput,
  closePane,
  closeTab,
  copyFile,
  createDirectory,
  createFile,
  createTab,
  createWorkspace,
  detach,
  moveTab,
  printDirectory,
  printFile,
  printFind,
  printNodeInfo,
  printTree,
  printWorkingDirectory,
  readStdin,
  remove,
  removeSelectedNodes,
  rename,
  saveProject,
  setPaneProperty,
  setContainerProperty,
  setPaneSpacing,
  setSelectedWorkspace,
  setSelectedWorkspacePaneTabView,
  setSelection,
  setProps,
  splitPane,
  symLink,
  toggleCommandPalette,
  printContainerInfo,
  printPaneInfo,
  toggleTerminal,
  selectIncoming,
  upsertProjectSecret
} from '../commands';

import { getProcessIdFromWorkspace, serializeGraph } from '../queries';
import { wget, sourceFile } from '../utils';
import { commands, palette } from '../registry';
import { gitInit, gitAdd, checkTree, gitCommit } from '../app/git/commands';
import { setActiveProcess } from '../app/process/commands';
import {
  addGraph,
  addNode,
  addInput,
  addOutput,
  addGroup,
  connectNodes,
  autoLayout
} from '../app/flow/commands';

import {
  setSelectedWorkspacePane,
  setTabsProperty
} from '../app/workspace/commands';
import { build, register } from '../app/compiler/commands';

// TODO add context so this only shows in network view!
palette.register('Autolayout', {
  name: 'layout',
  args: {}
});

palette.register('Open Network', {
  name: 'open',
  args: { title: 'Network', view: 'Network' }
});

palette.register('Open Terminal', {
  name: 'open',
  args: { title: 'Terminal', view: 'Terminal' }
});

palette.register('Open FileBrowser', {
  name: 'open',
  args: { title: 'FileBrowser', view: 'FileBrowser' }
});

palette.register('Open Source', {
  name: 'open',
  args: { title: 'Source', view: 'Source' }
});

palette.register('Open Properties', {
  name: 'open',
  args: { title: 'Properties', view: 'Properties', spawn: false }
});

palette.register('Open Definitions', {
  name: 'open',
  args: { title: 'Definitions', view: 'Definitions' }
});

palette.register('Open WebView', {
  name: 'open',
  args: { title: 'WebView', view: 'WebView' }
});

palette.register('Split Left', {
  name: 'split',
  args: { direction: 'left', copy: true }
});

palette.register('Split Right', {
  name: 'split',
  args: { direction: 'right', copy: true }
});

palette.register('Split Up', {
  name: 'split',
  args: { direction: 'up', copy: true }
});

palette.register('Split Down', {
  name: 'split',
  args: { direction: 'down', copy: true }
});

palette.register('Toggle Palette', {
  name: 'palette',
  keys: 'metaKey+shiftKey+p',
  args: {}
});

palette.register('Toggle Terminal', {
  name: 'terminal',
  keys: 'ctrlKey+`',
  args: {}
});

palette.register('Tab: Open', {
  name: 'open',
  keys: 'ctrlKey+o',
  args: {}
});

palette.register('Tab: Close', {
  name: 'closetab',
  keys: 'ctrlKey+w',
  args: {}
});

commands.register(removeSelectedNodes, {
  name: 'removeSelectedNodes'
});

palette.register('Network: Remove Selected Node(s)', {
  name: 'removeSelectedNodes',
  keys: 'Backspace',
  context: 'Network'
});

palette.register('Navigate: Jump up a directory', {
  name: 'cd',
  keys: 'u',
  context: 'Network',
  args: {
    path: '../'
  }
});

palette.register('Navigate: Jump into selected directory', {
  name: 'cd',
  keys: 'Enter',
  context: 'Network',
  args: {
    // path: path is passed in via context (selections)
  }
});

// 'DOM: Evaluate': 'ctrlKey+r',
// 'Command Palette: Toggle': 'metaKey+shiftKey+p',
// 'Terminal: Toggle': 'esc',
// 'Pane: Select Left': 'ctrlKey+left',
// 'Pane: Select Right': 'ctrlKey+right',
// 'Pane: Select Above': 'ctrlKey+up',
// 'Pane: Select Below': 'ctrlKey+down',
// 'Tab: Select Left': 'shiftKey+ctrlKey+left',
// 'Tab: Select Right': 'shiftKey+ctrlKey+right',

const changeWorkspace = name => {
  setSelectedWorkspace(name);
  const pid = getProcessIdFromWorkspace({ name });
  return setActiveProcess(pid);
};

const workspace = ({ name, create }) => {
  return create ? createWorkspace(name) : changeWorkspace(name);
};

commands.register(wget, {
  name: 'wget',
  description: 'download files',
  args: [
    {
      _: true,
      name: 'uri',
      type: 'string',
      required: true
    },
    {
      name: 'script',
      type: 'boolean',
      alias: 's'
    }
  ]
});

commands.register(autoLayout, {
  name: 'layout'
});

commands.register(upsertProjectSecret, {
  name: 'upsert-secret'
});

commands.register(selectIncoming, {
  name: 'select-incoming'
});

commands.register(setProps, {
  name: 'set'
});

commands.register(setSelection, {
  name: 'select'
});

commands.register(saveProject, {
  name: 'save'
});

commands.register(gitInit, {
  name: 'git-init'
});

commands.register(checkTree, {
  name: 'git-check'
});

commands.register(gitCommit, {
  name: 'git-commit'
});

commands.register(gitAdd, {
  name: 'git-add'
});

commands.register(sourceFile, {
  name: 'source',
  description: 'source scripts',
  args: [
    {
      _: true,
      name: 'path',
      type: 'path',
      required: true
    }
  ]
});

commands.register(build, {
  name: 'build'
});

commands.register(register, {
  name: 'register'
});

commands.register(printContainerInfo, {
  name: 'container-info',
  description: 'print container info',
  args: [
    {
      _: true,
      name: 'root',
      type: 'path'
    }
  ]
});

commands.register(printPaneInfo, {
  name: 'pane-info',
  description: 'print pane info',
  args: [
    {
      _: true,
      name: 'root',
      type: 'path'
    }
  ]
});

commands.register(createTab, {
  name: 'open',
  description: 'open files',
  args: [
    {
      _: true,
      name: 'path',
      type: 'path',
      default: '.'
    },
    {
      name: 'pane',
      type: 'string'
    },
    {
      name: 'view',
      type: 'string',
      alias: 'v'
    },
    {
      name: 'title',
      type: 'string',
      alias: 't'
    },
    {
      name: 'icon',
      type: 'string',
      alias: 'i'
    },
    {
      name: 'follow',
      type: 'path',
      alias: 'f'
    },
    {
      name: 'pid',
      type: 'string',
      alias: 'p'
    },
    {
      name: 'spawn',
      type: 'boolean',
      default: true,
      alias: 's'
    }
  ]
});

commands.register(closeTab, {
  name: 'closetab',
  description: 'close tab',
  args: [
    {
      name: 'pane',
      type: 'string'
    },
    {
      _: true,
      name: 'index',
      type: 'number'
    }
  ]
});

commands.register(moveTab, {
  name: 'movetab',
  description: 'move tab',
  args: [
    {
      _: true,
      name: 'sourceTabs',
      type: 'path'
    },
    {
      _: true,
      name: 'targetTabs',
      type: 'path'
    },
    {
      _: true,
      name: 'sourceIndex',
      type: 'number'
    },
    {
      _: true,
      name: 'targetIndex',
      type: 'number'
    }
  ]
});

commands.register(
  ({ root }) => {
    const json = serializeGraph({ root, strict: false, recursive: true });
    console.log(JSON.stringify(json));
  },
  {
    name: 'serialize',
    description: 'serialize',
    args: [
      {
        _: true,
        name: 'root',
        type: 'path'
      }
    ]
  }
);

commands.register(setSelectedWorkspacePaneTabView, {
  name: 'view',
  description: 'set view type',
  args: [
    {
      _: true,
      name: 'type',
      type: 'string'
    }
  ]
});

commands.register(workspace, {
  name: 'workspace',
  description: 'workspace',
  args: [
    {
      _: true,
      name: 'name',
      type: 'string',
      required: true
    },
    {
      name: 'create',
      type: 'boolean',
      alias: 'c',
      default: false
    }
  ]
});

commands.register(copyFile, {
  name: 'cp',
  description: 'copy file or directories',
  args: [
    {
      _: true,
      name: 'source',
      type: 'path',
      description: 'source to copy',
      required: true
    },
    {
      _: true,
      name: 'target',
      description: 'target to copy to',
      type: 'path',
      required: true
    },
    {
      name: 'recursive',
      description: 'recursive copy',
      type: 'boolean',
      default: false,
      alias: 'r'
    }
  ]
});

commands.register(symLink, {
  name: 'ln',
  description: 'sym links',
  args: [
    {
      _: true,
      name: 'source',
      type: 'path',
      required: true
    },
    {
      _: true,
      name: 'target',
      type: 'path',
      required: true
    }
  ]
});

commands.register(printTree, {
  name: 'tree',
  description: 'print a file/directory tree',
  args: [
    {
      _: true,
      name: 'path',
      type: 'path',
      required: true,
      default: '.'
    },
    {
      name: 'hidden',
      type: 'boolean',
      default: false,
      alias: 'a'
    }
  ]
});

commands.register(createDirectory, {
  name: 'mkdir',
  description: 'make directories',
  args: [
    {
      _: true,
      name: 'paths',
      type: 'array',
      items: {
        type: 'path'
      }
    },
    {
      name: 'recursive',
      type: 'boolean',
      default: false,
      alias: 'p'
    }
  ]
});

commands.register(changeDirectory, {
  name: 'cd'
});

commands.register(printDirectory, {
  name: 'ls',
  description: 'print directory info',
  args: [
    {
      _: true,
      name: 'path',
      type: 'path',
      default: '.'
    },
    {
      name: 'long',
      type: 'boolean',
      default: false,
      alias: 'l'
    },
    {
      name: 'hidden',
      type: 'boolean',
      default: false,
      alias: 'a'
    }
  ]
});

commands.register(remove, {
  name: 'rm',
  description: 'remove files or directories',
  args: [
    {
      _: true,
      name: 'paths',
      type: 'array',
      items: {
        type: 'path'
      }
    },
    {
      name: 'recursive',
      type: 'boolean',
      default: false,
      alias: 'r'
    },
    {
      name: 'force',
      type: 'boolean',
      default: false,
      alias: 'f'
    }
  ]
});

commands.register(setPaneSpacing, {
  name: 'panespace',
  description: 'set panespacing',
  args: [
    {
      _: true,
      name: 'args',
      type: 'array',
      items: {
        type: 'number'
      }
    },
    {
      name: 'root',
      type: 'path'
    },
    {
      name: 'equal',
      type: 'boolean',
      default: false,
      alias: 'e'
    }
  ]
});

commands.register(closePane, {
  name: 'close',
  description: 'close pane',
  args: [
    {
      _: true,
      name: 'root',
      type: 'string'
    }
  ]
});

commands.register(toggleCommandPalette, {
  name: 'palette',
  description: 'toggle palette',
  args: []
});

commands.register(toggleTerminal, {
  name: 'terminal',
  description: 'toggle terminal',
  args: []
});

commands.register(printFind, {
  name: 'find',
  args: [
    {
      _: true,
      name: 'dir',
      type: 'path',
      required: true
    },
    {
      _: true,
      alias: 'n',
      name: 'name',
      type: 'string',
      required: true
    },
    {
      name: 'type',
      alias: 't'
      // oneOf:
    }
  ]
});

commands.register(setContainerProperty, {
  name: 'containerset',
  description: 'set container properties',
  args: [
    {
      _: true,
      name: 'prop',
      type: 'string'
    },
    {
      _: true,
      name: 'value',
      type: 'string'
    }
  ]
});

commands.register(setPaneProperty, {
  setContainerProperty,
  name: 'paneset',
  description: 'set pane properties',
  args: [
    {
      _: true,
      name: 'prop',
      type: 'string'
    },
    {
      _: true,
      name: 'value',
      type: 'string'
    }
  ]
});

commands.register(setTabsProperty, {
  name: 'tabsset',
  description: 'set tabs properties',
  args: [
    {
      _: true,
      name: 'prop',
      type: 'string'
    },
    {
      _: true,
      name: 'value',
      type: 'string'
    }
  ]
});

commands.register(splitPane, {
  name: 'split',
  description: 'split pane',
  args: [
    {
      _: true,
      description: 'pane identifier to split',
      name: 'root',
      type: 'string'
    },
    {
      name: 'direction',
      type: 'string',
      alias: 'd',
      filter: dir => {
        if (dir === 'u') return 'up';
        if (dir === 'd') return 'down';
        if (dir === 'l') return 'left';
        if (dir === 'r') return 'right';
        return dir;
      }
    },
    {
      name: 'locked',
      type: 'boolean',
      default: false,
      alias: 'l'
    },
    {
      name: 'targetable',
      type: 'boolean',
      default: true,
      alias: 't'
    },
    {
      name: 'closeable',
      type: 'boolean',
      default: true,
      alias: 'c'
    },
    {
      name: 'minWidth',
      type: 'number'
    },
    {
      name: 'maxWidth',
      type: 'number'
    },
    {
      name: 'minHeight',
      type: 'number'
    },
    {
      name: 'maxHeight',
      type: 'number'
    },
    {
      description: 'pane identifier to create',
      name: 'pane',
      type: 'string'
    },
    {
      name: 'select',
      type: 'boolean',
      default: true,
      alias: 's'
    },
    {
      name: 'copy',
      type: 'boolean',
      default: false
    }
  ]
});

commands.register(setSelectedWorkspacePane, { name: 'pane-select' });

commands.register(addGraph, { name: 'flow-add-graph' });
commands.register(addNode, { name: 'flow-add-node' });
commands.register(addGroup, { name: 'flow-add-group' });
commands.register(addInput, { name: 'flow-add-input' });
commands.register(addOutput, { name: 'flow-add-output' });
commands.register(connectNodes, { name: 'connect' });
commands.register(connectNodes, { name: 'flow-add-edge' });

commands.register(clearTerminalOutput, { name: 'clear' });
commands.register(createFile, {
  name: 'touch',
  args: [
    {
      _: true,
      name: 'paths',
      type: 'array',
      items: {
        type: 'path'
      },
      required: true
    }
  ]
});
commands.register(detach, { name: 'detach' });
commands.register(printNodeInfo, {
  name: 'info',
  args: [
    {
      _: true,
      name: 'path',
      type: 'path',
      required: true
    }
  ]
});
commands.register(printWorkingDirectory, { name: 'pwd' });
commands.register(printFile, {
  name: 'cat',
  args: [
    {
      _: true,
      type: 'path',
      name: 'path',
      required: true
    }
  ]
});
commands.register(readStdin, { name: 'read' });

commands.register(rename, {
  name: 'mv',
  args: [
    {
      _: true,
      name: 'sources',
      type: 'array',
      items: {
        type: 'path'
      },
      required: true
    },
    {
      _: true,
      name: 'target',
      type: 'path',
      required: true
    }
  ]
});
commands.register(saveProject, { name: 'save' });

commands.register(
  ({ cmd }) => {
    const command = commands.get(cmd);
    if (command) {
      const hasArguments = command.args && command.args.length;
      const padding = '\t\t';
      const output = [];
      output.push('\n');
      output.push(color.whiteBright('NAME'));
      output.push(padding + color.gray(cmd));
      if (command.description) output.push(padding + command.description);
      output.push('\n');
      output.push(color.whiteBright('SYNOPSIS'));

      const printArg = ({ type, required, name, _ }) => {
        const title = type === 'array' ? `${name} ...` : name;
        return required
          ? color.gray(`${_ ? `` : `--${name} `}<${title}>`)
          : color.gray(`${_ ? `` : `--${name} `}[<${title}>]`);
      };

      const props = hasArguments ? command.args.map(printArg) : [];

      output.push(`${padding}${color.gray(cmd)} ${props.join(' ')}`);
      output.push('\n');
      output.push(color.whiteBright('DESCRIPTION'));

      if (hasArguments)
        output.push(`${padding}The following options are available:\n`);
      const printOption = ({ required, alias, name, _ }) => {
        return alias
          ? color.whiteBright(`${padding}-${alias}`) +
              ' or ' +
              color.gray(`--${name}`)
          : color.whiteBright(`${padding}--${name}`);
      };
      const printOptionLine = ({ required, alias, name, _, description }) => {
        const opt = printOption({ required, alias, name, _ });
        return `${opt} ${required ? color.white(' *required ') : ''}${
          description ? description : ''
        }\n`;
      };

      const options = hasArguments ? command.args.map(printOptionLine) : [];

      output.push(options.join('\n'));
      return appendTerminalOutput(output.join('\n'));
    } else {
      return appendTerminalOutput(`No manual entry for ${cmd}`);
    }
  },
  {
    name: 'man',
    description: 'man pages',
    args: [
      {
        _: true,
        name: 'cmd',
        type: 'string',
        required: true
      }
    ]
  }
);
