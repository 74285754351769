import AToZIcon from './icons/a-to-z';
import AccessIcon from './icons/access';
import ActivityIcon from './icons/activity';
import AddFolderIcon from './icons/add-folder';
import AddPageIcon from './icons/add-page';
import AddVariationIcon from './icons/add-variation';
import AddressIcon from './icons/address';
import AdjustPropertiesIcon from './icons/adjust-properties';
import AirpageLogoTextIcon from './icons/airpage-logo-text';
import AirpageLogoIcon from './icons/airpage-logo';
import AjaxIcon from './icons/ajax';
import AlignCenterIcon from './icons/align-center';
import AlignToLeftIcon from './icons/align-to-left';
import AlignToRightIcon from './icons/align-to-right';
import ApiKeysIcon from './icons/api-keys';
import ArrayIcon from './icons/array';
import Arrow_2HeadIcon from './icons/arrow-2-head';
import AttachmentIcon from './icons/attachment';
import BellIcon from './icons/bell';
import BevelCornerIcon from './icons/bevel-corner';
import BillingsIcon from './icons/billings';
import BlockedUsersIcon from './icons/blocked-users';
import BodyIcon from './icons/body';
import BoltIcon from './icons/bolt';
import BooleanLeftIcon from './icons/boolean-left';
import BooleanRightIcon from './icons/boolean-right';
import BooleanIcon from './icons/boolean';
import BordersIcon from './icons/borders';
import CalendarIcon from './icons/calendar';
import CenterStrokeIcon from './icons/center-stroke';
import CheckboxIcon from './icons/checkbox';
import CheckedIcon from './icons/checked';
import CircleAddIcon from './icons/circle-add';
import CircleChangeIcon from './icons/circle-change';
import CircleCheckIcon from './icons/circle-check';
import CircleExclamationIcon from './icons/circle-exclamation';
import CircleMinusIcon from './icons/circle-minus';
import CirclePlusIcon from './icons/circle-plus';
import CloneIcon from './icons/clone';
import CloseIcon from './icons/close';
import CodeForkIcon from './icons/code-fork';
import CodeIcon from './icons/code';
import CollaboratorIcon from './icons/collaborator';
import ColorPickerIcon from './icons/color-picker';
import ColorRgbIcon from './icons/color-rgb';
import ColorRgbaIcon from './icons/color-rgba';
import ColorSchemeIcon from './icons/color-scheme';
import ColorIcon from './icons/color';
import ColumnViewIcon from './icons/column-view';
import ColumnIcon from './icons/column';
import ColumnsIcon from './icons/columns';
import CommentIcon from './icons/comment';
import ComparisonIcon from './icons/comparison';
import ComponentsIcon from './icons/components';
import ConstrainIcon from './icons/constrain';
import ContactInfoIcon from './icons/contact-info';
import ContactIcon from './icons/contact';
import CopyIcon from './icons/copy';
import CssIcon from './icons/css';
import CurrencyIcon from './icons/currency';
import CustomListIcon from './icons/custom-list';
import CustomizeFieldIcon from './icons/customize-field';
import DatabaseIcon from './icons/database';
import DateTimeIcon from './icons/date-time';
import DateIcon from './icons/date';
import DeleteIcon from './icons/delete';
import DesktopIcon from './icons/desktop';
import DifferenceIcon from './icons/difference';
import DivIcon from './icons/div';
import DomainIcon from './icons/domain';
import DoubleUnderlineIcon from './icons/double-underline';
import DownIcon from './icons/down';
import DownloadIcon from './icons/download';
import DuplicateIcon from './icons/duplicate';
import EditIcon from './icons/edit';
import EmailIcon from './icons/email';
import EmoticonIcon from './icons/emoticon';
import ExpandIcon from './icons/expand';
import EyeIcon from './icons/eye';
import FilesIcon from './icons/files';
import FilterIcon from './icons/filter';
import FloatIcon from './icons/float';
import FolderIcon from './icons/folder';
import FormIcon from './icons/form';
import GeolocationIcon from './icons/geolocation';
import GithubIcon from './icons/github';
import GoBackIcon from './icons/go-back';
import GradientIcon from './icons/gradient';
import GraphicIcon from './icons/graphic';
import GroupIcon from './icons/group';
import H1Icon from './icons/h1';
import H2Icon from './icons/h2';
import H3Icon from './icons/h3';
import H4Icon from './icons/h4';
import H5Icon from './icons/h5';
import H6Icon from './icons/h6';
import HamburgerIcon from './icons/hamburger';
import HideIcon from './icons/hide';
import HierarchyIcon from './icons/hierarchy';
import HistoryIcon from './icons/history';
import HorizontalSplitIcon from './icons/horizontal-split';
import ImageIcon from './icons/image';
import InsertLeftIcon from './icons/insert-left';
import InsertRightIcon from './icons/insert-right';
import InsideStrokeIcon from './icons/inside-stroke';
import IntegerIcon from './icons/integer';
import IntegrationIcon from './icons/integration';
import IntersectIcon from './icons/intersect';
import JsIcon from './icons/js';
import JustifyCenterIcon from './icons/justify-center';
import JustifyLeftIcon from './icons/justify-left';
import JustifyRightIcon from './icons/justify-right';
import JustifyIcon from './icons/justify';
import KeybindingIcon from './icons/keybinding';
import LayoutIcon from './icons/layout';
import LeftIcon from './icons/left';
import LibraryIcon from './icons/library';
import ListViewIcon from './icons/list-view';
import ListIcon from './icons/list';
import LockIcon from './icons/lock';
import MapOIcon from './icons/map-o';
import MarginsIcon from './icons/margins';
import MathIcon from './icons/math';
import MaxIcon from './icons/max';
import MediaIcon from './icons/media';
import MenuIcon from './icons/menu';
import MinIcon from './icons/min';
import MobileLandscapeIcon from './icons/mobile-landscape';
import MobilePortraitIcon from './icons/mobile-portrait';
import MoreIcon from './icons/more';
import MultiSelectIcon from './icons/multi-select';
import NetworkViewIcon from './icons/network-view';
import NoColorIcon from './icons/no-color';
import NodePathIcon from './icons/node-path';
import NotificationsIcon from './icons/notifications';
import NullIcon from './icons/null';
import NumberIcon from './icons/number';
import ObjectIcon from './icons/object';
import OrderedMenuIcon from './icons/ordered-menu';
import OrganizationProfileIcon from './icons/organization-profile';
import OrganizationIcon from './icons/organization';
import OutsideStrokeIcon from './icons/outside-stroke';
import PackageIcon from './icons/package';
import PaddingsIcon from './icons/paddings';
import PageIcon from './icons/page';
import PaneContainerIcon from './icons/pane-container';
import PaneIcon from './icons/pane';
import ParagraphIcon from './icons/paragraph';
import PasswordIcon from './icons/password';
import PdfFileIcon from './icons/pdf-file';
import PhoneNumberIcon from './icons/phone-number';
import PlansIcon from './icons/plans';
import PlayCircleOIcon from './icons/play-circle-o';
import PluckIcon from './icons/pluck';
import PlusIcon from './icons/plus';
import PostIcon from './icons/post';
import PowerButtonIcon from './icons/power-button';
import PressIcon from './icons/press';
import PrinterIcon from './icons/printer';
import PropertiesIcon from './icons/properties';
import PropertyIcon from './icons/property';
import PublicProfileIcon from './icons/public-profile';
import PublishIcon from './icons/publish';
import RadioButtonIcon from './icons/radio-button';
import ReferenceIcon from './icons/reference';
import RefreshIcon from './icons/refresh';
import RenameIcon from './icons/rename';
import ResponsiveIcon from './icons/responsive';
import RightIcon from './icons/right';
import RootIcon from './icons/root';
import RoundCornerIcon from './icons/round-corner';
import RoutingLeftIcon from './icons/routing-left';
import RoutingRightIcon from './icons/routing-right';
import SaveIcon from './icons/save';
import SchemaBuilderIcon from './icons/schema-builder';
import ScssIcon from './icons/scss';
import SearchIcon from './icons/search';
import SectionIcon from './icons/section';
import SecurityIcon from './icons/security';
import SendIcon from './icons/send';
import SessionIcon from './icons/session';
import ShareIcon from './icons/share';
import ShoppingCartIcon from './icons/shopping-cart';
import SingleSelectIcon from './icons/single-select';
import SitemapIcon from './icons/sitemap';
import SnapshotIcon from './icons/snapshot';
import SolidColorIcon from './icons/solid-color';
import SpacerIcon from './icons/spacer';
import SplitIcon from './icons/split';
import SquareCornerIcon from './icons/square-corner';
import StampIcon from './icons/stamp';
import StarIcon from './icons/star';
import StrikeThroughIcon from './icons/strike-through';
import StringIcon from './icons/string';
import StyleRuleIcon from './icons/style-rule';
import StyleIcon from './icons/style';
import StylesheetIcon from './icons/stylesheet';
import SubgraphIcon from './icons/subgraph';
import SubtractIcon from './icons/subtract';
import SymbolsIcon from './icons/symbols';
import TabIcon from './icons/tab';
import TabletIcon from './icons/tablet';
import TagIcon from './icons/tag';
import TelephoneIcon from './icons/telephone';
import TerminalIcon from './icons/terminal';
import TextIcon from './icons/text';
import ThemesIcon from './icons/themes';
import ThumbnailViewIcon from './icons/thumbnail-view';
import TileViewIcon from './icons/tile-view';
import TimeMachineIcon from './icons/time-machine';
import ToggleLeftIcon from './icons/toggle-left';
import ToggleRightIcon from './icons/toggle-right';
import ToggleIcon from './icons/toggle';
import TrashbinIcon from './icons/trashbin';
import TypographyIcon from './icons/typography';
import UnderlineIcon from './icons/underline';
import UnionIcon from './icons/union';
import UnitIcon from './icons/unit';
import UpIcon from './icons/up';
import UploadIcon from './icons/upload';
import UrlIcon from './icons/url';
import UsernameIcon from './icons/username';
import VerticalSplitIcon from './icons/vertical-split';
import WebPageIcon from './icons/web-page';
import WindowManagerIcon from './icons/window-manager';
import WorkspaceIcon from './icons/workspace';
import ZToAIcon from './icons/z-to-a';
var icons = {
  'a-to-z': AToZIcon,
  'access': AccessIcon,
  'activity': ActivityIcon,
  'add-folder': AddFolderIcon,
  'add-page': AddPageIcon,
  'add-variation': AddVariationIcon,
  'address': AddressIcon,
  'adjust-properties': AdjustPropertiesIcon,
  'airpage-logo-text': AirpageLogoTextIcon,
  'airpage-logo': AirpageLogoIcon,
  'ajax': AjaxIcon,
  'align-center': AlignCenterIcon,
  'align-to-left': AlignToLeftIcon,
  'align-to-right': AlignToRightIcon,
  'api-keys': ApiKeysIcon,
  'array': ArrayIcon,
  'arrow-2-head': Arrow_2HeadIcon,
  'attachment': AttachmentIcon,
  'bell': BellIcon,
  'bevel-corner': BevelCornerIcon,
  'billings': BillingsIcon,
  'blocked-users': BlockedUsersIcon,
  'body': BodyIcon,
  'bolt': BoltIcon,
  'boolean-left': BooleanLeftIcon,
  'boolean-right': BooleanRightIcon,
  'boolean': BooleanIcon,
  'borders': BordersIcon,
  'calendar': CalendarIcon,
  'center-stroke': CenterStrokeIcon,
  'checkbox': CheckboxIcon,
  'checked': CheckedIcon,
  'circle-add': CircleAddIcon,
  'circle-change': CircleChangeIcon,
  'circle-check': CircleCheckIcon,
  'circle-exclamation': CircleExclamationIcon,
  'circle-minus': CircleMinusIcon,
  'circle-plus': CirclePlusIcon,
  'clone': CloneIcon,
  'close': CloseIcon,
  'code-fork': CodeForkIcon,
  'code': CodeIcon,
  'collaborator': CollaboratorIcon,
  'color-picker': ColorPickerIcon,
  'color-rgb': ColorRgbIcon,
  'color-rgba': ColorRgbaIcon,
  'color-scheme': ColorSchemeIcon,
  'color': ColorIcon,
  'column-view': ColumnViewIcon,
  'column': ColumnIcon,
  'columns': ColumnsIcon,
  'comment': CommentIcon,
  'comparison': ComparisonIcon,
  'components': ComponentsIcon,
  'constrain': ConstrainIcon,
  'contact-info': ContactInfoIcon,
  'contact': ContactIcon,
  'copy': CopyIcon,
  'css': CssIcon,
  'currency': CurrencyIcon,
  'custom-list': CustomListIcon,
  'customize-field': CustomizeFieldIcon,
  'database': DatabaseIcon,
  'date-time': DateTimeIcon,
  'date': DateIcon,
  'delete': DeleteIcon,
  'desktop': DesktopIcon,
  'difference': DifferenceIcon,
  'div': DivIcon,
  'domain': DomainIcon,
  'double-underline': DoubleUnderlineIcon,
  'down': DownIcon,
  'download': DownloadIcon,
  'duplicate': DuplicateIcon,
  'edit': EditIcon,
  'email': EmailIcon,
  'emoticon': EmoticonIcon,
  'expand': ExpandIcon,
  'eye': EyeIcon,
  'files': FilesIcon,
  'filter': FilterIcon,
  'float': FloatIcon,
  'folder': FolderIcon,
  'form': FormIcon,
  'geolocation': GeolocationIcon,
  'github': GithubIcon,
  'go-back': GoBackIcon,
  'gradient': GradientIcon,
  'graphic': GraphicIcon,
  'group': GroupIcon,
  'h1': H1Icon,
  'h2': H2Icon,
  'h3': H3Icon,
  'h4': H4Icon,
  'h5': H5Icon,
  'h6': H6Icon,
  'hamburger': HamburgerIcon,
  'hide': HideIcon,
  'hierarchy': HierarchyIcon,
  'history': HistoryIcon,
  'horizontal-split': HorizontalSplitIcon,
  'image': ImageIcon,
  'insert-left': InsertLeftIcon,
  'insert-right': InsertRightIcon,
  'inside-stroke': InsideStrokeIcon,
  'integer': IntegerIcon,
  'integration': IntegrationIcon,
  'intersect': IntersectIcon,
  'js': JsIcon,
  'justify-center': JustifyCenterIcon,
  'justify-left': JustifyLeftIcon,
  'justify-right': JustifyRightIcon,
  'justify': JustifyIcon,
  'keybinding': KeybindingIcon,
  'layout': LayoutIcon,
  'left': LeftIcon,
  'library': LibraryIcon,
  'list-view': ListViewIcon,
  'list': ListIcon,
  'lock': LockIcon,
  'map-o': MapOIcon,
  'margins': MarginsIcon,
  'math': MathIcon,
  'max': MaxIcon,
  'media': MediaIcon,
  'menu': MenuIcon,
  'min': MinIcon,
  'mobile-landscape': MobileLandscapeIcon,
  'mobile-portrait': MobilePortraitIcon,
  'more': MoreIcon,
  'multi-select': MultiSelectIcon,
  'network-view': NetworkViewIcon,
  'no-color': NoColorIcon,
  'node-path': NodePathIcon,
  'notifications': NotificationsIcon,
  'null': NullIcon,
  'number': NumberIcon,
  'object': ObjectIcon,
  'ordered-menu': OrderedMenuIcon,
  'organization-profile': OrganizationProfileIcon,
  'organization': OrganizationIcon,
  'outside-stroke': OutsideStrokeIcon,
  'package': PackageIcon,
  'paddings': PaddingsIcon,
  'page': PageIcon,
  'pane-container': PaneContainerIcon,
  'pane': PaneIcon,
  'paragraph': ParagraphIcon,
  'password': PasswordIcon,
  'pdf-file': PdfFileIcon,
  'phone-number': PhoneNumberIcon,
  'plans': PlansIcon,
  'play-circle-o': PlayCircleOIcon,
  'pluck': PluckIcon,
  'plus': PlusIcon,
  'post': PostIcon,
  'power-button': PowerButtonIcon,
  'press': PressIcon,
  'printer': PrinterIcon,
  'properties': PropertiesIcon,
  'property': PropertyIcon,
  'public-profile': PublicProfileIcon,
  'publish': PublishIcon,
  'radio-button': RadioButtonIcon,
  'reference': ReferenceIcon,
  'refresh': RefreshIcon,
  'rename': RenameIcon,
  'responsive': ResponsiveIcon,
  'right': RightIcon,
  'root': RootIcon,
  'round-corner': RoundCornerIcon,
  'routing-left': RoutingLeftIcon,
  'routing-right': RoutingRightIcon,
  'save': SaveIcon,
  'schema-builder': SchemaBuilderIcon,
  'scss': ScssIcon,
  'search': SearchIcon,
  'section': SectionIcon,
  'security': SecurityIcon,
  'send': SendIcon,
  'session': SessionIcon,
  'share': ShareIcon,
  'shopping-cart': ShoppingCartIcon,
  'single-select': SingleSelectIcon,
  'sitemap': SitemapIcon,
  'snapshot': SnapshotIcon,
  'solid-color': SolidColorIcon,
  'spacer': SpacerIcon,
  'split': SplitIcon,
  'square-corner': SquareCornerIcon,
  'stamp': StampIcon,
  'star': StarIcon,
  'strike-through': StrikeThroughIcon,
  'string': StringIcon,
  'style-rule': StyleRuleIcon,
  'style': StyleIcon,
  'stylesheet': StylesheetIcon,
  'subgraph': SubgraphIcon,
  'subtract': SubtractIcon,
  'symbols': SymbolsIcon,
  'tab': TabIcon,
  'tablet': TabletIcon,
  'tag': TagIcon,
  'telephone': TelephoneIcon,
  'terminal': TerminalIcon,
  'text': TextIcon,
  'themes': ThemesIcon,
  'thumbnail-view': ThumbnailViewIcon,
  'tile-view': TileViewIcon,
  'time-machine': TimeMachineIcon,
  'toggle-left': ToggleLeftIcon,
  'toggle-right': ToggleRightIcon,
  'toggle': ToggleIcon,
  'trashbin': TrashbinIcon,
  'typography': TypographyIcon,
  'underline': UnderlineIcon,
  'union': UnionIcon,
  'unit': UnitIcon,
  'up': UpIcon,
  'upload': UploadIcon,
  'url': UrlIcon,
  'username': UsernameIcon,
  'vertical-split': VerticalSplitIcon,
  'web-page': WebPageIcon,
  'window-manager': WindowManagerIcon,
  'workspace': WorkspaceIcon,
  'z-to-a': ZToAIcon
};
export default icons;