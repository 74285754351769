import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PasswordIcon = function PasswordIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M16.304 1.696a5.807 5.807 0 010 8.204c-1.545 1.546-3.783 2.042-5.848 1.373l-1.352 1.334a.527.527 0 01-.373.155h-.964v.982a.529.529 0 01-.588.523l-.801-.093-.105 1.11a.526.526 0 01-.475.476l-1.11.104.092.802a.527.527 0 01-.15.433l-.747.746a.527.527 0 01-.372.155H.527A.527.527 0 010 17.473v-2.984c0-.14.056-.274.155-.372l6.532-6.533A5.799 5.799 0 018.1 1.696a5.807 5.807 0 018.204 0zM14.56 5.561a1.502 1.502 0 000-2.122 1.502 1.502 0 00-2.122 0 1.502 1.502 0 000 2.122 1.502 1.502 0 002.122 0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PasswordIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PasswordIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PasswordIcon';
export default ForwardRef;