import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { em } from 'polished';

const variant = props => {
  switch (props.variant) {
    case 'info':
      return css({
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderColor: 'rgba(0,0,0,0.3)',
        '&::before': {
          backgroundColor: 'rgb(179,210,255)',
          borderColor: 'rgb(23,66,130)'
        }
      });
    case 'success':
      return css({
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderColor: 'rgba(0,0,0,0.3)',
        '&::before': {
          backgroundColor: 'rgb(76,255,243)',
          borderColor: 'rgb(116,179,172)'
        }
      });
    case 'warning':
      return css({
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderColor: 'rgba(0,0,0,0.3)',
        '&::before': {
          backgroundColor: 'rgb(255,249,179)',
          borderColor: 'rgb(102,95,20)'
        }
      });
    case 'danger':
      return css({
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderColor: 'rgba(0,0,0,0.3)',
        '&::before': {
          backgroundColor: 'rgb(255,179,185)',
          borderColor: 'rgb(128,26,34)'
        }
      });
    default:
      return null;
  }
};

export const Port = styled.div(
  {
    borderRadius: em(6),
    borderStyle: 'solid',
    borderWidth: 1,
    height: em(12 - 2),
    width: em(12 - 2),
    '&::before': {
      margin: em(1),
      display: 'flex',
      borderRadius: em(4),
      borderStyle: 'solid',
      borderWidth: 1,
      content: '""',
      height: em(8 - 2),
      width: em(8 - 2)
    }
  },
  variant
);

Port.propTypes = {
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'danger']).isRequired
};
