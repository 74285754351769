import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useDropzone } from 'react-dropzone';
import themeGet from '@styled-system/theme-get';
import { Box, Button, Icon, Text } from '../ui';

const ThumbWrap = styled(Box)(props => ({
  display: 'inline-flex',
  borderRadius: 4,
  width: 119,
  height: 119,
  boxSizing: 'border-box',
  transition: `all ${themeGet('duration.standard')(props)}ms ${themeGet(
    'easing.easeOutQuart'
  )(props)}`,
  borderWidth: 0.5,
  borderColor: props.isDragActive
    ? themeGet('colors.blue.1')(props)
    : themeGet('colors.border')(props),
  borderStyle: !props.isActive ? 'dashed' : 'none',
  color: props.isDragActive
    ? themeGet('colors.blue.1')(props)
    : themeGet('textColors.primary')(props)
}));

const ThumbInner = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden'
});

const Thumb = styled.img({
  display: 'block',
  width: 'auto',
  height: '100%'
});

export const FaviconUpload = ({
  fluid,
  accept,
  onDrop: onDropProp,
  children,
  DropZoneProps,
  ...rest
}) => {
  const [favicon, setFavicon] = useState({ preview: null });
  const onDrop = useCallback(
    acceptedFiles => {
      const acceptedFavicon = acceptedFiles[0];
      setFavicon({
        ...acceptedFavicon,
        preview: URL.createObjectURL(acceptedFavicon)
      });

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          // dimension is available now
          acceptedFavicon.width = img.width;
          acceptedFavicon.height = img.height;
          onDropProp(acceptedFavicon);
        };
        img.src = reader.result;
      };

      reader.readAsDataURL(acceptedFavicon);
    },
    [onDropProp]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    ...DropZoneProps
  });

  return (
    <Box
      {...rest}
      {...getRootProps()}
      display={fluid ? 'flex' : 'inline-flex'}
      flexDirection="column"
    >
      <input {...getInputProps()} />
      <ThumbWrap
        isActive={Boolean(favicon.preview)}
        isDragActive={isDragActive}
      >
        <ThumbInner>
          {favicon.preview && <Thumb src={favicon.preview} />}
          {!favicon.preview && (
            <Box textAlign="center">
              <Icon name="upload" size={30} color="inherit" />
              <Text variant="caption" color="inherit">
                Upload file
              </Text>
            </Box>
          )}
        </ThumbInner>
      </ThumbWrap>
      {children || <Button marginTop="3">Update</Button>}
    </Box>
  );
};

FaviconUpload.propTypes = {
  onDrop: PropTypes.func,
  accept: PropTypes.string,
  fluid: PropTypes.bool
};

FaviconUpload.defaultProps = {
  fluid: false,
  onDrop: () => {},
  accept: 'image/*',
  DropZoneProps: {}
};
