import gql from 'graphql-tag';

export const SignUpMutation = gql`
  mutation SignUpMutation(
    $displayName: String
    $email: String!
    $password: String
    $inviteToken: String
    $acceptTerms: Boolean
  ) {
    signUp(
      input: {
        displayName: $displayName
        email: $email
        password: $password
        inviteToken: $inviteToken
        acceptTerms: $acceptTerms
      }
    ) {
      token {
        accessToken
        roleId
      }
    }
  }
`;
