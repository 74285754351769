import styled from '@emotion/styled/macro';

export const Tabs = styled.div({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto',
  flexDirection: 'column',
  font: 'inherit',
  lineHeight: 18 / 16
});
