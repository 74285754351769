import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var HistoryIcon = function HistoryIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9.908 2.272l.592.577-.69.671L8 1.76 9.81 0l.69.671-.72.7C14.399 1.871 18 5.991 18 11h-.833c0-4.476-3.164-8.171-7.26-8.728zM7 12V6H6v6h1zm-.5 6a6.5 6.5 0 110-13 6.5 6.5 0 010 13zm.5-7v1h4v-1H7z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

HistoryIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(HistoryIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'HistoryIcon';
export default ForwardRef;