import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PdfFileIcon = function PdfFileIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2 .25A.25.25 0 012.25 0h9.5a.25.25 0 01.25.25v3.5c0 .138.112.25.25.25h3.5a.25.25 0 01.25.25V8H2V.25zm11 .354a.25.25 0 01.427-.177l2.146 2.146a.25.25 0 01-.177.427H13.25a.25.25 0 01-.25-.25V.604zM3.511 10.58v3.205h.824v-1.05h.516c.233 0 .428-.022.584-.087.157-.064.284-.166.412-.292a.957.957 0 00.223-.345c.05-.138.068-.27.068-.414 0-.185-.034-.35-.103-.482a.842.842 0 00-.292-.328c-.107-.07-.22-.12-.36-.155a2.116 2.116 0 00-.515-.051H3.51v-.001zm3.211 0v3.205h1.133c.255 0 .467-.014.653-.051.187-.038.36-.112.516-.207.207-.126.379-.299.515-.535.136-.237.206-.51.206-.81 0-.31-.061-.581-.189-.81a1.44 1.44 0 00-.515-.551 1.58 1.58 0 00-.481-.173 2.914 2.914 0 00-.67-.069H6.723l-.001.001zm3.657 0v3.205h.824v-1.378h1.357v-.62h-1.357V11.2h1.46v-.62h-2.284zm-6.044.603h.138c.135 0 .244-.004.326 0a.763.763 0 01.258.052.402.402 0 01.172.138c.048.064.068.15.068.241 0 .093-.01.16-.034.224a.433.433 0 01-.344.277c-.09.017-.203.017-.343.017h-.24v-.948zm3.211 0h.206c.178 0 .308.002.395.017.089.015.19.045.275.086a.776.776 0 01.36.345c.078.15.12.34.12.551 0 .213-.05.39-.12.535a.787.787 0 01-.326.344 1.018 1.018 0 01-.275.104c-.091.017-.233.017-.429.017h-.206v-1.999zM2 16h14v1.75a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V16z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PdfFileIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PdfFileIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PdfFileIcon';
export default ForwardRef;