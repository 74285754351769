import React from 'react';

class RadioGroup extends React.Component {
  static defaultProps = {
    defaultSelectedIndex: 0,
    options: [],
    onChange: () => undefined
  };
  state = { selectedItem: this.props.options[this.props.defaultSelectedIndex] };
  references = {};
  setSelectedItem = selectedItem => {
    this.setState(() => ({ selectedItem }));
    this.references[selectedItem].focus();
    this.props.onChange(selectedItem);
  };
  itemViewProps = (item, stateFn = () => ({})) => ({
    role: 'radio',
    'aria-checked': item === this.state.selectedItem,
    tabIndex: item === this.state.selectedItem ? 0 : -1,
    ...stateFn(item === this.state.selectedItem),
    ref: node => {
      if (node) {
        this.references[item] = node;
      }
    },
    onClick: () => this.setSelectedItem(item),
    onKeyDown: event => {
      const currentIndex = this.props.options.indexOf(this.state.selectedItem);
      switch (event.key) {
        case 'ArrowLeft':
        case 'ArrowUp':
          this.setSelectedItem(
            this.props.options[Math.max(0, currentIndex - 1)]
          );
          break;
        case 'ArrowRight':
        case 'ArrowDown':
          this.setSelectedItem(
            this.props.options[
              Math.min(this.props.options.length - 1, currentIndex + 1)
            ]
          );
          break;
        default:
          break;
      }
    }
  });

  collectionViewProps = label => ({ role: 'radiogroup', 'aria-label': label });
  render() {
    return this.props.children({
      setSelectedItem: this.setSelectedItem,
      itemViewProps: this.itemViewProps,
      collectionViewProps: this.collectionViewProps
    });
  }
}

export { RadioGroup };
