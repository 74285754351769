import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import themeGet from '@styled-system/theme-get';
import { padding } from 'polished';
import { Text, Grid, GridRow, GridRowCell } from '../../ui';
import { List } from './List';

const ListHeaderWrap = styled.div(props => ({
  ...padding(themeGet('space.3')(props), themeGet('space.4')(props)),
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  borderWidth: 0.5,
  borderStyle: 'solid',
  borderColor: themeGet('colors.border')(props),
  borderBottom: 'none',
  [`& + ${List}`]: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }
}));

export const ListHeader = ({ title, children, className }) => {
  return (
    <ListHeaderWrap className={className}>
      <Grid>
        <GridRow alignItems="center" justifyContent="space-between">
          <GridRowCell>
            <Text as="h2" m="0" variant="header">
              {title}
            </Text>
          </GridRowCell>
          <GridRowCell>{children}</GridRowCell>
        </GridRow>
      </Grid>
    </ListHeaderWrap>
  );
};

ListHeader.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node
};
