import gql from 'graphql-tag';
import { ContentFeedFragment } from '../fragments';

export const ContentQuery = gql`
  query ContentQuery($id: UUID!) {
    content(id: $id) {
      ...ContentFeedFragment
    }
  }
  ${ContentFeedFragment}
`;
