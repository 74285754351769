export const chainFunctions = (...funcs) => {
  return funcs.reduce(
    (acc, func) => {
      if (!func) return acc;
      return function chainedFunc(...args) {
        acc.apply(this, args);
        func.apply(this, args);
      };
    },
    () => {}
  );
};
