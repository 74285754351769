import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import {
  Box,
  Divider,
  Icon,
  Menu,
  MenuBar,
  MenuBarList,
  MenuBarListItem,
  MenuBarListItemLink,
  MenuItem,
  MenuItemLink,
  Select,
  Text
} from '../ui';
import { CurrentRoleQuery } from '../queries';
import { CurrentAvatar } from './CurrentAvatar';
import { SignOutLink } from './SignOutLink';
import CreateOrgForm from './CreateOrgForm';

import { Popup } from './Popup';
import { NotificationCenter } from './NotificationCenter';

export const DashboardMenu = () => {
  const {
    data: {
      currentRole = {
        organization: { username: 'Organization name' }
      }
    }
  } = useQuery(CurrentRoleQuery);

  return (
    <MenuBar aria-label="Main menu">
      <MenuBarList px="3" py="2">
        <MenuBarListItem>
          <MenuBarListItemLink as={Link} to="/">
            <Icon
              name="airpage-logo"
              title="Go to Dashboard"
              size={18}
              fill="rgb(0,128,255)"
            />
          </MenuBarListItemLink>
          <Divider height="30px" width="1px" marginLeft="1" marginRight="3" />
        </MenuBarListItem>
        <MenuBarListItem fluid={true}>
          <Select
            id="custom"
            value={currentRole.organization.username}
            valueToString={v => String(v)}
            renderValue={value => (
              <MenuItem as="div">
                <MenuItemLink as="div">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CurrentAvatar size={16} marginRight="2" marginBottom="1" />
                    <Text variant="body" as="span" margin="0" lineHeight="1">
                      {value}
                    </Text>
                  </Box>
                </MenuItemLink>
              </MenuItem>
            )}
            renderOption={({ option }) => (
              <MenuItem as="div">
                <MenuItemLink as="div">
                  <Box display="flex">
                    <Text m="0">{option}</Text>
                  </Box>
                </MenuItemLink>
              </MenuItem>
            )}
            options={[currentRole.organization.username]}
            aria-label="Current user"
            footer={
              <Box>
                <Divider width="100%" height="1px" />
                <Box p="3" textAlign="center">
                  <CreateOrgForm />
                </Box>
              </Box>
            }
            MenuProps={{
              width: 200
            }}
            PopupProps={{
              closeOnClickAway: false
            }}
            width={200}
            margin="2"
          />
        </MenuBarListItem>
        <MenuBarListItem>
          <NotificationCenter />
        </MenuBarListItem>
        <MenuBarListItem>
          <Divider height="30px" width="1px" mx="3" />
          <Popup
            placement="bottom-end"
            modifiers={{ shift: { enabled: true } }}
            popup={
              <Menu aria-label="User">
                <MenuItem>
                  <MenuItemLink as={SignOutLink}>Sign out</MenuItemLink>
                </MenuItem>
              </Menu>
            }
          >
            {({ setExpanded }) => (
              <MenuBarListItemLink
                tabIndex={-1}
                href="#"
                id="avatar-link"
                aria-controls="avatar-menu"
                onClick={event => {
                  event.preventDefault();
                  setExpanded(expanded => !expanded);
                }}
              >
                <CurrentAvatar size={24} />
              </MenuBarListItemLink>
            )}
          </Popup>
        </MenuBarListItem>
      </MenuBarList>
    </MenuBar>
  );
};
