import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var HamburgerIcon = function HamburgerIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.25 3h17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H.25A.25.25 0 010 4.75v-1.5A.25.25 0 01.25 3zm0 5h17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H.25A.25.25 0 010 9.75v-1.5A.25.25 0 01.25 8zm6 5h11.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H6.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

HamburgerIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(HamburgerIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'HamburgerIcon';
export default ForwardRef;