import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var H1Icon = function H1Icon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm8.95 6.086v3.096h-3.56V6.086h-.976v7.2h.977v-3.158h3.559v3.158h.977v-7.2h-.977zm4.402-.113l-2.263 1.419.442.802.957-.586v5.678h.967V5.973h-.103z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

H1Icon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(H1Icon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'H1Icon';
export default ForwardRef;