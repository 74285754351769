import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var TypographyIcon = function TypographyIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M7.843 5.05l-1.84 5.274c.239 0 .73.008 1.477.024a88.66 88.66 0 001.736.023c.137 0 .343-.008.617-.023-.627-1.977-1.29-3.743-1.99-5.297zM0 18l.022-.926c.166-.054.367-.103.605-.146s.444-.084.617-.123c.173-.04.352-.096.535-.17a1.29 1.29 0 00.482-.34c.137-.152.249-.35.335-.592L5.16 8.484 8.189 0h1.384c.058.11.098.191.12.246l2.217 5.625c.238.61.62 1.615 1.146 3.018.527 1.402.938 2.474 1.234 3.216.108.266.317.83.627 1.694.31.863.57 1.521.779 1.974.144.352.27.575.379.668.137.118.454.233.951.346.498.113.8.193.91.24.042.297.064.52.064.668 0 .032-.002.082-.005.153-.004.07-.006.12-.006.152-.454 0-1.14-.031-2.055-.094a33.151 33.151 0 00-2.066-.093c-.548 0-1.324.027-2.326.082a97.106 97.106 0 01-1.925.093c0-.336.014-.64.043-.914l1.417-.328a6.371 6.371 0 00.303-.07 2.42 2.42 0 00.157-.053.703.703 0 00.28-.17.318.318 0 00.098-.129.456.456 0 00.027-.164c0-.125-.111-.502-.335-1.13a73.453 73.453 0 00-.779-2.08 117.22 117.22 0 01-.454-1.173l-4.868-.023c-.187.453-.463 1.217-.828 2.29-.364 1.075-.546 1.71-.546 1.905 0 .172.05.319.152.44.1.12.257.216.47.287.213.07.388.123.525.158s.342.068.616.1c.274.03.422.046.444.046.007.149.01.375.01.68 0 .07-.006.176-.02.317-.42 0-1.048-.04-1.888-.118-.84-.078-1.47-.117-1.888-.117a1.45 1.45 0 00-.287.047c-.133.031-.21.047-.232.047C1.457 17.946.779 18 0 18z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

TypographyIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(TypographyIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'TypographyIcon';
export default ForwardRef;