import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AddressIcon = function AddressIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2.097 0h13.806c.053 0 .097.048.097.107v17.786c0 .059-.044.107-.097.107H2.097C2.044 18 2 17.952 2 17.893V.107C2 .048 2.044 0 2.097 0zM4 14v1h10v-1H4zm0 2v1h3v-1H4zm4 0v1h5.778v-1H8zm5-10.192C13 3.708 11.206 2 9 2S5 3.708 5 5.808c0 .63.163 1.225.449 1.749l.011.026 2.754 4.966.003.005c.15.265.445.446.783.446s.633-.18.783-.446l.003-.005 2.754-4.966.011-.026A3.636 3.636 0 0013 5.807zM9 8a2 2 0 110-4 2 2 0 010 4z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AddressIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AddressIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AddressIcon';
export default ForwardRef;