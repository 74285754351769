import gql from 'graphql-tag';

export const ProjectsOrderByEnumValuesQuery = gql`
  query ProjectsOrderByEnumValuesQuery {
    __type(name: "ProjectsOrderBy") {
      enumValues {
        name
      }
    }
  }
`;
