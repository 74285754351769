import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var BellIcon = function BellIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M14.362 12.53l1.574 1.573a.25.25 0 01.073.177v.47a.25.25 0 01-.25.25h-13.5a.25.25 0 01-.25-.25v-.47a.25.25 0 01.073-.177l1.574-1.574V7c0-2.553 1.73-4.612 4.118-5.188v-.577C7.774.576 8.35 0 9.009 0c.659 0 1.235.576 1.235 1.235v.577c2.388.576 4.118 2.635 4.118 5.188v5.53zM7 16h4a2 2 0 11-4 0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

BellIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(BellIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'BellIcon';
export default ForwardRef;