import styled from '@emotion/styled/macro';

export const PanelTabsNavList = styled.div({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  overflowX: 'hidden',
  '&:hover, &:focus': {
    overflowX: 'auto'
  }
});

PanelTabsNavList.defaultProps = {
  role: 'tablist'
};
