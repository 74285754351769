import gql from 'graphql-tag';
import { ProjectFeedProjectFragment } from '../fragments';

export const DeleteProjectMutation = gql`
  mutation DeleteProjectMutation($id: UUID!) {
    deleteProject(input: { id: $id }) {
      project {
        ...ProjectFeedProjectFragment
      }
    }
  }
  ${ProjectFeedProjectFragment}
`;
