import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var LeftIcon = function LeftIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M14.497 17.938l-11.4-8.752c-.122-.093-.13-.252-.018-.353a.28.28 0 01.018-.015l11.4-8.752a.343.343 0 01.423.015c.051.046.08.106.08.169v17.504c0 .139-.135.25-.3.25a.335.335 0 01-.203-.066z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

LeftIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(LeftIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'LeftIcon';
export default ForwardRef;