import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var MobileLandscapeIcon = function MobileLandscapeIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M16 13.75v-9.5c0-.138.128-.25.286-.25h1.428c.158 0 .286.112.286.25v9.5c0 .138-.128.25-.286.25h-1.428c-.158 0-.286-.112-.286-.25zM17.5 9a.5.5 0 10-1 0 .5.5 0 001 0zM0 13.75v-9.5A.25.25 0 01.25 4h14.5a.25.25 0 01.25.25v9.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25zM1.129 13H3.87c.077 0 .129-.055.129-.123V12H2.125A.126.126 0 012 11.875V10h-.877a.125.125 0 00-.123.129v2.742c0 .077.058.129.129.129z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

MobileLandscapeIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(MobileLandscapeIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'MobileLandscapeIcon';
export default ForwardRef;