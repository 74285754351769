import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var TrashbinIcon = function TrashbinIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2.98 17.72L2.02 4.28c-.011-.159.11-.28.272-.28h13.416c.171 0 .283.125.272.28l-.96 13.44a.299.299 0 01-.293.28H3.273a.311.311 0 01-.293-.28zM5 6.247v9.506a.25.25 0 00.252.247h.496c.145 0 .252-.11.252-.247V6.247A.25.25 0 005.748 6h-.496A.246.246 0 005 6.247zm7 0v9.506a.25.25 0 00.252.247h.496c.145 0 .252-.11.252-.247V6.247A.25.25 0 0012.748 6h-.496a.246.246 0 00-.252.247zm-3.5 0v9.506a.25.25 0 00.252.247h.496c.145 0 .252-.11.252-.247V6.247A.25.25 0 009.248 6h-.496a.246.246 0 00-.252.247zM17 1.274v1.447a.283.283 0 01-.278.279H1.278A.275.275 0 011 2.721V1.274c0-.147.127-.279.283-.279h4.434C5.877.995 6 .873 6 .723V.272C6 .122 6.13 0 6.29 0h5.42c.165 0 .29.122.29.272v.451c0 .15.127.272.283.272h4.434c.16 0 .283.125.283.279z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

TrashbinIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(TrashbinIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'TrashbinIcon';
export default ForwardRef;