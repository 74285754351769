import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var BillingsIcon = function BillingsIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5 5L13.498.034a.25.25 0 01.341.09L17 5H5zM.25 6h17.5a.25.25 0 01.25.25v11.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25V6.25A.25.25 0 01.25 6zm1 1a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25h15.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H1.25zm12 6a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h3.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-3.5zm-12 0a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h6.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-6.5zm4 2a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-.5zm-4 0a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h2.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-2.5z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

BillingsIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(BillingsIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'BillingsIcon';
export default ForwardRef;