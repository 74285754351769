import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { layout, position, space, flexbox } from 'styled-system';
import { transparentize } from 'polished';
import { Text } from '../Text';
import { Button } from '../Button';

const Input = styled.input(
  props => ({
    cursor: 'pointer',
    appearance: 'none',
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    margin: 0,
    zIndex: 0
  }),
  props =>
    props.type === 'radio' && {
      '&[data-radio="on"]': {
        right: 0,
        left: 'auto'
      },
      '&[data-radio="off"]': {
        left: 0
      }
    }
);

const Icon = styled.div(props => {
  const iconBg =
    props.type === 'radio'
      ? themeGet('colors.blue.1')(props)
      : props.checked
      ? themeGet('colors.blue.1')(props)
      : `${transparentize(0.91, themeGet('colors.white')(props))}`;
  return {
    border: `${themeGet('borders.1')(props)} ${transparentize(
      0.5,
      themeGet('colors.black')(props)
    )}`,
    borderRadius: themeGet('radii.5')(props),
    boxShadow: `0px 2px 8px 1px ${transparentize(
      0.6,
      themeGet('colors.black')(props)
    )}`,
    minHeight: themeGet('space.4')(props),
    width: themeGet('space.4')(props),
    position: 'absolute',
    top: 2,
    backgroundColor: iconBg,
    transition: 'all 150ms',
    right: props.checked ? 1 : 21
  };
});

const SwitchWrapper = styled.div(props => ({
  display: 'inline-flex',
  height: themeGet('space.5')(props),
  [`${Button}`]: {
    flexShrink: 0,
    height: '100%',
    width: `${themeGet('space.7')(props) - themeGet('space.4')(props)}px`
  }
}));

export const Switch = styled(
  ({
    checked: checkedProp,
    value,
    type,
    id,
    name,
    onLabel,
    onValue,
    offLabel,
    offValue,
    onChange,
    className,
    ...rest
  }) => {
    const handleOnChange = () => {
      if (type !== 'radio') return onChange(!checkedProp);
      const nextVal = value === onValue ? offValue : onValue;
      onChange(nextVal);
    };
    const isChecked = type === 'radio' ? value === onValue : checkedProp;

    const inputs =
      type === 'radio' ? (
        <React.Fragment>
          <Input
            id={id + offLabel}
            name={name}
            type="radio"
            value={onValue}
            checked={value === onValue}
            onChange={handleOnChange}
            data-radio="on"
            {...rest}
          />
          <Input
            id={id + offLabel}
            name={name}
            type="radio"
            value={offValue}
            checked={value === offValue}
            onChange={handleOnChange}
            data-radio="off"
            {...rest}
          />
        </React.Fragment>
      ) : (
        <Input
          id={id}
          name={name}
          type="checkbox"
          checked={checkedProp}
          onChange={handleOnChange}
          {...rest}
        />
      );
    return (
      <SwitchWrapper className={className}>
        {offLabel && (
          <Text
            color={isChecked ? 'secondary' : 'primary'}
            as="label"
            htmlFor={id + offLabel}
            marginRight="2"
          >
            {offLabel}
          </Text>
        )}
        <Button
          position="relative"
          fluid
          padding="2px"
          borderRadius="3"
          boxShadow="none"
          onClick={handleOnChange}
        >
          <Icon
            type={type}
            checked={isChecked}
            pose={isChecked ? 'checked' : 'unchecked'}
          />
          {inputs}
        </Button>
        {onLabel && (
          <Text
            color={isChecked ? 'primary' : 'secondary'}
            as="label"
            htmlFor={id + onLabel}
            marginLeft="2"
          >
            {onLabel}
          </Text>
        )}
      </SwitchWrapper>
    );
  }
)(layout, position, space, flexbox);

Switch.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string
};

Switch.defaultProps = {
  checked: false,
  onChange: () => {},
  onLabel: '',
  offLabel: ''
};
