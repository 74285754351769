import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AToZIcon = function AToZIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.734 7.296l-.672-1.531H1.81l-.672 1.53H0L3.35 0H3.5l3.36 7.296H5.735zM2.222 4.819H4.65L3.436 2.045 2.222 4.819zM18 10.807v.123l-3.209 6.125h3.1V18h-4.628v-.144l3.23-6.104h-3.187v-.945H18zM9 4.106C9 4.047 9.038 4 9.085 4h.83c.047 0 .085.045.085.106v9.788c0 .059-.038.106-.085.106h-.83C9.038 14 9 13.955 9 13.894V4.106z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AToZIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AToZIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AToZIcon';
export default ForwardRef;