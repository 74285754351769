import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PostIcon = function PostIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M18 5.25v12.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25V4.25A.25.25 0 01.25 4h8.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H1v12h16V5.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25zM15.25 7h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm-13-1h3.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25h-3.5A.25.25 0 012 9.75v-3.5A.25.25 0 012.25 6zm11 3h2.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm-11 2h6.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm8 0h5.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm-8 2h3.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm5 0h3.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm5 0h3.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm-10 2h5.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zM15.418.022c.029-.029.075-.03.106.001l2.454 2.454c.03.03.028.08.001.107l-6.938 6.938a.262.262 0 01-.127.063l-2.584.37a.25.25 0 01-.283-.284l.37-2.584a.27.27 0 01.063-.126L15.418.022z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PostIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PostIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PostIcon';
export default ForwardRef;