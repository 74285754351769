import React, { useRef } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { ControlThumb } from '../../../ui/ControlThumb';
import { clamp } from '../../../utils/math';
import { ACTIONS } from '../reducer';

const useGradientStop = ({
  stop,
  stopCount,
  stopEl,
  gradientRect,
  dispatch,
  dropThreshold,
  minStops
}) => {
  const activateStop = id => dispatch({ type: ACTIONS.activateStop, id });

  const changeOffset = ({ id, offset }) =>
    dispatch({
      type: ACTIONS.changeStopOffset,
      id,
      offset
    });

  const removeColor = id => {
    if (stopCount - 1 < minStops) return;
    dispatch({ type: ACTIONS.removeStop, id });
  };

  const onDrag = event => {
    // Dragged outside of the gradient band, drop the stop
    const stopY = stopEl.current.getBoundingClientRect().top;
    const deltaY = Math.abs(event.clientY - stopY);
    if (deltaY > dropThreshold) {
      window.removeEventListener('mousemove', onDrag, false);
      window.removeEventListener('mouseup', onDragEnd, false);
      return removeColor(stop.id);
    }

    // Else change the position
    const newOffsetPx = clamp(
      event.clientX - gradientRect.left,
      0,
      gradientRect.width
    );
    changeOffset({ id: stop.id, offset: newOffsetPx / gradientRect.width });
  };

  const onDragEnd = () => {
    window.removeEventListener('mousemove', onDrag, false);
    window.removeEventListener('mouseup', onDragEnd, false);
  };

  return {
    onMouseDown: event => {
      event.preventDefault();
      event.stopPropagation();
      // Right click on this stop, activate it
      if (event.button === 0) {
        activateStop(stop.id);
        window.addEventListener('mousemove', onDrag, false);
        window.addEventListener('mouseup', onDragEnd, false);
      }
    }
  };
};

export const GradientStop = styled(
  ({
    stop,
    dispatch,
    gradientRect,
    dropThreshold,
    minStops,
    stopCount,
    className
  }) => {
    const stopEl = useRef();
    const { onMouseDown } = useGradientStop({
      stop,
      stopEl,
      gradientRect,
      dropThreshold,
      stopCount,
      minStops,
      dispatch
    });

    const deactivateStop = () => dispatch({ type: ACTIONS.deactivateStop });

    return (
      <ControlThumb
        left={stop.left}
        ref={stopEl}
        onMouseDown={onMouseDown}
        onDoubleClick={deactivateStop}
        className={className}
        bg={stop.active ? 'white' : undefined}
      />
    );
  }
)();

const noop = () => {};

GradientStop.propTypes = {
  stop: PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    offset: PropTypes.number,
    active: PropTypes.bool
  }),
  // Threshold to remove the stop once dragged
  // outside of the gradient band
  dropThreshold: PropTypes.number,
  // Min color stops
  minStops: PropTypes.number,
  // Max color stops
  maxStops: PropTypes.number,
  onActivateStop: PropTypes.func,
  onOffsetChange: PropTypes.func,
  onRemoveColor: PropTypes.func
};

GradientStop.defaultProps = {
  dropThreshold: 50,
  onActivateStop: noop,
  onOffsetChange: noop,
  onRemoveColor: noop
};
