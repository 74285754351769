import styled from '@emotion/styled/macro';
import {
  layout,
  space,
  color,
  typography,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow
} from 'styled-system';

export const Box = styled.div(
  layout,
  space,
  color,
  typography,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow
);
