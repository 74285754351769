import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

export const WindowSplitter = styled.div(props => ({
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: props.direction
}));

WindowSplitter.propTypes = {
  direction: PropTypes.oneOf(['row', 'column']).isRequired
};
