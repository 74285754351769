import React from 'react';
import styled from '@emotion/styled/macro';
import { GradientStop } from './GradientStop';

const StopsContainer = styled.div({
  cursor: 'copy',
  [`${GradientStop}`]: {
    position: 'absolute'
  }
});

export const GradientStops = styled(({ stops, className, ...rest }) => {
  return (
    <StopsContainer className={className}>
      {stops.map(stop => (
        <GradientStop
          key={stop.id}
          stop={stop}
          stopCount={stops.length}
          {...rest}
        />
      ))}
    </StopsContainer>
  );
})();

GradientStops.propTypes = {};
