import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var OrganizationIcon = function OrganizationIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M12.656 5.625h5.063c.155 0 .281.126.281.281V17.72a.281.281 0 01-.281.281h-5.063a.281.281 0 01-.281-.281V5.906c0-.155.126-.281.281-.281zM13.5 6.75V9h1.125V6.75H13.5zm0 3.375v2.25h1.125v-2.25H13.5zm0 3.375v2.25h1.125V13.5H13.5zM.281 1.125h.844V.281c0-.155.126-.281.281-.281h8.438c.155 0 .281.126.281.281v.844h.844c.155 0 .281.126.281.281V17.72a.281.281 0 01-.281.281H7.875v-4.5h-4.5V18H.281A.281.281 0 010 17.719V1.406c0-.155.126-.281.281-.281zM2.25 6.75V9H4.5V6.75H2.25zm0-3.375v2.25H4.5v-2.25H2.25zm0 6.75v2.25H4.5v-2.25H2.25zm4.5-3.375V9H9V6.75H6.75zm0 3.375v2.25H9v-2.25H6.75zm0-6.75v2.25H9v-2.25H6.75z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

OrganizationIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(OrganizationIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'OrganizationIcon';
export default ForwardRef;