import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var TextIcon = function TextIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zM8.68 2.143a.107.107 0 00-.108.107v.643c0 .059.048.107.108.107h7.071c.06 0 .107-.048.107-.107V2.25a.107.107 0 00-.107-.107H8.679zm-6.429 0a.107.107 0 00-.107.107v.643c0 .059.048.107.107.107h5.357c.06 0 .107-.048.107-.107V2.25a.107.107 0 00-.107-.107H2.25zm0 2.143a.107.107 0 00-.107.107v.643c0 .059.048.107.107.107h6.643c.059 0 .107-.048.107-.107v-.643a.107.107 0 00-.107-.107H2.25zm7.714 0a.107.107 0 00-.107.107v.643c0 .059.048.107.107.107h2.786c.06 0 .107-.048.107-.107v-.643a.107.107 0 00-.107-.107H9.964zm3.857 0a.107.107 0 00-.107.107v.643c0 .059.048.107.107.107h1.929c.06 0 .107-.048.107-.107v-.643a.107.107 0 00-.107-.107h-1.929zM6.536 6.429a.107.107 0 00-.107.107v.643c0 .059.048.107.107.107h9.214c.06 0 .107-.048.107-.107v-.643a.107.107 0 00-.107-.107H6.536zm-4.286 0a.107.107 0 00-.107.107v.643c0 .059.048.107.107.107h3.214c.06 0 .107-.048.107-.107v-.643a.107.107 0 00-.107-.107H2.25zM9.536 8.57a.107.107 0 00-.107.108v.642c0 .06.048.108.107.108h6.214c.06 0 .107-.048.107-.108V8.68a.107.107 0 00-.107-.108H9.536zm-7.286 0a.107.107 0 00-.107.108v.642c0 .06.048.108.107.108h6.214c.06 0 .107-.048.107-.108V8.68a.107.107 0 00-.107-.108H2.25zm0 2.143a.107.107 0 00-.107.107v.643c0 .06.048.107.107.107h13.5c.06 0 .107-.048.107-.107v-.643a.107.107 0 00-.107-.107H2.25zm4.714 2.143a.107.107 0 00-.107.107v.643c0 .06.048.107.107.107h8.786c.06 0 .107-.048.107-.107v-.643a.107.107 0 00-.107-.107H6.964zm-4.714 0a.107.107 0 00-.107.107v.643c0 .06.048.107.107.107h3.643c.059 0 .107-.048.107-.107v-.643a.107.107 0 00-.107-.107H2.25zm0 2.143a.107.107 0 00-.107.107v.643c0 .06.048.107.107.107h13.5c.06 0 .107-.048.107-.107v-.643A.107.107 0 0015.75 15H2.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

TextIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(TextIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'TextIcon';
export default ForwardRef;