import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DatabaseIcon = function DatabaseIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 7.714c1.44 0 2.786-.144 4.038-.432 1.251-.288 2.239-.713 2.962-1.275v1.707c0 .462-.313.89-.939 1.286-.626.395-1.476.708-2.552.94-1.075.23-2.245.346-3.509.346-1.264 0-2.434-.116-3.51-.347C4.416 9.71 3.566 9.395 2.94 9 2.313 8.605 2 8.176 2 7.714V6.007c.723.562 1.71.987 2.962 1.275 1.252.288 2.598.432 4.038.432zm0 7.715c1.44 0 2.786-.144 4.038-.432 1.251-.288 2.239-.714 2.962-1.276v1.708c0 .462-.313.89-.939 1.285-.626.395-1.476.708-2.552.94-1.075.23-2.245.346-3.509.346-1.264 0-2.434-.116-3.51-.347-1.075-.23-1.925-.544-2.551-.939C2.313 16.32 2 15.891 2 15.43V13.72c.723.562 1.71.988 2.962 1.276 1.252.288 2.598.432 4.038.432zm0-3.858c1.44 0 2.786-.144 4.038-.431 1.251-.288 2.239-.714 2.962-1.276v1.707c0 .462-.313.891-.939 1.286-.626.395-1.476.708-2.552.94-1.075.23-2.245.346-3.509.346-1.264 0-2.434-.116-3.51-.347-1.075-.23-1.925-.544-2.551-.939-.626-.395-.939-.824-.939-1.286V9.864c.723.562 1.71.988 2.962 1.276 1.252.287 2.598.431 4.038.431zM9 0c1.264 0 2.434.116 3.51.347 1.075.23 1.925.544 2.551.939.626.395.939.823.939 1.285v1.286c0 .462-.313.89-.939 1.286-.626.395-1.476.708-2.552.939-1.075.231-2.245.347-3.509.347-1.264 0-2.434-.116-3.51-.347-1.075-.231-1.925-.544-2.551-.94C2.313 4.749 2 4.32 2 3.858V2.571c0-.462.313-.89.939-1.285C3.565.89 4.415.578 5.49.346 6.566.117 7.736 0 9 0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DatabaseIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DatabaseIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DatabaseIcon';
export default ForwardRef;