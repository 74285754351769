import React, { useMemo, memo } from 'react';
import {
  Pagination as PaginationView,
  PaginationList,
  PaginationListItem,
  PaginationListItemLink,
  PaginationListItemLinkNext,
  PaginationListItemLinkPrevious,
  Icon,
  VisuallyHidden
} from '../ui';
import { propsAreEqual } from '../utils/react';

const getPaginationListItems = (totalCount, first, offset, itemsToShow) =>
  Array.from(
    {
      length: Math.ceil(totalCount / first)
    },
    (_key, value) => value
  ).splice(
    Math.min(
      Math.max(Math.ceil(offset / first) - Math.floor(itemsToShow / 2), 0),
      Math.floor(totalCount / first) - itemsToShow
    ),
    itemsToShow
  );

export const Pagination = memo(function Pagination({
  hasNextPage,
  hasPreviousPage,
  totalCount,
  onPageChange,
  itemsToShow,
  variables,
  ...props
}) {
  const pageItems = useMemo(
    () =>
      getPaginationListItems(
        totalCount,
        variables.first,
        variables.offset,
        itemsToShow
      ).map(page => (
        <PaginationListItem key={page}>
          <PaginationListItemLink
            aria-current={page * variables.first === variables.offset}
            as="button"
            onClick={event => {
              event.preventDefault();
              onPageChange({
                ...variables,
                offset: page * variables.first
              });
            }}
          >
            <VisuallyHidden>Page</VisuallyHidden> {page + 1}
          </PaginationListItemLink>
        </PaginationListItem>
      )),
    [totalCount, variables, itemsToShow, onPageChange]
  );

  return (
    <PaginationView {...props}>
      <PaginationList>
        <PaginationListItem>
          <PaginationListItemLinkPrevious
            aria-disabled={!hasPreviousPage}
            as="button"
            onClick={
              hasPreviousPage
                ? event => {
                    event.preventDefault();
                    onPageChange({
                      ...variables,
                      offset: variables.offset - variables.first
                    });
                  }
                : null
            }
          >
            <Icon name="routing-left" title="Previous page" size={18} />
          </PaginationListItemLinkPrevious>
        </PaginationListItem>
        {pageItems}
        <PaginationListItem>
          <PaginationListItemLinkNext
            aria-disabled={!hasNextPage}
            as="button"
            onClick={
              hasNextPage
                ? event => {
                    event.preventDefault();
                    onPageChange({
                      ...variables,
                      offset: variables.offset + variables.first
                    });
                  }
                : null
            }
          >
            <Icon name="routing-right" title="Next page" size={18} />
          </PaginationListItemLinkNext>
        </PaginationListItem>
      </PaginationList>
    </PaginationView>
  );
},
propsAreEqual);

Pagination.defaultProps = {
  itemsToShow: 5
};
