import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SquareCornerIcon = function SquareCornerIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M6.375 0c.069 0 .125.056.125.125v11.75c0 .069.056.125.125.125h11.25c.069 0 .125.056.125.125v5.75a.125.125 0 01-.125.125H.125A.125.125 0 010 17.875V.125C0 .056.056 0 .125 0h6.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SquareCornerIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SquareCornerIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SquareCornerIcon';
export default ForwardRef;