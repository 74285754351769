import styled from '@emotion/styled/macro';
import { layout, space, flexbox, position } from 'styled-system';

export const GridRowCell = styled.div(
  ({ fluid }) => ({
    display: 'flex',
    flex: fluid ? '1 0 auto' : '0 0 auto',
    flexDirection: 'column'
  }),
  layout,
  space,
  flexbox,
  position
);
