import gql from 'graphql-tag';

export const CreateServiceTokenMutation = gql`
  mutation CreateServiceTokenMutation {
    createServiceToken(input: {}) {
      token {
        accessToken
      }
    }
  }
`;
