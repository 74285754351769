import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PlayCircleOIcon = function PlayCircleOIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 18A9 9 0 119 0a9 9 0 010 18zm-3.429-4.484c.001.229.118.44.306.55.187.11.416.102.597-.02l7.399-4.507a.675.675 0 00.27-.517.63.63 0 00-.27-.507l-7.4-4.546a.706.706 0 00-.313-.112.574.574 0 00-.42.19.656.656 0 00-.169.451v9.018z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PlayCircleOIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PlayCircleOIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PlayCircleOIcon';
export default ForwardRef;