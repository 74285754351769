import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var H3Icon = function H3Icon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zM7.9 6.086v3.096H4.341V6.086h-.978v7.2h.978v-3.158h3.558v3.158h.978v-7.2h-.978zm4.989 2.571l1.666-1.841v-.73h-4.021v.967h2.612l-2.047 2.304.453.483c.319-.175.617-.298 1.028-.298.834 0 1.471.607 1.471 1.43 0 .812-.607 1.45-1.45 1.45-.792 0-1.337-.576-1.512-1.265l-.915.4c.267.947 1.07 1.832 2.427 1.832 1.481 0 2.448-1.163 2.448-2.418 0-1.172-.823-2.18-2.16-2.314z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

H3Icon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(H3Icon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'H3Icon';
export default ForwardRef;