import React from 'react';
import styled from '@emotion/styled/macro';
import { em, margin, padding } from 'polished';
import { ReactComponent as Handle } from './handle.svg';

export const TabsNavActionsHandle = styled.button({
  ...margin(0),
  ...padding(0, em(6)),
  appearance: 'none',
  backgroundColor: 'transparent',
  borderWidth: 0,
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  lineHeight: 18 / 16,
  textDecoration: 'none',
  '&[draggable="true"]': {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing'
    }
  }
});

TabsNavActionsHandle.defaultProps = {
  children: <Handle />
};
