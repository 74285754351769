import styled from '@emotion/styled/macro';

export const ScrollView = styled.div({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  transform: 'translate3d(0px,0px,0px)',
  width: '100%'
});
