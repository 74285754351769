import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AjaxIcon = function AjaxIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.357 11.057l.972-1.29a.119.119 0 01.16-.025l.897.607a.107.107 0 01.027.153L3.81 13.955a.119.119 0 01-.187 0l-2.602-3.451a.107.107 0 01.026-.154l.897-.607a.119.119 0 01.16.025l.921 1.222V.11c0-.06.052-.11.115-.11h11.744c.063 0 .115.05.115.11v1.597c0 .061-.052.11-.115.11h-1.1a.113.113 0 01-.115-.11v-.344c0-.061-.052-.11-.116-.11H4.472a.113.113 0 00-.115.11v9.694zm9.286-4.114l-.972 1.29a.119.119 0 01-.16.025l-.897-.607a.107.107 0 01-.027-.153l2.602-3.452a.119.119 0 01.187 0l2.602 3.451a.107.107 0 01-.026.154l-.897.607a.119.119 0 01-.16-.025l-.921-1.222V17.89c0 .06-.052.11-.115.11H3.115A.113.113 0 013 17.89v-1.66c0-.06.052-.11.115-.11h1.1c.064 0 .115.05.115.11v.407c0 .061.052.11.116.11h9.082c.064 0 .115-.049.115-.11V6.943z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AjaxIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AjaxIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AjaxIcon';
export default ForwardRef;