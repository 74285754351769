import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SessionIcon = function SessionIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M6.174 11a6.518 6.518 0 00-.155 2H3.2c-.11 0-.2-.112-.2-.25v-1.5c0-.138.09-.25.2-.25h2.974zM14 6.174A6.502 6.502 0 006.498 10H.194A.193.193 0 010 9.808V.192C0 .086.087 0 .194 0h13.612c.107 0 .194.086.194.192v5.982zM12.5 18a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm.5-5V8.25a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25v4.5c0 .138.112.25.25.25H13zm0-1v1h3.75a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25H13z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SessionIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SessionIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SessionIcon';
export default ForwardRef;