import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ParagraphIcon = function ParagraphIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm8.898 6.086H7.236v7.2h.977v-2.633h.792c1.584 0 2.448-1.019 2.448-2.284 0-1.131-.864-2.283-2.448-2.283zm-.041 3.61h-.75V7.042h.75c.905 0 1.491.576 1.491 1.327 0 .813-.586 1.327-1.491 1.327z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ParagraphIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ParagraphIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ParagraphIcon';
export default ForwardRef;