import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { em, margin, padding } from 'polished';

const variant = css({
  color: 'rgb(180,180,180)',
  '&:hover': {
    color: 'rgb(250,250,250)'
  }
});

export const TabsNavActionsButton = styled.button(
  {
    ...margin(0),
    ...padding(0, em(6)),
    appearance: 'none',
    backgroundColor: 'transparent',
    borderWidth: 0,
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 18 / 16,
    textDecoration: 'none'
  },
  variant
);
