import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { em } from 'polished';

const variant = css({
  borderColor: 'rgba(0,0,0,0.8)'
});

export const Kbd = styled.kbd(
  {
    alignItems: 'center',
    borderRadius: em(3),
    borderStyle: 'solid',
    borderWidth: 0.5,
    boxSizing: 'border-box',
    color: 'inherit',
    display: 'inline-block',
    font: 'inherit',
    height: 24,
    lineHeight: 16 / 16,
    textAlign: 'center',
    width: 24,
    padding: 4,
    marginLeft: 3,
    marginRight: 3,
    textTransform: 'uppercase',
    '&:last-of-type': {
      marginRight: 0
    },
    '&:first-of-type': {
      marginLeft: 0
    }
  },
  variant
);
