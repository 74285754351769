import styled from '@emotion/styled/macro';

export const ModalContainer = styled.div({
  height: '100%',
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

ModalContainer.defaultProps = {
  role: 'document'
};
