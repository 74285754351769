import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AirpageLogoIcon = function AirpageLogoIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9.04 18l3.444-3.322h4.168L9.04 0 1.4 14.678h4.168L9.04 18zm2.81-4.983l-2.84 2.718-2.808-2.718H4.088L9.01 3.534l4.953 9.483H11.85z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AirpageLogoIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AirpageLogoIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AirpageLogoIcon';
export default ForwardRef;