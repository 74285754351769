import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var NodePathIcon = function NodePathIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.143 1.829a.857.857 0 100 1.485v1.829H0V0h5.143v1.829zm7.714 12.314a.857.857 0 100-1.714v-1.715H18v5.143h-5.143v-1.714zM.43 6h4.285v1.286H.43V6zm12.857 10.714h4.285V18h-4.285v-1.286zM4.714 3a.429.429 0 010-.857c1.25 0 2.196.652 2.94 1.865.535.872.86 1.728 1.508 3.753l.011.037c1.221 3.814 1.99 5.06 3.684 5.06a.429.429 0 110 .856c-1.266 0-2.225-.656-2.978-1.877-.542-.88-.872-1.748-1.522-3.778l-.012-.036C7.131 4.23 6.377 3 4.715 3z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

NodePathIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(NodePathIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'NodePathIcon';
export default ForwardRef;