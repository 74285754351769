import styled from '@emotion/styled/macro';

export const MenuBarListItem = styled.li(({ fluid }) => ({
  alignItems: 'center',
  display: fluid ? 'flex' : 'inline-flex',
  flexGrow: fluid ? 1 : 0,
  flexShrink: 0,
  flexBasis: 'auto',
  position: 'relative'
}));
