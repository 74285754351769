import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Box, Field, Label, Input, Button, Divider } from '../ui';

const Form = styled.form({
  maxWidth: 720,
  margin: '0 auto'
});

export const DashboardSettingsProfileForm = props => {
  const [bio, setBio] = useState(props.patch.bio);
  const [company, setCompany] = useState(props.patch.company);
  const [displayName, setDisplayName] = useState(props.patch.displayName);
  const [location, setLocation] = useState(props.patch.location);
  const [url, setUrl] = useState(props.patch.url);

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        props.updateRoleProfileByRoleId({
          variables: {
            roleId: props.roleId,
            patch: { bio, company, displayName, location, url }
          }
        });
      }}
    >
      <Field>
        <Label
          htmlFor="role-profile-displayName"
          id="role-profile-displayName-label"
        >
          Display name
        </Label>
        <Input
          aria-labelledby="role-profile-displayName-label"
          fluid={true}
          id="role-profile-displayName"
          onChange={event => setDisplayName(event.target.value)}
          value={displayName}
        />
      </Field>
      <Field>
        <Label htmlFor="role-profile-location" id="role-profile-location-label">
          Location
        </Label>
        <Input
          aria-labelledby="role-profile-location-label"
          fluid={true}
          id="role-profile-location"
          name="location"
          onChange={event => setLocation(event.target.value)}
          value={location}
        />
      </Field>
      <Field>
        <Label htmlFor="role-profile-company" id="role-profile-company-label">
          Company
        </Label>
        <Input
          aria-labelledby="role-profile-company-label"
          fluid={true}
          id="role-profile-company"
          name="company"
          onChange={event => setCompany(event.target.value)}
          value={company}
        />
      </Field>
      <Field>
        <Label htmlFor="role-profile-bio" id="role-profile-bio-label">
          Bio
        </Label>
        <Input
          aria-labelledby="role-profile-bio-label"
          fluid={true}
          id="role-profile-bio"
          name="bio"
          onChange={event => setBio(event.target.value)}
          value={bio}
        />
      </Field>
      <Field>
        <Label htmlFor="role-profile-url" id="role-profile-url-label">
          Website
        </Label>
        <Input
          aria-labelledby="role-profile-url-label"
          fluid={true}
          id="role-profile-url"
          name="url"
          onChange={event => setUrl(event.target.value)}
          value={url}
        />
      </Field>
      <Divider width="100%" height="1px" my="5" />
      <Box display="flex" justifyContent="flex-end">
        <Button as={Link} to="/dashboard/settings" marginRight="3">
          Cancel
        </Button>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </Box>
    </Form>
  );
};

DashboardSettingsProfileForm.defaultProps = {
  roleId: '',
  patch: {
    bio: '',
    company: '',
    displayName: '',
    location: '',
    url: ''
  }
};

DashboardSettingsProfileForm.propTypes = {
  updateRoleProfileByRoleId: PropTypes.func.isRequired,
  roleId: PropTypes.string.isRequired,
  patch: PropTypes.shape({
    bio: '',
    company: '',
    displayName: '',
    location: '',
    url: ''
  }).isRequired
};
