import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

const variant = css({
  backgroundColor: 'rgb(54,54,54)',
  color: 'rgb(180,180,180)'
});

export const PanelTabsPanel = styled.div(
  {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative'
  },
  variant
);

PanelTabsPanel.defaultProps = {
  role: 'tabpanel',
  tabIndex: 0
};
