import styled from '@emotion/styled/macro';
import { margin, transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';
import { space, layout } from 'styled-system';
import { ListItemLink } from '../List';
import { MenuItem } from './MenuItem';

const darkVariant = props => ({
  [`&:active:not([aria-disabled="true"]),
    ${MenuItem}[aria-selected="true"] &`]: {
    backgroundColor: transparentize(0.5, themeGet('colors.blue.1')(props)),
    borderColor: themeGet('colors.blue.1')(props),
    color: themeGet('colors.white')(props)
  },

  [`${MenuItem}[aria-current="true"] &`]: {
    backgroundColor: themeGet('colors.blue.1')(props),
    borderColor: themeGet('colors.blue.1')(props),
    color: 'rgb(6, 45, 99)'
  }
});

const lightVariant = props => ({
  [`&:active:not([aria-disabled="true"]),
    &:visited:not([aria-disabled="true"]),
    ${MenuItem}[aria-selected="true"] &`]: {
    backgroundColor: transparentize(0.5, themeGet('colors.blue.0')(props)),
    color: themeGet('colors.blue.1')(props)
  },

  [`${MenuItem}[aria-current="true"] &`]: {
    backgroundColor: themeGet('colors.blue.0')(props),
    color: themeGet('colors.blue.1')(props)
  }
});

const variant = props =>
  ({ dark: darkVariant, light: lightVariant }[props.theme.mode](props));

export const MenuItemLink = styled(ListItemLink)(
  {
    ...margin(0),
    maxWidth: '100%',
    width: '100%'
  },
  variant,
  space,
  layout
);

MenuItemLink.defaultProps = {
  role: 'menuitem'
};
