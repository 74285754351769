import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ToggleIcon = function ToggleIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.143 4h7.714C15.774 4 18 6.33 18 9.004 18 11.677 15.743 14 12.857 14H5.143C2.189 14 0 11.645 0 9s2.158-5 5.143-5zM13 13a4 4 0 100-8 4 4 0 000 8z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ToggleIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ToggleIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ToggleIcon';
export default ForwardRef;