import React from 'react';
import get from 'lodash.get';
import { Grid, GridRow, GridRowCell } from '../../ui';

export const GridLayout = ({
  renderItem,
  rowKey,
  data,
  as,
  CellProps,
  RowProps,
  GridProps,
  ...rest
}) => {
  return (
    <Grid as={as} {...GridProps} {...rest}>
      <GridRow flexWrap="wrap" {...RowProps}>
        {data.map((item, index) => {
          const key =
            typeof rowKey === 'function' ? rowKey(item) : get(item, rowKey);
          return (
            <GridRowCell key={`layout-list-${key || index}`} {...CellProps}>
              {renderItem(item, index)}
            </GridRowCell>
          );
        })}
      </GridRow>
    </Grid>
  );
};
