/** @jsx jsx */
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { jsx } from '@emotion/core';
import themeGet from '@styled-system/theme-get';
import { Icon } from '../Icon';

const Wheel = ({ onUp, onDown, ...rest }) => (
  <div {...rest}>
    <span onClick={onUp}>
      <Icon name="up" title="Increment" />
    </span>
    <span onClick={onDown}>
      <Icon name="up" title="Decrement" />
    </span>
  </div>
);

export const NumberWheel = styled(Wheel)(props => ({
  display: 'flex',
  flexDirection: 'column',
  color: themeGet('colors.input.placeholder')(props),
  padding: `
    ${themeGet('space.3')(props) - 1}px
    ${themeGet('space.3')(props) + 2}px`,

  '& span': {
    display: 'inline-block',
    height: `${themeGet('space.3')(props)}px`,
    cursor: 'pointer',
    lineHeight: '6px'
  },

  '& span:last-child': {
    transform: 'rotate(180deg)'
  },

  [`${Icon}`]: {
    fontSize: 6
  }
}));

NumberWheel.propTypes = {
  onUp: PropTypes.func,
  onDown: PropTypes.func
};

NumberWheel.propTypes = {
  onUp: () => {},
  onDown: () => {}
};
