import styled from '@emotion/styled/macro';

export const Toolbar = styled.div({
  display: 'flex',
  flexBasis: 'auto',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 0,
  position: 'relative'
});
