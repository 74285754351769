import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { em, margin, padding } from 'polished';

const variant = css({
  backgroundColor: 'rgb(24,24,24)',
  color: 'rgb(180,180,180)',
  '&:active, &[aria-selected="true"]': {
    backgroundColor: 'rgb(54,54,54)',
    color: 'rgb(250,250,250)'
  }
});

export const PanelTabsNavListItem = styled.button(
  {
    ...margin(0),
    ...padding(em(3, 15), em(12, 15)),
    appearance: 'none',
    backgroundColor: 'transparent',
    borderTopLeftRadius: em(3, 15),
    borderTopRightRadius: em(3, 15),
    borderWidth: 0,
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: em(15),
    fontWeight: 600,
    lineHeight: 24 / 15,
    minWidth: em(120),
    overflow: 'hidden',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  variant
);

PanelTabsNavListItem.defaultProps = {
  role: 'tab'
};
