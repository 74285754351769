import styled from '@emotion/styled/macro';
import { em, margin, padding } from 'polished';

export const AlertClose = styled.button({
  ...margin(0),
  ...padding(0),
  appearance: 'none',
  backgroundColor: 'transparent',
  borderWidth: 0,
  color: 'inherit',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  lineHeight: 18 / 16,
  position: 'absolute',
  right: em(12),
  textDecoration: 'none',
  top: em(12)
});
