import React from 'react';
import { Box, Divider, SideNav, SideNavPanel, Text } from '../ui';

export const MobileNavMenu = ({ title, rightNav, leftNav, children }) => (
  <SideNav paddingTop="7" flexDirection="column">
    <Box padding="4" display="flex">
      {leftNav}
      <Text variant="header" margin="0" flex="1">
        {title}
      </Text>
      {rightNav}
    </Box>
    <Divider height="1px" mx="5" marginBottom="0" />
    <SideNavPanel id="mobile-nav-panel">{children}</SideNavPanel>
  </SideNav>
);
