import React from 'react';
import get from 'lodash.get';
import { List } from '../../ui';

export const ListLayout = ({ data, renderItem, rowKey, ...rest }) => {
  return (
    <List {...rest}>
      {data.map((item, index) => {
        const key =
          typeof rowKey === 'function' ? rowKey(item) : get(item, rowKey);
        const child = React.cloneElement(renderItem(item, index), {
          key: `layout-list-${key || index}`
        });
        return child;
      })}
    </List>
  );
};
