export const credentialManager = {
  async fill({ url }) {
    return {
      token: localStorage.getItem('token')
    };
  },

  async approved({ url, auth }) {
    console.log('approved!');
  },

  async rejected({ url, auth }) {
    console.log('rejected!');
  }
};
