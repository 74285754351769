import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var LockIcon = function LockIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M14.704 8.1V5.4C14.704 2.422 12.145 0 9 0 5.855 0 3.296 2.422 3.296 5.4v2.7H2.25V18h13.5V8.1h-1.046zM4.817 5.4c0-2.184 1.876-3.96 4.183-3.96s4.183 1.776 4.183 3.96v2.7H4.817V5.4z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

LockIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(LockIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'LockIcon';
export default ForwardRef;