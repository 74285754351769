/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled/macro';
import { jsx } from '@emotion/core';
import themeGet from '@styled-system/theme-get';
import { transparentize } from 'polished';
import { Icon } from '../Icon';
import { Box } from '../Box';

const variant = props => ({
  backgroundColor: transparentize(0.95, themeGet('colors.white')(props)),
  borderColor: themeGet('colors.input.border')(props),

  '&:hover, &:focus': {
    backgroundColor: transparentize(0.97, themeGet('colors.white')(props)),
    borderColor: themeGet('colors.blue.1')(props)
  },

  '&:active': {
    backgroundColor: transparentize(0.5, themeGet('colors.blue.1')(props)),
    borderColor: themeGet('colors.blue.1')(props)
  },

  '&::placeholder': {
    color: themeGet('colors.input.placeholder')(props)
  },

  "&[aria-required='true']": {
    outlineColor: themeGet('colors.intent.success.0')(props)
  },

  "&[aria-invalid='true']": {
    outlineColor: themeGet('colors.intent.error.0')(props)
  },

  "$[aria-disabled='true']": {
    backgroundColor: 'transparent',
    borderColor: transparentize(
      0.7,
      themeGet('colors.input.placeholder')(props)
    )
  }
});

const state = props => ({
  color: themeGet('colors.input.color')(props),
  ...(props.disabled && {
    cursor: 'not-allowed',
    color: transparentize(0.7, themeGet('colors.input.placeholder')(props)),
    borderColor: transparentize(
      0.7,
      themeGet('colors.input.placeholder')(props)
    ),
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent',
      borderColor: transparentize(
        0.7,
        themeGet('colors.input.placeholder')(props)
      )
    }
  }),
  ...(props.required && {
    outlineColor: themeGet('colors.intent.success.0')(props)
  }),
  ...(props.invalid && {
    outlineColor: themeGet('colors.intent.error.0')(props)
  })
});

const CheckboxInput = styled.input({
  appearance: 'none',
  opacity: 0,
  position: 'absolute',
  left: 0,
  top: 0,
  padding: 8,
  zIndex: 0
});

const CheckboxWrapper = styled(Box)(
  props => ({
    width: themeGet('space.5')(props) - 2,
    height: themeGet('space.5')(props) - 2,
    borderRadius: themeGet('radii.1')(props),
    borderStyle: 'solid',
    borderWidth: 0.5,
    boxSizing: 'border-box',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    font: 'inherit',
    lineHeight: 24 / 16,
    verticalAlign: 'middle',
    position: 'relative',
    cursor: 'pointer',
    [`${Icon}`]: {
      display: 'none',
      '&[data-checked="true"]': {
        display: 'inline-block'
      }
    }
  }),
  variant,
  state
);

export const Checkbox = React.forwardRef(function Checkbox(
  { value, onChange, name, InputProps, ...rest },
  ref
) {
  return (
    <CheckboxWrapper {...rest} ref={ref}>
      <CheckboxInput
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        {...InputProps}
      />
      <Icon
        name="checked"
        title={`${name} checked`}
        size={16}
        role="presentation"
        data-checked={value}
      />
    </CheckboxWrapper>
  );
});

Checkbox.defaultProps = {
  type: 'checkbox'
};
