import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Text } from '../Text';
import { Box } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';

const Title = styled(Text)({
  color: 'rgb(77, 77, 77)'
});

export const ModalHeader = ({
  id,
  onClose,
  title,
  closeButton: closeButtonProp
}) => {
  const modalOpenerRef = React.useRef(null);

  useEffect(() => {
    // Semantically, a modal should focus the close button as soon as it's visible
    if (!modalOpenerRef.current || !modalOpenerRef.current.focus) return;
    modalOpenerRef.current.focus();
  }, []);

  let closeButton = (
    <Button
      onClick={onClose}
      border="none"
      backgroundColor="transparent"
      py="0"
      px="1"
      ref={modalOpenerRef}
    >
      <Icon name="close" size={12} />
    </Button>
  );

  if (closeButtonProp) {
    closeButton = React.cloneElement(closeButtonProp, {
      onClick: onClose,
      innerRef: modalOpenerRef,
      ref: modalOpenerRef
    });
  }

  return (
    <React.Fragment>
      <Box display="flex" marginBottom="5">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginRight="3"
        >
          {closeButton}
        </Box>
        <Box
          width="1px"
          height="30px"
          backgroundColor="divider"
          marginRight="3"
        />
        <Box>
          <Title id={id + '-title'} variant="header" as="h3" margin="0">
            {title}
          </Title>
        </Box>
      </Box>
      <Box width="100%" height="1px" backgroundColor="divider" />
    </React.Fragment>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func,
  closeButton: PropTypes.node
};
