import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var LayoutIcon = function LayoutIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.281 0H17.72c.155 0 .281.126.281.281V3.72a.281.281 0 01-.281.281H.28A.281.281 0 010 3.719V.28C0 .126.126 0 .281 0zm0 5H4.72c.155 0 .281.126.281.281V17.72a.281.281 0 01-.281.281H.28A.281.281 0 010 17.719V5.28C0 5.126.126 5 .281 5zm6 0H17.72c.155 0 .281.126.281.281V17.72a.281.281 0 01-.281.281H6.28A.281.281 0 016 17.719V5.28C6 5.126 6.126 5 6.281 5z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

LayoutIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(LayoutIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'LayoutIcon';
export default ForwardRef;