import gql from 'graphql-tag';

export const DashboardSettingsRoleProfileFragment = gql`
  fragment DashboardSettingsRoleProfileFragment on RoleProfile {
    roleId
    displayName
    avatarUrl
    bio
    url
    company
    location
  }
`;
