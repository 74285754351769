import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

const variant = css({
  backgroundColor: 'rgb(50,50,50)',
  borderColor: 'rgba(0,0,0,0.8)'
});

export const ToolbarNav = styled.nav(
  {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0
  },
  variant
);
