import { serializeNode, serializeHierarchy, readFile } from '../../queries';
import injector from '../../injector';
import {
  WORKSPACES_DIRECTORY_PATH,
  WORKSPACES_SELECTED_FILENAME,
  WORKSPACES_NODE_NAME
} from './constants';
import { dirname, join, getShortId, basename, findSync } from '../../utils';
import { getProcessPath, getActiveProcessPath } from '../process/queries';
import { PROCESSES_ACTIVE_PROCESS_FILENAME } from '../process/constants';
import { getOrderedNodeChildren, getSelectionPaths } from '../core/queries';

const { inject } = injector;
const isNodeGraph = node => node.hasOwnProperty('graph');
const nodeHasChildren = node =>
  node.hasOwnProperty('nodes') && Object.keys(node.nodes).length;

const getTypeFromNodeJSON = node =>
  isNodeGraph(node) ? node.graph.type : node.type;

const compareNodeMetaPosition = (nodes, a, b, val) => {
  if (nodes[a] && nodes[b] && nodes[a].meta && nodes[b].meta) {
    return nodes[a].meta[val] > nodes[b].meta[val];
  }
  return false;
};

const getWindowId = () => `win-${getShortId()}`;

const isCommandPaletteOpen = () => {
  const workspace = getSelectedWorkspaceNode();
  return workspace.data.commandPaletteOpen;
};

const isTerminalOpen = () => {
  const workspace = getSelectedWorkspaceNode();
  return workspace.data.terminalOpen;
};

const serializeWorkspace = (root, fullPath = '/', context = {}) => {
  const kind = getTypeFromNodeJSON(root);
  const data = root.data || {};
  data.path = fullPath;

  if (kind === 'Pane' || kind === 'Container') {
    context = {
      toolbar: context.toolbar || data.toolbar
    };
  }
  if (kind === 'Pane') {
    data.isSelectedWorkspacePane = data.path === getSelectedWorkspacePane();
  }
  const children = nodeHasChildren(root)
    ? Object.keys(root.nodes)
        .sort((a, b) =>
          compareNodeMetaPosition(root.nodes, a, b, 'x') ? 1 : -1
        )
        .map(key => ({ key, node: root.nodes[key] }))
        .map(item =>
          serializeWorkspace(item.node, join(fullPath, item.key), context)
        )
    : [];

  // TODO refactor Container and RootContainer resizer and direction logic here

  if (kind === 'Tabs') {
    data.tabs = children.map(tab => {
      return tab.data;
    });

    if (data.tabs.length === 0) {
      return {
        type: 'Start',
        data: {},
        children: []
      };
    }

    // getSelectedWorkspacePane could be memoized per render call
    data.isSelectedWorkspacePane =
      dirname(data.path) === getSelectedWorkspacePane();

    data.toolbar = context.toolbar;
    const child = children[data.selectedTabIndex];

    if (child) {
      child.data.actualProcessPath = getProcessPath(child.data.pid);
      child.data.processPath =
        child.data.pid === PROCESSES_ACTIVE_PROCESS_FILENAME
          ? getActiveProcessPath()
          : child.data.actualProcessPath;
    }

    return {
      type: data.toolbar ? 'Toolbar' : 'Tabs',
      data,
      children: [child]
    };
  }

  if (kind === 'Properties') {
    // console.log({ data });
    // console.log({ pid: data.pid });
    // console.log({ paths: getSelectionPaths({ pid: data.pid }) });
    data.selectionPaths = getSelectionPaths({ pid: data.pid });
  }

  if (kind === 'RootContainer' || kind === 'Container') {
    const newData = children.reduce((data, pane, i) => {
      const { data: props } = pane;
      data.flexValues = data.flexValues || [];
      data.minWidths = data.minWidths || [];
      data.maxWidths = data.maxWidths || [];
      data.minHeights = data.minHeights || [];
      data.maxHeights = data.maxHeights || [];
      data.resizablePaneIndexes = data.resizablePaneIndexes || [];
      data.minWidths.push(props.minWidth);
      data.maxWidths.push(props.maxWidth);
      data.minHeights.push(props.minHeight);
      data.maxHeights.push(props.maxHeight);
      data.flexValues.push([props.flexGrow, props.flexShrink, props.flexBasis]);
      // if (!!props.flexGrow && (!data.toolbar || !context.toolbar))
      // data.resizablePaneIndexes.push(i);
      data.resizablePaneIndexes.push(i);
      return data;
    }, data);

    return {
      type: kind,
      data: newData,
      children
    };
  }

  return {
    type: kind,
    data,
    children
  };
};

// given path or name, will return path to pane
const getPanePath = pane => {
  if (pane.startsWith(WORKSPACES_DIRECTORY_PATH)) {
    return pane;
  }
  const workspace = getSelectedWorkspaceFileName();
  if (!workspace) throw new Error('no workspace found!');
  const found = findSync({
    dir: getWorkspacePath(workspace),
    name: basename(pane),
    type: 'd'
  });

  if (!found || !found.length) {
    throw new Error('could not find pane!');
  }
  return found[0];
};

const getSelectedWorkspacePointerPath = () =>
  join(WORKSPACES_DIRECTORY_PATH, WORKSPACES_SELECTED_FILENAME);

const getWorkspacePath = workspace =>
  join(WORKSPACES_DIRECTORY_PATH, workspace);

const getWorkspaceNodePath = workspace =>
  join(getWorkspacePath(workspace), WORKSPACES_NODE_NAME);

const getWorkspaceRootNodePath = workspace =>
  join(getWorkspacePath(workspace), 'root');

const getSelectedWorkspaceFileName = () => {
  const workspace = readFile({
    path: getSelectedWorkspacePointerPath()
  });

  if (!workspace) {
    return;
  }

  return workspace;
};

const getSelectedWorkspace = ({ serialize = false } = {}) => {
  const workspace = getSelectedWorkspaceFileName();
  if (!serialize || !workspace) return workspace;

  const fullPath = getWorkspacePath(workspace);
  const result = serializeHierarchy({
    root: fullPath,
    recursive: true
  });
  return serializeWorkspace(
    result.nodes.root,
    getWorkspaceRootNodePath(workspace)
  );
};

const getSelectedWorkspaceNodePath = () => {
  const workspace = getSelectedWorkspace();
  return getWorkspaceNodePath(workspace);
};

const getSelectedWorkspaceNode = () => {
  const workspacePath = getSelectedWorkspaceNodePath();
  return serializeNode({ root: workspacePath });
};

const getSelectedWorkspacePane = () => {
  const node = getSelectedWorkspaceNode();
  return node.data.selected;
};

const getTargetedWorkspacePane = () => {
  const node = getSelectedWorkspaceNode();
  return node.data.targeted;
};

const getSelectedWorkspacePaneContainer = () => {
  return dirname(getSelectedWorkspacePane());
};

const getWorkspacePaneTabIndex = ({
  pane = getSelectedWorkspacePane()
} = {}) => {
  const node = serializeNode({ root: join(pane, 'tabs') });
  return node.data.selectedTabIndex;
};

const getSelectedWorkspacePaneTabIndex = () => {
  const pane = getSelectedWorkspacePane();
  return getWorkspacePaneTabIndex({ pane });
};

const getSelectedWorkspacePaneTabViewPath = () => {
  const index = getSelectedWorkspacePaneTabIndex();
  const pane = getSelectedWorkspacePane();
  const tabs = join(pane, 'tabs');
  const children = getOrderedNodeChildren({ root: tabs });
  if (!children.length) return;
  if (typeof index !== 'number') return;
  return join(tabs, children[index]);
};

const getProcessIdFromWorkspacePaneTab = ({ root, index } = {}) => {
  const tabs = getOrderedNodeChildren({ root });
  if (!tabs.length) {
    return;
  }

  if (typeof index === 'undefined') {
    index = getWorkspacePaneTabIndex({ pane: dirname(root) });
  }

  if (index >= tabs.length) {
    console.warn('you probably did not update the tab index!');
    return;
  }

  if (!tabs[index]) {
    console.warn('you probably did not update the tab index!');
    return;
  }

  const tab = serializeNode({
    root: join(root, tabs[index])
  });

  const {
    data: { pid }
  } = tab;

  return pid;
};

const getProcessIdFromWorkspacePane = inject(['fs'], function({
  pane = getSelectedWorkspacePane()
} = {}) {
  if (!this.fs.existsSync(pane)) {
    const moved = findSync({
      dir: WORKSPACES_DIRECTORY_PATH,
      name: basename(pane),
      type: 'd'
    });
    if (moved.length) {
      throw new Error('the fucking pane moved and we found it');
    } else {
      throw new Error('the fucking pane moved and we DID NOT find it');
    }
  }

  return getProcessIdFromWorkspacePaneTab({ root: join(pane, 'tabs') });
});

const getProcessIdFromWorkspace = ({ name = getSelectedWorkspace() } = {}) => {
  // TODO later store pid on workspace if there are no panes
  return getProcessIdFromWorkspacePane();
};

export {
  getProcessIdFromWorkspace,
  getProcessIdFromWorkspacePane,
  getProcessIdFromWorkspacePaneTab,
  getSelectedWorkspace,
  getSelectedWorkspaceNode,
  getSelectedWorkspaceNodePath,
  getSelectedWorkspacePane,
  getSelectedWorkspacePaneContainer,
  getSelectedWorkspacePaneTabIndex,
  getSelectedWorkspacePaneTabViewPath,
  getSelectedWorkspacePointerPath,
  getTargetedWorkspacePane,
  getWindowId,
  getPanePath,
  getWorkspacePaneTabIndex,
  getWorkspacePath,
  isCommandPaletteOpen,
  isTerminalOpen,
  serializeWorkspace
};
