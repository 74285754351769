import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DownIcon = function DownIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.259 3h17.482c.143 0 .259.127.259.285 0 .07-.024.138-.067.19l-8.77 10.681a.243.243 0 01-.385 0L.066 3.474a.305.305 0 01.02-.402A.246.246 0 01.259 3z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DownIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DownIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DownIcon';
export default ForwardRef;