import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var IntersectIcon = function IntersectIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M6.429 6.429v.75c0 .059-.048.107-.108.107H5.68a.107.107 0 01-.108-.107v-1.5c0-.06.048-.108.108-.108h1.5c.059 0 .107.048.107.108v.642c0 .06-.048.108-.107.108h-.75zm0 5.142h.75c.059 0 .107.048.107.108v.642c0 .06-.048.108-.107.108h-1.5a.107.107 0 01-.108-.108v-1.5c0-.059.048-.107.108-.107h.642c.06 0 .108.048.108.107v.75zm5.968.826a.107.107 0 01-.076.032h-1.5a.107.107 0 01-.107-.108v-.642c0-.06.048-.108.107-.108h.75v-.75c0-.059.048-.107.108-.107h.642c.06 0 .108.048.108.107v1.5c0 .03-.012.057-.032.076zm0-6.794c.02.02.032.046.032.076v1.5c0 .059-.048.107-.108.107h-.642a.107.107 0 01-.108-.107v-.75h-.75a.107.107 0 01-.107-.108V5.68c0-.06.048-.108.107-.108h1.5c.03 0 .057.012.076.032zm-7.58-.46a.107.107 0 00-.103.103v7.937c0 .05.046.103.103.103h7.937a.107.107 0 00.103-.103v4.706a.11.11 0 01-.111.111H.111A.11.11 0 010 17.889V5.254a.11.11 0 01.111-.111h4.706zm8.366 7.714a.107.107 0 00.103-.103V4.817a.107.107 0 00-.103-.103H5.246a.107.107 0 00-.103.103V.111A.11.11 0 015.254 0h12.635A.11.11 0 0118 .111v12.635a.11.11 0 01-.111.111h-4.706zM8.25 5.571h1.5c.06 0 .107.048.107.108v.642c0 .06-.048.108-.107.108h-1.5a.107.107 0 01-.107-.108V5.68c0-.06.048-.108.107-.108zm3.429 2.572h.642c.06 0 .108.048.108.107v1.5c0 .06-.048.107-.108.107h-.642a.107.107 0 01-.108-.107v-1.5c0-.06.048-.107.108-.107zM8.25 11.57h1.5c.06 0 .107.048.107.108v.642c0 .06-.048.108-.107.108h-1.5a.107.107 0 01-.107-.108v-.642c0-.06.048-.108.107-.108zM5.679 8.143h.642c.06 0 .108.048.108.107v1.5c0 .06-.048.107-.108.107H5.68a.107.107 0 01-.108-.107v-1.5c0-.06.048-.107.108-.107z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

IntersectIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(IntersectIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'IntersectIcon';
export default ForwardRef;