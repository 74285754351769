import React, { useState, memo } from 'react';
import { propsAreEqual } from '../../utils/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { getFormVariables } from '../../utils/form';
import {
  AuthForm,
  AuthHeading,
  Button,
  Field,
  Input,
  Label,
  AuthLoader,
  AuthParagraph
} from '../../ui';
import { SendVerificationEmailMutation } from '../../mutations';
import { UserEmailsQuery, UserAchievedQuery } from '../../queries';

const SendVerifyEmail = memo(function SendVerifyEmail({
  step,
  setStep,
  sent,
  setSent
}) {
  const [wantsToSend, setWantsToSend] = useState(false);
  const [sendVerification] = useMutation(SendVerificationEmailMutation, {
    onCompleted: () => {
      setSent(true);
    },
    onError: () => {},
    refetchQueries: [
      {
        query: UserAchievedQuery,
        variables: { achievement: 'verify_email' }
      }
    ]
  });
  const { data } = useQuery(UserEmailsQuery);

  let email = null;
  let disabled = true;

  if (data.userEmails && data.userEmails.nodes) {
    const emailToVerify = data.userEmails.nodes[0];
    email = emailToVerify.email;
    disabled = false;
  }

  if (!wantsToSend) {
    return (
      <AuthForm
        onSubmit={event => {
          event.preventDefault();
          setWantsToSend(true);
        }}
      >
        <AuthHeading>Email Verification</AuthHeading>
        <AuthParagraph>
          You should have recieved an email asking you to confirm your email
          address. If you didn't, you can request another one by clicking the
          button below. Otherwise proceed to your email to continue.
        </AuthParagraph>
        <Button
          fluid={true}
          disabled={disabled}
          type="submit"
          variant="primary"
        >
          Re-Send Email Verification
        </Button>
      </AuthForm>
    );
  }
  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        const variables = getFormVariables(event);
        sendVerification({ variables: { email: variables.email } });
      }}
    >
      <AuthHeading>Send Email Verification</AuthHeading>
      <Field fluid={true}>
        <Label htmlFor="email" id="email-label">
          Confirm Email
        </Label>
        <Input
          aria-labelledby="email-label"
          autoComplete="email"
          fluid={true}
          id="email"
          name="email"
          type="input"
          disabled={true}
          value={email}
        />
      </Field>
      <Button fluid={true} disabled={disabled} type="submit" variant="primary">
        Send Email Verification
      </Button>
    </AuthForm>
  );
},
propsAreEqual);

const VerifyEmailSent = memo(function VerifyEmailCompleted({ step, setStep }) {
  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        setStep(step + 1);
      }}
    >
      <AuthHeading>Send Email Verification</AuthHeading>
      <AuthParagraph>
        Your verification email has been sent. Please check your email to
        proceed.
      </AuthParagraph>
    </AuthForm>
  );
}, propsAreEqual);

const VerifyEmailCompleted = memo(function VerifyEmailCompleted({
  step,
  setStep
}) {
  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        setStep(step + 1);
      }}
    >
      <AuthHeading>Send Email Verification</AuthHeading>
      <AuthParagraph>Your email has already been verified.</AuthParagraph>
      <Button fluid={true} disabled={false} type="submit" variant="primary">
        Next
      </Button>
    </AuthForm>
  );
},
propsAreEqual);

const VerifyEmail = memo(function VerifyEmail(props) {
  const { data: achieved, loading, error } = useQuery(UserAchievedQuery, {
    variables: { achievement: 'verify_email' }
  });
  const [sent, setSent] = useState(false);

  if (loading) return <AuthLoader title="Send Email Verification" />;
  if (error) return <div>Error verify_email</div>;

  if (achieved.oncomplete) {
    return <VerifyEmailCompleted {...props} />;
  }

  if (sent) {
    return <VerifyEmailSent {...props} />;
  }

  return <SendVerifyEmail {...props} sent={sent} setSent={setSent} />;
}, propsAreEqual);

export { VerifyEmail };
