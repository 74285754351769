import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SingleSelectIcon = function SingleSelectIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.105 0H12.55c.059 0 .106.048.106.107v8.427L7.782 5.247a.105.105 0 00-.146.03.108.108 0 00-.018.07l.82 7.454H.104A.106.106 0 010 12.694V.107C0 .047.047 0 .105 0zm9.984 17.91L8.333 6.65a.107.107 0 01.087-.122.105.105 0 01.078.018l9.457 6.754a.107.107 0 01-.042.192l-4.576.818a.105.105 0 00-.062.036l-3.002 3.616a.105.105 0 01-.148.013.107.107 0 01-.036-.065zm5.203-2.976h2.32c.059 0 .106.048.106.107v2.346a.106.106 0 01-.105.107h-2.32a.106.106 0 01-.106-.107v-2.346c0-.06.047-.107.105-.107z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SingleSelectIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SingleSelectIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SingleSelectIcon';
export default ForwardRef;