import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import {
  borderRadius,
  borderWidth,
  em,
  margin,
  transparentize,
  padding
} from 'polished';
import themeGet from '@styled-system/theme-get';

const variant = props => ({
  backgroundColor: 'transparent',
  borderColor: themeGet('colors.border')(props),
  color: themeGet('colors.action.default')(props),

  '&:hover, &:focus': {
    borderColor: themeGet('colors.border')(props),
    backgroundColor:
      props.theme.mode === 'dark'
        ? `${transparentize(0.91, themeGet('colors.white')(props))}`
        : themeGet('colors.blue.1')(props),
    color:
      props.theme.mode === 'dark'
        ? themeGet('color.action.active')(props)
        : themeGet('colors.white')(props)
  },

  '&:active, &[aria-current="true"]': {
    borderColor: themeGet('colors.border')(props),
    backgroundColor:
      props.theme.mode === 'dark'
        ? `${transparentize(0.91, themeGet('colors.white')(props))}`
        : themeGet('colors.blue.1')(props),
    color:
      props.theme.mode === 'dark'
        ? themeGet('color.action.active')(props)
        : themeGet('colors.white')(props)
  },

  '&[aria-disabled="true"]': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: themeGet('color.action.disabled')(props)
  }
});

export const PaginationListItemLinkPrevious = styled.a(
  {
    ...borderRadius('left', em(3)),
    ...borderWidth(0, 0.5, 0, 0),
    ...margin(0.5, 0),
    ...padding(em(11.5), em(11.5 - 0.5), em(11.5), em(11.5)),
    appearance: 'none',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 0,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 18 / 16,
    textDecoration: 'none'
  },
  variant
);

PaginationListItemLinkPrevious.propTypes = {
  'aria-current': PropTypes.bool,
  'aria-disabled': PropTypes.bool
};
