import tinyColor from 'tinycolor2';

export class Color {
  constructor(input) {
    this.color = tinyColor(input);
    this.initColorTypes();
  }

  initColorTypes = () => {
    this.initRGB();
    this.initHSB();
  };

  initRGB() {
    const { a, r, g, b } = this.color.toRgb();
    this.alphaValue = a;
    this.redValue = r;
    this.greenValue = g;
    this.blueValue = b;
  }

  initHSB() {
    // Sketch and Figma use HSB/HSV so we use HSV instead of HSL
    const { h, s, v } = this.color.toHsv();
    this.hueValue = h;
    this.saturationValue = s;
    this.brightnessValue = v;
  }

  get isValid() {
    return this.color.isValid();
  }

  // Get RGBA attributes
  get alpha() {
    return this.alphaValue;
  }

  get red() {
    return this.redValue;
  }

  get green() {
    return this.greenValue;
  }

  get blue() {
    return this.blueValue;
  }

  // Get HSB attributes
  get hue() {
    return this.hueValue;
  }

  get saturation() {
    return this.saturationValue;
  }

  get brightness() {
    return this.brightnessValue;
  }

  get hexString() {
    return this.color.toHexString();
  }

  get rgbString() {
    return this.color.toRgbString();
  }

  // Set RGB attributes
  set alpha(value) {
    if (value > 1) {
      value = value / 100;
    }
    this.alphaValue = value;
    this.color.setAlpha(value);
  }

  set red(value) {
    this.redValue = value;
    this.color = tinyColor({
      ...this.color.toRgb(),
      r: value
    });
    this.initHSB();
  }

  set green(value) {
    this.greenValue = value;
    this.color = tinyColor({
      ...this.color.toRgb(),
      g: value
    });
    this.initHSB();
  }

  set blue(value) {
    this.blueValue = value;
    this.color = tinyColor({
      ...this.color.toRgb(),
      b: value
    });
    this.initHSB();
  }

  // Set HSB attributes
  set hue(value) {
    this.hueValue = value;
    this.color = tinyColor({
      h: value,
      s: this.saturation,
      v: this.brightness
    });
    this.initRGB();
  }

  set saturation(value) {
    this.saturationValue = value;
    this.color = tinyColor({
      h: this.hueValue,
      s: value,
      v: this.brightnessValue
    });
    this.initRGB();
  }
  set brightness(value) {
    this.brightnessValue = value;
    this.color = tinyColor({
      h: this.hue,
      s: this.saturation,
      v: value
    });
    this.initRGB();
  }
}
