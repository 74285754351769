import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var NoColorIcon = function NoColorIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.857.857v16.286h16.286V.857H.857zM9 8.394L16.411.983l.606.606L9.607 9l7.41 7.411-.606.606L9 9.607l-7.411 7.41-.606-.606L8.393 9 .984 1.589l.606-.606L9 8.393zM0 0h18v18H0V0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

NoColorIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(NoColorIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'NoColorIcon';
export default ForwardRef;