import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { margin, em } from 'polished';

const variant = css({
  color: 'rgb(180,180,180)',
  backgroundColor: 'rgb(50,50,50)',

  '&:active, &:focus': {
    boxShadow: `rgba(0,0,0,0.15) 0 ${em(6)} ${em(16)}`,
    color: 'rgb(250,250,250)',
    backgroundColor: 'rgb(50,50,50)'
  },
  '&:hover': {
    boxShadow: `rgba(0,0,0,0.15) 0 ${em(6)} ${em(16)}`,
    color: 'rgb(250,250,250)',
    backgroundColor: 'rgb(50,50,50)'
  }
});

export const ProjectFeedItem = styled.div(
  {
    ...margin(em(12)),
    cursor: 'pointer',
    display: 'block',
    height: em(240),
    position: 'relative',
    textDecoration: 'none',
    transitionDuration: '0.15s',
    transitionProperty: 'background-color, color, boxShadow',
    transitionTimingFunction: 'ease-in-out',
    width: em(228)
  },
  variant
);

ProjectFeedItem.defaultProps = {
  role: 'article'
};
