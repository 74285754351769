import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';

export class FlatList extends React.Component {
  static defaultProps = {
    as: React.Fragment,
    refreshing: false
  };
  static propTypes = {
    data: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    as: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    refreshing: PropTypes.bool.isRequired
  };
  shouldComponentUpdate(nextProps) {
    return nextProps.refreshing === false && !isEqual(this.props, nextProps);
  }
  render() {
    const Component = this.props.as;
    return (
      <Component>
        {this.props.data.map((item, index) =>
          this.props.renderItem({ item, index })
        )}
      </Component>
    );
  }
}
