import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CheckboxIcon = function CheckboxIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 .106C0 .047.052 0 .106 0h17.788c.059 0 .106.052.106.106v17.788a.109.109 0 01-.106.106H.106A.109.109 0 010 17.894V.106zM6.108 14.82c.123.12.246.18.43.18.185 0 .308-.06.431-.18l9.846-9.96a.57.57 0 000-.84l-.861-.84a.605.605 0 00-.862 0l-8.307 8.34a.303.303 0 01-.431 0l-3.446-3.6a.605.605 0 00-.862 0l-.861.84A.64.64 0 001 9.18c0 .12.062.3.185.42l4.923 5.22z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CheckboxIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CheckboxIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CheckboxIcon';
export default ForwardRef;