import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var H2Icon = function H2Icon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm7.978 6.086v3.096h-3.56V6.086H3.55v7.2h.977v-3.158h3.559v3.158h.977v-7.2h-.977zm2.345 7.2h4.443v-.947H12.24c.494-.617.998-1.234 1.481-1.851.576-.73 1.07-1.44 1.07-2.345 0-1.132-.895-2.191-2.273-2.191-1.389 0-2.243 1.07-2.315 2.222l.906.257c.051-.864.606-1.512 1.409-1.512a1.26 1.26 0 011.285 1.255c0 .771-.504 1.378-1.049 2.057-.792 1.008-1.543 1.975-2.324 2.942v.113z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

H2Icon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(H2Icon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'H2Icon';
export default ForwardRef;