import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var BodyIcon = function BodyIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm10.158 9.377c.442-.329.7-.833.7-1.43 0-.977-.69-1.861-2.109-1.861H6.871v7.2h2.355c1.471 0 2.243-1.019 2.243-2.068 0-.864-.474-1.522-1.204-1.84zm-1.47-2.335c.74 0 1.182.412 1.182.998 0 .566-.452 1.039-1.193 1.039h-.936V7.042h.946zm.36 5.287H7.847v-2.283h1.306c.823 0 1.296.493 1.296 1.131s-.483 1.152-1.296 1.152z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

BodyIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(BodyIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'BodyIcon';
export default ForwardRef;