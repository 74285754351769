import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var FilesIcon = function FilesIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M16.875 1.125H5.625v2.25h-.844a.281.281 0 01-.281-.281V.28C4.5.126 4.626 0 4.781 0H17.72c.155 0 .281.126.281.281v16.313a.281.281 0 01-.281.281h-.844V1.125zM0 4.612C0 4.55.05 4.5.103 4.5h9.258a.263.263 0 00.172-.08l.828-.964a.267.267 0 01.177-.081h4.137c.06 0 .14.037.177.08l.828.964c.039.045.07.13.07.193v1.013H0V4.612zM0 6.75h15.75v10.969a.281.281 0 01-.281.281H.102C.046 18 0 17.953 0 17.888V6.75zm10.406 6.75a.281.281 0 00-.281.281v2.813c0 .155.126.281.281.281h3.938a.281.281 0 00.281-.281V13.78a.281.281 0 00-.281-.281h-3.938z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

FilesIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(FilesIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'FilesIcon';
export default ForwardRef;