import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var WorkspaceIcon = function WorkspaceIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.143 0H6v6l-.857-.857V0zm0 10.286v3.857H6v-3.001l-.857-.856zm12 6.857V.857H6V0h12v18H0V0l17.143 17.143zM6.857 1.714h9.429v.857H6.857v-.857zm3.429 1.715h6v.857h-6v-.857zm-3.429 0H9.43v.857H6.857v-.857zm1.714 1.714h7.715V6H8.57v-.857zm1.715 1.714h2.571v.857h-2.571v-.857zm3.428 0h2.572v.857h-2.572v-.857zM12 8.571h4.286v.858H12V8.57zM3.857 9v5.143H9L3.857 9z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

WorkspaceIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(WorkspaceIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'WorkspaceIcon';
export default ForwardRef;