import gql from 'graphql-tag';

export const CompleteProfileTasksQuery = gql`
  query CompleteProfileTasksQuery {
    userAchievements(condition: { name: "profile_complete" }) {
      edges {
        node {
          id
          name
          achievementUserTasks(orderBy: PRIORITY_ASC) {
            edges {
              node {
                id
                name
                priority
              }
            }
          }
        }
      }
    }
  }
`;
