import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SectionIcon = function SectionIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M18 .25v17.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25V.25A.25.25 0 01.25 0h17.5a.25.25 0 01.25.25zM1 3.274v13.452c0 .151.112.274.25.274h15.5c.138 0 .25-.123.25-.274V3.274c0-.151-.112-.274-.25-.274H1.25c-.138 0-.25.123-.25.274z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SectionIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SectionIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SectionIcon';
export default ForwardRef;