import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { transparentize } from 'polished';
import { Icon } from '../Icon';

const coverHeight = 120;

const coverRatio = props =>
  props.keepCoverRatio
    ? {
        '& > *': {
          height: '100%',
          width: 'auto'
        },
        '& img': {
          maxWidth: '100%',
          maxHeight: '100%'
        }
      }
    : {
        '& img': {
          width: '100%',
          height: coverHeight
        }
      };

const variant = props =>
  ({
    light: {
      borderBottom: `0.5px solid ${transparentize(
        0.8,
        themeGet('colors.black')(props)
      )}`
    },
    dark: {
      backgroundColor: themeGet('colors.background.default')(props),
      borderBottom: `0.5px solid ${themeGet('colors.black')(props)}`
    }
  }[props.theme.mode]);

export const CardCover = styled.div(
  props => ({
    position: 'relative',
    overflow: 'hidden',
    margin: 0.5,
    height: coverHeight,
    '& > *': {
      display: 'block',
      margin: '0 auto'
    },
    '& img': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: 'transform .5s'
    },
    '&:hover img': {
      transform: 'scale(1.1)'
    },
    [`& > ${Icon}`]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }),
  variant,
  coverRatio
);

CardCover.propTypes = {
  // If true, cover will have some space off the container to retain ratio
  keepCoverRatio: PropTypes.bool
};

CardCover.defaultProps = {
  keepCoverRatio: false
};
