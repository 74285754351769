import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SymbolsIcon = function SymbolsIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M12.457 2.172a.443.443 0 01-.277.267l-1.412.523a.433.433 0 01-.162.03.484.484 0 01-.449-.326.504.504 0 01.296-.632l.22-.079a6.874 6.874 0 00-2.062-.316c-3.95 0-7.157 3.316-7.157 7.402 0 1.007.191 1.984.563 2.882a.503.503 0 01-.248.651.476.476 0 01-.63-.257A8.625 8.625 0 01.5 9.042C.5 4.412 4.136.652 8.611.652c.869 0 1.699.138 2.5.405L10.96.692a.503.503 0 01.248-.652.477.477 0 01.63.257l.62 1.49a.453.453 0 010 .385zm5.043 6.73c0 4.79-3.762 8.68-8.392 8.68a7.902 7.902 0 01-3.169-.644l.148.347a.515.515 0 01-.257.674.483.483 0 01-.642-.265l-.651-1.542a.582.582 0 010-.409.548.548 0 01.286-.275l1.462-.541a.496.496 0 01.631.316.508.508 0 01-.296.654l-.276.102a6.808 6.808 0 002.764.561c4.087 0 7.405-3.43 7.405-7.658a7.714 7.714 0 00-.593-2.981.52.52 0 01.257-.674.49.49 0 01.652.265 8.898 8.898 0 01.671 3.39zM9 13a4 4 0 110-8 4 4 0 010 8z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SymbolsIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SymbolsIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SymbolsIcon';
export default ForwardRef;