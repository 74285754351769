import React, { memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Avatar, Loader } from '../ui';
import { CurrentRoleQuery } from '../queries';
import { propsAreEqual } from '../utils/react';

export const CurrentAvatar = memo(function CurrentAvatar(props) {
  const {
    data: { currentRole } = { currentRole: { id: 'loading' } },
    loading
  } = useQuery(CurrentRoleQuery, {
    fetchPolicy: 'cache-first'
  });

  let gravatarUrl = localStorage.getItem('gravatar');
  gravatarUrl = gravatarUrl ? `${gravatarUrl}?s=${props.size}` : gravatarUrl;

  const url =
    gravatarUrl ||
    `https://robohash.org/bgset_bg2/${currentRole.id}.jpeg?size=${props.size}x${
      props.size
    }`;

  return loading ? (
    <Loader width={props.size} height={props.size} />
  ) : (
    <Avatar {...props} url={url} />
  );
}, propsAreEqual);
