import gql from 'graphql-tag';
import { ContentFeedFragment } from '../fragments';

export const DeleteContentMutation = gql`
  mutation DeleteContentMutation($id: UUID!) {
    deleteContent(input: { id: $id }) {
      content {
        ...ContentFeedFragment
      }
    }
  }
  ${ContentFeedFragment}
`;
