import React from 'react';
import { ThemeProvider } from 'emotion-theming';

export const AppContext = React.createContext();

export const useAppContext = () => React.useContext(AppContext);

export const AppProvider = ({ children, theme, setTheme }) => (
  <AppContext.Provider value={{ theme, setTheme }}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </AppContext.Provider>
);
