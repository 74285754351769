import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { lighten } from 'polished';
import { Box } from '../Box';

export const Tag = styled(Box)(props => {
  const intentColor = themeGet(`colors.intent.${props.intent}.0`)(props);
  const intentBorderColor = themeGet(`colors.intent.${props.intent}.1`)(props);

  return {
    display: 'inline-block',
    backgroundColor: intentColor,
    padding: `0 ${themeGet('space.2')(props)}px`,
    maxHeight: 16,
    fontSize: 12,
    lineHeight: themeGet('lineHeights.normal')(props),
    color: themeGet('colors.white')(props),
    borderRadius: 4,
    border: `1px solid ${intentBorderColor}`,
    ...(props.interactive
      ? {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: lighten(0.05, intentColor)
          },
          '&:active': {
            backgroundColor: lighten(0.1, intentColor)
          }
        }
      : {})
  };
});

Tag.defaultProps = {
  as: 'span'
};
