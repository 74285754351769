import styled from '@emotion/styled';
import { Box } from './Box';

export const Flex = styled(Box)({
  display: 'flex'
});

Flex.Item = styled(Box)({
  display: 'flex'
});
