import {
  mkdirpSync,
  rimrafSync,
  join,
  dirname,
  basename,
  moveArrayElements,
  findSync,
  getNewIndexAfterRemoval,
  extname
} from '../../utils';

import injector from '../../injector';

import { WORKSPACES_DIRECTORY_PATH, WORKSPACES_NODE_NAME } from './constants';

import {
  getPanePath,
  getProcessIdFromWorkspacePane,
  getProcessIdFromWorkspacePaneTab,
  getSelectedWorkspace,
  getSelectedWorkspaceNode,
  getSelectedWorkspaceNodePath,
  getSelectedWorkspacePane,
  getSelectedWorkspacePaneContainer,
  getSelectedWorkspacePaneTabViewPath,
  getSelectedWorkspacePointerPath,
  getTargetedWorkspacePane,
  getWindowId,
  getWorkspacePaneTabIndex,
  getWorkspacePath
} from './queries';

import {
  getCurrentWorkingDirectory,
  getNodeChildren,
  getOrderedNodeChildren,
  getUniqLocalId,
  getNodeMetaData,
  serializeNode
} from '../../queries';

import {
  addGraph,
  addNode,
  createProcess,
  kill,
  removeNodeMetaData,
  rename,
  safeRename,
  setActiveProcess,
  setProp,
  updateNodeMetaData,
  updateFolderMetaData
} from '../../commands';
import { setOrderedNodeChildren, setProperty, setType } from '../flow/commands';
import {
  PROCESSES_ACTIVE_PROCESS_FILENAME,
  PROCESSES_DIRECTORY_PATH,
  PROCESSES_FOLLOWER_EXTENSIONS
} from '../process/constants';
import { appendTerminalOutput } from '../terminal/commands';
import { readState } from '../core/queries';
import { mod } from '../../../utils/math';

const { inject } = injector;

// TODO just convert panes/containers dont transfer props
const transferProps = [
  'minWidth',
  'maxWidth',
  'minHeight',
  'maxHeight',
  'flexGrow',
  'flexShrink',
  'flexBasis',
  'closeable',
  'toolbar'
];

const transferPropsDefaults = [
  0,
  Number.MAX_SAFE_INTEGER,
  0,
  Number.MAX_SAFE_INTEGER,
  1,
  1,
  'auto',
  true
];

// used for cloning a tab node
const getPidFromPid = ({ pid }) => {
  if (pid === PROCESSES_ACTIVE_PROCESS_FILENAME) {
    return pid;
  }
  if (pid.endsWith(PROCESSES_FOLLOWER_EXTENSIONS)) {
    return pid;
  }
  return undefined;
};

const getPid = ({ pid, title, spawn, follow, path, data }) => {
  const pidFromPid = data && data.pid && getPidFromPid({ pid: data.pid });
  if (pidFromPid) return pidFromPid;
  if (!spawn) return PROCESSES_ACTIVE_PROCESS_FILENAME;
  if (follow) return `${basename(follow)}${PROCESSES_FOLLOWER_EXTENSIONS}`;
  return title
    ? createProcess({
        title, // TODO slugify title for pid
        pid: pid ? pid : `${title}-${getUniqLocalId(PROCESSES_DIRECTORY_PATH)}`,
        cwd: path
      })
    : createProcess({ cwd: path, pid: pid ? pid : undefined });
};

// TODO get list of components from compiled definitions, icon, title, etc
const getIconFromView = view => {
  switch (view) {
    case 'Source':
      return 'library';
    case 'Network':
      return 'subgraph';
    case 'Properties':
      return 'adjust-properties';
    case 'Definitions':
      return 'package';
    case 'Terminal':
      return 'terminal';
    case 'FileBrowser':
      return 'files';
    default:
      return 'object';
  }
};

const getDefaultViewFromFilename = path => {
  switch (extname(path)) {
    case '.css':
    case '.scss':
    case '.js':
    case '.jsx':
    case '.ts':
    case '.tsx':
    case '.json':
    case '.sh':
      return 'CodeEditor';
    case '':
      switch (basename(path)) {
        case '.babelrc':
          return 'CodeEditor';
        default:
          return 'Terminal';
      }
    default:
      return 'Terminal';
  }
};

const createTab = ({
  pane = getTargetedWorkspacePane() || getSelectedWorkspacePane(),
  view,
  data = {},
  path = getCurrentWorkingDirectory(),
  title,
  pid,
  icon,
  spawn = true,
  follow
} = {}) => {
  // data used for cloning tab nodes
  if (data && data.type) view = data.type;
  if (!view) view = getDefaultViewFromFilename(path);

  const tabsPath = join(pane, 'tabs');
  pid = getPid({ pid, title, spawn, follow, path, data });

  if (!title) title = pid;

  icon = icon || getIconFromView(view);

  const tabsBefore = getNodeChildren({ root: tabsPath });
  const [tab] = addGraph({
    root: tabsPath,
    type: 'Tab',
    data: {
      pid,
      title,
      icon
    },
    meta: {
      x: tabsBefore.length
    }
  });

  const [tabElement] = addNode({
    root: tab,
    type: view,
    data: {
      ...data,
      pid
    }
  });

  setOrderedNodeChildren({
    root: tabsPath,
    children: tabsBefore.concat(basename(tabElement))
  });

  setProp({
    root: pane,
    node: 'tabs',
    prop: 'selectedTabIndex',
    value: tabsBefore.length
  });

  return [tab, tabElement];
};

const openCommandPalette = () => {
  const path = getSelectedWorkspaceNodePath();
  setProperty({ path, prop: 'commandPaletteOpen', value: false });
  return [path];
};

const closeCommandPalette = () => {
  const path = getSelectedWorkspaceNodePath();
  setProperty({ path, prop: 'commandPaletteOpen', value: false });
  return [path];
};

const toggleCommandPalette = () => {
  const node = getSelectedWorkspaceNode();
  const path = getSelectedWorkspaceNodePath();
  node.data.commandPaletteOpen = !node.data.commandPaletteOpen;
  setProperty({
    path,
    prop: 'commandPaletteOpen',
    value: node.data.commandPaletteOpen
  });
  return [path];
};

// TODO move to it's own file since it could be outside of workspace
const toggleTerminal = () => {
  const node = getSelectedWorkspaceNode();
  const path = getSelectedWorkspaceNodePath();
  node.data.terminalOpen = !node.data.terminalOpen;
  setProperty({ path, prop: 'terminalOpen', value: node.data.terminalOpen });
  return [path];
};

const closeTab = ({ pane = getSelectedWorkspacePane(), index } = {}) => {
  const tabsPath = join(pane, 'tabs');
  const tabs = getOrderedNodeChildren({ root: tabsPath });
  if (tabs.length === 0) return closePane({ root: pane });
  if (typeof index === 'undefined') index = getWorkspacePaneTabIndex({ pane });
  if (index < 0) return [];
  if (index >= tabs.length) return [];
  const tab = tabs[index];
  const currentIndex = getWorkspacePaneTabIndex({ pane });
  const node = serializeNode({ root: join(tabsPath, tab) });
  kill(node.data.pid);
  removeNodeMetaData({ path: join(tabsPath, tab) });
  rimrafSync(join(tabsPath, tab));
  setProperty({
    path: tabsPath,
    prop: 'selectedTabIndex',
    value: getNewIndexAfterRemoval(currentIndex, index, tabs.length)
  });
  if (tabs.length === 1) return [tabsPath].concat(closePane({ root: pane }));
  return [tabsPath];
};

const createPane = ({
  root,
  flexGrow,
  flexBasis,
  flexShrink,
  locked = false,
  closeable = true,
  targetable = true,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  view,
  data,
  paneId
}) => {
  const name = paneId ? paneId : getWindowId();
  const [pane] = addGraph({
    root,
    type: 'Pane',
    name,
    data: {
      flexGrow,
      flexBasis,
      flexShrink,
      locked,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
      closeable,
      targetable
    },
    meta: {
      x: 0
    }
  });

  const [tabs] = addGraph({
    root: pane,
    type: 'Tabs',
    name: 'tabs',
    data: {
      selectedTabIndex: -1
    },
    meta: {
      x: 0
    }
  });

  return [pane, tabs].concat(
    view || (data && data.type) ? createTab({ pane, view, data }) : []
  );
};

const setSelectedWorkspace = inject(['fs'], function setSelectedWorkspace(
  name
) {
  const { fs } = this;
  const fullPath = getSelectedWorkspacePointerPath();
  const workspacePath = getWorkspacePath(name);
  if (!fs.existsSync(workspacePath)) {
    throw new Error('workspace does not exist');
  }
  fs.writeFileSync(fullPath, name);
  // TODO handle fact that we dont call updateWorkspaceActiveProcess here
  return [workspacePath];
});

const setSelectedWorkspacePane = inject(['fs'], function({
  name = getSelectedWorkspace(),
  pane
} = {}) {
  pane = getPanePath(pane);

  if (!this.fs.existsSync(pane)) {
    // probably an event not-preventing default or checking if defaultPrevented
    console.log(
      'somebody is trying to set workspace pane to a non-existent pane'
    );
    return [];
  }

  const workspacePath = getWorkspacePath(name);
  const node = serializeNode({ root: pane });
  const pid = getProcessIdFromWorkspacePane({ pane });

  setProp({
    root: workspacePath,
    node: WORKSPACES_NODE_NAME,
    prop: 'selected',
    value: pane
  });

  if (node.data.targetable) {
    setProp({
      root: workspacePath,
      node: WORKSPACES_NODE_NAME,
      prop: 'targeted',
      value: pane
    });
  }

  return [workspacePath].concat(pid ? setActiveProcess(pid) : []);
});

setSelectedWorkspacePane.description = 'select pane';
setSelectedWorkspacePane.args = [
  {
    _: true,
    name: 'pane',
    type: 'string'
  }
];

const setSelectedWorkspacePaneTabView = ({ type }) => {
  const path = getSelectedWorkspacePaneTabViewPath();
  if (!path) return [];
  setType({ path, type });
  return [path];
};

const setSelectedWorkspacePaneTabIndex = ({
  pane = getSelectedWorkspacePane(),
  index
} = {}) => {
  const tabs = getOrderedNodeChildren({ root: join(pane, 'tabs') });
  if (index >= tabs.length) {
    throw new Error('cannot set tab index. outside of range');
  }

  setProp({
    root: pane,
    node: 'tabs',
    prop: 'selectedTabIndex',
    value: index
  });

  const pid = getProcessIdFromWorkspacePaneTab({
    root: join(pane, 'tabs'),
    index
  });

  return [pane].concat(pid ? setActiveProcess(pid) : []);
};

const createWorkspace = name => {
  if (!name) name = getUniqLocalId(WORKSPACES_DIRECTORY_PATH);
  const workspacePath = getWorkspacePath(name);
  mkdirpSync(workspacePath);

  const direction = 'row';
  addNode({
    root: workspacePath,
    type: 'Workspace',
    name: WORKSPACES_NODE_NAME,
    data: {},
    meta: {
      x: 0
    }
  });

  const [root] = addGraph({
    root: workspacePath,
    type: 'RootContainer',
    name: 'root',
    data: {
      direction,
      resizers: true
    },
    meta: {
      x: 0
    }
  });

  const [pane] = createPane({ root, paneId: 'DefaultPane' });

  setSelectedWorkspace(name);
  setSelectedWorkspacePane({ name, pane });
  const pid = getProcessIdFromWorkspacePane({ pane });
  pid && setActiveProcess(pid);
  return [root];
};

const killProcessesOfTabGroup = inject(
  ['fs', 'path'],
  function killProcessesOfTabGroup(root) {
    if (!this.fs.existsSync(root)) return;
    const tabs = getNodeChildren({ root });
    const pids = tabs.map(
      tab => serializeNode({ root: this.path.join(root, tab) }).data.pid
    );
    kill(...pids);
  }
);

const findNearestPaneChild = ({ root }) => {
  const node = serializeNode({ root });
  if (node.type === 'Pane') return root;
  if (node.type === 'Container') {
    const children = getNodeChildren({ root });
    const childrenPaths = children.map(child => join(root, child));
    for (let child of childrenPaths) {
      const path = findNearestPaneChild({ root: child });
      if (path) {
        return path;
      }
    }
  }
};

const setPaneProperty = ({
  pane = getSelectedWorkspacePane(),
  prop,
  value
} = {}) => setProperty({ path: pane, prop, value });

const setContainerProperty = ({
  pane = getSelectedWorkspacePane(),
  prop,
  value
} = {}) => setProperty({ path: dirname(pane), prop, value });

const setTabsProperty = ({
  pane = getSelectedWorkspacePane(),
  prop,
  value
} = {}) => setProperty({ path: join(pane, 'tabs'), prop, value });

const closePane = inject(['path'], function closePane({
  root = getSelectedWorkspacePane()
} = {}) {
  const { path } = this;
  const containerRoot = path.dirname(root);
  const parentContainer = serializeNode({ root: containerRoot });
  const paneToClose = serializeNode({ root });
  if (!paneToClose.data.closeable) {
    return [];
  }

  if (
    parentContainer.type !== 'Container' &&
    parentContainer.type !== 'RootContainer'
  ) {
    throw new Error('closePane requires a Pane living inside of a Container');
  }

  const siblings = getNodeChildren({ root: containerRoot }).filter(
    child => child !== path.basename(root)
  );
  if (siblings.length === 0) {
    // console.warn('cannot close last pane yet!');
    return [];
  }

  killProcessesOfTabGroup(path.join(root, 'tabs'));

  if (siblings.length === 1) {
    if (parentContainer.type === 'RootContainer') {
      /*
         ONLY one sibling at the root
         1. delete the selected pane
         2. check the last sibling type, if it's type is:
            Container:
              move all it's children to containerRoot
              delete it
            Pane:
              -
          3. set current pane
      */

      // 1
      rimrafSync(root);

      // 2
      const lastSiblingPath = path.join(containerRoot, siblings[0]);
      const lastSiblingNode = serializeNode({ root: lastSiblingPath });
      if (lastSiblingNode.type === 'Container') {
        const lastSiblingContainerChildren = getNodeChildren({
          root: lastSiblingPath
        });
        // const containerMetaData = getNodeMetaData({ path: lastSiblingPath });
        const lastSiblingContainerChildrenPaths = lastSiblingContainerChildren.map(
          child => path.join(lastSiblingPath, child)
        );
        safeRename({
          sources: lastSiblingContainerChildrenPaths,
          target: containerRoot
        });
        rimrafSync(lastSiblingPath);

        // TODO get the pane values and maintain them intead of just equal spacing
        // TODO get the missing meta data!

        const newChildPath = path.join(
          containerRoot,
          lastSiblingContainerChildren[0]
        );
        const newPanePath = findNearestPaneChild({ root: newChildPath });
        if (!newPanePath) {
          throw new Error('cannot find a pane!');
        }
        setSelectedWorkspacePane({ pane: newPanePath });
      } else if (lastSiblingNode.type === 'Pane') {
        setPaneSpacing({ root: containerRoot, equal: true });
        setSelectedWorkspacePane({ pane: lastSiblingPath });
      } else {
        throw new Error(
          `dont know what do to with type ${lastSiblingNode.type}`
        );
      }

      // TODO make sure it's not a container!
    } else {
      /*
      ONLY one sibling, meaning
      1. delete selected pane
      2. determine the grandparent of current pane root, grantContainerRoot
      3. take last sibling, and insert it into the grandContainerRoot
      */

      const grandContainerRoot = path.dirname(containerRoot);
      const grandParent = serializeNode({ root: grandContainerRoot });
      const parent = serializeNode({ root: containerRoot });

      if (
        grandParent.type !== 'Container' &&
        grandParent.type !== 'RootContainer'
      ) {
        throw new Error(
          'closePane requires a Pane living inside of a Container'
        );
      }
      const [newPath] = safeRename({
        sources: [path.join(containerRoot, siblings[0])],
        target: grandContainerRoot
      });
      const meta = getNodeMetaData({ path: containerRoot });
      removeNodeMetaData({ path: containerRoot });
      rimrafSync(containerRoot);

      // get positional meta data
      updateNodeMetaData({ path: newPath, meta });

      // NOTE transfer props
      transferProps.forEach(prop => {
        setProperty({
          path: newPath,
          prop,
          value: parent.data[prop]
        });
      });

      const foundPane = findNearestPaneChild({
        root: path.join(grandContainerRoot, path.basename(newPath))
      });
      if (foundPane) {
        setSelectedWorkspacePane({ pane: foundPane });
      } else {
        console.warn('could NOT find a pane upon close!');
      }
    }
  } else {
    /*
      more than one sibling, meaning
      1. delete selected pane
      2. set new pane to any sibling
      3. check if it is a container, get first pane child to select
    */
    rimrafSync(root);
    setPaneSpacing({ root: containerRoot, equal: true });
    const nextSiblingPath = path.join(containerRoot, siblings[0]);
    const foundPane = findNearestPaneChild({ root: nextSiblingPath });
    if (foundPane) {
      setSelectedWorkspacePane({ pane: foundPane });
    } else {
      console.warn('could NOT find a pane upon close!');
    }
  }

  return [containerRoot];
});

const splitMoveTab = ({
  sourceTabs,
  targetTabs,
  sourceIndex = 0,
  targetIndex = 0,
  direction
} = {}) => {
  if (!direction) {
    return moveTab({
      sourceTabs,
      targetTabs,
      sourceIndex,
      targetIndex,
      direction
    });
  }

  const targetPane = dirname(targetTabs);
  const [pane] = splitPane({ root: targetPane, direction, select: false });

  // finding the source because it could have moved due to the split!
  const sourcePaneFound = findSync({
    dir: WORKSPACES_DIRECTORY_PATH,
    name: basename(dirname(sourceTabs)),
    type: 'd'
  });

  if (!sourcePaneFound.length) {
    return console.warn('no panes found!');
  }

  return moveTab({
    sourceTabs: join(sourcePaneFound[0], 'tabs'),
    targetTabs: join(pane, 'tabs'),
    sourceIndex,
    targetIndex: 0
  });
};

const moveTab = ({
  sourceTabs,
  targetTabs,
  sourceIndex = 0,
  targetIndex = 0
} = {}) => {
  sourceIndex = Number(sourceIndex);
  targetIndex = Number(targetIndex);
  const targetPane = dirname(targetTabs);
  const isSameGroup = sourceTabs === targetTabs;

  const previousSelectedIndex = getWorkspacePaneTabIndex({
    pane: dirname(sourceTabs)
  });

  if (isSameGroup) {
    const order = moveArrayElements(
      getOrderedNodeChildren({
        root: sourceTabs
      }),
      sourceIndex,
      targetIndex
    );

    setOrderedNodeChildren({ root: sourceTabs, children: order });
    setProperty({
      path: sourceTabs,
      prop: 'selectedTabIndex',
      value:
        Math.abs(targetIndex - sourceIndex) === 1
          ? targetIndex
          : Math.max(0, targetIndex - 1)
    });
    return [sourceTabs].concat(setSelectedWorkspacePane({ pane: targetPane }));
  } else {
    const sourceOrder = getOrderedNodeChildren({
      root: sourceTabs
    });
    const targetOrder = getOrderedNodeChildren({
      root: targetTabs
    });

    // TODO do we need to handle case for targetIndex = 0?
    if (targetIndex > targetOrder.length) {
      targetIndex = mod(targetIndex, targetOrder.length);
    }

    setProperty({
      path: sourceTabs,
      prop: 'selectedTabIndex',
      value: getNewIndexAfterRemoval(
        previousSelectedIndex,
        sourceIndex,
        sourceOrder.length
      )
    });

    const sourceTab = sourceOrder[sourceIndex];
    removeNodeMetaData({ path: join(sourceTabs, sourceTab) });
    const [newPath] = safeRename({
      sources: [join(sourceTabs, sourceTab)],
      target: targetTabs
    });

    targetOrder.splice(targetIndex, 0, basename(newPath));
    setOrderedNodeChildren({ root: targetTabs, children: targetOrder });

    const value =
      targetOrder.length < 2 ? 0 : mod(targetIndex, targetOrder.length - 1);

    setProperty({
      path: targetTabs,
      prop: 'selectedTabIndex',
      value
    });

    if (sourceOrder.length <= 1) {
      // DONT CLOSE and set workspace pane because it's done by closePane
      return [targetTabs].concat(closePane({ root: dirname(sourceTabs) }));
    } else {
      return [sourceTabs, targetTabs].concat(
        setSelectedWorkspacePane({ pane: targetPane })
      );
    }
  }
};

// used to copy tabs when splitting via cmd palette
const getCopiedTabContent = pane => {
  const tabsPath = join(pane, 'tabs');
  const tabsChildren = getOrderedNodeChildren({ root: tabsPath });
  if (tabsChildren.length) {
    const selectedTab = getWorkspacePaneTabIndex({
      pane
    });
    const tabPath = join(tabsPath, tabsChildren[selectedTab]);
    const json = getNodeChildren({ root: tabPath }).find(a =>
      a.endsWith('.json')
    );
    if (json) {
      const fullPath = join(tabPath, json);
      return readState(fullPath);
    }
  }
};

const splitPane = ({
  root = getSelectedWorkspacePane(),
  direction,
  select = true,
  copy = false,
  pane: paneId
} = {}) => {
  root = getPanePath(root);

  let inc = 0;
  switch (direction) {
    case 'up':
      inc = 0;
      direction = 'column';
      break;
    case 'down':
      inc = 1;
      direction = 'column';
      break;
    case 'left':
      inc = 0;
      direction = 'row';
      break;
    case 'right':
      inc = 1;
      direction = 'row';
      break;
    default:
      throw new Error('cannot split; no direction specified!');
  }

  const node = serializeNode({ root });
  if (node.type !== 'Pane') {
    throw new Error(`${node.type} is not a Pane!`);
  }

  const containerRoot = dirname(root);
  const originalPaneName = basename(root);
  const parentContainer = serializeNode({ root: containerRoot });

  if (
    parentContainer.type !== 'Container' &&
    parentContainer.type !== 'RootContainer'
  ) {
    throw new Error('split requires a Pane living inside of a Container');
  }

  if (parentContainer.type === 'RootContainer') {
    const panes = getOrderedNodeChildren({ root: containerRoot });
    if (panes.length === 1) {
      parentContainer.data.direction = direction;
      setProperty({
        path: containerRoot,
        prop: 'direction',
        value: direction
      });
    }
  }

  if (parentContainer.data.direction !== direction) {
    const name = getWindowId();

    const data = {
      resizers: true, // TODO pass resizers as option that defaults to true
      direction // NOTE even though direction is overridden by parent components, we need to know how to split children
    };

    transferProps.forEach(prop => {
      data[prop] = node.data[prop];
    });

    const [newContainer] = addGraph({
      root: containerRoot,
      type: 'Container',
      name,
      data,
      meta: getNodeMetaData({ path: root })
    });

    removeNodeMetaData({ path: root });
    // no need for safeRename since it's a new container
    const [paneWithOriginalContent] = rename({
      sources: [root],
      target: newContainer
    });

    // TODO, try converting the Pane into a Container and just keep the props
    transferProps.forEach((prop, i) => {
      setProperty({
        path: paneWithOriginalContent,
        prop,
        value: transferPropsDefaults[i]
      });
    });

    const [pane] = createPane({
      root: newContainer,
      paneId,
      data: copy ? getCopiedTabContent(paneWithOriginalContent) : {}
    });

    if (select) setSelectedWorkspacePane({ pane });
    updateFolderMetaData({
      path: newContainer,
      meta: {
        [basename(pane)]: {
          x: inc === 1 ? 1 : 0
        },
        [basename(paneWithOriginalContent)]: {
          x: inc === 1 ? 0 : 1
        }
      }
    });

    setPaneSpacing({ root: newContainer, equal: true });

    return [
      pane,
      join(containerRoot, basename(paneWithOriginalContent)),
      containerRoot
    ];
  } else {
    const children = getOrderedNodeChildren({ root: containerRoot });
    let position = children.indexOf(originalPaneName);

    const [pane] = createPane({
      root: containerRoot,
      paneId,
      data: copy ? getCopiedTabContent(root) : {}
    });
    position += inc;
    children.splice(position, 0, basename(pane));
    setOrderedNodeChildren({ root: containerRoot, children });
    if (select) setSelectedWorkspacePane({ pane });
    setPaneSpacing({ root: containerRoot, equal: true });
    return [pane, root, containerRoot];
  }
};

const setPaneSpacing = ({
  args = [],
  root = getSelectedWorkspacePaneContainer(),
  equal = false
} = {}) => {
  const container = serializeNode({ root });
  if (container.type !== 'Container' && container.type !== 'RootContainer') {
    throw new Error('not a Container!');
  }
  const siblings = getOrderedNodeChildren({ root });
  if (equal) {
    const nodes = siblings.map(s => serializeNode({ root: join(root, s) }));
    const num = nodes.reduce(
      (num, pane, i) => (pane.data.flexBasis === 'auto' ? num + 1 : num),
      0
    );
    args = nodes.map((node, i) =>
      node.data.flexBasis !== 'auto' ? 0 : 1 / num
    );
  }

  siblings.forEach((c, i) => {
    if (i < args.length) {
      let value = args[i];
      if (value) {
        setProp({ root, node: c, prop: 'flexGrow', value });
      }
    }
  });
  return [root];
};

const setPaneFlexValues = ({
  args = [],
  root = getSelectedWorkspacePaneContainer(),
  equal = false
} = {}) => {
  const container = serializeNode({ root });
  if (container.type !== 'Container' && container.type !== 'RootContainer') {
    throw new Error('not a Container!');
  }

  const siblings = getOrderedNodeChildren({ root });

  siblings.forEach((c, i) => {
    if (i < args.length) {
      const [grow, shrink, basis] = args[i];
      setProp({ root, node: c, prop: 'flexGrow', value: grow });
      setProp({ root, node: c, prop: 'flexShrink', value: shrink });
      setProp({ root, node: c, prop: 'flexBasis', value: basis });
    }
  });
  return [root];
};

const printContainerInfo = ({
  root = getSelectedWorkspacePaneContainer()
} = {}) => {
  const container = serializeNode({ root });
  if (container.type !== 'Container' && container.type !== 'RootContainer') {
    throw new Error('not a Container!');
  }

  const siblings = getOrderedNodeChildren({ root });

  const values = siblings.map(c => {
    const node = serializeNode({ root: join(root, c) });
    return [c, node.data.flexGrow, node.data.flexShrink, node.data.flexBasis];
  });
  const out =
    JSON.stringify(container, null, 2) + '\n' + JSON.stringify(values, null, 2);
  return [appendTerminalOutput(out)];
};

const printPaneInfo = ({ root = getSelectedWorkspacePane() } = {}) => {
  const pane = serializeNode({ root });
  return [appendTerminalOutput(JSON.stringify(pane, null, 2))];
};

export {
  closeCommandPalette,
  closePane,
  closeTab,
  createTab,
  createWorkspace,
  moveTab,
  openCommandPalette,
  printContainerInfo,
  printPaneInfo,
  setContainerProperty,
  setPaneFlexValues,
  setPaneProperty,
  setPaneSpacing,
  setSelectedWorkspace,
  setSelectedWorkspacePane,
  setSelectedWorkspacePaneTabIndex,
  setSelectedWorkspacePaneTabView,
  setTabsProperty,
  splitMoveTab,
  splitPane,
  toggleCommandPalette,
  toggleTerminal
};
