import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var StarIcon = function StarIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.122 11.538l-.927 5.656c-.042.258.06.52.262.675a.635.635 0 00.693.052L9 15.25l4.85 2.671a.63.63 0 00.693-.052.707.707 0 00.261-.675l-.926-5.656 3.924-4.006a.715.715 0 00.166-.707.667.667 0 00-.53-.469l-5.423-.826L9.59.384A.657.657 0 009 0c-.25 0-.48.149-.59.384L5.985 5.53l-5.422.825a.667.667 0 00-.53.47.713.713 0 00.166.706l3.923 4.006z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

StarIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(StarIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'StarIcon';
export default ForwardRef;