import styled from '@emotion/styled/macro';
import { margin, padding, transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';
import { space, layout, border, shadow } from 'styled-system';
import { List } from '../List';

const variant = props => ({
  boxShadow:
    props.theme.mode === 'dark'
      ? `0px 2px 8px 1px ${transparentize(
          0.6,
          themeGet('colors.black')(props)
        )}`
      : `0px 2px 6px 0px ${transparentize(
          0.85,
          themeGet('colors.black')(props)
        )}`,
  borderColor:
    props.theme.mode === 'dark'
      ? `${transparentize(0.2, themeGet('colors.black')(props))}`
      : `${transparentize(0.8, themeGet('colors.black')(props))}`
});

export const Menu = styled(List)(
  {
    ...margin(0),
    ...padding(0)
  },
  variant,
  space,
  layout,
  border,
  shadow
);

Menu.defaultProps = {
  role: 'menu'
};
