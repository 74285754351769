import React from 'react';
import PropTypes from 'prop-types';
import { SingleThumb } from './SingleThumb';

export const RangeInput = props => {
  return <SingleThumb {...props} />;
};

RangeInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func
};

RangeInput.defaultProps = {
  min: 0,
  max: 100,
  onChange: () => {}
};
