import { walkSync, getShortId, getAvailableName, join } from '../../utils';
import color from 'ansi-colors';
import { AIRPAGE_DIRECTORY_NAME } from '../../constants';
import { getFolderGraphMeta } from '../flow/queries';
import injector from '../../injector';

// TODO remove circular dep!
import {
  getActiveProcess,
  getProcess,
  getActiveProcessId
} from '../process/queries';
const { inject } = injector;

const getCurrentWorkingDirectory = () => getActiveProcess().cwd;

const readFile = inject(['fs'], function({ path, defaultValue }) {
  const { fs } = this;
  return fs.existsSync(path) ? fs.readFileSync(path).toString() : defaultValue;
});

const getUniqLocalId = inject(['fs'], function getUniqLocalId(root) {
  return getAvailableName({
    root,
    name: getShortId()
  });
});

const resolvePath = inject(['path'], function(path = '.') {
  return path.startsWith('/')
    ? path
    : this.path.join(getCurrentWorkingDirectory(), path);
});

const _readState = inject(['fs'], function({ root, defaultState }) {
  const { fs } = this;
  return fs.existsSync(root)
    ? JSON.parse(fs.readFileSync(root).toString())
    : defaultState;
});

// TODO use only one readState
const readState = inject(['fs'], function(root, defaultState) {
  return _readState({ root, defaultState });
});

const readDirectory = inject(['fs', 'path'], function(
  fullPath,
  pretty = true,
  colors = false
) {
  if (this.fs.existsSync(fullPath)) {
    return this.fs.readdirSync(fullPath).map(file => {
      if (!pretty) return file;
      const p = this.path.join(fullPath, file);
      // "pretty" prints "/" at the end of dirs
      return this.fs.statSync(p).isDirectory()
        ? colors
          ? color.cyan(`${file}/`)
          : `${file}/`
        : file;
    });
  } else {
    return [];
  }
});

const getNodeChildren = inject(['fs'], function({ root }) {
  const { fs } = this;
  if (fs.existsSync(root)) {
    return fs.readdirSync(root).filter(name => name !== AIRPAGE_DIRECTORY_NAME);
  } else {
    return [];
  }
});

const getOrderedNodeChildren = inject(['fs'], function({ root }) {
  const { fs } = this;
  if (fs.existsSync(root)) {
    const meta = getFolderGraphMeta(root);
    return getNodeChildren({ root }).sort((a, b) => {
      const A = meta[a] || { x: 0 };
      const B = meta[b] || { x: 0 };
      const value = A.x > B.x;
      return value ? 1 : -1;
    });
  } else {
    return [];
  }
});

const readTree = inject(['path'], function readTree({
  path,
  hidden = false
} = {}) {
  return walkSync(path).filter(
    file =>
      hidden ||
      (!hidden &&
        this.path.basename(this.path.dirname(file)) !==
          AIRPAGE_DIRECTORY_NAME &&
        !this.path.basename(file).startsWith('.'))
  );
});

const directoryExists = inject(['fs'], function directoryExists(fullPath) {
  return this.fs.existsSync(fullPath);
});

const getSelection = ({ pid = getActiveProcessId() } = {}) => {
  const proc = getProcess(pid);
  const selection = proc.selection;
  if (selection instanceof Array) return selection;
  return [];
};

const getSelectionPaths = ({ pid = getActiveProcessId() } = {}) => {
  const proc = getProcess(pid);
  const selection = getSelection({ pid });
  return selection.map(sel => join(proc.cwd, sel));
};

export {
  directoryExists,
  getCurrentWorkingDirectory,
  getNodeChildren,
  getOrderedNodeChildren,
  getSelection,
  getSelectionPaths,
  getUniqLocalId,
  readDirectory,
  readFile,
  readState,
  readTree,
  resolvePath
};
