import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var BoltIcon = function BoltIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M13.875 4.738c.134.144.16.303.078.476L7.928 17.73a.493.493 0 01-.468.27.968.968 0 01-.156-.022.475.475 0 01-.285-.205.4.4 0 01-.05-.325l2.198-8.74L4.637 9.8a.613.613 0 01-.134.011.528.528 0 01-.346-.119.397.397 0 01-.145-.422L6.255.346a.464.464 0 01.178-.249A.517.517 0 016.746 0h3.66c.14 0 .26.045.356.135.097.09.145.197.145.32a.433.433 0 01-.055.194L8.944 5.657l4.418-1.06a.628.628 0 01.134-.021c.141 0 .268.054.38.162z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

BoltIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(BoltIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'BoltIcon';
export default ForwardRef;