import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var UnionIcon = function UnionIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.143 5.143V.11A.11.11 0 015.254 0h12.635A.11.11 0 0118 .111v12.635a.11.11 0 01-.111.111h-5.032v5.032a.11.11 0 01-.111.111H.111A.11.11 0 010 17.889V5.254a.11.11 0 01.111-.111h5.032z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

UnionIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(UnionIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'UnionIcon';
export default ForwardRef;