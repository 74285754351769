import { useState } from 'react';
import { createTheme } from './modes';

export { globalStyles } from './globalStyles';
export * from './useMedia';

export const useTheme = (mode = 'dark') => {
  const [theme, _setTheme] = useState(createTheme(mode));
  const setTheme = mode => {
    _setTheme(createTheme(mode));
  };
  return [theme, setTheme];
};

export { createTheme };
