import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var UnitIcon = function UnitIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 4h17.786c.059 0 .107.047.107.104v9.792a.106.106 0 01-.107.104H.107A.106.106 0 010 13.896V4.104C0 4.047.048 4 .107 4zm2.018 3C2.056 7 2 7.048 2 7.107v5.786c0 .059.056.107.125.107h1.75c.069 0 .125-.048.125-.107V7.107C4 7.048 3.944 7 3.875 7h-1.75zm3 1C5.056 8 5 8.047 5 8.104v4.792c0 .057.056.104.125.104h1.75c.069 0 .125-.047.125-.104V8.104C7 8.047 6.944 8 6.875 8h-1.75zm3-1C8.056 7 8 7.048 8 7.107v5.786c0 .059.056.107.125.107h1.75c.069 0 .125-.048.125-.107V7.107C10 7.048 9.944 7 9.875 7h-1.75zm3 1c-.069 0-.125.047-.125.104v4.792c0 .057.056.104.125.104h1.75c.069 0 .125-.047.125-.104V8.104c0-.057-.056-.104-.125-.104h-1.75zm3-1c-.069 0-.125.048-.125.107v5.786c0 .059.056.107.125.107h1.75c.069 0 .125-.048.125-.107V7.107c0-.059-.056-.107-.125-.107h-1.75z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

UnitIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(UnitIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'UnitIcon';
export default ForwardRef;