import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ActivityIcon = function ActivityIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M3.023 10.165L4.245 9 5 9.703l-2.5 2.382L0 9.703.755 9l1.265 1.206A53.2 53.2 0 012 9c0-4.41 3.59-8 8-8s8 3.59 8 8-3.59 8-8 8c-1.76 0-3.4-.58-4.72-1.55l.65-.76C7.08 15.52 8.48 16 10 16c3.87 0 7-3.13 7-7s-3.13-7-7-7-7 3.13-7 7c0 .191.006.7.023 1.165zM9 4h1v5h3v1H9V4z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ActivityIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ActivityIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ActivityIcon';
export default ForwardRef;