import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CurrencyIcon = function CurrencyIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M8.053 9.016a5.979 5.979 0 00-1.126 2.28c-.63.094-1.338.147-2.084.147C2.168 11.443 0 10.761 0 9.92V8.61a.51.51 0 01.03-.165c1.236.644 3.06.903 4.817.903v.003c1.094 0 2.219-.102 3.206-.335zM6.75 12.79c.005.715.134 1.4.368 2.034-.677.113-1.45.177-2.27.177C2.171 15 .001 14.322 0 13.468v-1.31c0-.057.01-.113.03-.166 1.236.644 3.06.904 4.817.904.635 0 1.28-.034 1.903-.107zM9.69 1.523v1.31c0 .841-2.168 1.524-4.843 1.524S.005 3.679.005 2.834V1.523C.005.683 2.172 0 4.847 0S9.69.68 9.69 1.523zM4.847 5.801c1.755 0 3.59-.26 4.809-.904a.513.513 0 01.03.165v1.31c0 .846-2.168 1.523-4.843 1.523S0 7.213 0 6.372v-1.31c0-.056.01-.112.03-.165 1.236.644 3.06.904 4.817.904zM12.75 18a5.25 5.25 0 110-10.5 5.25 5.25 0 010 10.5zm1.72-5c-.313-.299-.79-.563-1.421-.782-.617-.211-.872-.388-.978-.5-.133-.14-.193-.319-.193-.565 0-.254.075-.457.229-.617.151-.157.393-.236.718-.236.305 0 .53.095.693.289.17.206.257.485.257.83 0 .19.152.348.336.348h.481c.14 0 .262-.087.33-.268l.014-.099c.01-.645-.151-1.174-.476-1.57a1.875 1.875 0 00-1.073-.641v-.59a.343.343 0 00-.337-.349h-.434a.343.343 0 00-.335.349v.587a1.958 1.958 0 00-1.06.57c-.346.365-.52.84-.52 1.407 0 .595.178 1.07.532 1.41.317.306.8.566 1.423.772.582.216.843.4.958.517.14.14.204.313.204.543a.774.774 0 01-.257.605c-.355.33-1.151.342-1.6-.018-.199-.16-.3-.446-.3-.848a.343.343 0 00-.336-.349h-.476c-.126 0-.24.072-.307.209l-.04.09-.001.07c-.013.725.19 1.283.606 1.66.32.29.71.477 1.16.556v.521c0 .192.151.349.337.349h.433a.343.343 0 00.337-.349v-.531c.436-.088.8-.273 1.083-.556.36-.357.543-.835.543-1.419 0-.59-.179-1.06-.53-1.394z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CurrencyIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CurrencyIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CurrencyIcon';
export default ForwardRef;