import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var MultiSelectIcon = function MultiSelectIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M11.143 17.248c0-.058.05-.105.107-.105h1.5c.06 0 .107.046.107.105v.647c0 .058-.05.105-.107.105h-1.5a.106.106 0 01-.107-.105v-.647zm6-5.998c0-.06.046-.107.105-.107h.647c.058 0 .105.05.105.107v1.5c0 .06-.046.107-.105.107h-.647a.107.107 0 01-.105-.107v-1.5zM18 16.393v1.502c0 .058-.05.105-.107.105h-1.5a.106.106 0 01-.107-.105v-.647c0-.058.05-.105.107-.105h.75v-.75c0-.06.046-.107.105-.107h.647c.058 0 .105.05.105.107zm-.857-2.571c0-.06.046-.108.105-.108h.647c.058 0 .105.05.105.108v1.5c0 .059-.046.107-.105.107h-.647a.107.107 0 01-.105-.108v-1.5zm-6.857-.858c0-.059.046-.107.105-.107h.647c.058 0 .105.05.105.107v1.5c0 .06-.046.107-.105.107h-.647a.107.107 0 01-.105-.107v-1.5zm0 2.572c0-.06.046-.107.105-.107h.647c.058 0 .105.049.105.107v1.5c0 .059-.046.107-.105.107h-.647a.107.107 0 01-.105-.107v-1.5zm2.571-5.145c0-.058.05-.105.107-.105h1.5c.06 0 .107.046.107.105v.647c0 .058-.049.105-.107.105h-1.5a.106.106 0 01-.107-.105v-.647zm.857 6.857c0-.058.05-.105.108-.105h1.5c.059 0 .107.046.107.105v.647c0 .058-.05.105-.108.105h-1.5a.106.106 0 01-.107-.105v-.647zm-2.571-6.105v.75c0 .059-.046.107-.105.107h-.647a.107.107 0 01-.105-.107V10.39c0-.058.05-.105.107-.105h1.5c.059 0 .107.046.107.105v.647c0 .058-.05.105-.107.105h-.75zm4.286-.752c0-.058.049-.105.107-.105h1.5c.059 0 .107.046.107.105v.647c0 .058-.05.105-.107.105h-1.5a.106.106 0 01-.107-.105v-.647zM10.286.109c0-.06.049-.109.109-.109h7.496c.06 0 .109.05.109.11v7.495c0 .06-.05.11-.11.11h-7.495a.11.11 0 01-.11-.11V.11zM0 .11C0 .05.05 0 .11 0h7.495c.06 0 .11.05.11.11v7.495c0 .06-.05.11-.11.11H.11A.11.11 0 010 7.604V.11zm0 10.286c0-.06.05-.11.11-.11h7.495c.06 0 .11.05.11.11v7.496c0 .06-.05.109-.11.109H.11A.11.11 0 010 17.89v-7.495z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

MultiSelectIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(MultiSelectIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'MultiSelectIcon';
export default ForwardRef;