import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import React, {
  memo,
  useMemo,
  useRef,
  useEffect,
  useImperativeHandle
} from 'react';
import { propsAreEqual } from '../utils/react';
import { getOwnerDocument } from '../utils/dom';

function getContainer(container) {
  container = typeof container === 'function' ? container() : container;
  return ReactDOM.findDOMNode(container);
}

export const GenericPortal = memo(
  React.forwardRef(function GenericPortal(props, ref) {
    const { onRendered } = props;
    const childRef = useRef(null);
    const doc = getOwnerDocument();

    const mountNode = useMemo(() => getContainer(props.container) || doc.body, [
      doc,
      props.container
    ]);

    useImperativeHandle(ref, () => mountNode || childRef.current, [mountNode]);

    useEffect(() => {
      if (onRendered && mountNode) {
        onRendered();
      }
    }, [onRendered, mountNode]);

    return mountNode
      ? ReactDOM.createPortal(props.children, mountNode)
      : mountNode;
  }),
  propsAreEqual
);

GenericPortal.propTypes = {
  open: PropTypes.bool,
  container: PropTypes.object,
  children: PropTypes.node,
  onRendered: PropTypes.func
};
