import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { em, margin, mix, padding, transparentize } from 'polished';

const variant = props => ({
  backgroundColor: themeGet('colors.background.default')(props),
  borderColor: themeGet('colors.border')(props),
  color: themeGet('colors.action.default')(props),

  '&:hover, &:focus': {
    backgroundColor:
      props.theme.mode === 'dark'
        ? `${transparentize(0.91, themeGet('colors.white')(props))}`
        : themeGet('colors.blue.1')(props),
    color:
      props.theme.mode === 'dark'
        ? themeGet('color.action.active')(props)
        : themeGet('colors.white')(props)
  },

  '&:active, &[aria-current="true"]': {
    backgroundColor:
      props.theme.mode === 'dark'
        ? mix(0.15, 'rgb(0,0,0)', 'rgb(50,50,50)')
        : themeGet('colors.blue.1')(props),
    color:
      props.theme.mode === 'dark'
        ? themeGet('color.action.active')(props)
        : themeGet('colors.white')(props)
  }
});

export const BreadcrumbListItemLink = styled.a(
  {
    ...margin(0),
    ...padding(em(6 - 0.5), em(12 - 0.5)),
    appearance: 'none',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 0.5,
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 18 / 16,
    marginBottom: -0.5,
    marginTop: -0.5,
    textAlign: 'center',
    textDecoration: 'none',
    verticalAlign: 'middle'
  },
  variant
);

BreadcrumbListItemLink.propTypes = {
  'aria-current': PropTypes.bool
};
