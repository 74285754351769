import React from 'react';
import { ColorPicker as StyledColorPicker } from './ColorPicker';
import { ColorPickerProvider } from './ColorPickerContext';
import { Color } from './Color';
import { colorReducer } from './reducer';
import { normalizeStops } from './utils';

const initialState = {
  mode: 'RGB', // one of 'RGB' or 'HSL'
  color: new Color('#33ccff'),
  gradient: [
    { offset: 0.0, color: '#33ccff' },
    { offset: 0.49, color: '#fff' }
  ],
  alpha: 1,
  activeStopColor: null,
  activeStop: null
};

export const ColorPicker = ({ picker = initialState, ...rest }) => {
  picker.gradient = normalizeStops(picker.gradient);
  return (
    <ColorPickerProvider initialState={picker} reducer={colorReducer}>
      <StyledColorPicker {...rest} />
    </ColorPickerProvider>
  );
};
