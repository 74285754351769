import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { padding, em, triangle, transparentize } from 'polished';

const tooltipArrowHeight = 6;
const tooltipArrowWidth = 12;

const variant = css({
  backgroundColor: 'rgb(180,180,180)',
  boxShadow: `${transparentize(0.85, 'rgb(0,0,0)')} 0 ${em(6)} ${em(6)}`,
  color: 'rgb(50,50,50)',
  marginTop: em(tooltipArrowHeight),
  '&::before': {
    content: '""',
    position: 'absolute',
    ...triangle({
      pointingDirection: 'top',
      width: em(tooltipArrowWidth),
      height: em(tooltipArrowHeight),
      foregroundColor: 'rgb(180,180,180)'
    }),
    top: em(-tooltipArrowHeight),
    left: 0
  }
});

export const Tooltip = styled.span(
  {
    ...padding(em(12)),
    display: 'inline-flex',
    font: 'inherit',
    lineHeight: 18 / 16,
    position: 'relative',
    verticalAlign: 'top',

    '&[hidden]': {
      display: 'none'
    }
  },
  variant
);

Tooltip.defaultProps = {
  role: 'tooltip'
};
