import gql from 'graphql-tag';

export const CurrentRoleQuery = gql`
  query CurrentRoleQuery {
    currentRole {
      id
      __typename
      organization {
        username
      }
    }
  }
`;
