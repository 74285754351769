import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var FormIcon = function FormIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M17 .107v14.357c0 .06-.047.107-.105.107h-1.053a.106.106 0 01-.105-.107V1.286H4.474a.106.106 0 01-.106-.107V.107c0-.059.048-.107.106-.107h12.42c.059 0 .106.048.106.107zM2.79 1.714h12.42c.059 0 .106.048.106.107V16.18c0 .059-.047.107-.105.107h-1.053a.106.106 0 01-.105-.107V3H2.789a.106.106 0 01-.105-.107V1.82c0-.059.047-.107.105-.107zM1 3.537c0-.06.046-.108.11-.108h12.412c.06 0 .11.047.11.108v14.354c0 .06-.046.109-.11.109H1.11A.108.108 0 011 17.891V3.537zm1.263 1.715V6.32c0 .06.047.11.106.11H7.21c.062 0 .106-.05.106-.11V5.252c0-.06-.047-.11-.106-.11H2.37c-.062 0-.106.05-.106.11zm0 2.139v.647c0 .059.048.105.107.105h9.892a.104.104 0 00.106-.105V7.39a.105.105 0 00-.106-.105H2.37a.104.104 0 00-.107.105zm0 1.714v.647c0 .06.047.105.104.105H6.37a.105.105 0 00.104-.105v-.647A.104.104 0 006.37 9H2.367a.105.105 0 00-.104.105zm5.053 0v.647c0 .06.047.105.105.105h4.842a.103.103 0 00.105-.105v-.647A.105.105 0 0012.263 9H7.42a.103.103 0 00-.105.105zm-5.053 2.576v1.067c0 .06.047.11.106.11H7.21c.062 0 .106-.05.106-.11V11.68c0-.06-.047-.11-.106-.11H2.37c-.062 0-.106.05-.106.11zm0 2.138v.647c0 .06.048.105.107.105h9.892a.104.104 0 00.106-.105v-.647a.105.105 0 00-.106-.105H2.37a.104.104 0 00-.107.105zm3.369 1.715v.647c0 .059.047.105.105.105h6.526a.103.103 0 00.105-.105v-.647a.105.105 0 00-.105-.105H5.737a.103.103 0 00-.105.105zm-3.369 0v.647c0 .059.049.105.109.105H4.68a.104.104 0 00.108-.105v-.647a.106.106 0 00-.108-.105H2.37a.104.104 0 00-.108.105z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

FormIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(FormIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'FormIcon';
export default ForwardRef;