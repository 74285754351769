import styled from '@emotion/styled/macro';
import { em, margin, padding, transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';
import { space, layout, border, shadow } from 'styled-system';
import { ListItem } from './ListItem';

const darkVariant = props => ({
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  color: themeGet('colors.action.default')(props),

  '&:hover, &:focus': {
    backgroundColor: transparentize(0.97, themeGet('colors.white')(props)),
    borderColor: transparentize(0.5, themeGet('colors.black')(props)),
    color: themeGet('colors.white')(props)
  },

  [`&:active:not([aria-disabled="true"]),
    ${ListItem}[aria-selected="true"] &`]: {
    backgroundColor: transparentize(0.5, themeGet('colors.blue.1')(props)),
    borderColor: themeGet('colors.blue.1')(props),
    color: themeGet('colors.white')(props)
  },

  [`${ListItem}[aria-current="true"] &`]: {
    backgroundColor: themeGet('colors.blue.1')(props),
    borderColor: themeGet('colors.blue.1')(props),
    color: 'rgb(6, 45, 99)'
  }
});

const lightVariant = props => ({
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  color: themeGet('colors.action.default')(props),

  '&:hover, &:focus': {
    backgroundColor: transparentize(0.96, themeGet('colors.black')(props)),
    color: 'rgba(77, 77, 77, 1)'
  },

  [`&:active:not([aria-disabled="true"]),
    &:visited:not([aria-disabled="true"]),
    ${ListItem}[aria-selected="true"] &`]: {
    backgroundColor: transparentize(0.5, themeGet('colors.blue.0')(props)),
    color: themeGet('colors.blue.1')(props)
  },

  [`${ListItem}[aria-current="true"] &`]: {
    backgroundColor: themeGet('colors.blue.0')(props),
    color: themeGet('colors.blue.1')(props)
  }
});

const variant = props =>
  ({ dark: darkVariant, light: lightVariant }[props.theme.mode](props));

export const ListItemLink = styled.a(
  {
    ...margin(0),
    ...padding(em(9 - 0.5), em(12 - 0.5)),
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    borderRadius: em(3),
    borderStyle: 'solid',
    borderWidth: 0.5,
    cursor: 'pointer',
    display: 'flex',
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 500,
    lineHeight: 18 / 16,
    textDecoration: 'none',
    '> :not(:first-child)': {
      marginLeft: em(6)
    }
  },
  variant,
  props => ({
    '&[aria-disabled="true"]': {
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: themeGet('textColors.disabled')(props)
    }
  }),
  space,
  layout,
  border,
  shadow
);
