import React, { useState, memo } from 'react';
import md5 from 'md5';
import { useMutation } from '@apollo/react-hooks';
import errors from '../utils/errors';

import {
  AuthForm,
  AuthHeading,
  AuthParagraph,
  Button,
  Field,
  Input,
  Label
} from '../ui';

import { AuthLayout } from '../layouts/AuthLayout';

import { signIn } from '../state/commands';
import { SignInMutation } from '../mutations';
import { propsAreEqual } from '../utils/react';
import { NotificationBox } from '../components';

const SignInForm = memo(function SignInForm(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        localStorage.setItem(
          'gravatar',
          `https://www.gravatar.com/avatar/${md5(email)}`
        );
        setLoading(true);
        props
          .signIn({ variables: { email, password } })
          .then(({ data: { signIn: { token } } }) => {
            if (token && token.accessToken) {
              signIn(token.accessToken);
            } else {
              setLoading(false);
              setNotification({
                text: "The sign in details you've provided are invalid",
                variant: 'warning'
              });
            }
          })
          .catch(e => {
            setLoading(false);
            if (e.graphQLErrors && e.graphQLErrors.length) {
              const error = e.graphQLErrors[0];
              const code = error.message;
              setNotification({
                text: errors(code),
                variant: 'warning'
              });
            } else {
              setNotification({
                text: "The sign in details you've provided are invalid",
                variant: 'warning'
              });
            }
          });
      }}
    >
      <NotificationBox
        notification={notification}
        setNotification={setNotification}
      />
      <AuthHeading>Sign in</AuthHeading>
      <Field fluid={true}>
        <Label htmlFor="email" id="email-label">
          Email
        </Label>
        <Input
          aria-labelledby="email-label"
          autoComplete="email"
          autoFocus={true}
          fluid={true}
          id="email"
          name="email"
          onChange={event => setEmail(event.target.value)}
          type="email"
          value={email}
        />
      </Field>
      <Field fluid={true}>
        <Label htmlFor="password" id="password-label">
          Password
        </Label>
        <Input
          aria-labelledby="password-label"
          autoComplete="current-password"
          fluid={true}
          id="password"
          name="password"
          onChange={event => setPassword(event.target.value)}
          type="password"
          value={password}
        />
      </Field>
      <Button fluid={true} disabled={loading} type="submit" variant="primary">
        Sign In
      </Button>
      <AuthParagraph variant={'caption'}>
        Don't have an account?&nbsp;
        <a href={'/signup'}>sign up</a>
      </AuthParagraph>
    </AuthForm>
  );
}, propsAreEqual);

export const SignIn = memo(function SignIn(props) {
  const [notification, setNotification] = useState(
    (props.location.state && props.location.state.notification) || null
  );
  const [signIn] = useMutation(SignInMutation);

  return (
    <AuthLayout>
      <NotificationBox
        notification={notification}
        setNotification={setNotification}
      />
      <SignInForm signIn={signIn} />
    </AuthLayout>
  );
}, propsAreEqual);
