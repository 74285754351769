import styled from '@emotion/styled/macro';
import { padding, em } from 'polished';

export const TreeItemGroup = styled.ul({
  ...padding(0, 0, 0, em(24)),
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  margin: 0
});

TreeItemGroup.defaultProps = {
  role: 'group'
};
