import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var NetworkViewIcon = function NetworkViewIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.223 14.571h9.554L9 5.018 4.223 14.57zm8.67 1.286H5.107a2.572 2.572 0 11-1.56-2.808l4.135-8.27a2.571 2.571 0 112.637 0l4.134 8.27a2.571 2.571 0 11-1.56 2.808z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

NetworkViewIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(NetworkViewIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'NetworkViewIcon';
export default ForwardRef;