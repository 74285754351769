import React from 'react';
import { Alert, AlertClose, Icon } from '../../ui';
import { NotificationPortal } from '../../components';

export const NotificationBox = props => {
  const { notification, setNotification } = props;

  return (
    notification && (
      <NotificationPortal>
        <Alert variant={notification.variant}>
          {notification.text}
          <AlertClose onClick={() => setNotification(null)}>
            <Icon name="delete" size={18} title="Close Alert" />
          </AlertClose>
        </Alert>
      </NotificationPortal>
    )
  );
};
