import { arraysEqual, readJSONSync, writeJSONSync, mkdirpSync } from './utils';
import { getDefinitionIcon } from '../components/DefinitionIcon';
import Ajv from 'ajv';
import { Node } from '@airpage/flow-schemas';

const ajv = new Ajv({ coerceTypes: true, useDefaults: true });
const nodeValidator = ajv.compile(Node);

class Registry {
  entries = {};
  register(command, meta = {}) {
    const name = meta.name || command.name;
    if (!name) {
      throw new Error('cannot register anonymous function');
    }
    delete meta.name;
    this.entries[name] = command;

    for (const key in meta) {
      this.entries[name][key] = meta[key];
    }
  }
  has(name) {
    return this.entries.hasOwnProperty(name);
  }
  get(name) {
    return this.entries[name];
  }
  list() {
    const entries = [];
    for (let name in this.entries) {
      entries.push({
        name,
        description: this.entries[name].description
      });
    }
    return entries;
  }
}

class PaletteRegistry {
  entries = {};
  constructor(registry) {
    this.registry = registry;
  }
  register(name, meta) {
    const { args: metaArgs, name: metaName, ...rest } = meta;
    this.entries[name] = args =>
      this.registry.get(metaName)({ ...metaArgs, ...args });
    for (const key in rest) {
      this.entries[name][key] = rest[key];
    }
  }
  has(name) {
    return this.entries.hasOwnProperty(name);
  }
  get(name) {
    return this.entries[name];
  }
  list() {
    const entries = [];
    for (let name in this.entries) {
      entries.push({
        name,
        description: this.entries[name].description,
        keys: this.entries[name].keys
      });
    }
    return entries;
  }
  keyFunction(keysPressed, context) {
    const pressed = keysPressed.map(key => key.toLowerCase());
    for (let name in this.entries) {
      const ctx = this.entries[name].context;
      if (ctx && ctx !== context) continue;

      const values = (this.entries[name].keys || '')
        .split('+')
        .map(s => s.toLowerCase());

      if (arraysEqual(pressed, values)) {
        return this.get(name);
      }
    }
  }
}

// registry is used because it will store a compiled
// version of the schemas via AJV, and potentially
// implementation functions
class NodeRegistry {
  entries = {};
  schemas = {};
  icons = {};
  registerNode(project, Node) {
    nodeValidator(Node);
    if (nodeValidator.errors && nodeValidator.errors.length) {
      console.error(nodeValidator.errors);
      console.error(Node);
      throw new Error('not a valid node!');
    }

    const name = Node.meta.name || Node.name;
    if (!name) {
      throw new Error('cannot register anonymous Node');
    }
    this.entries[project] = this.entries[project] || {};
    this.icons[project] = this.icons[project] || {};
    this.schemas[project] = this.schemas[project] || {};

    const meta = Node.meta;
    const schema = meta.schema;

    this.entries[project][name] = {
      kind: Node.kind,
      name,
      icon: meta.iconText,
      ...schema
    };
    this.icons[project][name] = getDefinitionIcon(meta.iconText);
    this.schemas[project][name] = ajv.compile(schema.data ? schema.data : {});
  }
  has(project, name) {
    return this.entries[project] && this.entries[project].hasOwnProperty(name);
  }
  icon(project, name) {
    return this.icons[project] && this.icons[project][name];
  }
  get(project, name) {
    return this.entries[project] && this.entries[project][name];
  }
  schema(project, name) {
    return this.schemas[project] && this.schemas[project][name];
  }
  save(project) {
    if (!this.entries[project]) {
      return;
    }
    mkdirpSync(`/local/${project}/`);
    writeJSONSync(`/local/${project}/airpage.lock.json`, this.entries[project]);
  }
  list(project) {
    if (!this.entries[project]) {
      const result = readJSONSync(`/local/${project}/airpage.lock.json`);
      const values = Object.values(result);
      if (values.length) {
        this.entries[project] = result;
        values.forEach(value => {
          this.icons[project] = this.icons[project] || {};
          this.schemas[project] = this.schemas[project] || {};
          this.icons[project][value.name] = getDefinitionIcon(value.icon);
          this.schemas[project][value.name] = ajv.compile(
            value.data ? value.data : {}
          );
        });
        return this.list(project);
      }
    }
    return (this.entries[project]
      ? Object.values(this.entries[project])
      : []
    ).map(item => {
      item.iconComponent = this.icon(project, item.name);
      return item;
    });
  }
}

const commands = new Registry();
const palette = new PaletteRegistry(commands);
const nodes = new NodeRegistry(commands);

export { commands, palette, nodes };
