import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DivIcon = function DivIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zM9.89 7.176c.38 0 .658-.288.658-.669a.641.641 0 00-.658-.648.641.641 0 00-.659.648c0 .381.278.669.659.669zm-.484 6.11h.967V8.143h-.967v5.143zm5.956-5.143l-1.523 3.734-1.532-3.734h-1.05l2.274 5.163h.617l2.253-5.163h-1.04zM4.263 6.086H2.371v7.2h1.892c2.263 0 3.61-1.625 3.61-3.62 0-1.986-1.347-3.58-3.61-3.58zm.01 6.243h-.925V7.042h.926c1.645 0 2.612 1.152 2.612 2.623 0 1.44-.967 2.664-2.612 2.664z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DivIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DivIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DivIcon';
export default ForwardRef;