import gql from 'graphql-tag';

const typeDefs = gql`
  extend type Query {
    isSignedIn: Boolean!
  }
`;

const resolvers = {
  Query: {
    isSignedIn() {
      return !!localStorage.getItem('token');
    }
  }
};

export { typeDefs, resolvers };
