import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PublishIcon = function PublishIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M18 0v16h-1V2H2V0h16zM0 6h16v12H0V6zm4.242 6.093c.032.032.07.058.112.077l1.597.7c.007.425.136.79.4 1.054.266.266.635.395 1.066.4l.71 1.619a.369.369 0 00.268.213.366.366 0 00.327-.101l1.46-1.461a1.133 1.133 0 00.09-1.499 8.3 8.3 0 00.662-.596c1.631-1.63 1.47-4.382 1.463-4.498l-.008-.116-.115-.007c-.117-.008-2.868-.168-4.499 1.463-.217.217-.417.44-.598.666a1.132 1.132 0 00-1.474.107l-1.46 1.46a.367.367 0 000 .52zm.47 3.493c.04.04.102.05.153.024.066-.034.237-.112.384-.152.029.085.07.158.082.178a.132.132 0 00.123.065c.04-.003.994-.077 1.47-.552.109-.11.203-.231.28-.362a.132.132 0 00-.104-.199c-.41-.032-.743-.175-.99-.423-.241-.24-.383-.563-.42-.96a.132.132 0 00-.201-.1 1.813 1.813 0 00-.341.268c-.476.476-.549 1.43-.552 1.47a.132.132 0 00.064.123c.02.012.093.053.179.082-.04.147-.119.318-.153.384a.132.132 0 00.025.154zM0 3h16v2H0V3zm1.5 1.5a.5.5 0 100-1 .5.5 0 000 1zm2 0a.5.5 0 100-1 .5.5 0 000 1zm2 0a.5.5 0 100-1 .5.5 0 000 1zm5 5.929a.643.643 0 110-1.286.643.643 0 010 1.286z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PublishIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PublishIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PublishIcon';
export default ForwardRef;