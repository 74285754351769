import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SaveIcon = function SaveIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.25 0h13.646a.25.25 0 01.177.073l3.854 3.854a.25.25 0 01.073.177V17.75a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25V.25A.25.25 0 01.25 0zm5 1a.25.25 0 00-.25.25v4.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-7.5zm-2 8a.25.25 0 00-.25.25v6.5c0 .138.112.25.25.25h11.5a.25.25 0 00.25-.25v-6.5a.25.25 0 00-.25-.25H3.25zM4 11h3v1H4v-1zm7 2h1v1h-1v-1zm-7 0h6v1H4v-1zm6-11h2v3h-2V2z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SaveIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SaveIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SaveIcon';
export default ForwardRef;