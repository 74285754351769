import styled from '@emotion/styled/macro';
import { em, margin, padding, transparentize } from 'polished';
import { space, border } from 'styled-system';
import themeGet from '@styled-system/theme-get';

const variant = props => ({
  backgroundColor: 'transparent',
  borderColor:
    props.theme.mode === 'dark'
      ? transparentize(0.2, themeGet('colors.black')(props))
      : transparentize(0.8, themeGet('colors.black')(props))
});

export const MenuBarList = styled.ul(
  props => {
    const mq = themeGet('mediaQueries')(props);
    return {
      ...margin(0),
      ...padding(0, themeGet('space.1')(props)),
      alignItems: 'center',
      borderStyle: 'solid',
      borderWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      display: 'flex',
      flexDirection: 'row',
      height: em(46),
      listStyle: 'none',
      [mq[2]]: {
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1
      }
    };
  },
  variant,
  space,
  border
);

MenuBarList.defaultProps = {
  as: 'ul',
  role: 'menubar'
};
