import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var WindowManagerIcon = function WindowManagerIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.281 0H17.72c.155 0 .281.126.281.281V2.25H0V.281C0 .126.126 0 .281 0zM0 16.875h18v.844a.281.281 0 01-.281.281H.28A.281.281 0 010 17.719v-.844zM0 2.25h1.125v14.625H0V2.25zm16.875 0H18v14.625h-1.125V2.25zm-4.5 3.375H13.5v.844a.281.281 0 01-.281.281h-.563a.281.281 0 01-.281-.281v-.844zm-10.125 0h1.125v6.469a.281.281 0 01-.281.281H2.53a.281.281 0 01-.281-.281V5.625zM2.531 4.5H13.22c.155 0 .281.126.281.281v.844H2.25v-.844c0-.155.126-.281.281-.281zm3.375 3.375h9.563c.155 0 .281.126.281.281v6.188a.281.281 0 01-.281.281H5.906a.281.281 0 01-.281-.281V8.156c0-.155.126-.281.281-.281z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

WindowManagerIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(WindowManagerIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'WindowManagerIcon';
export default ForwardRef;