import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { typography, space, flex, layout, color } from 'styled-system';
import themeGet from '@styled-system/theme-get';
import systemTypes from '@styled-system/prop-types';

const variant = props => {
  switch (props.variant) {
    case 'header':
      return {
        fontWeight: themeGet('fontWeights.semiBold')(props),
        fontSize: themeGet('fontSizes.2')(props),
        lineHeight: themeGet('lineHeights.large')(props),
        color: themeGet('textColors.header')(props)
      };
    case 'body':
      return {
        fontWeight: themeGet('fontWeights.medium')(props),
        fontSize: themeGet('fontSizes.1')(props),
        lineHeight: themeGet('lineHeights.normal')(props)
      };
    case 'caption':
      return {
        fontWeight: themeGet('fontWeights.medium')(props),
        fontSize: themeGet('fontSizes.0')(props),
        lineHeight: themeGet('lineHeights.tight')(props)
      };
    case 'small':
      return {
        fontWeight: themeGet('fontWeights.medium')(props),
        fontSize: themeGet('fontSizes.0')(props),
        lineHeight: themeGet('lineHeights.tight')(props),
        color: themeGet('textColors.secondary')(props)
      };
    default:
      // default is body variant
      return {
        fontWeight: themeGet('fontWeights.medium')(props),
        fontSize: themeGet('fontSizes.1')(props),
        lineHeight: themeGet('lineHeights.normal')(props)
      };
  }
};

const textColor = props => {
  return {
    color: themeGet(`textColors.${props.color}`)(props)
      ? themeGet(`textColors.${props.color}`)(props)
      : color(props)
  };
};

export const Text = styled.p(
  textColor,
  variant,
  typography,
  space,
  flex,
  layout
);

Text.propTypes = {
  ...systemTypes.space,
  ...systemTypes.typography,
  variant: PropTypes.oneOf(['header', 'body', 'caption']),
  color: PropTypes.oneOf(['primary', 'secondary'])
};

Text.defaultProps = {
  variant: 'body',
  color: 'primary'
};
