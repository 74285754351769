import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CircleExclamationIcon = function CircleExclamationIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 18A9 9 0 119 0a9 9 0 010 18zm-1.125-7.031c0 .155.126.281.281.281h1.688a.281.281 0 00.281-.281V3.656a.281.281 0 00-.281-.281H8.156a.281.281 0 00-.281.281v7.313zm.303 3.344c.219.208.487.312.805.312.317 0 .586-.107.805-.32.219-.214.328-.48.328-.797 0-.306-.112-.572-.337-.796a1.089 1.089 0 00-.796-.337c-.318 0-.583.11-.797.328-.213.22-.32.488-.32.805 0 .318.104.586.312.805z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CircleExclamationIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CircleExclamationIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CircleExclamationIcon';
export default ForwardRef;