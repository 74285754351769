import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ShoppingCartIcon = function ShoppingCartIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.227 3.935h13.67a.104.104 0 01.1.127l-2.005 9.855a.103.103 0 01-.1.083H5.408a.103.103 0 01-.1-.083L3.303 4.062a.106.106 0 01.053-.115l-.748-2.04a.103.103 0 00-.095-.068H.103A.104.104 0 010 1.734v-.63C0 1.048.046 1 .102 1H3.08c.043 0 .081.027.096.068l1.05 2.867zM8 17a1 1 0 110-2 1 1 0 010 2zm5 0a1 1 0 110-2 1 1 0 010 2z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ShoppingCartIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ShoppingCartIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ShoppingCartIcon';
export default ForwardRef;