import gql from 'graphql-tag';

export const RegisterOrganizationMutation = gql`
  mutation RegisterOrganizationMutation($displayName: String!) {
    registerOrganization(input: { displayName: $displayName }) {
      organization {
        id
        username
        createdAt
      }
    }
  }
`;
