import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var EmoticonIcon = function EmoticonIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 0C4.05 0 0 4.05 0 9s4.05 9 9 9 9-4.05 9-9-4.05-9-9-9zm3.15 5.4c.72 0 1.35.63 1.35 1.35 0 .72-.63 1.35-1.35 1.35-.72 0-1.35-.63-1.35-1.35 0-.72.63-1.35 1.35-1.35zm-6.3 0c.72 0 1.35.63 1.35 1.35 0 .72-.63 1.35-1.35 1.35-.72 0-1.35-.63-1.35-1.35 0-.72.63-1.35 1.35-1.35zm3.15 9c-2.34 0-4.32-1.53-5.04-3.6h10.08c-.72 2.07-2.7 3.6-5.04 3.6z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

EmoticonIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(EmoticonIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'EmoticonIcon';
export default ForwardRef;