import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ColorRgbaIcon = function ColorRgbaIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 10.957A5.478 5.478 0 119 0a5.478 5.478 0 010 10.957zm0-.783A4.696 4.696 0 109 .783a4.696 4.696 0 000 9.39zm-3.522 6.26a5.478 5.478 0 110-10.956 5.478 5.478 0 010 10.957zm0-.782a4.696 4.696 0 100-9.391 4.696 4.696 0 000 9.391zm7.044.783a5.478 5.478 0 110-10.957 5.478 5.478 0 010 10.957zm0-.783a4.696 4.696 0 100-9.391 4.696 4.696 0 000 9.391zM9 18a3 3 0 110-6 3 3 0 010 6z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ColorRgbaIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ColorRgbaIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ColorRgbaIcon';
export default ForwardRef;