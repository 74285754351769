import gql from 'graphql-tag';

export const CreateInviteMutation = gql`
  mutation CreateInviteMutation($email: Email!) {
    createInvite(input: { invite: { email: $email } }) {
      invite {
        email
        expiresAt
      }
    }
  }
`;
