import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var NotificationsIcon = function NotificationsIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M3.647 7.985A4 4 0 007.411 1.91c.116-.037.234-.07.354-.098v-.577C7.765.576 8.34 0 9 0c.659 0 1.235.576 1.235 1.235v.577c2.389.576 4.118 2.635 4.118 5.188v5.53l1.574 1.573a.25.25 0 01.073.177v.47a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-.47a.25.25 0 01.073-.177l1.574-1.574V7.985zM6.991 16h4a2 2 0 01-4 0zM4 7a3 3 0 110-6 3 3 0 010 6z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

NotificationsIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(NotificationsIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'NotificationsIcon';
export default ForwardRef;