import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CssIcon = function CssIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm4.568 13.41c.658 0 1.214-.135 1.697-.381v-1.101c-.411.33-.957.535-1.687.535-1.615 0-2.695-1.296-2.695-2.777s1.08-2.777 2.695-2.777c.73 0 1.276.205 1.687.534v-1.1c-.483-.247-1.039-.38-1.697-.38C2.433 5.962.993 7.69.993 9.685c0 1.995 1.44 3.723 3.682 3.723zm4.855-.021c1.399 0 2.232-.875 2.242-1.955.03-2.427-3.363-2.026-3.353-3.59 0-.534.452-.925 1.11-.925.566 0 1.08.36 1.43.75l.638-.658c-.442-.534-1.172-1.028-2.088-1.028-1.203 0-2.078.812-2.088 1.851 0 2.356 3.364 1.862 3.364 3.59 0 .576-.474 1.018-1.194 1.018-.648 0-1.254-.4-1.656-.977l-.71.669c.464.71 1.328 1.255 2.305 1.255zm5.4 0c1.399 0 2.232-.875 2.242-1.955.03-2.427-3.363-2.026-3.353-3.59 0-.534.452-.925 1.11-.925.566 0 1.08.36 1.43.75l.638-.658c-.442-.534-1.172-1.028-2.088-1.028-1.203 0-2.078.812-2.088 1.851 0 2.356 3.364 1.862 3.364 3.59 0 .576-.474 1.018-1.194 1.018-.648 0-1.254-.4-1.656-.977l-.71.669c.464.71 1.328 1.255 2.305 1.255z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CssIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CssIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CssIcon';
export default ForwardRef;