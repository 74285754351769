import styled from '@emotion/styled/macro';

export const ToolbarPanel = styled.div({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative'
});

ToolbarPanel.defaultProps = {
  role: 'tabpanel',
  tabIndex: 0
};
