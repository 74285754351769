import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var RoutingLeftIcon = function RoutingLeftIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M7.117 9.097l6.97 7.346a.141.141 0 01-.007.2l-1.437 1.32a.143.143 0 01-.2-.007L4.04 9.097a.141.141 0 010-.194L12.443.044a.143.143 0 01.2-.006l1.436 1.318a.141.141 0 01.008.2v.001l-6.97 7.346a.141.141 0 000 .194z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

RoutingLeftIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(RoutingLeftIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'RoutingLeftIcon';
export default ForwardRef;