import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var UsernameIcon = function UsernameIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 0C4.038 0 0 4.038 0 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9zm0 3.774a3.779 3.779 0 013.774 3.774A3.779 3.779 0 019 11.323a3.779 3.779 0 01-3.774-3.775A3.779 3.779 0 019 3.774zM9 17.42a8.386 8.386 0 01-5.692-2.222 6.85 6.85 0 015.837-3.294 6.769 6.769 0 015.728 3.121A8.389 8.389 0 019 17.42z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

UsernameIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(UsernameIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'UsernameIcon';
export default ForwardRef;