import React, { useState, memo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import queryString from 'query-string';
import { getFormVariables } from '../utils/form';

import {
  AuthForm,
  AuthHeading,
  AuthParagraph,
  Button,
  Field,
  Input,
  Label
} from '../ui';
import md5 from 'md5';
import { TERMS_URL } from '../urls';
import { signIn } from '../state/commands';
import { AuthLayout } from '../layouts/AuthLayout';

import { SignUpMutation } from '../mutations';
import { propsAreEqual } from '../utils/react';
import { NotificationBox } from '../components';

const SignUpForm = memo(function SignUpform(props) {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const { params } = props;
  const [email, setEmail] = useState(params.email || '');

  const handleSignup = variables => {
    setLoading(true);
    props
      .signUp({ variables })
      .then(({ data: { signUp: { token } } }) => {
        if (token && token.accessToken) {
          signIn(token.accessToken);
          localStorage.setItem(
            'gravatar',
            `https://www.gravatar.com/avatar/${md5(variables.email)}`
          );
        } else {
          setNotification({
            text: "The sign in details you've provided are invalid",
            variant: 'warning'
          });
        }
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
        setNotification({
          text: 'Sorry, something bad happened.',
          variant: 'danger'
        });
      });
  };

  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        const variables = getFormVariables(event);

        // only here we set terms to true
        // because we mention it in the copy
        variables.acceptTerms = true;
        handleSignup(variables);
      }}
    >
      <NotificationBox
        notification={notification}
        setNotification={setNotification}
      />
      <AuthHeading>Sign Up</AuthHeading>
      {/* <Field fluid={true}>
        <Label htmlFor="display-name" id="display-name-label">
          Display Name
        </Label>
        <Input
          aria-labelledby="display-name-label"
          autoComplete="name"
          autoFocus={true}
          fluid={true}
          id="display-name"
          name="displayName"
          type="text"
        />
      </Field> */}
      <Field fluid={true}>
        <Label htmlFor="email" id="email-label">
          {/* Email */}
        </Label>
        <Input
          aria-labelledby="email-label"
          autoComplete="email"
          fluid={true}
          id="email"
          placeholder="your@email.com"
          name="email"
          type="email"
          value={email}
          autoFocus={true}
          onChange={event => {
            event.preventDefault();
            const value = event.currentTarget.value;
            setEmail(value);
          }}
        />
      </Field>
      {/* <Field fluid={true}>
        <Label htmlFor="password" id="password-label">
          Password
        </Label>
        <Input
          aria-labelledby="password-label"
          autoComplete="password"
          fluid={true}
          id="password"
          name="password"
          type="password"
        />
      </Field> */}
      <Field>
        <Input
          aria-labelledby="inviteToken-label"
          autoComplete="inviteToken"
          id="inviteToken"
          name="inviteToken"
          type="hidden"
          value={params.invite}
        />
      </Field>
      <Button fluid={true} disabled={loading} type="submit" variant="primary">
        Sign Up
      </Button>
      <br />
      <AuthParagraph variant={'small'}>
        By signing up, you agree to our&nbsp;
        <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
          terms of service
        </a>
      </AuthParagraph>
      <AuthParagraph variant={'caption'}>
        Already have an account?&nbsp;
        <a href={'/signin'}>sign in</a>
      </AuthParagraph>
    </AuthForm>
  );
}, propsAreEqual);

export const SignUp = memo(function SignUp(props) {
  const [signUp] = useMutation(SignUpMutation);
  const params = queryString.parse(props.location.search);
  return (
    <AuthLayout>
      <SignUpForm signUp={signUp} history={props.history} params={params} />
    </AuthLayout>
  );
}, propsAreEqual);
