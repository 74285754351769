import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import {
  Box,
  Button,
  Divider,
  Icon,
  Menu,
  MenuBar,
  MenuBarList,
  MenuBarListItem,
  MenuBarListItemLink,
  MenuItem,
  MenuItemLink,
  Select,
  Text
} from '../ui';
import { CurrentRoleQuery } from '../queries';
import { CurrentAvatar } from './CurrentAvatar';
import { SignOutLink } from './SignOutLink';
import CreateOrgForm from './CreateOrgForm';

import { Popup } from './Popup';
import { NotificationCenter } from './NotificationCenter';

export const PageEditorTopMenuBar = () => {
  const {
    data: {
      currentRole = {
        organization: { username: 'Organization name' }
      }
    }
  } = useQuery(CurrentRoleQuery);

  return (
    <MenuBar aria-label="Main menu">
      <MenuBarList px="3" py="2">
        <MenuBarListItem>
          <MenuBarListItemLink as={Link} to="/">
            <Icon
              name="airpage-logo"
              title="Go to Dashboard"
              size={18}
              fill="rgb(0,128,255)"
            />
          </MenuBarListItemLink>
          <Divider height="30px" width="1px" marginLeft="1" marginRight="3" />
        </MenuBarListItem>
        <MenuBarListItem fluid={true}>Title of the Page</MenuBarListItem>
        <MenuBarListItem>
          <Button> Save </Button>
        </MenuBarListItem>
        <MenuBarListItem>
          <Divider height="30px" width="1px" mx="3" />
          <Popup
            placement="bottom-end"
            modifiers={{ shift: { enabled: true } }}
            popup={
              <Menu aria-label="User">
                <MenuItem>
                  <MenuItemLink as={SignOutLink}>Sign out</MenuItemLink>
                </MenuItem>
              </Menu>
            }
          >
            {({ setExpanded }) => (
              <MenuBarListItemLink
                tabIndex={-1}
                href="#"
                id="avatar-link"
                aria-controls="avatar-menu"
                onClick={event => {
                  event.preventDefault();
                  setExpanded(expanded => !expanded);
                }}
              >
                <CurrentAvatar size={24} />
              </MenuBarListItemLink>
            )}
          </Popup>
        </MenuBarListItem>
      </MenuBarList>
    </MenuBar>
  );
};
