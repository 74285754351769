import React from 'react';
import { Card as StyledCard } from './Card';
import { CardCover } from './CardCover';

export const Card = React.forwardRef(function Card(
  { cover, keepCoverRatio, children, ...rest },
  ref
) {
  return (
    <StyledCard {...rest} ref={ref}>
      {cover && <CardCover keepCoverRatio={keepCoverRatio}>{cover}</CardCover>}
      {children}
    </StyledCard>
  );
});

export * from './CardBody';
export * from './CardMeta';
export * from './CardAction';
