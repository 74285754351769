import React from 'react';
import PropTypes from 'prop-types';
import { getOwnerDocument } from '../utils/dom';

export const FocusTrap = ({
  children,
  disableAutoFocus = false,
  disableRestoreFocus = false,
  enabled,
  open
}) => {
  const focalStart = React.useRef(null);
  const focalEnd = React.useRef(null);
  const rootRef = React.createRef();

  const lastFocusedNode = React.useRef();

  React.useMemo(() => {
    if (!open) return;
    lastFocusedNode.current = getOwnerDocument().activeElement;
  }, [open]);

  React.useEffect(() => {
    if (!open) return;
    const doc = getOwnerDocument(rootRef.current);

    if (
      !disableAutoFocus &&
      rootRef.current &&
      !rootRef.current.contains(doc.activeElement)
    ) {
      rootRef.current.focus();
    }
    const hijackDocFocus = () => {
      if (!enabled()) {
        return;
      }
      if (rootRef.current && !rootRef.current.contains(doc.activeElement)) {
        rootRef.current.focus();
      }
    };

    const trapFocus = event => {
      if (!enabled() || event.keyCode !== 9) return;

      if (doc.activeElement === rootRef.current) {
        if (event.shiftKey) {
          focalEnd.current.focus();
        } else {
          focalStart.current.focus();
        }
      }
    };

    doc.addEventListener('focus', hijackDocFocus, true);
    doc.addEventListener('keydown', trapFocus, true);

    // Workaround: Bug with browser where it doesn't automatically
    // focus the rootRef after the portal content is mounted
    const interval = setInterval(() => {
      hijackDocFocus();
    }, 50);

    return () => {
      clearInterval(interval);
      doc.removeEventListener('focus', hijackDocFocus, true);
      doc.removeEventListener('keydown', trapFocus, true);
      if (disableRestoreFocus) return;
      if (lastFocusedNode.current && lastFocusedNode.current.focus) {
        lastFocusedNode.current.focus();
      }
      lastFocusedNode.current = null;
    };
  }, [disableAutoFocus, disableRestoreFocus, enabled, rootRef, open]);
  return (
    <React.Fragment>
      <div tabIndex={0} ref={focalStart} />
      {React.cloneElement(React.Children.only(children), { ref: rootRef })}
      <div tabIndex={0} ref={focalEnd} />
    </React.Fragment>
  );
};

FocusTrap.propTypes = {
  open: PropTypes.bool,
  enabled: PropTypes.func,
  // If true, will not autofocus the content inside the focus trap
  disableAutoFocus: PropTypes.bool,
  // If true, will not restore focus to the last active focus el
  disableRestoreFocus: PropTypes.bool
};

FocusTrap.defaultProps = {
  enabled: () => true
};
