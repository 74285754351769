const counter = {};

export const uniqueId = (prefix = 'airpage_id') => {
  if (!counter[prefix]) {
    counter[prefix] = 0;
  }
  const id = ++counter[prefix];
  if (prefix === 'airpage_id') return `${id}`;
  return `${prefix}${id}`;
};
