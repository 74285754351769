import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { transparentize } from 'polished';
import { background, position, color } from 'styled-system';

export const thumbSize = 12;

export const ControlThumb = styled.div(
  props => ({
    width: thumbSize,
    height: thumbSize,
    borderRadius: '50%',
    background: transparentize(0.5, themeGet('colors.white')(props)),
    boxShadow: `0px 2px 8px 1px ${transparentize(
      0.6,
      themeGet('colors.black')(props)
    )};`,
    cursor: 'pointer'
  }),
  position,
  background,
  color
);
