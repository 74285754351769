import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AddVariationIcon = function AddVariationIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18zm0-1.125a7.875 7.875 0 1 0 0-15.75 7.875 7.875 0 0 0 0 15.75zm.703-8.578h4.746c.097 0 .176.079.176.176v1.054a.176.176 0 0 1-.176.176H9.703v4.746a.176.176 0 0 1-.176.176H8.473a.176.176 0 0 1-.176-.176V9.703H3.55a.176.176 0 0 1-.176-.176V8.473c0-.097.079-.176.176-.176h4.746V3.55c0-.097.079-.176.176-.176h1.054c.097 0 .176.079.176.176v4.746z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AddVariationIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AddVariationIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AddVariationIcon';
export default ForwardRef;