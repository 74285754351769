import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var EditIcon = function EditIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M13.352.04a.132.132 0 01.191.002l4.418 4.417c.053.054.05.143.002.191L5.473 17.14a.472.472 0 01-.227.113L0 18.003l.75-5.246a.485.485 0 01.113-.228L13.353.04z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

EditIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(EditIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'EditIcon';
export default ForwardRef;