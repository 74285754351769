import React from 'react';

import {
  Avatar,
  Select,
  Badge,
  Button,
  Box,
  Divider,
  Icon,
  Menu,
  MenuItem,
  MenuItemLink,
  Text
} from '../ui';
import { Popup } from './Popup';

const NotificationItem = () => (
  <MenuItemLink as="div">
    <Box display="flex">
      <Box flexShrink="0">
        <Badge
          dot
          color="success"
          position="left"
          offset={{ left: 14, top: 4 }}
          paddingTop="0"
        >
          <Avatar
            url={`https://robohash.org/bgset_bg2/${123}.jpeg?size=${40}x${40}`}
            size={40}
          />
        </Badge>
      </Box>
      <Box>
        <Text variant="body" margin="0" style={{ whiteSpace: 'normal' }}>
          Nhu Nguyen saved a draft in 'Homepage' Page
        </Text>
        <Text variant="caption" margin="0" style={{ whiteSpace: 'normal' }}>
          2 hour ago
        </Text>
      </Box>
    </Box>
  </MenuItemLink>
);

export const NotificationCenter = () => {
  const [filterValue, setFilterValue] = React.useState('');
  return (
    <Popup
      closeOnClickAway
      placement="bottom-end"
      popup={
        <Menu aria-label="Nofifications" width="300px">
          <MenuItem>
            <Box>
              <Box p="3">
                <Select
                  fluid
                  id="filter"
                  placeholder="Filter.."
                  value={filterValue}
                  onChange={event => setFilterValue(event.target.value)}
                  renderOption={({ option }) => (
                    <MenuItem as="div">
                      <MenuItemLink as="div">
                        <Text m="0">{option === '' ? 'Show All' : option}</Text>
                      </MenuItemLink>
                    </MenuItem>
                  )}
                  options={['']}
                />
              </Box>
              <Divider width="100%" height="1px" />
            </Box>
          </MenuItem>
          <MenuItem>
            <NotificationItem />
          </MenuItem>
          <MenuItem>
            <NotificationItem />
          </MenuItem>
          <MenuItem>
            <NotificationItem />
          </MenuItem>
          <MenuItem>
            <NotificationItem />
          </MenuItem>
          <MenuItem>
            <NotificationItem />
          </MenuItem>
        </Menu>
      }
    >
      {({ setExpanded }) => (
        <Badge color="danger" content={3} position="left">
          <Button
            p="1"
            style={{ lineHeight: 1 }}
            onClick={() => setExpanded(true)}
          >
            <Icon name="bell" size={16} color="inherit" />
          </Button>
        </Badge>
      )}
    </Popup>
  );
};
