import styled from '@emotion/styled/macro';
import { em, margin, padding, transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';

const variant = props => ({
  backgroundColor: themeGet('colors.background.default')(props),
  borderColor: themeGet('colors.border')(props),
  boxShadow: `${transparentize(0.85, themeGet('colors.black')(props))} 0 ${em(
    6
  )} ${em(16)}`
});

export const PaginationList = styled.ol(
  {
    ...margin(0),
    ...padding(0),
    borderRadius: em(3),
    borderStyle: 'solid',
    borderWidth: 0.5,
    display: 'inline-flex',
    listStyle: 'none'
  },
  variant
);
