import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { em, padding } from 'polished';
import { ReactComponent as Handle } from './handle.svg';

const variant = css({ color: 'rgb(90,90,90)' });

export const PanelTabsNavHandle = styled.button(
  {
    ...padding(em(6.5), em(3)),
    appearance: 'none',
    background: 'transparent',
    border: 0,
    '&[draggable="true"]': {
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing'
      }
    }
  },
  variant
);

PanelTabsNavHandle.defaultProps = {
  children: <Handle />
};
