import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ZToAIcon = function ZToAIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.516 0v.124l-3.059 6.16h2.956v.95H0V7.09L3.08.95H.04V0h4.475zm12.411 17.998l-.64-1.54h-3.101l-.641 1.54H11.46l3.193-7.338h.145l3.204 7.338h-1.075zm-3.357-2.489h2.315l-1.158-2.79-1.157 2.79zM8 4.105C8 4.047 8.038 4 8.085 4h.83c.047 0 .085.045.085.105v9.79c0 .058-.038.105-.085.105h-.83C8.038 14 8 13.955 8 13.894V4.105z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ZToAIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ZToAIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ZToAIcon';
export default ForwardRef;