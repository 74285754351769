import React from 'react';
import { Box, Button, Icon, Input, InputGroup, InputGroupAppend } from '../ui';

export const DashboardSearchBar = ({
  onChange,
  addButton = true,
  AddButtonProps,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      maxHeight="60px"
      borderColor="divider"
      borderWidth="1px"
      borderStyle="solid"
      borderBottom="none"
      borderLeft="none"
      borderRight="none"
      backgroundColor="background.default"
      px="5"
      py="3"
      {...rest}
    >
      {addButton && (
        <React.Fragment>
          <Button variant="primary" {...AddButtonProps}>
            <Icon
              name="plus"
              title="Add projects"
              size={12}
              fill="currentColor"
            />
          </Button>
          <Box backgroundColor="divider" height="30px" width="1px" mx="3" />
        </React.Fragment>
      )}
      <Box flexGrow="1">
        <InputGroup>
          <Input
            id="dashboard-search"
            onChange={onChange}
            placeholder="Search"
            aria-label="Search input"
          />
          <InputGroupAppend>
            <Button>
              <Icon
                name="search"
                title="Search"
                size={12}
                fill="currentColor"
              />
            </Button>
          </InputGroupAppend>
        </InputGroup>
      </Box>
    </Box>
  );
};
