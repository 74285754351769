import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

export const WindowSplitterPane = styled.div(props => ({
  backgroundColor: 'rgb(50,50,50)',
  display: 'flex',
  flexBasis: 'auto',
  flexGrow: 1,
  flexShrink: 1,
  height: props.direction === 'column' ? 0 : undefined,
  overflow: 'hidden',
  width: props.direction === 'row' ? 0 : undefined,
  '*': props.isDragging ? { pointerEvents: 'none' } : undefined
}));

WindowSplitterPane.propTypes = {
  direction: PropTypes.oneOf(['column', 'row']).isRequired,
  isDragging: PropTypes.bool
};
