import { CommandProcessor } from './CommandProcessor';
import { commands, emitFileChanged } from './events';
import { clearTerminalOutput, appendTerminalOutput } from './commands';
import { client } from '../apollo';
import injector from './injector';
import * as git from 'isomorphic-git';
import { credentialManager } from '../fs/credentialManager';
import { nodes as registry } from './registry';

import {
  closeTab,
  createTab,
  moveTab,
  setPaneSpacing,
  setPaneFlexValues,
  setSelectedWorkspacePane,
  setSelectedWorkspacePaneTabIndex,
  splitMoveTab
} from './app/workspace/commands';

import {
  addEdge,
  addGraph,
  addGroup,
  addInput,
  addNode,
  addOutput,
  removeEdge,
  removeGroup,
  removeNode,
  setProperty,
  setProps,
  updateNodePosition
} from './app/flow/commands';
import { setSelection, changeDirectory, writeFile } from './app/core/commands';

import * as BrowserFS from 'browserfs';

export const initialize = () =>
  new Promise(resolve => {
    fetch(`${process.env.PUBLIC_URL}/scripts.zip?date=${new Date()}`)
      .then(response => response.arrayBuffer())
      .then(zipData => {
        BrowserFS.FileSystem.InMemory.Create({}, (e, imfs) => {
          BrowserFS.FileSystem.LocalStorage.Create({}, (e, lfs) => {
            BrowserFS.FileSystem.MountableFileSystem.Create(
              {
                '/': imfs,
                '/local': lfs
              },
              (e, mfs) => {
                BrowserFS.initialize(mfs);
                BrowserFS.FileSystem.ZipFS.Create(
                  { zipData: Buffer.from(zipData) },
                  (e, zfs) => {
                    mfs.mount('/scripts', zfs);
                    if (e) throw e;
                    const fs = BrowserFS.BFSRequire('fs');
                    const path = BrowserFS.BFSRequire('path');
                    injector.register('fs', fs);
                    injector.register('path', path);
                    git.plugins.set('fs', fs);
                    git.plugins.set('credentialManager', credentialManager);
                    injector.register('git', git);
                    injector.register('apollo', client);
                    injector.register('registry', registry);
                    resolve();
                  }
                );
              }
            );
          });
        });
      });
  });

const commandProcessor = new CommandProcessor();

commands.on('terminal:parse', ({ buffer, pid }) => {
  commandProcessor.parse(buffer, pid);
});

commands.on('terminal:clear', ({ pid }) => {
  emitFileChanged(...clearTerminalOutput());
});

commands.on('terminal:append', ({ output, pid }) => {
  emitFileChanged(...appendTerminalOutput(output));
});

const nodeScope = {
  addEdge,
  addGraph,
  addGroup,
  addInput,
  addNode,
  addOutput,
  removeEdge,
  removeGroup,
  removeNode,
  setProperty,
  setProps,
  updateNodePosition
};

for (let key in nodeScope) {
  commands.on(`nodes:${key}`, (...args) => {
    emitFileChanged(...nodeScope[key](...args));
  });
}

const workspaceScope = {
  closeTab,
  createTab,
  moveTab,
  setPaneFlexValues,
  setPaneSpacing,
  setSelectedWorkspacePane,
  setSelectedWorkspacePaneTabIndex,
  splitMoveTab
};

for (let key in workspaceScope) {
  commands.on(`workspace:${key}`, (...args) => {
    emitFileChanged(...workspaceScope[key](...args));
  });
}

const coreScope = {
  setSelection,
  changeDirectory,
  writeFile
};

for (let key in coreScope) {
  commands.on(`core:${key}`, (...args) => {
    emitFileChanged(...coreScope[key](...args));
  });
}
