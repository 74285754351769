import React from 'react';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { transparentize } from 'polished';
import { layout, space } from 'styled-system';
import { clamp } from '../../utils/math';
import { rangeThumbSelectors, rangeTrackSelectors } from './shared';

const thumbSize = 12;
const trackHeight = 6;

const createStyles = selectors => styles =>
  selectors.reduce((obj, selector) => {
    obj[selector] = styles;
    return obj;
  }, {});

const Input = styled.input(props => ({
  margin: 0,
  padding: `${themeGet('space.1')(props)}px 0`,
  backgroundColor: 'transparent',
  cursor: 'pointer',
  width: props.fluid ? '100%' : props.width,
  height: thumbSize,
  appearance: 'none',
  '&:focus': {
    outline: 'none'
  },
  ...createStyles(rangeTrackSelectors)({
    width: '100%',
    height: trackHeight,
    cursor: 'pointer',
    background: props.trackBg
      ? props.trackBg
      : transparentize(0.5, themeGet('colors.black')(props)),
    borderColor: 'transparent',
    border: `${themeGet('borders.1')(props)} ${transparentize(
      0.5,
      themeGet('colors.black')(props)
    )}`,
    borderRadius: themeGet('radii.1')(props),
    color: 'transparent'
  }),
  ...createStyles(rangeThumbSelectors)({
    appearance: 'none',
    width: thumbSize,
    height: thumbSize,
    borderRadius: '50%',
    background: transparentize(0.5, themeGet('colors.white')(props)),
    boxShadow: `0px 2px 8px 1px ${transparentize(
      0.6,
      themeGet('colors.black')(props)
    )};`
  }),
  '&::-webkit-slider-thumb': {
    // Necessary for chrome browser
    marginTop: -thumbSize / 2
  },
  '&::-moz-focus-outer': {
    // Remove firefox outline when focus
    border: 0
  }
}));

const Wrapper = styled.div(props => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flex: '1 1 auto',
  height: themeGet('space.4')(props)
}));

export const SingleThumb = styled(
  ({
    value,
    min,
    max,
    id,
    disabled,
    fluid,
    trackBg,
    onChange,
    onBlur,
    onFocus,
    className
  }) => {
    const clampedValue = clamp(value, min, max);
    return (
      <Wrapper className={className}>
        <Input
          type="range"
          id={id}
          min={min}
          max={max}
          value={clampedValue}
          onChange={_handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          fluid={fluid}
          disabled={disabled}
          trackBg={trackBg}
        />
      </Wrapper>
    );

    function _handleChange(event) {
      onChange(clamp(event.currentTarget.value, min, max), id);
    }
  }
)(layout, space);
