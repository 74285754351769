import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var GeolocationIcon = function GeolocationIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M15 6.23C15 2.796 12.308 0 9 0S3 2.795 3 6.23a6.38 6.38 0 00.673 2.863c.006.014.01.029.018.043l4.13 8.125.004.009c.225.434.667.73 1.175.73s.95-.296 1.175-.73l.005-.009 4.13-8.125c.006-.014.01-.029.017-.043A6.38 6.38 0 0015 6.23zM9 4.155c1.103 0 2 .931 2 2.077 0 1.145-.897 2.077-2 2.077S7 7.376 7 6.23c0-1.146.897-2.077 2-2.077z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

GeolocationIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(GeolocationIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'GeolocationIcon';
export default ForwardRef;