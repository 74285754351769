import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var MapOIcon = function MapOIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5 .25v14.74a.25.25 0 01-.12.213l-4.5 2.759a.25.25 0 01-.38-.214V3.008a.25.25 0 01.12-.212l4.5-2.76A.25.25 0 015 .25zm7 2.802v14.694c0 .14-.112.254-.25.254a.246.246 0 01-.112-.027l-5.5-2.798A.255.255 0 016 14.948V.254C6 .114 6.112 0 6.25 0c.039 0 .077.01.112.027l5.5 2.798a.255.255 0 01.138.227zm6-2.797v14.73c0 .09-.046.174-.121.22l-4.5 2.759a.247.247 0 01-.343-.088.26.26 0 01-.036-.131V3.015c0-.09.046-.174.121-.22l4.5-2.759a.247.247 0 01.343.088.26.26 0 01.036.131z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

MapOIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(MapOIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'MapOIcon';
export default ForwardRef;