import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ColorRgbIcon = function ColorRgbIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 11.136A5.318 5.318 0 119 .5a5.318 5.318 0 010 10.636zm0-.818a4.5 4.5 0 100-9 4.5 4.5 0 000 9zm-3.682 7.364a5.318 5.318 0 110-10.637 5.318 5.318 0 010 10.637zm0-.818a4.5 4.5 0 100-9 4.5 4.5 0 000 9zm7.364.818a5.318 5.318 0 110-10.637 5.318 5.318 0 010 10.637zm0-.818a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM8.962 9.74c.377-.543.902-.995 1.604-1.365.936-.492 1.776-.598 2.468-.513-.245.572-.86 1.335-1.711 1.852a4.27 4.27 0 01-1.964.606c.286.543.531 1.308.502 2.054-.042 1.057-.416 2.039-.861 2.601-.435-.57-.856-1.604-.878-2.6-.015-.713.24-1.498.53-2.058l-.02-.002.006-.012c-.663-.042-1.307-.195-1.944-.582-.851-.517-1.469-1.321-1.74-1.862a4.526 4.526 0 012.478.508 4.226 4.226 0 011.53 1.373z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ColorRgbIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ColorRgbIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ColorRgbIcon';
export default ForwardRef;