import styled from '@emotion/styled/macro';
import { margin, em } from 'polished';
import { Box } from '../Box';

export const ProjectFeed = styled(Box)({
  ...margin(0, em(12)),
  alignItems: 'center',
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'row',
  flexWrap: 'wrap'
});

ProjectFeed.defaultProps = {
  role: 'feed'
};
