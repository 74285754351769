import styled from '@emotion/styled/macro';

export const ToolbarNavList = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0
});

ToolbarNavList.defaultProps = {
  role: 'tablist'
};
