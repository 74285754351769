export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: 'airpage://schemas/Node.json',
  definitions: {
    object: {
      type: 'object',
      required: ['type', 'properties'],
      properties: {
        uiSchema: {},
        type: {
          const: 'object'
        },
        properties: {
          $ref: 'http://json-schema.org/draft-07/schema#/properties/properties'
        },
        additionalProperties: {
          $ref: 'http://json-schema.org/draft-07/schema#/properties/additionalProperties'
        },
        required: {
          $ref: 'http://json-schema.org/draft-07/schema#/properties/required'
        }
      },
      additionalProperties: false
    }
  },
  type: 'object',
  title: 'AirPage Node Meta-Schema',
  required: ['kind', 'meta'],
  properties: {
    kind: {
      $id: '#/properties/kind',
      enum: ['Node', 'Context'],
      title: 'Kind',
      description: 'The type of node. Currently support Node and Context types.'
    },
    meta: {
      $id: '#/properties/meta',
      type: 'object',
      title: 'Metadata',
      required: ['icon', 'description', 'category', 'name', 'schema'],
      properties: {
        icon: {
          $id: '#/properties/meta/properties/icon',
          type: 'string',
          title: 'Icon',
          description: 'a relative path to the svg icon representing the node',
          examples: ['../../assets/MyNode.svg']
        },
        description: {
          $id: '#/properties/meta/properties/description',
          type: 'string',
          title: 'Description',
          description: 'A description of the node',
          examples: ['a descriptive description']
        },
        category: {
          $id: '#/properties/meta/properties/category',
          type: 'string',
          title: 'Category',
          description: 'The category of the node',
          default: 'Generic',
          examples: ['Generic']
        },
        name: {
          $id: '#/properties/meta/properties/name',
          type: 'string',
          title: 'Name',
          description: 'The name of the node is used to register the node for usage within a context.',
          examples: ['MyNode']
        },
        schema: {
          $id: '#/properties/meta/properties/schema',
          type: 'object',
          title: 'Schema',
          description: 'Schema representing the node. Contains inputs, outputs and data.',
          properties: {
            inputs: {
              $ref: '#/definitions/object'
            },
            data: {
              $ref: '#/definitions/object'
            },
            outputs: {
              $ref: '#/definitions/object'
            }
          }
        }
      }
    }
  }
};