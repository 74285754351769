import {
  readJSONSync,
  join,
  basename,
  dirname,
  aggregateAllPaths
} from '../../utils';
import injector from '../../injector';

import {
  AIRPAGE_DIRECTORY_NAME,
  AIRPAGE_EDGES_FILENAME,
  AIRPAGE_GROUP_FILENAME,
  AIRPAGE_DATA_FILENAME,
  AIRPAGE_META_FILENAME,
  AIRPAGE_GRAPH_FILENAME
} from './constants';

const { inject } = injector;

const getFolderRootMetaDirectory = root => join(root, AIRPAGE_DIRECTORY_NAME);
const getFolderDataPath = root =>
  join(getFolderRootMetaDirectory(root), AIRPAGE_DATA_FILENAME);
const getFolderGraphEdgePath = root =>
  join(getFolderRootMetaDirectory(root), AIRPAGE_EDGES_FILENAME);
const getFolderGraphGroupPath = root =>
  join(getFolderRootMetaDirectory(root), AIRPAGE_GROUP_FILENAME);
const getFolderGraphMetaPath = root =>
  join(getFolderRootMetaDirectory(root), AIRPAGE_META_FILENAME);
const getFolderGraphConfigPath = root =>
  join(getFolderRootMetaDirectory(root), AIRPAGE_GRAPH_FILENAME);

const getFolderGraphConfig = root =>
  readJSONSync(getFolderGraphConfigPath(root));
const getFolderGraphEdge = root =>
  readJSONSync(getFolderGraphEdgePath(root), []);
const getFolderGraphGroup = root => readJSONSync(getFolderGraphGroupPath(root));
const getFolderData = root => readJSONSync(getFolderDataPath(root));
const getFolderGraphMeta = root => readJSONSync(getFolderGraphMetaPath(root));

// TODO continue to refactor - get BETTER NAMING CONVENTION for .airpage graph props
const getNodeMetaData = ({ path }) => {
  const root = dirname(path);
  const name = basename(path);
  const meta = getFolderGraphMeta(root);
  return meta[name];
};

// TODO should we have types w/o scopes like this? or should we use @airpage/Input, etc.
const isInput = node => node.type === 'Input';
const isProperty = node => node.type === 'Property';
const isOutput = node => node.type === 'Output';

const serialize = inject(['fs'], function({
  root,
  recursive = true,
  levels = Number.MAX_SAFE_INTEGER,
  n = 0
}) {
  const { fs } = this;
  const graph = getFolderGraphConfig(root);
  const edges = getFolderGraphEdge(root);
  const groups = getFolderGraphGroup(root);
  const data = getFolderData(root);
  const meta = getFolderGraphMeta(root);

  const nodes = fs
    .readdirSync(root)
    .filter(a => a !== AIRPAGE_DIRECTORY_NAME)
    .reduce((m, v) => {
      const fullPath = join(root, v);
      if (fs.statSync(fullPath).isDirectory()) {
        if (recursive && n < levels) {
          m[v] = serialize({ root: fullPath, recursive, levels, n: n + 1 });

          // get type from graph if it exists...
          if (!m[v].type && m[v].graph && m[v].graph.type) {
            m[v].type = m[v].graph.type;
          }
        } else {
          const data = getFolderData(fullPath);
          const graph = getFolderGraphConfig(fullPath);
          m[v] = { ...graph, data };
        }
      } else {
        m[v] = readJSONSync(fullPath);
      }

      if (meta.hasOwnProperty(v)) {
        // metadata
        m[v].meta = meta[v];
      }

      return m;
    }, {});

  const { inputs, properties, outputs } = Object.entries(nodes).reduce(
    (m, [name, node]) => {
      if (isInput(node)) m.inputs.push(name);
      if (isOutput(node)) m.outputs.push(name);
      if (isProperty(node)) m.properties.push(name);
      return m;
    },
    { inputs: [], properties: [], outputs: [] }
  );

  return {
    inputs,
    outputs,
    properties,
    nodes,
    graph,
    edges,
    data,
    groups
  };
});

const serializeGraph = ({ root, recursive = true, levels }) => {
  const graph = serialize({ root, recursive, levels });

  graph.nodes = Object.keys(graph.nodes).map(name => {
    const node = graph.nodes[name];
    node.name = name;
    if (!node.meta) {
      node.meta = {
        x: 0,
        y: 0,
        // TODO have another convo about this
        // selections wont work unless this is here...
        width: 50,
        height: 50
      };
    }
    if (!node.meta.width) {
      node.meta.width = 50;
    }
    if (!node.meta.height) {
      node.meta.height = 50;
    }
    if (!node.meta.x) {
      node.meta.x = 0;
    }
    if (!node.meta.y) {
      node.meta.y = 0;
    }
    return node;
  });
  return graph;
};

// TODO add order option
const serializeHierarchy = inject(['fs'], function({ root, recursive = true }) {
  const graph = getFolderGraphConfig(root);
  const data = getFolderData(root);
  const meta = getFolderGraphMeta(root);
  const { fs } = this;
  const nodes = fs
    .readdirSync(root)
    .filter(a => a !== AIRPAGE_DIRECTORY_NAME)
    .reduce((m, v) => {
      const fullPath = join(root, v);
      if (fs.statSync(fullPath).isDirectory()) {
        if (recursive) {
          m[v] = serializeHierarchy({
            root: fullPath,
            recursive
          });
        } else {
          const data = getFolderData(fullPath);
          const graph = getFolderGraphConfig(fullPath);
          m[v] = { ...graph, data };
        }
      } else {
        m[v] = readJSONSync(fullPath);
      }

      if (meta.hasOwnProperty(v)) {
        // metadata
        m[v].meta = meta[v];
      }

      return m;
    }, {});

  return {
    nodes,
    graph,
    data
  };
});

// TODO lookup icon based on extension
const serializeFolderHierarchy = inject(['fs'], function({
  root,
  recursive = true
}) {
  const { fs } = this;
  const nodes = fs
    .readdirSync(root)
    .filter(a => a !== AIRPAGE_DIRECTORY_NAME)
    .reduce((m, v) => {
      const fullPath = join(root, v);
      if (fs.statSync(fullPath).isDirectory()) {
        if (recursive) {
          m[v] = serializeFolderHierarchy({
            root: fullPath,
            recursive
          });
        }
      } else {
        m[v] = {
          path: join(root, v),
          icon: 'files'
        };
      }
      return m;
    }, {});

  return {
    children: Object.values(nodes),
    icon: 'folder',
    path: root
  };
});

const serializeFolderHierarchyScoped = inject(['fs'], function({
  root = '/',
  paths,
  _init = false
}) {
  const { fs } = this;
  if (!_init) {
    paths = aggregateAllPaths(paths).filter(p => p.startsWith(root));
  }
  const nodes = fs
    .readdirSync(root)
    .filter(a => a !== AIRPAGE_DIRECTORY_NAME)
    .reduce((m, v) => {
      const path = join(root, v);
      if (fs.statSync(path).isDirectory()) {
        if (paths.includes(path)) {
          m[v] = serializeFolderHierarchyScoped({
            root: path,
            paths,
            _init: true
          });
        } else {
          m[v] = {
            path,
            icon: 'folder'
          };
        }
      } else {
        m[v] = {
          path,
          icon: 'files'
        };
      }
      return m;
    }, {});

  return {
    children: Object.values(nodes),
    icon: 'folder',
    path: root
  };
});

const serializeFolderChildren = inject(['fs'], function({ root }) {
  const { fs } = this;
  const nodes = fs
    .readdirSync(root)
    .filter(a => a !== AIRPAGE_DIRECTORY_NAME)
    .reduce((m, v) => {
      const path = join(root, v);
      let icon = 'files';
      if (fs.statSync(path).isDirectory()) {
        icon = 'folder';
      }
      m[v] = {
        path,
        icon
      };
      return m;
    }, {});

  return Object.values(nodes);
});

const serializeNode = inject(['fs'], function({ root }) {
  const { fs } = this;
  if (!fs.existsSync(root)) return {};
  if (fs.statSync(root).isDirectory()) {
    const { data, graph, properties } = serialize({
      root,
      recursive: true,
      levels: 1
    });
    return { ...graph, data, properties, isDirectory: true };
  } else {
    return readJSONSync(root);
  }
});

export {
  getFolderDataPath,
  getFolderGraphConfig,
  getFolderGraphConfigPath,
  getFolderGraphEdgePath,
  getFolderGraphGroupPath,
  getFolderGraphMetaPath,
  getFolderRootMetaDirectory,
  serialize,
  serializeGraph,
  serializeFolderChildren,
  serializeFolderHierarchy,
  serializeFolderHierarchyScoped,
  serializeHierarchy,
  serializeNode,
  getFolderData,
  getFolderGraphEdge,
  getFolderGraphGroup,
  getFolderGraphMeta,
  getNodeMetaData
};
