import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CircleCheckIcon = function CircleCheckIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 18A9 9 0 109 0a9 9 0 000 18zM2.25 9.152a.54.54 0 01.156-.354l.727-.71a.51.51 0 01.727 0l2.907 3.038c.104.101.26.101.364 0l7.01-7.037a.51.51 0 01.726 0l.727.71a.481.481 0 010 .708L7.287 13.91a.477.477 0 01-.364.152.477.477 0 01-.363-.152L2.406 9.506a.54.54 0 01-.156-.354z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CircleCheckIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CircleCheckIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CircleCheckIcon';
export default ForwardRef;