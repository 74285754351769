import React from 'react';
import isEqual from 'react-fast-compare';

export const propsAreEqual = (prevProps, nextProps) =>
  isEqual(prevProps, nextProps);

const setRef = (ref, value) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

// Allow sharing refs between forwarded ref and internally used ref within a component
export const useForkRef = (refA, refB) => {
  return React.useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }
    return refValue => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
};
