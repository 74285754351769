import gql from 'graphql-tag';
import { ContentFeedFragment } from '../fragments';

export const UpdateContentMutation = gql`
  mutation updateContent($id: UUID!, $patch: ContentPatch!) {
    updateContent(input: { id: $id, patch: $patch }) {
      content {
        ...ContentFeedFragment
      }
    }
  }
  ${ContentFeedFragment}
`;
