import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var OrganizationProfileIcon = function OrganizationProfileIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M14 9.582a8.13 8.13 0 00-2.625-.573V7.875H10.5v1.14a7.962 7.962 0 00-.875.105V4.594c0-.121.098-.219.219-.219h3.937c.121 0 .219.098.219.219v4.988zm-7.875 1.01V10h-3.5v4H.219A.219.219 0 010 13.781V1.094C0 .973.098.875.219.875h.656V.219C.875.098.973 0 1.094 0h6.562c.121 0 .219.098.219.219v.656h.656c.121 0 .219.098.219.219v8.23c-.92.268-1.8.698-2.625 1.268zM10.5 5.25V7h.875V5.25H10.5zM2 4v2h2V4H2zm0-3v2h2V1H2zm0 6v2h2V7H2zm3-3v2h2V4H5zm0 3v2h2V7H5zm0-6v2h2V1H5zm6 9c2.675 0 5.1 1.403 6.89 3.684a.514.514 0 010 .634C16.1 16.598 13.675 18 11 18s-5.1-1.403-6.89-3.684a.514.514 0 010-.634C5.9 11.402 8.325 10 11 10zm.196 6.994a3.016 3.016 0 002.798-2.798 3.002 3.002 0 00-3.19-3.19 3.007 3.007 0 00-2.798 2.798 3.002 3.002 0 003.19 3.19zm-.068-.998a1.997 1.997 0 01-2.124-2.124 2.008 2.008 0 011.868-1.868 1.997 1.997 0 012.124 2.124 2.008 2.008 0 01-1.868 1.868z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

OrganizationProfileIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(OrganizationProfileIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'OrganizationProfileIcon';
export default ForwardRef;