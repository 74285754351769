import styled from '@emotion/styled/macro';
import { margin } from 'polished';
import { css } from '@emotion/core';

const variant = css({
  backgroundColor: 'rgb(50,50,50)',
  borderColor: 'rgba(0,0,0,0.8)',
  color: 'rgb(180,180,180)'
});

export const Accordion = styled.dl(
  {
    ...margin(0),
    borderStyle: 'solid',
    borderWidth: 0.5,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto'
  },
  variant
);

Accordion.defaultProps = {
  role: 'presentation'
};
