import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { Box } from '../Box';

export const MenuBar = styled(Box)(props => {
  const mq = themeGet('mediaQueries')(props);
  return {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: themeGet('zIndex.appBar')(props),
    [mq[2]]: {
      position: 'relative'
    }
  };
});

MenuBar.defaultProps = {
  as: 'nav'
};
