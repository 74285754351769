import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

const variant = css({
  backgroundColor: 'rgb(50,50,50)',
  outlineColor: 'transparent',

  '&:focus': {
    outlineColor: 'rgb(0,128,255)'
  }
});

export const TabsPanel = styled.div(
  {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    flexDirection: 'column',
    outlineStyle: 'solid',
    outlineWidth: 0.5,
    overflow: 'hidden',
    position: 'relative',
    '&[hidden]': {
      display: 'none'
    }
  },
  variant
);
