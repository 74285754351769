const isRequire = node =>
  node.callee.name === 'require' &&
  node.callee.type === 'Identifier' &&
  node.arguments &&
  node.arguments.length === 1 &&
  node.arguments[0].type === 'StringLiteral';

const registerDeps = (filename, sourceData) => ({
  visitor: {
    ImportDeclaration(path) {
      const node = path.node;
      const dep = node.source.value;
      const specifiers = node.specifiers || [];
      specifiers.forEach(spec => {
        switch (spec.type) {
          case 'ImportDefaultSpecifier':
            sourceData.push({
              dep,
              filename,
              import: 'default',
              type: spec.type
            });
            break;
          case 'ImportNamespaceSpecifier':
          case 'ImportSpecifier':
            sourceData.push({
              dep,
              filename,
              import: spec.local.name,
              type: spec.type
            });
            break;
          default:
            break;
        }
      });
    },
    CallExpression(path) {
      const node = path.node;
      if (isRequire(node)) {
        const value = node.arguments[0].value;
        sourceData.push({ dep: value, filename, require: true });
      }
    }
  }
});

export { registerDeps };
