import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var VerticalSplitIcon = function VerticalSplitIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 1.714h7.5c.06 0 .107.048.107.107v14.786c0 .06-.048.107-.107.107h-7.5A.107.107 0 010 16.607V1.821c0-.059.048-.107.107-.107zm10.286 0h7.5c.059 0 .107.048.107.107v14.786c0 .06-.048.107-.107.107h-7.5a.107.107 0 01-.107-.107V1.821c0-.059.048-.107.107-.107zM8.679 0h.642c.06 0 .108.048.108.107v17.786c0 .059-.048.107-.108.107H8.68a.107.107 0 01-.108-.107V.107C8.571.048 8.62 0 8.68 0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

VerticalSplitIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(VerticalSplitIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'VerticalSplitIcon';
export default ForwardRef;