import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { em, mix, margin, padding, transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';
import { Button } from '../Button';

const variant = props =>
  props.theme.mode === 'dark'
    ? {
        '&:hover, &:focus': {
          backgroundColor: mix(
            0.15,
            themeGet('colors.white')(props),
            themeGet('colors.background.default')(props)
          ),
          borderColor: 'transparent',
          color: themeGet('colors.action.active')(props)
        },

        '&:active, &[aria-selected="true"]': {
          backgroundColor: mix(
            0.15,
            themeGet('colors.black')(props),
            themeGet('colors.background.default')(props)
          ),
          borderColor: 'transparent',
          color: themeGet('colors.action.active')(props)
        },

        '&[aria-current="true"]': {
          backgroundColor: themeGet('colors.gray.0')(props),
          borderColor: 'transparent',
          color: themeGet('colors.action.active')(props)
        }
      }
    : {
        borderColor: transparentize(0.01, themeGet('colors.border')(props)),
        borderLeft: 'none',
        borderRight: 'none',
        '&:first-child, & + &': {
          borderTop: 'none'
        },
        '&:last-child': {
          borderBottom: 'none'
        },
        '&:hover, &:focus': {
          backgroundColor: themeGet('colors.blue.1')(props),
          borderColor: 'transparent',
          color: themeGet('colors.action.active')(props)
        },
        '&:active, &[aria-selected="true"]': {
          backgroundColor: themeGet('colors.blue.1')(props),
          borderColor: 'transparent',
          color: themeGet('colors.action.active')(props)
        },
        '&[aria-current="true"]': {
          backgroundColor: transparentize(
            0.2,
            themeGet('colors.blue.1')(props)
          ),
          borderColor: 'transparent',
          color: themeGet('colors.action.active')(props)
        }
      };

export const TileTabsListItem = styled(Button)(
  props => ({
    ...margin(0),
    ...padding(0),
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: themeGet('colors.background.default')(props),
    borderColor: 'transparent',
    color: themeGet('colors.action.default')(props),
    borderStyle: 'solid',
    borderWidth: 0.5,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    height: em(120 - 0.5 * 2),
    justifyContent: 'center',
    lineHeight: 18 / 16,
    textAlign: 'center',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
    borderRadius: '0',
    figure: {
      margin: 0,
      lineHeight: 18 / 16
    },
    svg: {
      ...margin(0, 'auto', em(12)),
      display: 'block'
    }
  }),
  variant
);

TileTabsListItem.defaultProps = {
  role: 'tab'
};

TileTabsListItem.propTypes = {
  'aria-controls': PropTypes.string.isRequired,
  'aria-selected': PropTypes.bool,
  id: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired
};
