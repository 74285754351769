import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { base } from '../Theme/modes/base';
import { useForkRef } from '../../utils/react';
import { createTransition } from './utils';

const defaultTimeout = {
  enter: base.duration.standard,
  exit: base.duration.standard
};

export const Slide = React.forwardRef(function Slide(props, ref) {
  const {
    children,
    in: inProp,
    direction,
    onEnter,
    onEntering,
    onExit,
    onExited,
    style,
    initialOffset,
    timeout = defaultTimeout,
    ...other
  } = props;
  const childrenRef = React.useRef(null);
  const handleRef = useForkRef(childrenRef, ref);

  const handleEnter = () => {
    const node = childrenRef.current;
    setTranslate(direction, node);

    if (onEnter) {
      onEnter(node);
    }
  };

  const handleEntering = () => {
    const node = childrenRef.current;
    const transition = createTransition(['transform'], {
      duration: timeout.enter,
      easing: timeout.easing || base.easing.easeOutQuart
    });
    const initialTransform = initialOffset
      ? getInitialTransform(direction, initialOffset)
      : 'none';

    node.style.webkitTransition = transition;
    node.style.transition = transition;

    node.style.webkitTransform = initialTransform;
    node.style.transform = initialTransform;

    if (onEntering) {
      onEntering(node);
    }
  };

  const handleExit = () => {
    const node = childrenRef.current;
    const transition = createTransition(['transform'], {
      duration: timeout.exit,
      easing: timeout.easing || base.easing.easeOutQuart
    });

    node.style.webkitTransition = transition;
    node.style.transition = transition;
    setTranslate(direction, node);

    if (onExit) {
      onExit(node);
    }
  };

  const handleExited = () => {
    const node = childrenRef.current;
    node.style.webkitTransition = '';
    node.style.transition = '';

    if (onExited) {
      onExited(node);
    }
  };

  const updatePosition = React.useCallback(() => {
    if (childrenRef.current) {
      setTranslate(direction, childrenRef.current);
    }
  }, [direction]);

  React.useEffect(() => {
    if (!inProp) {
      // update the position when hidden
      updatePosition();
    }
  }, [inProp, updatePosition]);

  return (
    <Transition
      appear
      in={inProp}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onExit={handleExit}
      onExited={handleExited}
      timeout={timeout}
      {...other}
    >
      {(state, childProps) => {
        return React.cloneElement(children, {
          style: {
            visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
            ...style,
            ...children.props.style,
            transform: getInitialTransform(direction, initialOffset)
          },
          ref: handleRef,
          ...childProps
        });
      }}
    </Transition>
  );
});

Slide.propTypes = {
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  // The initial offset after entering transition
  // This will affect the position of the children after it has finished sliding in
  initialOffset: PropTypes.number,
  direction: PropTypes.oneOf(['left', 'right', 'down', 'up']),
  timeout: PropTypes.shape({
    enter: PropTypes.number,
    exit: PropTypes.exit
  })
};

Slide.defaultProps = {
  direction: 'left'
};

function getTranslate(direction = 'left', node) {
  const rect = node.getBoundingClientRect();
  let transform;
  let offsetX = 0;
  let offsetY = 0;

  const computedStyle = window.getComputedStyle(node);
  transform =
    computedStyle.getPropertyValue('-webkit-transform') ||
    computedStyle.getPropertyValue('transform');

  if (transform && transform !== 'none' && typeof transform === 'string') {
    // Computed transform value is always a value of 'matrix(a, b, c, d, tx, ty)'
    const transformValues = transform
      .split('(')[1]
      .split(')')[0]
      .split(',');
    offsetX = parseInt(transformValues[4], 10);
    offsetY = parseInt(transformValues[5], 10);
  }
  if (direction === 'left') {
    return `translateX(${window.innerWidth}px) translateX(-${rect.left -
      offsetX}px)`;
  }
  if (direction === 'right') {
    return `translateX(-${rect.left + rect.width - offsetX}px)`;
  }
  if (direction === 'up') {
    return `translateY(${window.innerHeight}px) translateY(-${rect.top -
      offsetY}px)`;
  }
  return `translateY(-${rect.top + rect.height - offsetY}px)`;
}

function setTranslate(direction, node) {
  const translate = getTranslate(direction, node);
  if (!translate) return;
  node.style.webkitTransform = translate;
  node.style.transform = translate;
}

function getInitialTransform(direction = 'left', pushOffset) {
  if (!pushOffset) return 'none';
  if (direction === 'left' || direction === 'right') {
    return `translateX(${direction === 'left' ? -pushOffset : pushOffset}px)`;
  }
  if (direction === 'top' || direction === 'bottom') {
    return `translateY(${direction === 'top' ? -pushOffset : pushOffset}px)`;
  }
}
