import injector from '../../injector';
import { appendTerminalOutput } from '../terminal/commands';
import { nodes as nodeRegistry } from '../../registry';

import {
  getBabelTranspilerArguments,
  transpile,
  getTranspiledContext
} from './utils';

import color from 'ansi-colors';
import { join, dirname } from '../../utils';
import { getSelectedProjectId } from '../git/queries';

const { inject } = injector;

const build = inject(['fs'], async function({ path, output, verbose = false }) {
  const { pkg, main, babelConfig, files } = getBabelTranspilerArguments({
    dir: path
  });
  const fs = this.fs;
  const generated = await transpile({ pkg, babelConfig, main, files });
  if (output) {
    const outFile = output.endsWith('.js') ? output : output + '.js';
    if (verbose)
      appendTerminalOutput(color.bold(color.white(`${outFile} written.`)));
    fs.writeFileSync(outFile, generated.code);
  } else {
    if (verbose) appendTerminalOutput(color.blueBright(generated.code));
  }

  if (verbose) appendTerminalOutput(color.bold(color.green('success!')));
  return [];
});

build.args = [
  {
    _: true,
    name: 'path',
    type: 'path',
    required: true
  },
  {
    name: 'output',
    type: 'path',
    alias: 'o'
  },
  {
    name: 'verbose',
    type: 'path',
    alias: 'v',
    default: false
  }
];

const register = inject(['fs'], async function({
  path,
  output,
  verbose = false
}) {
  const projectId = getSelectedProjectId();

  const { pkg, main, babelConfig, files } = getBabelTranspilerArguments({
    dir: path
  });

  const fs = this.fs;
  const airpagerc = join(path, '.airpagerc');
  const airpageConfig = fs.existsSync(airpagerc)
    ? JSON.parse(fs.readFileSync(airpagerc).toString())
    : {};

  const context = await getTranspiledContext({
    pkg,
    babelConfig,
    main,
    files
  });

  const nodesFromLibraries = airpageConfig.libraries
    .reduce((m, v) => {
      const path =
        v.startsWith('/') || v.startsWith('.')
          ? join(dirname(airpagerc), v)
          : v;

      const items = context.require(path).default;
      return m.concat(items.nodes);
    }, [])
    .map(item => {
      try {
        const icon = context.require(item.meta.icon).default;
        item.meta.iconText = icon;
      } catch (e) {
      } finally {
        return item;
      }
    });

  const allNodes = airpageConfig.definitions.reduce((m, v) => {
    const path =
      v.startsWith('/') || v.startsWith('.') ? join(dirname(airpagerc), v) : v;

    const item = context.require(path).default;
    const icon =
      item.meta.icon.startsWith('/') || item.meta.icon.startsWith('.')
        ? join(dirname(context.require.resolve(path)), item.meta.icon)
        : item.meta.icon;

    item.meta.iconText = context.require(icon).default;
    return m.concat(item);
  }, nodesFromLibraries);

  allNodes.forEach(Node => {
    nodeRegistry.registerNode(projectId, Node);
  });

  nodeRegistry.save(projectId);

  return [];
});

register.args = [
  {
    _: true,
    name: 'path',
    type: 'path',
    required: true
  },
  {
    name: 'output',
    type: 'path',
    alias: 'o'
  },
  {
    name: 'verbose',
    type: 'path',
    alias: 'v',
    default: false
  }
];

export { build, register };
