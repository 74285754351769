import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CheckedIcon = function CheckedIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 9.21c0-.14.07-.35.208-.49l.969-.98a.664.664 0 01.97 0l3.876 4.2c.139.14.346.14.485 0l9.346-9.73a.664.664 0 01.97 0l.968.98c.277.28.277.7 0 .98L6.715 15.79a.625.625 0 01-.484.21.625.625 0 01-.485-.21L.208 9.7A.76.76 0 010 9.21z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CheckedIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CheckedIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CheckedIcon';
export default ForwardRef;