import gql from 'graphql-tag';
import { DashboardSettingsRoleProfileFragment } from '../fragments';

export const UpdateRoleProfileByRoleIdMutation = gql`
  mutation UpdateRoleProfileByRoleIdMutation(
    $roleId: UUID!
    $patch: RoleProfilePatch!
  ) {
    updateRoleProfileByRoleId(input: { roleId: $roleId, patch: $patch }) {
      roleProfile {
        ...DashboardSettingsRoleProfileFragment
      }
    }
  }
  ${DashboardSettingsRoleProfileFragment}
`;
