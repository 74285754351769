import styled from '@emotion/styled/macro';
import { Card } from '../Card';

export const Breadcrumb = styled(Card)({
  borderStyle: 'solid',
  borderWidth: 0.5,
  display: 'flex',
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto',
  font: 'inherit',
  lineHeight: 18 / 16
});

Breadcrumb.defaultProps = {
  as: 'nav'
};
