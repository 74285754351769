import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AdjustPropertiesIcon = function AdjustPropertiesIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2.25 0h10.646a.25.25 0 01.177.073l2.854 2.854a.25.25 0 01.073.177V17.75a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V.25A.25.25 0 012.25 0zM9 5.25v.5c0 .138.112.25.25.25h4.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-4.5a.25.25 0 00-.25.25zm-5 0v.5c0 .138.112.25.25.25h1.5A.25.25 0 006 5.75v-.5A.25.25 0 005.75 5h-1.5a.25.25 0 00-.25.25zm3-1v2.5c0 .138.112.25.25.25h.5A.25.25 0 008 6.75v-2.5A.25.25 0 007.75 4h-.5a.25.25 0 00-.25.25zm-3 5v.5c0 .138.112.25.25.25h5.5a.25.25 0 00.25-.25v-.5A.25.25 0 009.75 9h-5.5a.25.25 0 00-.25.25zm9 0v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25zm-2-1v2.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25zm-3 4v2.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25zm-4 1v.5c0 .138.112.25.25.25h2.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-2.5a.25.25 0 00-.25.25zm6 0v.5c0 .138.112.25.25.25h3.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AdjustPropertiesIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AdjustPropertiesIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AdjustPropertiesIcon';
export default ForwardRef;