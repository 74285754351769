import Anser from 'anser';
import React from 'react';
import PropTypes from 'prop-types';

const hasStyle = style =>
  style.backgroundColor ||
  style.color ||
  style.textDecoration ||
  style.visibility ||
  style.filter ||
  style.opacity ||
  style.fontWeight;

export const AnsiText = ({ children }) =>
  Anser.ansiToJson(children).reduce((m, obj, key) => {
    if (obj.content === '') {
      return m;
    }
    let style = {
      color: obj.fg ? `rgb(${obj.fg})` : null,
      backgroundColor: obj.bg ? `rgb(${obj.bg})` : null
    };
    switch (obj.decoration) {
      case 'bold':
        style.fontWeight = 'bold';
        break;
      case 'dim':
        style.opacity = 0.5;
        break;
      case 'italic':
        style.fontStyle = 'italic';
        break;
      case 'reverse':
        style.filter = 'invert(100%)';
        break;
      case 'hidden':
        style.visibility = 'hidden';
        break;
      case 'strikethrough':
        style.textDecoration = 'line-through';
        break;
      default:
        style.textDecoration = obj.decoration;
        break;
    }
    return hasStyle(style)
      ? m.concat(React.createElement('span', { key, style }, obj.content))
      : m.concat(obj.content);
  }, []);

AnsiText.propTypes = {
  children: PropTypes.string
};
