import gql from 'graphql-tag';
import { DashboardSettingsRoleProfileFragment } from '../fragments';

export const RoleProfileByRoleIdQuery = gql`
  query RoleProfileByRoleIdQuery($roleId: UUID!) {
    roleProfileByRoleId(roleId: $roleId) {
      ...DashboardSettingsRoleProfileFragment
    }
  }
  ${DashboardSettingsRoleProfileFragment}
`;
