import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { space, layout, position } from 'styled-system';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';

import { clamp } from '../../../utils/math';
import { thumbSize } from '../../../ui/ControlThumb';
import { useColorPicker } from '../ColorPickerContext';

import { GradientBand } from './GradientBand';
import { GradientStops } from './GradientStops';

const GradientWrapper = styled.div(props => ({
  borderRadius: themeGet('radii.1')(props),
  border: `${themeGet('borders.1')(props)} ${transparentize(
    0.5,
    themeGet('colors.black')(props)
  )}`,
  height: themeGet('space.3')(props),
  position: 'relative',
  [`${GradientStops}`]: {
    position: 'absolute',
    top: 0
  }
}));

export const GradientPicker = styled(({ minStops, maxStops, className }) => {
  const [picker, dispatch] = useColorPicker();
  const [gradientRect, setGradientRect] = useState({ width: 0, left: 0 });
  const gradientEl = useRef();

  useEffect(() => {
    setGradientRect(
      gradientEl.current
        ? gradientEl.current.getBoundingClientRect()
        : { width: 0, left: 0 }
    );
  }, []);

  const stops = picker.gradient.map(s => ({
    ...s,
    left: clamp(
      gradientRect.width * s.offset,
      0,
      gradientRect.width - thumbSize
    ),
    active: picker.activeStop ? picker.activeStop.id === s.id : false
  }));

  return (
    <React.Fragment>
      <GradientWrapper ref={gradientEl} className={className}>
        <GradientBand
          stops={stops}
          dispatch={dispatch}
          gradientRect={gradientRect}
        />
        <GradientStops
          stops={stops}
          minStops={minStops}
          maxStops={maxStops}
          gradientRect={gradientRect}
          dispatch={dispatch}
        />
      </GradientWrapper>
    </React.Fragment>
  );
})(space, layout, position);

GradientPicker.propTypes = {
  stops: PropTypes.arrayOf(
    PropTypes.shape({
      offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      color: PropTypes.string
    })
  ),
  // Min color stops
  minStops: PropTypes.number,
  // Max color stops
  maxStops: PropTypes.number,
  onChange: PropTypes.func,
  children: PropTypes.func
};

GradientPicker.defaultProps = {
  stops: [],
  onChange: () => {},
  children: () => null
};
