import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { slugify } from '../utils/string';
import { Field, Tooltip, Input, Label } from '../ui';
import { Popup } from './Popup';

const ProjectCardHeaderForm = styled.form({ flex: '1 0 auto' });

export const CreateProjectForm = props => {
  const { onChange, onSubmit } = props;
  const [name, setName] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(event);
  };
  useEffect(() => {
    typeof onChange === 'function' && onChange({ name });
  }, [name, onChange]);

  return (
    <ProjectCardHeaderForm onSubmit={handleSubmit}>
      <Popup
        placement="bottom-end"
        popup={
          <Tooltip>
            Your new project will be created as&nbsp;
            <strong>{slugify(name)}</strong>
          </Tooltip>
        }
      >
        {({ expanded, setExpanded }) => (
          <Field fluid={true}>
            <Label fluid={true} htmlFor="project-name" id="project-name-label">
              Project name
            </Label>
            <Input
              id="project-name"
              aria-label="Project name"
              autoFocus
              fluid={true}
              name="name"
              placeholder="Project name"
              type="text"
              value={name}
              onChange={event => {
                setName(event.target.value);
                if (
                  !expanded &&
                  event.target.value !== slugify(event.target.value)
                ) {
                  setExpanded(true);
                } else if (
                  expanded &&
                  event.target.value === slugify(event.target.value)
                ) {
                  setExpanded(false);
                }
              }}
            />
          </Field>
        )}
      </Popup>
    </ProjectCardHeaderForm>
  );
};

CreateProjectForm.propTypes = {
  createProject: PropTypes.func
};
