import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var MediaIcon = function MediaIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.384 4.033l7.51 4.756a.25.25 0 010 .422l-7.51 4.755a.25.25 0 01-.384-.21V4.244a.25.25 0 01.384-.212zM18 12.25v1.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zm0-4v1.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zm0-4v1.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zm-15 8v1.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25v-1.5A.25.25 0 01.25 12h2.5a.25.25 0 01.25.25zm0-4v1.5a.25.25 0 01-.25.25H.25A.25.25 0 010 9.75v-1.5A.25.25 0 01.25 8h2.5a.25.25 0 01.25.25zm0-4v1.5a.25.25 0 01-.25.25H.25A.25.25 0 010 5.75v-1.5A.25.25 0 01.25 4h2.5a.25.25 0 01.25.25zm15 12v1.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25v-1.5A.25.25 0 01.25 16h17.5a.25.25 0 01.25.25zm0-16v1.5a.25.25 0 01-.25.25H.25A.25.25 0 010 1.75V.25A.25.25 0 01.25 0h17.5a.25.25 0 01.25.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

MediaIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(MediaIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'MediaIcon';
export default ForwardRef;