import injector from '../../injector';

import {
  PROCESSES_DIRECTORY_PATH,
  PROCESSES_ACTIVE_PROCESS_FILENAME,
  PROCESSES_FOLLOWER_EXTENSIONS
} from './constants';

import { readState } from '../core/queries';
import { join } from '../../utils';
const { inject } = injector;

const getPinnedProcessPath = pid => {
  return pid === PROCESSES_ACTIVE_PROCESS_FILENAME
    ? join(PROCESSES_DIRECTORY_PATH, getActiveProcessId())
    : join(
        PROCESSES_DIRECTORY_PATH,
        pid.split(PROCESSES_FOLLOWER_EXTENSIONS)[0]
      );
};

const getProcessPath = pid =>
  isPinnedProcess(pid)
    ? getPinnedProcessPath(pid)
    : join(PROCESSES_DIRECTORY_PATH, pid);

const getActiveProcessPath = inject(['path'], function getActiveProcessPath() {
  return this.path.join(
    PROCESSES_DIRECTORY_PATH,
    PROCESSES_ACTIVE_PROCESS_FILENAME
  );
});

const isPinnedProcess = pid =>
  pid === PROCESSES_ACTIVE_PROCESS_FILENAME ||
  pid.endsWith(PROCESSES_FOLLOWER_EXTENSIONS);

const getPinnedProcess = pid => {
  return pid === PROCESSES_ACTIVE_PROCESS_FILENAME
    ? getActiveProcess()
    : readState(
        join(
          PROCESSES_DIRECTORY_PATH,
          pid.split(PROCESSES_FOLLOWER_EXTENSIONS)[0]
        ),
        {
          cwd: '/'
        }
      );
};

const getProcess = pid =>
  isPinnedProcess(pid)
    ? getPinnedProcess(pid)
    : readState(getProcessPath(pid), {
        cwd: '/'
      });

const getActiveProcessId = inject(['fs'], function getActiveProcessId() {
  const { fs } = this;
  const activeProcessPath = getActiveProcessPath();

  if (!fs.existsSync(activeProcessPath)) {
    return 'root';
  }

  return fs.readFileSync(activeProcessPath).toString();
});

const getActiveProcess = () => {
  const pid = getActiveProcessId();
  return getProcess(pid);
};

export {
  isPinnedProcess,
  getActiveProcess,
  getActiveProcessId,
  getActiveProcessPath,
  getProcess,
  getProcessPath
};
