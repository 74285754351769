import deepmerge from 'deepmerge';
import { base } from './base';
import createPalette from './createPalette';
import createTypography from './createTypography';
import createButton from './createButton';

export function createTheme(mode = 'dark') {
  return {
    mode,
    ...deepmerge(base, {
      ...createPalette(mode),
      ...createTypography(mode),
      ...createButton(mode)
    })
  };
}
