import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ThemesIcon = function ThemesIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.281 0H11.72c.155 0 .281.126.281.281V2H0V.281C0 .126.126 0 .281 0zM0 9h6v.719a.281.281 0 01-.281.281H.28A.281.281 0 010 9.719V9zm0-7h1v7H0V2zm11 0h1v3h-1V2zm1 4c3.333 0 6 2.4 6 5.333a3.301 3.301 0 01-3.333 3.334h-1.2c-.534 0-1 .466-1 1 0 .266.133.466.266.666.2.2.267.4.267.667 0 .533-.467 1-1 1-3.333 0-6-2.667-6-6s2.667-6 6-6zm-3.667 6c.534 0 1-.467 1-1 0-.533-.466-1-1-1-.533 0-1 .467-1 1 0 .533.467 1 1 1zm2-2.667c.534 0 1-.466 1-1 0-.533-.466-1-1-1-.533 0-1 .467-1 1 0 .534.467 1 1 1zm3.334 0c.533 0 1-.466 1-1 0-.533-.467-1-1-1-.534 0-1 .467-1 1 0 .534.466 1 1 1zm2 2.667c.533 0 1-.467 1-1 0-.533-.467-1-1-1-.534 0-1 .467-1 1 0 .533.466 1 1 1z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ThemesIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ThemesIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ThemesIcon';
export default ForwardRef;