const deps = {};

export default {
  register(key, value) {
    deps[key] = value;
  },
  inject(dependencies, func, scope = {}) {
    return function(...args) {
      for (const dependency of dependencies) {
        if (deps[dependency]) {
          scope[dependency] = deps[dependency];
        } else {
          throw new Error(`Cannot find dependency '${dependency}'`);
        }
      }
      return func.apply(scope, args);
    };
  }
};
