import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Port } from '../Port';
import { em, padding } from 'polished';

const variant = props => {
  switch (props.variant) {
    case 'info':
      return {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderColor: 'rgba(0,0,0,0.8)',
        color: 'rgb(25,68,128)'
      };
    case 'success':
      return {
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderColor: 'rgba(0,0,0,0.8)',
        color: 'rgb(76,255,243)'
      };
    default:
      return null;
  }
};

export const OutlinedBadge = styled.div(
  {
    ...padding(em(3 - 0.5, 10), em(6 - 0.5, 10)),
    borderRadius: em(3, 10),
    borderStyle: 'solid',
    borderWidth: 0.5,
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: em(10),
    fontWeight: 500,
    lineHeight: 12 / 10,
    [`${Port}`]: {
      marginLeft: em(6)
    }
  },
  variant
);

OutlinedBadge.propTypes = {
  variant: PropTypes.oneOf(['info', 'success']).isRequired
};
