import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { em } from 'polished';
import { space, layout, typography, color } from 'styled-system';

export const Label = styled.label(
  props => ({
    display: props.fluid ? 'block' : 'inline-block',
    fontSize: em(10),
    fontWeight: 500,
    lineHeight: 18 / 10,
    verticalAlign: props.fluid ? undefined : 'middle',
    width: props.fluid ? '100%' : undefined,
    color: 'rgb(180, 180, 180)',
    '&[for]': {
      cursor: 'pointer'
    }
  }),
  space,
  layout,
  typography,
  color
);

Label.propTypes = {
  fluid: PropTypes.bool
};
