import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var StyleRuleIcon = function StyleRuleIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2.104 6.757c0 .733-.105 1.317-.601 1.736.496.418.6 1.017.6 1.735v1.915c0 .778.392 1.45 1.323 1.496V15C1.728 15 .691 13.878.691 12.008V9.884c0-.539-.225-1.032-.691-1.347v-.09c.466-.314.691-.807.691-1.33V4.991C.691 3.122 1.728 2 3.426 2v1.361c-.931.045-1.322.718-1.322 1.496v1.9zm15.205.36c0 .523.225 1.016.691 1.33v.09c-.466.315-.691.808-.691 1.347v2.124c0 1.87-1.037 2.992-2.735 2.992v-1.361c.931-.045 1.322-.718 1.322-1.496v-1.915c0-.718.105-1.317.601-1.735-.496-.42-.6-1.003-.6-1.736v-1.9c0-.778-.392-1.45-1.323-1.496V2c1.698 0 2.735 1.122 2.735 2.992v2.124zM6.964 9.631L5.326 7.967a.295.295 0 01-.07-.293.278.278 0 01.222-.195l2.264-.343 1.012-2.14A.274.274 0 019 4.838c.104 0 .2.062.246.16l1.013 2.139 2.263.343c.103.015.19.091.222.195a.296.296 0 01-.07.293l-1.638 1.665.387 2.35a.293.293 0 01-.11.28.268.268 0 01-.288.022L9 11.174l-2.025 1.11a.266.266 0 01-.289-.021.294.294 0 01-.11-.28l.388-2.351z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

StyleRuleIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(StyleRuleIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'StyleRuleIcon';
export default ForwardRef;