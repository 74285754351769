import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AddPageIcon = function AddPageIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M2 .125C2 .055 2.06 0 2.124 0H13l3 3v14.87c0 .072-.056.13-.121.13H2.121A.12.12 0 012 17.875V.125zM10 8V5.126A.122.122 0 009.875 5h-1.75A.126.126 0 008 5.126V8H5.126A.122.122 0 005 8.125v1.75c0 .067.056.125.126.125H8v2.874c0 .075.056.126.125.126h1.75a.126.126 0 00.125-.126V10h2.874A.122.122 0 0013 9.875v-1.75A.126.126 0 0012.874 8H10z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AddPageIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AddPageIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AddPageIcon';
export default ForwardRef;