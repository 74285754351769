import injector from '../../injector';

import {
  getCurrentWorkingDirectory,
  getTerminalHistory,
  getTerminalOutput,
  readDirectory,
  readTree,
  readState,
  getActiveProcessId,
  serializeNode
} from '../../queries';

import { writeState } from '../core/commands';

import {
  TERMINAL_STDIN_FILENAME,
  TERMINAL_DATA_FILENAME,
  TERMINAL_DIRECTORY_PATH,
  TERMINAL_HISTORY_FILENAME,
  TERMINAL_STDOUT_FILENAME
} from './constants';
import { findSync } from '../../utils';

const { inject } = injector;

const readStdin = inject(['fs', 'path'], function readStdin(...args) {
  const state = readState(
    this.path.join(
      TERMINAL_DIRECTORY_PATH,
      getActiveProcessId(),
      TERMINAL_DATA_FILENAME
    ),
    {}
  );
  writeState(
    this.path.join(
      TERMINAL_DIRECTORY_PATH,
      getActiveProcessId(),
      TERMINAL_DATA_FILENAME
    ),
    {
      ...state,
      read: true,
      current: 0,
      questions: args.map(name => ({ name }))
    }
  );
});

const readIntoBuffer = inject(['path'], function(string) {
  appendTerminalOutput(string);

  const { current, read, questions, cb, ...state } = readState(
    this.path.join(
      TERMINAL_DIRECTORY_PATH,
      getActiveProcessId(),
      TERMINAL_DATA_FILENAME
    ),
    {}
  );

  appendTerminalOutput(`${questions[current].name}: `);

  const buffer = {
    ...readState(
      this.path.join(
        TERMINAL_DIRECTORY_PATH,
        getActiveProcessId(),
        TERMINAL_STDIN_FILENAME
      ),
      {}
    ),
    [questions[current].name]: string
  };

  const next = current + 1;
  if (next === questions.length) {
    // look for callback!/
    if (cb) {
      // call with new minimist'd args!
    } else {
      appendTerminalOutput(JSON.stringify(buffer));
    }
    writeState(
      this.path.join(
        TERMINAL_DIRECTORY_PATH,
        getActiveProcessId(),
        TERMINAL_DATA_FILENAME
      ),
      {
        ...state
      }
    );
    writeState(
      this.path.join(
        TERMINAL_DIRECTORY_PATH,
        getActiveProcessId(),
        TERMINAL_STDIN_FILENAME
      ),
      {}
    );
  } else {
    writeState(
      this.path.join(
        TERMINAL_DIRECTORY_PATH,
        getActiveProcessId(),
        TERMINAL_STDIN_FILENAME
      ),
      buffer
    );
    writeState(
      this.path.join(
        TERMINAL_DIRECTORY_PATH,
        getActiveProcessId(),
        TERMINAL_DATA_FILENAME
      ),
      {
        ...state,
        read,
        questions,
        cb,
        current: next
      }
    );
  }
});

const printFind = ({ dir, name, type }) => {
  const files = findSync({ dir, name, type });
  return appendTerminalOutput(files.join('\n'));
};

const printFile = inject(['fs'], function printFile({ path }) {
  const value = this.fs.readFileSync(path).toString();
  appendTerminalOutput(value);
});

const printNodeInfo = inject(['fs'], function({ path }) {
  const info = serializeNode({ root: path });
  appendTerminalOutput(JSON.stringify(info, null, 2));
});

const appendTerminalOutput = inject(['path'], function(string) {
  const output = getTerminalOutput();
  const pid = getActiveProcessId();
  const outputPath = this.path.join(
    TERMINAL_DIRECTORY_PATH,
    pid,
    TERMINAL_STDOUT_FILENAME
  );
  writeState(outputPath, output.concat([string]));

  const changes = [outputPath];

  if (pid !== 'root') {
    const rootOutputPath = this.path.join(
      TERMINAL_DIRECTORY_PATH,
      'root',
      TERMINAL_STDOUT_FILENAME
    );
    writeState(rootOutputPath, output.concat([string]));
    return changes.concat(rootOutputPath);
  }

  return changes;
});

const appendTerminalHistory = inject(['path'], function(string) {
  const history = getTerminalHistory();
  const pid = getActiveProcessId();
  const historyPath = this.path.join(
    TERMINAL_DIRECTORY_PATH,
    pid,
    TERMINAL_HISTORY_FILENAME
  );
  const changes = [historyPath];

  writeState(historyPath, history.concat([string]));

  if (pid !== 'root') {
    const rootHistoryPath = this.path.join(
      TERMINAL_DIRECTORY_PATH,
      'root',
      TERMINAL_HISTORY_FILENAME
    );
    writeState(rootHistoryPath, history.concat([string]));
    return changes.concat(rootHistoryPath);
  }

  return changes;
});

const clearTerminalOutput = inject(['path'], function() {
  writeState(
    this.path.join(
      TERMINAL_DIRECTORY_PATH,
      getActiveProcessId(),
      TERMINAL_STDOUT_FILENAME
    ),
    []
  );
});

const printWorkingDirectory = () => {
  appendTerminalOutput(getCurrentWorkingDirectory());
};

const printTree = ({ path = getCurrentWorkingDirectory(), hidden } = {}) => {
  const cwd = getCurrentWorkingDirectory();
  const printEntry = entry => {
    if (cwd === '/') return entry;
    const parts = entry.split(cwd);
    if (entry.includes(cwd)) return parts[1];
    return entry;
  };
  appendTerminalOutput(
    readTree({ path, hidden })
      .map(printEntry)
      .join('\n')
  );
};

const printDirectory = ({ path = getCurrentWorkingDirectory() } = {}) => {
  try {
    const children = readDirectory(path, true, true);
    appendTerminalOutput(children.join(' '));
  } catch (e) {
    appendTerminalOutput(`${path} not a directory.`);
  }
};

export {
  appendTerminalHistory,
  appendTerminalOutput,
  clearTerminalOutput,
  printDirectory,
  printNodeInfo,
  printTree,
  printWorkingDirectory,
  printFile,
  printFind,
  readIntoBuffer,
  readStdin
};
