import styled from '@emotion/styled/macro';
import { em } from 'polished';
import { Box } from '../Box';

export const ListItemLinkTitle = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto',
  lineHeight: 18 / 16
});

ListItemLinkTitle.defaultProps = {
  as: 'span'
};

export const ListItemLinkDescription = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto',
  lineHeight: 18 / 16,
  fontSize: em(12)
});

ListItemLinkDescription.defaultProps = {
  as: 'span'
};
