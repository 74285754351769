import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CloseIcon = function CloseIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 6.967L15.862.105a.36.36 0 01.508 0l1.525 1.525a.36.36 0 010 .508L11.033 9l6.862 6.862a.36.36 0 010 .508l-1.525 1.525a.36.36 0 01-.508 0L9 11.033l-6.862 6.862a.36.36 0 01-.508 0L.105 16.37a.36.36 0 010-.508L6.967 9 .105 2.138a.36.36 0 010-.508L1.63.105a.36.36 0 01.508 0L9 6.967z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CloseIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CloseIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CloseIcon';
export default ForwardRef;