import gql from 'graphql-tag';
import { ProjectFeedProjectFragment } from '../fragments';

export const CreateProjectMutation = gql`
  mutation CreateProjectMutation($name: String!) {
    createProject(input: { project: { name: $name } }) {
      project {
        ...ProjectFeedProjectFragment
      }
    }
  }
  ${ProjectFeedProjectFragment}
`;
