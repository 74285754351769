import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CommentIcon = function CommentIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 .104C0 .046.052 0 .106 0h17.788c.059 0 .106.049.106.104v14.79a.107.107 0 01-.11.104h-1.176v3l-3.857-3H.111c-.061 0-.111-.048-.111-.103V.104zm7.714 6.435v2.35c0 .066.05.11.11.11h2.351c.066 0 .11-.049.11-.11V6.54c0-.066-.049-.11-.11-.11h-2.35c-.066 0-.11.049-.11.11zm5.143 0v2.35c0 .066.05.11.11.11h2.351c.066 0 .11-.049.11-.11V6.54c0-.066-.049-.11-.11-.11h-2.35c-.066 0-.11.049-.11.11zm-10.286 0v2.35c0 .066.05.11.11.11h2.351c.066 0 .11-.049.11-.11v-2.35c0-.066-.049-.11-.11-.11h-2.35c-.066 0-.11.049-.11.11z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CommentIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CommentIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CommentIcon';
export default ForwardRef;