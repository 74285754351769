import styled from '@emotion/styled/macro';
import { ListItem } from '../List';

export const MenuItem = styled(ListItem)({
  '&:not(:first-of-type)': {
    marginTop: 0
  }
});

MenuItem.defaultProps = {
  role: 'none'
};
