import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var TagIcon = function TagIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.086 7.937l-.084.085L0 .002 8.023 0 7.94.085a.119.119 0 01.171.002l9.854 9.854a.12.12 0 01.002.17l-7.852 7.853a.119.119 0 01-.172-.002L.088 8.108a.12.12 0 01-.002-.17zm3.592-4.26A.945.945 0 102.341 2.34a.945.945 0 001.337 1.337z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

TagIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(TagIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'TagIcon';
export default ForwardRef;