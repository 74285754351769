import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var RadioButtonIcon = function RadioButtonIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM9 18A9 9 0 119 0a9 9 0 010 18zm0-3A6 6 0 109 3a6 6 0 000 12z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

RadioButtonIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(RadioButtonIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'RadioButtonIcon';
export default ForwardRef;