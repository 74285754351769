import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var MarginsIcon = function MarginsIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M15 3H3v12h12V3zm3-3v18H0V0h18zM5 13h8V5H5v8zm-1 1V4h10v10H4z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

MarginsIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(MarginsIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'MarginsIcon';
export default ForwardRef;