import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var HorizontalSplitIcon = function HorizontalSplitIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M1.393 0h15.214c.06 0 .107.048.107.107v7.5c0 .06-.048.107-.107.107H1.393a.107.107 0 01-.107-.107v-7.5c0-.059.048-.107.107-.107zM.107 8.571h17.786c.059 0 .107.048.107.108v.642c0 .06-.048.108-.107.108H.107A.107.107 0 010 9.32V8.68c0-.06.048-.108.107-.108zm1.286 1.715h15.214c.06 0 .107.048.107.107v7.5c0 .059-.048.107-.107.107H1.393a.107.107 0 01-.107-.107v-7.5c0-.06.048-.107.107-.107z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

HorizontalSplitIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(HorizontalSplitIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'HorizontalSplitIcon';
export default ForwardRef;