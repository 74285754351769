import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Fade,
  ModalContainer,
  ModalHeader,
  ModalFooter,
  ModalContent
} from '../../ui';
import { Modal } from '../Modal';

export const Dialog = ({
  id,
  open: openProp,
  title,
  onClose,
  children,
  footer,
  backdrop,
  disableBackdropClick,
  disableScrollLock,
  keyboard,
  container,
  renderTarget,
  closeButton,
  ...rest
}) => {
  const [openState, setOpenState] = React.useState(false);
  const isControlled = typeof openProp !== 'undefined';
  const open = isControlled ? openProp : openState;

  const handleClose = event => {
    if (!isControlled) setOpenState(false);
    if (onClose) onClose(event);
  };

  const handleOpen = () => {
    setOpenState(true);
  };

  const footerMarkup =
    typeof footer === 'function' ? footer(handleClose) : footer;

  return (
    <React.Fragment>
      {!isControlled && renderTarget(handleOpen)}
      <Modal
        id={id}
        open={open}
        onClose={handleClose}
        backdrop={backdrop}
        disableBackdropClick={disableBackdropClick}
        disableScrollLock={disableScrollLock}
        keyboard={keyboard}
        container={container}
        {...rest}
      >
        <Fade in={open}>
          <ModalContainer>
            <ModalContent padding="5">
              {title && (
                <ModalHeader
                  title={title}
                  onClose={handleClose}
                  id={id}
                  closeButton={closeButton}
                />
              )}
              <Box py="5" px="0">
                {typeof children === 'function'
                  ? children(handleClose)
                  : children}
              </Box>
              {footer && <ModalFooter>{footerMarkup}</ModalFooter>}
            </ModalContent>
          </ModalContainer>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

Dialog.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  backdrop: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableScrollLock: PropTypes.bool,
  keyboard: PropTypes.bool,
  container: PropTypes.any,
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.node,
  renderTarget: PropTypes.func
};

Dialog.defaultProps = {
  onClose: () => {},
  renderTarget: () => null
};
