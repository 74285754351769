import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var HideIcon = function HideIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.857 9c0 3.568 2.815 6.727 6 7.22V1.78c-3.185.493-6 3.652-6 7.22zM7.714.857v16.286C3.857 17.143 0 13.497 0 9 0 4.503 3.857.857 7.714.857zm2.572 16.286V.857C14.143.857 18 4.503 18 9c0 4.497-3.857 8.143-7.714 8.143zM8.57 0h.858v18H8.57V0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

HideIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(HideIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'HideIcon';
export default ForwardRef;