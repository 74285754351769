import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var DomainIcon = function DomainIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M18 3.25v14.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25V3.25A.25.25 0 01.25 3h17.5a.25.25 0 01.25.25zM9 4c-3.308 0-6 2.692-6 6s2.692 6 6 6c3.309 0 6-2.692 6-6s-2.691-6-6-6zm2.626 10.786l-.358-.104-.48-.075-.87.187-.241.15h-.44l-.27.01-.29-.122-.181-.225-.214-.056.034.215-.16.234-.54-.262-.172.109C5.429 14.096 4 12.254 4 10.104c0-.296.029-.585.08-.868l.473.148.32.187.1.197.18.336.32.14-.7.282-.02.88.088.393.373.206.22.3.58.973.25.636.51.468.441.169.12-.187-.18-.356.034-.618.106-.374.64-.281-.18-.43s.26-.469.24-.581c-.02-.113.361-.281.361-.281l.186-.079.134-.314-.065-.236-.235-.064-.58-.646-.52-.336-1.342-.085-.26.225-.28.019.04-.244-.04-.168.12-.262.187-.111-.107-.152h-.4s-.2-.3-.34-.449c-.14-.15.58-1.086.58-1.086l.72-.018.11.374.19.187.28.094-.18-.45v-.28l.231-.337.25-.394v-.337l.24-.112.44-.093.072-.214s-.096-.199-.051-.236c.045-.037.062-.3.245-.43a.202.202 0 00.03-.026c.187-.048.377-.087.571-.117l-.106.18v.262h.36l.18-.206.44-.13L9.447 5h.01c.332 0 .656.03.971.083l-.22.074.376.138.417.079.218.134.04.375.5.3.409.065-.168.215-.34.15-.2.187.06.243.517.168.263-.093.352-.112.188-.262.28.169-.1.262.55.075.431.262v.112l-.43-.374-.69-.038-.641.019-.42.206-.42.187-.441.337-.18.468.18.28.116.312-.236.253.2.353.56.262.42.318.633.075.408.075.36-.075.18.281.02.262-.14.45.13.187-.45.561v.375l-.192.592-.228.307.253.28.338-.003.128-.109.093.008a5.477 5.477 0 01-1.925 1.313zM18 .25v1.5a.25.25 0 01-.25.25H.25A.25.25 0 010 1.75V.25A.25.25 0 01.25 0h17.5a.25.25 0 01.25.25zM1.5 1.5a.5.5 0 100-1 .5.5 0 000 1zm2 0a.5.5 0 100-1 .5.5 0 000 1zm2 0a.5.5 0 100-1 .5.5 0 000 1z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

DomainIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(DomainIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'DomainIcon';
export default ForwardRef;