import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SearchIcon = function SearchIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.564 10.358a6.955 6.955 0 01-.937-3.502C4.627 3.069 7.62 0 11.313 0S18 3.07 18 6.856s-2.994 6.856-6.687 6.856a6.548 6.548 0 01-3.95-1.324l-5.439 5.577a.11.11 0 01-.161-.001L.033 16.19a.12.12 0 010-.165l5.513-5.653a.123.123 0 01.018-.015zm6.34-9.052a5.66 5.66 0 00-5.66 5.66h.87a4.79 4.79 0 014.79-4.789v-.87z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SearchIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SearchIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SearchIcon';
export default ForwardRef;