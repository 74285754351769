import color from 'ansi-colors';
import injector from '../../injector';

import {
  getActiveProcessId,
  readState,
  getCurrentWorkingDirectory
} from '../../queries';

import {
  TERMINAL_STDIN_FILENAME,
  TERMINAL_DATA_FILENAME,
  TERMINAL_DIRECTORY_PATH,
  TERMINAL_HISTORY_FILENAME,
  TERMINAL_STDOUT_FILENAME,
  TERMINAL_PROMPT_FILENAME
} from './constants';
const { inject } = injector;
const getTerminalBufferPath = inject(['path'], function getTerminalBufferPath(
  pid
) {
  return this.path.join(TERMINAL_DIRECTORY_PATH, pid, TERMINAL_STDIN_FILENAME);
});

const getTerminalDataPath = inject(['path'], function getTerminalDataPath(pid) {
  return this.path.join(TERMINAL_DIRECTORY_PATH, pid, TERMINAL_DATA_FILENAME);
});

const getTerminalHistoryPath = inject(['path'], function getTerminalHistoryPath(
  pid
) {
  return this.path.join(
    TERMINAL_DIRECTORY_PATH,
    pid,
    TERMINAL_HISTORY_FILENAME
  );
});

const getTerminalOutputPath = inject(['path'], function getTerminalOutputPath(
  pid
) {
  return this.path.join(TERMINAL_DIRECTORY_PATH, pid, TERMINAL_STDOUT_FILENAME);
});

const getTerminalPromptPath = inject(['path'], function getTerminalPromptPath(
  pid
) {
  return this.path.join(TERMINAL_DIRECTORY_PATH, pid, TERMINAL_PROMPT_FILENAME);
});

const getTerminalBuffer = (pid = getActiveProcessId()) =>
  readState(getTerminalBufferPath(pid), {});

const getTerminalData = (pid = getActiveProcessId()) =>
  readState(getTerminalDataPath(pid), {});

const getTerminalHistory = (pid = getActiveProcessId()) =>
  readState(getTerminalHistoryPath(pid), []);

const getTerminalOutput = (pid = getActiveProcessId()) =>
  readState(getTerminalOutputPath(pid), []);

const getTerminalPrompt = (pid = getActiveProcessId()) => {
  const userInfo = readState(getTerminalPromptPath(pid), {
    username: 'hacker@airpage'
  });
  return `${color.blueBright(userInfo.username)} ${color.white(
    'in'
  )} ${color.greenBright(getCurrentWorkingDirectory())}`;
};

export {
  getTerminalBuffer,
  getTerminalBufferPath,
  getTerminalData,
  getTerminalDataPath,
  getTerminalHistory,
  getTerminalHistoryPath,
  getTerminalOutput,
  getTerminalOutputPath,
  getTerminalPrompt,
  getTerminalPromptPath
};
