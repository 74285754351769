import React from 'react';
import {
  AuthContainer,
  AuthContainerWrapper,
  Icon,
  VisuallyHidden
} from '../ui';
import { Footer, ExternalLink, Link } from '../imported';

import styled from '@emotion/styled/macro';
const FlexTop = styled.div({
  flex: '1 0 auto',
  maxWidth: '360px',
  textAlign: 'center',
  margin: 'auto'
});
const FlexBottom = styled.div({
  flexShrink: '0'
});

export const SimpleWithFooter = props => {
  return (
    <AuthContainer style={{ display: 'flex', flexDirection: 'column' }}>
      <AuthContainerWrapper>
        <VisuallyHidden as="h1">AirPage</VisuallyHidden>
        {props.linkLocal ? (
          <Link href={'/'}>
            <Icon name="airpage-logo-text" title="AirPage" size={120} />
          </Link>
        ) : (
          <ExternalLink href={process.env.REACT_APP_WEBSITE_URL}>
            <Icon name="airpage-logo-text" title="AirPage" size={120} />
          </ExternalLink>
        )}
      </AuthContainerWrapper>
      <FlexTop>{props.children}</FlexTop>
      <FlexBottom>
        <Footer />
      </FlexBottom>
    </AuthContainer>
  );
};
