import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ArrayIcon = function ArrayIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M18 2.281V15.72a.281.281 0 01-.281.281H.28A.281.281 0 010 15.719V2.28C0 2.126.126 2 .281 2H17.72c.155 0 .281.126.281.281zM2.25 14h3.5a.25.25 0 00.25-.25v-9.5A.25.25 0 005.75 4h-3.5a.25.25 0 00-.25.25v9.5c0 .138.112.25.25.25zm5 0h3.5a.25.25 0 00.25-.25v-9.5a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25v9.5c0 .138.112.25.25.25zm5 0h3.5a.25.25 0 00.25-.25v-9.5a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25v9.5c0 .138.112.25.25.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ArrayIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ArrayIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ArrayIcon';
export default ForwardRef;