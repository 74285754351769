import React, { ReactElement } from 'react';
import { useRef } from 'react';

const noop = () => undefined;

export const useMoveable = ({
  onStart = noop,
  onMove = noop,
  onEnd = noop
}) => {
  const pointRef = useRef();
  const handleMouseMove = event => {
    pointRef.current = [event.pageX, event.pageY];
    onMove(pointRef.current);
  };
  const handleMouseUp = event => {
    if (event.pageX && event.pageY) {
      pointRef.current = [event.pageX, event.pageY];
    }
    onEnd(pointRef.current);
    window.removeEventListener('mousemove', handleMouseMove, false);
    window.removeEventListener('mouseup', handleMouseUp, false);
  };
  const handleTouchMove = event => {
    if (
      event.touches.length &&
      event.touches[0].pageX &&
      event.touches[0].pageY
    ) {
      pointRef.current = [event.touches[0].pageX, event.touches[0].pageY];
    }
    onMove(pointRef.current);
  };
  const handleTouchEnd = event => {
    if (
      event.touches.length &&
      event.touches[0].pageX &&
      event.touches[0].pageY
    ) {
      pointRef.current = [event.touches[0].pageX, event.touches[0].pageY];
    }
    onEnd(pointRef.current);
    window.removeEventListener('touchmove', handleTouchMove, false);
    window.removeEventListener('touchend', handleTouchEnd, false);
  };

  return {
    onTouchStart: event => {
      event.preventDefault();
      onStart([event.touches[0].pageX, event.touches[0].pageY], event);
      window.addEventListener('touchmove', handleTouchMove, false);
      window.addEventListener('touchend', handleTouchEnd, false);
    },
    onMouseDown: event => {
      event.preventDefault();
      onStart([event.pageX, event.pageY], event);
      window.addEventListener('mousemove', handleMouseMove, false);
      window.addEventListener('mouseup', handleMouseUp, false);
    }
  };
};

export const Moveable = ({ children, ...props }) =>
  children(useMoveable(props));
