import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var GithubIcon = function GithubIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 0a9 9 0 100 18A9 9 0 009 0zm5.568 14.607a7.85 7.85 0 01-3.229 1.96v-1.41c0-.74-.253-1.286-.76-1.635a6.585 6.585 0 001.717-.367c.297-.104.562-.228.797-.372s.461-.33.678-.56c.216-.23.398-.49.545-.78.146-.291.262-.64.347-1.048.086-.407.129-.855.129-1.345 0-.949-.309-1.757-.925-2.424.28-.735.25-1.534-.092-2.397l-.229-.028c-.158-.018-.444.05-.856.202-.412.153-.874.404-1.387.753a8.402 8.402 0 00-2.262-.303c-.787 0-1.538.101-2.252.303a7.838 7.838 0 00-.92-.546 4.73 4.73 0 00-.697-.294 2.38 2.38 0 00-.773-.105.584.584 0 00-.092.018c-.342.87-.372 1.668-.092 2.397-.616.667-.924 1.475-.924 2.424 0 .49.042.938.128 1.345.085.408.201.757.348 1.047.146.291.328.551.545.78.216.23.442.417.677.561.235.144.5.268.797.372.296.104.577.184.842.239.266.055.557.098.875.128-.5.343-.751.888-.751 1.635v1.437a7.812 7.812 0 01-3.32-1.987 7.874 7.874 0 01-2.307-5.584 7.866 7.866 0 012.307-5.585 7.849 7.849 0 018.633-1.693 7.85 7.85 0 012.504 1.693 7.874 7.874 0 012.306 5.585 7.867 7.867 0 01-2.307 5.584z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

GithubIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(GithubIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'GithubIcon';
export default ForwardRef;