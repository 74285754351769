import React from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as Handle } from './handle.svg';
import { padding, em } from 'polished';
import { css } from '@emotion/core';

const variant = css({ color: 'rgb(90,90,90)' });

export const ToolbarNavHandle = styled.button(
  {
    ...padding(em(6)),
    alignItems: 'center',
    appearance: 'none',
    background: 'transparent',
    border: 0,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    '&[draggable="true"]': {
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing'
      }
    }
  },
  variant
);

ToolbarNavHandle.defaultProps = {
  children: <Handle />
};
