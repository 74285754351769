import injector from '../../injector';
import Ajv from 'ajv';

import {
  assertFileExists,
  basename,
  dirname,
  extname,
  getAvailableName,
  join,
  mkdirpSync,
  readJSONSync,
  writeJSONSync,
  rimrafSync
} from '../../utils';

import {
  getFolderDataPath,
  getFolderGraphConfigPath,
  getFolderGraphEdgePath,
  getFolderGraphGroupPath,
  getFolderGraphMetaPath,
  getFolderRootMetaDirectory,
  getFolderGraphConfig,
  serializeGraph
} from './queries';

import {
  getOrderedNodeChildren,
  getSelectionPaths,
  getNodeChildren,
  getCurrentWorkingDirectory
} from '../core/queries';

import { AIRPAGE_NODE_EXTENSION } from './constants';
import { setSelection } from '../core/commands';
import { mod } from '../../../utils/math';
import { getSelectedProjectId } from '../git/queries';
import { getActiveProcessId } from '../process/queries';
const ajv = new Ajv({ coerceTypes: true, useDefaults: true });

// TODO schema refactor
// 1. use arrays, not objects
// 2. create a Registration system for components/functions/etc, and stick "schema" on these
// 3. register with scope/namespace such as workspace
const PaneProperties = {
  toolbar: { type: 'boolean', default: false },
  targetable: { type: 'boolean', default: true },
  flexGrow: { type: 'number', default: 1 },
  flexShrink: { type: 'number', default: 1 },
  flexBasis: {
    oneOf: [{ type: 'number' }, { const: 'auto' }],
    default: 'auto'
  },
  closeable: { type: 'boolean', default: true },
  locked: { type: 'boolean', default: false },
  minWidth: { type: 'number', default: 0 },
  maxWidth: { type: 'number', default: Number.MAX_SAFE_INTEGER },
  minHeight: { type: 'number', default: 0 },
  maxHeight: { type: 'number', default: Number.MAX_SAFE_INTEGER }
};
const TYPES = {
  Workspace: ajv.compile({
    type: 'object',
    properties: {
      targeted: { type: 'string' },
      selected: { type: 'string' },
      commandPaletteOpen: { type: 'boolean', default: false },
      terminalOpen: { type: 'boolean', default: false }
    },
    additionalProperties: false
  }),
  Pane: ajv.compile({
    type: 'object',
    properties: PaneProperties,
    additionalProperties: false
  }),
  RootContainer: ajv.compile({
    type: 'object',
    properties: {
      resizers: { type: 'boolean', default: false },
      direction: {
        enum: ['row', 'column']
      }
    },
    required: ['direction'],
    additionalProperties: false
  }),
  Container: ajv.compile({
    type: 'object',
    properties: {
      ...PaneProperties,
      resizers: { type: 'boolean', default: false },
      direction: {
        enum: ['row', 'column']
      }
    },
    required: ['direction'],
    additionalProperties: false
  }),
  Tabs: ajv.compile({
    type: 'object',
    properties: {
      toolbar: { type: 'boolean', default: false },
      selectedTabIndex: { type: 'number' }
    },
    additionalProperties: false
  }),
  Tab: ajv.compile({
    type: 'object',
    properties: {
      pid: { type: 'string' },
      title: { type: 'string' },
      icon: { type: 'string' }
    },
    required: ['pid'],
    additionalProperties: false
  })
};
const { inject } = injector;

const initializeFolderRoot = ({ root }) => {
  mkdirpSync(getFolderRootMetaDirectory(root));
  return [root];
};

// TODO move to queries
const getAllIncoming = ({ graph, node, nodes = [] }) => {
  if (nodes.includes(node)) return nodes;
  nodes.push(node);

  const incoming = graph.edges
    .filter(edge => edge.target.node === node)
    .map(edge => {
      return edge.source.node;
    });

  incoming.forEach(node => {
    getAllIncoming({ graph, node, nodes });
  });

  return nodes;
};

const selectIncoming = ({ selection }) => {
  const root = dirname(selection);
  const node = basename(selection);
  const graph = serializeGraph({ root, strict: false, recursive: false });
  const incoming = getAllIncoming({ graph, node });
  // TODO pid is NOT set!
  return [root].concat(setSelection({ selection: incoming }));
};
selectIncoming.description = 'select incoming nodes';
selectIncoming.args = [
  {
    _: true,
    name: 'selection',
    type: 'path',
    required: true
  }
];

const getNodePositionsFromEdges = ({
  root,
  graph,
  node,
  hash = {},
  visits = {},
  n = 0
}) => {
  if (visits[node]) return hash;
  visits[node] = true;
  graph.edges.forEach(edge => {
    if (edge.target.node !== node || edge.source.node === node) return;
    const key = edge.source.node;
    hash[key] = n;
    getNodePositionsFromEdges({
      root,
      graph,
      node: key,
      hash,
      visits,
      n: n + 1
    });
  });
  return hash;
};

const getNodePositions = ({
  root,
  outputPositions,
  inputs,
  outputs,
  disconnected
} = {}) => {
  const NODE_LAYOUT_XINC = 115;
  const NODE_LAYOUT_YINC = 100;
  const NODE_LAYOUT_PADDING = 25;
  const NODE_LAYOUT_MODULO = 7;

  let results = [];
  let y = 0;
  let x = 0;

  disconnected.forEach(name => {
    results.push({
      root,
      name,
      x: NODE_LAYOUT_XINC * x + NODE_LAYOUT_PADDING,
      y: NODE_LAYOUT_YINC * y + NODE_LAYOUT_PADDING
    });

    x = mod(x + 1, NODE_LAYOUT_MODULO);

    if (x === 0) {
      y++;
    }
  });

  outputs.forEach((node, j) => {
    const c = y + j + 3;
    const newX = NODE_LAYOUT_XINC * 3 + (NODE_LAYOUT_XINC + 25);
    const newY = c * NODE_LAYOUT_YINC + NODE_LAYOUT_YINC + y;
    results.push({
      root,
      x: newX,
      y: newY,
      name: node.name
    });
  });

  inputs.forEach((node, i) => {
    const d = y + i + 1;
    const newY = d * NODE_LAYOUT_YINC + NODE_LAYOUT_YINC + y;
    results.push({
      root,
      x: NODE_LAYOUT_PADDING,
      y: newY,
      name: node.name
    });
  });

  for (let i = 0, iLength = outputs.length; i < iLength; i++) {
    let maxY = y;

    const hash = outputPositions[i];

    let num = Object.keys(hash).reduce(
      (m, k) => (hash[k] > m ? hash[k] : m),
      -Infinity
    );

    if (num === -Infinity) {
      num = 0;
    }

    const groups = Object.keys(hash).reduce((m, k) => {
      const value = num - hash[k];
      if (!Array.isArray(m[value])) m[value] = [];
      m[value].push(k);
      return m;
    }, new Array(num + 1));

    for (let i = 0, iLength = groups.length; i < iLength; i++) {
      const group = groups[i];
      if (!group) continue;
      for (let j = 0, jLength = group.length; j < jLength; j++) {
        const nodeName = group[j];
        const newX = i * NODE_LAYOUT_XINC + NODE_LAYOUT_XINC;
        const newY = (j + y) * NODE_LAYOUT_YINC + (i * 50 + 150);
        maxY = Math.max(y, maxY);
        results.push({
          root,
          name: nodeName,
          x: newX,
          y: newY
        });
      }
    }

    y = maxY + 2;
  }

  return results;
};

const autoLayout = ({ root = getCurrentWorkingDirectory() } = {}) => {
  const graph = serializeGraph({ root, strict: false, recursive: true });
  const children = getNodeChildren({ root });

  const { sources, targets } = graph.edges.reduce(
    (hash, edge) => {
      hash.sources.push(edge.source.node);
      hash.targets.push(edge.target.node);
      return hash;
    },
    { sources: [], targets: [] }
  );

  const disconnected = children.filter(
    child => !targets.includes(child) || !sources.includes(child)
  );

  const outputs = graph.nodes.filter(
    node =>
      node.type === 'Output' ||
      (targets.includes(node.name) && !sources.includes(node.name))
  );
  const inputs = graph.nodes.filter(
    node =>
      node.type === 'Input' ||
      (!targets.includes(node.name) && sources.includes(node.name))
  );

  const outputPositions = outputs.map(obj =>
    getNodePositionsFromEdges({
      root,
      graph,
      node: obj.name
    })
  );

  const results = getNodePositions({
    root,
    inputs,
    outputs,
    disconnected,
    outputPositions
  });
  results.forEach(obj => updateNodePosition(obj));
  return [root];
};

autoLayout.description = 'layout/organize files and/or nodes';
autoLayout.args = [
  {
    _: true,
    name: 'root',
    type: 'path',
    default: '.',
    required: true
  }
];

const ensureRootMetaDirectoryExists = inject(['fs'], function(root) {
  if (!this.fs.existsSync(getFolderRootMetaDirectory(root))) {
    initializeFolderRoot({ root });
  }
});

const validateData = inject(['registry'], function(type, data, path) {
  let validator;

  // TODO better separation for workspace and project types
  if (type && typeof TYPES[type] === 'function') {
    validator = TYPES[type];
  } else {
    const projId = getSelectedProjectId();
    if (projId) {
      validator = this.registry.schema(projId, type);
    }
  }
  if (validator) {
    const valid = validator(data);
    if (!valid) {
      console.log('data:');
      console.log(data);
      console.log('path:');
      console.log(path);
      console.log(validator.errors);
      throw new Error(`not a valid ${type}`);
    }
  }
});

const addNode = ({ root, name, type, data = {}, meta }) => {
  ensureRootMetaDirectoryExists(root);
  name = getAvailableName({ root, name, type, ext: AIRPAGE_NODE_EXTENSION });
  const fullPath = join(root, name);
  validateData(type, data, fullPath);
  writeJSONSync(fullPath, { name, type, data });
  setNodeMeta({ root, name, meta: meta || { x: 0, y: 0 } });
  return [fullPath];
};

addNode.description = 'Add a node to the graph.';
addNode.args = [
  {
    name: 'root',
    type: 'path',
    default: '.',
    required: true
  },
  {
    _: true,
    name: 'type',
    type: 'string',
    required: true
  },
  {
    _: true,
    name: 'name',
    type: 'string'
  }
];

const addGraph = inject(['fs'], function addGraph({
  root,
  type,
  name,
  lib,
  icon,
  description,
  data = {},
  meta = {}
}) {
  ensureRootMetaDirectoryExists(root);
  name = getAvailableName({ root, name, type });
  const fullPath = join(root, name);

  validateData(type, data, fullPath);

  this.fs.mkdirSync(fullPath);
  setGraphConfig({
    root: fullPath,
    config: {
      type,
      lib,
      icon,
      description
    }
  });
  setGraphData({ root: fullPath, data });
  setNodeMeta({ root, name, meta });

  return [fullPath];
});

addGraph.description = 'Add a flow graph.';
addGraph.args = [
  {
    name: 'root',
    type: 'path',
    default: '.',
    required: true
  },
  {
    _: true,
    name: 'type',
    type: 'string',
    required: true
  }
];

const addInput = ({ root, name, type, data = {} }) => {
  if (type) {
    data = {
      type
    };
  }
  return addNode({ root, name: `${name}.input`, type: 'Input', data });
};

addInput.description = 'Add an input to the graph.';
addInput.args = [
  {
    name: 'root',
    type: 'path',
    default: '.',
    required: true
  },
  {
    _: true,
    name: 'type',
    type: 'string',
    required: true
  },
  {
    _: true,
    name: 'name',
    type: 'string',
    required: true
  }
];

const addOutput = ({ root, name, type, data = {} }) => {
  if (type) {
    data = {
      type
    };
  }
  return addNode({ root, name: `${name}.output`, type: 'Output', data });
};

addOutput.description = 'Add an output to the graph.';
addOutput.args = [
  {
    name: 'root',
    type: 'path',
    default: '.',
    required: true
  },
  {
    _: true,
    name: 'type',
    type: 'string',
    required: true
  },
  {
    _: true,
    name: 'name',
    type: 'string',
    required: true
  }
];

const updateNode = ({ root, name, type, data, meta }) => {
  // for now this might just work as long as you have all the data...
  // TODO make this more granular like set prop, set pos, etc
  addNode({ root, name, type, data, meta });
  // _editNode ...
};

const _setType = inject(['fs'], function setProp({ path, type }) {
  if (this.fs.statSync(path).isDirectory()) {
    const json = getGraphConfig({ root: path });
    json.type = type;
    setGraphConfig({ root: path, config: json });
  } else {
    const json = getNodeData({ path });
    json.type = type;
    setNodeData({ path, data: json });
  }
});

const setType = ({ path, type }) => {
  return _setType({ path, type });
};

// TODO refactor to use this API (path vs. root + node)
const setProperty = ({ path, prop, value }) => {
  const root = dirname(path);
  const node = basename(path);
  return setProp({ root, node, prop, value });
};

// TODO should we just splat or use strictly prop/value?
const setProps = ({ paths, prop, value }) => {
  if (!paths || !paths.length) {
    paths = getSelectionPaths();
  }

  for (let path of paths) {
    setProperty({ path, prop, value });
  }

  return paths;
};
setProps.description = 'set properties';
setProps.args = [
  {
    _: true,
    name: 'prop',
    type: 'string',
    required: true
  },
  {
    _: true,
    name: 'value',
    type: 'string',
    required: true
  },
  {
    _: true,
    name: 'paths',
    type: 'array',
    items: {
      type: 'path'
    },
    required: true
  }
];

const setProp = inject(['fs'], function setProp({ root, node, prop, value }) {
  const fullPath = join(root, node);
  if (this.fs.statSync(fullPath).isDirectory()) {
    const config = getFolderGraphConfig(fullPath);
    const type = config.type;
    const json = getGraphData({ root: fullPath });
    json[prop] = value;
    validateData(type, json, fullPath);
    setGraphData({ root: fullPath, data: json });
  } else {
    const json = getNodeData({ path: fullPath });
    json.data = json.data || {};
    json.data[prop] = value;
    validateData(json.type, json.data, fullPath);
    setNodeData({ path: fullPath, data: json });
  }
  return [fullPath];
});

const updateNodePosition = ({ root, name, x, y }) => {
  updateNodeMeta({ root, name, meta: { x, y } });
  return [root];
};

const setOrderedNodeChildren = inject(['fs'], function({
  root,
  children = []
}) {
  const actual = getOrderedNodeChildren({ root });
  children = children.filter(child => actual.includes(child));
  children = children.concat(actual.filter(child => !children.includes(child)));

  const meta = children.reduce((meta, child, i) => {
    meta[child] = meta[child] || {};
    meta[child].x = i * (50 + 20);
    meta[child].y = 0;
    return meta;
  }, getGraphMeta({ root }));
  setGraphMeta({ root, meta });
});

const removeNodeFromGroups = ({ root, name }) => {
  const currentGroups = getGraphGroups({ root });
  const groups = Object.keys(currentGroups).reduce((m, v) => {
    let group = currentGroups[v];
    group.nodes = group.nodes.filter(node => node !== name);
    m[v] = group;
    return m;
  }, {});
  setGraphGroups({ root, groups });
};

const removeNode = ({ root, name }) => {
  const fullPath = join(root, name);
  assertFileExists(fullPath, 'node does not exist!');
  removeNodeEdges({ root, name });
  removeNodeMeta({ root, name });
  removeNodeFromGroups({ root, name });
  // rimraf handles both node & graph cases
  rimrafSync(fullPath);
  return [root];
};

removeNode.description = 'Remove a node from the graph';
removeNode.args = [
  {
    name: 'root',
    type: 'path',
    default: '.',
    required: true
  },
  {
    _: true,
    name: 'name',
    type: 'string',
    required: true
  }
];

const removeSelectedNodes = ({ pid = getActiveProcessId() } = {}) => {
  const paths = getSelectionPaths({ pid });
  if (!paths.length) return [];

  const root = dirname(paths[0]);
  paths.forEach(path => {
    const name = basename(path);
    removeNode({ root, name });
  });

  return [root];
};

removeSelectedNodes.description = 'Remove a node from the graph';
removeSelectedNodes.args = [];

const updateGroupsRenameNode = ({ root, from, to }) => {
  const groups = getGraphGroups({ root });
  const edited = Object.keys(groups).reduce((m, v) => {
    let group = groups[v];
    group.nodes = group.nodes.map(node => (node === from ? to : node));
    m[v] = group;
    return m;
  }, {});
  setGraphGroups({ root, groups: edited });
};

const updateEdgesRenameNode = ({ root, from, to }) => {
  const edges = getGraphEdges({ root }).map(edge => {
    if (edge.source.node === from) {
      edge.source.node = to;
      return edge;
    } else if (edge.target.node === from) {
      edge.target.node = to;
      return edge;
    }
    return edge;
  });
  setGraphEdges({ root, edges });
};

const updateMetaRenameNode = ({ root, from, to }) => {
  let meta = getGraphMeta({ root });
  const data = meta[from];
  delete meta[from];
  meta[to] = data;
  setGraphMeta({ root, meta });
};

const updateNodeName = inject(['fs'], function({ root, from, to }) {
  const fromPath = join(root, from);

  assertFileExists(fromPath, 'node does not exist!');

  if (to.includes('/') || from.includes('/')) {
    throw new Error('NOT YET IMPLEMENTED');
  }

  // update meta
  updateMetaRenameNode({ root, from, to });

  // update contents of node
  // TODO dont actually set a name property!
  if (!this.fs.statSync(fromPath).isDirectory()) {
    const json = getNodeData({ path: fromPath });
    json.name = to;
    setNodeData({ path: fromPath, data: json });
  }

  // update groups
  updateGroupsRenameNode({ root, from, to });

  // update fs
  this.fs.renameSync(fromPath, join(root, to));

  // update edges
  updateEdgesRenameNode({ root, from, to });
});

const renameNode = inject(['fs'], function({ from, to }) {
  const fromDir = dirname(from);
  const toDir = dirname(to);
  const fromName = basename(from);
  const toName = basename(to);
  if (fromDir === toDir) {
    return updateNodeName({ root: toDir, from: fromName, to: toName });
  }
  throw new Error('not yet implemented!');
});

// this hash does not take into account `index` property on purpose!
function hashEdge(edge) {
  return `${edge.source.port}|${edge.source.node}|${edge.target.port}|${
    edge.target.node
  }`;
}

const addEdge = ({ root, source, target }) => {
  const edges = getGraphEdges({ root });

  edges.push({ source, target });

  var hash = edges.reduce((m, v) => {
    var h = hashEdge(v);
    m[h] = v;
    return m;
  }, {});

  setGraphEdges({ root, edges: Object.values(hash) });

  return [root];
};

const getEdgeObj = str => {
  const port = extname(str);
  const node = basename(str, port);

  if (!node || !port) throw new Error('not a proper edge!');
  return {
    node,
    port: port.split('.')[1]
  };
};

const connectNodes = ({ root, source, target }) => {
  return addEdge({
    root,
    source: getEdgeObj(source),
    target: getEdgeObj(target)
  });
};

connectNodes.description = 'Connect nodes with an edge';
connectNodes.args = [
  {
    name: 'root',
    type: 'path',
    default: '.',
    required: true
  },
  {
    _: true,
    name: 'source',
    type: 'string',
    required: true
  },
  {
    _: true,
    name: 'target',
    type: 'string',
    required: true
  }
];

const removeEdge = ({ root, source, target }) => {
  const edges = getGraphEdges({ root });
  setGraphEdges({
    root,
    edges: edges.filter(
      edge =>
        edge.source.node !== source.node ||
        edge.source.port !== source.port ||
        edge.target.node !== target.node ||
        edge.target.port !== target.port
    )
  });
  return [root];
};

const removeNodeEdges = ({ root, name }) => {
  const edges = getGraphEdges({ root });
  setGraphEdges({
    root,
    edges: edges.filter(
      edge => edge.source.node !== name && edge.target.node !== name
    )
  });
};

const addGroup = ({ root, name, nodes, meta }) => {
  const groups = getGraphGroups({ root });
  groups[name] = {
    nodes,
    meta
  };
  setGraphGroups({ root, groups });
  return [root];
};

addGroup.description = 'Add a node group to the graph';
addGroup.args = [
  {
    name: 'root',
    type: 'path',
    default: '.',
    required: true
  },
  {
    _: true,
    name: 'name',
    type: 'string',
    required: true
  },
  {
    _: true,
    name: 'nodes',
    type: 'array',
    items: {
      type: 'string'
    },
    required: true
  }
];

const removeGroup = ({ root, name }) => {
  const groups = getGraphGroups({ root });
  delete groups[name];
  setGraphGroups({ root, groups });
  return [root];
};

const updateGroup = ({ root, name, nodes, meta }) => {
  const groups = getGraphGroups({ root });
  groups[name] = {
    nodes,
    meta
  };
  setGraphGroups({ root, groups });
  return [root];
};

// editing data

const getGraphEdges = ({ root }) =>
  readJSONSync(getFolderGraphEdgePath(root), []);

const setGraphEdges = ({ root, edges }) => {
  ensureRootMetaDirectoryExists(root);
  writeJSONSync(getFolderGraphEdgePath(root), edges);
};

const getGraphGroups = ({ root }) =>
  readJSONSync(getFolderGraphGroupPath(root), {});

const setGraphGroups = ({ root, groups }) => {
  ensureRootMetaDirectoryExists(root);
  writeJSONSync(getFolderGraphGroupPath(root), groups);
};

const getGraphMeta = ({ root }) =>
  readJSONSync(getFolderGraphMetaPath(root), {});

const setGraphMeta = ({ root, meta }) => {
  ensureRootMetaDirectoryExists(root);
  writeJSONSync(getFolderGraphMetaPath(root), meta);
};

const updateNodeMeta = ({ root, name, meta }) => {
  const metadata = getGraphMeta({ root });
  metadata[name] = metadata[name] || {};
  Object.assign(metadata[name], meta);
  setGraphMeta({ root, meta: metadata });
};

const updateNodeMetaData = ({ path, meta }) => {
  const root = dirname(path);
  const name = basename(path);
  updateNodeMeta({ root, name, meta });
};

const setNodeMeta = ({ root, name, meta }) => {
  const metadata = getGraphMeta({ root });
  metadata[name] = meta || {};
  setGraphMeta({ root, meta: metadata });
};

// TODO replace usage of this function since it's just set not update
const updateFolderMetaData = ({ path, meta }) => {
  setGraphMeta({ root: path, meta });
};

const removeNodeMeta = ({ root, name }) => {
  const meta = getGraphMeta({ root });
  delete meta[name];
  setGraphMeta({ root, meta });
};

const removeNodeMetaData = ({ path }) => {
  const root = dirname(path);
  const name = basename(path);
  const meta = getGraphMeta({ root });
  delete meta[name];
  setGraphMeta({ root, meta });
};

const getNodeData = ({ path }) => readJSONSync(path, {});

const setNodeData = ({ path, data }) => {
  writeJSONSync(path, data);
};

const getGraphData = ({ root }) => readJSONSync(getFolderDataPath(root), {});

const setGraphData = ({ root, data }) => {
  ensureRootMetaDirectoryExists(root);
  writeJSONSync(getFolderDataPath(root), data);
};

const getGraphConfig = ({ root }) =>
  readJSONSync(getFolderGraphConfigPath(root));

const setGraphConfig = ({ root, config }) => {
  ensureRootMetaDirectoryExists(root);
  writeJSONSync(getFolderGraphConfigPath(root), config);
};

export {
  connectNodes,
  addEdge,
  addGraph,
  addGroup,
  addInput,
  addNode,
  addOutput,
  initializeFolderRoot,
  removeEdge,
  removeGroup,
  removeNode,
  removeNodeEdges,
  removeNodeMeta,
  removeNodeMetaData,
  removeSelectedNodes,
  updateNodeName,
  renameNode,
  setOrderedNodeChildren,
  setProp,
  setProps,
  setProperty,
  setType,
  selectIncoming,
  updateGroup,
  updateNode,
  autoLayout,
  updateNodeMeta,
  updateNodeMetaData,
  updateFolderMetaData,
  updateNodePosition
};
