import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { transparentize } from 'polished';
import { Button } from '../Button';

export const InputGroupAdornment = styled.div(props => ({
  display: 'flex',
  backgroundColor: themeGet('colors.input.adornmentBg')(props),
  position: 'absolute',
  top: 1,
  bottom: 1,
  borderRadius: themeGet('radii.1')(props),
  maxHeight: themeGet('space.6')(props) - 2,
  zIndex: 1,
  [`${Button}`]: {
    border: 'none',
    background: 'transparent'
  }
}));

export const InputGroupAppend = styled(InputGroupAdornment)(props => ({
  boxShadow: `-0.5px 0px 0px 0px ${transparentize(
    props.theme.mode === 'dark' ? 0.5 : 0.8,
    themeGet('colors.black')(props)
  )}`,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
}));

export const InputGroupPrepend = styled(InputGroupAdornment)(props => ({
  boxShadow: `0.5px 0px 0px 0px ${transparentize(
    0.5,
    themeGet('colors.black')(props)
  )}`,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0
}));
