import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

export const VisuallyHidden = styled.div(props => ({
  clip: 'rect(1px, 1px, 1px, 1px)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  width: 1,
  '&[hidden]': {
    display: 'none'
  },
  '&:focus': props.focusable
    ? {
        position: 'static',
        width: 'auto',
        height: 'auto',
        clip: 'auto'
      }
    : null
}));

VisuallyHidden.propTypes = {
  hidden: PropTypes.bool
};
