import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { space, layout } from 'styled-system';
import { Box } from '../Box';

const variant = props => {
  const colors = themeGet('colors.intent')(props);
  const bgColors = {
    success: colors.success[0],
    primary: colors.primary[0],
    danger: colors.danger[0],
    warning: colors.warning[0]
  };

  const borderColors = {
    success: colors.success[1],
    primary: colors.primary[1],
    danger: colors.danger[1],
    warning: colors.warning[1]
  };

  return {
    backgroundColor: bgColors[props.color] || bgColors.primary,
    borderColor: borderColors[props.color] || borderColors.primary,
    color: themeGet('colors.white')(props)
  };
};

const BadgeContent = styled.div(
  {
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    width: 16 - 2,
    height: 16 - 2,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'center'
  },
  variant
);

const BadgeDot = styled.div(
  {
    height: 7,
    width: 7,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '100%'
  },
  variant
);

const badgePosition = props =>
  ({
    right: {
      right: props.offset ? props.offset.right : 4,
      top: props.offset ? props.offset.top : 4
    },
    left: {
      left: props.offset ? props.offset.left : 4,
      top: props.offset ? props.offset.top : 4
    }
  }[props.position]);

const BadgeWrapper = styled(Box)(
  props => ({
    position: 'relative',
    display: 'inline-flex',
    padding: themeGet('space.3')(props),
    [`${BadgeContent}, ${BadgeDot}`]: {
      position: 'absolute',
      ...badgePosition(props)
    }
  }),
  space,
  layout
);

export const Badge = React.forwardRef(function Badge(
  { content, dot, color, children, position, offset, ...rest },
  ref
) {
  return (
    <BadgeWrapper
      ref={ref}
      {...rest}
      dot={dot}
      position={position}
      offset={offset}
    >
      {dot ? (
        <BadgeDot as="span" color={color} />
      ) : (
        <BadgeContent as="span" color={color}>
          {content}
        </BadgeContent>
      )}
      {children}
    </BadgeWrapper>
  );
});

Badge.propTypes = {
  dot: PropTypes.bool,
  color: PropTypes.oneOf(['success', 'primary', 'danger', 'warning']),
  position: PropTypes.oneOf(['right', 'left']),
  content: PropTypes.node
};

Badge.defaultProps = {
  dot: false,
  color: 'primary',
  position: 'right'
};
