import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SchemaBuilderIcon = function SchemaBuilderIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M7 2H6v4h1V5h11v3H7V7H6v4h1v-1h11v3H7v-1H6v4h1v-1h11v3H7v-1H5.25a.25.25 0 01-.25-.25V9.5H4V11H0V7h4v1.5h1V1.25A.25.25 0 015.25 1H7V0h11v3H7V2z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SchemaBuilderIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SchemaBuilderIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SchemaBuilderIcon';
export default ForwardRef;