import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';

export const ModalRoot = styled.div(props => ({
  position: 'fixed',
  zIndex: themeGet('zIndex.modal')(props),
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  inset: '0px'
}));
