import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var RoutingRightIcon = function RoutingRightIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.046 1.356L5.482.038a.143.143 0 01.2.006l8.404 8.859a.141.141 0 010 .194l-8.404 8.859a.143.143 0 01-.2.006l-1.436-1.318a.141.141 0 01-.008-.2v-.001l6.97-7.346a.141.141 0 000-.194l-6.97-7.346a.141.141 0 01.007-.2z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

RoutingRightIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(RoutingRightIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'RoutingRightIcon';
export default ForwardRef;