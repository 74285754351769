import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var InsertLeftIcon = function InsertLeftIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M6.129 10.31h11.76a.11.11 0 01.111.108v1.504c0 .059-.047.107-.111.107H6.129l4.51 4.79a.108.108 0 01-.003.151h-.001l-1.082 1.002a.107.107 0 01-.151-.006l-6.333-6.722a.108.108 0 010-.148l6.333-6.723a.107.107 0 01.15-.005l1.083 1c.043.041.046.109.006.153h-.001l-4.511 4.79zM1.714 1.719V.108c0-.06.05-.108.108-.108h1.5c.059 0 .107.045.107.108v1.61h1.606c.06 0 .108.05.108.108v1.503c0 .06-.045.108-.108.108H3.43v1.61c0 .06-.05.108-.108.108h-1.5a.105.105 0 01-.107-.107V3.437H.107A.108.108 0 010 3.329V1.826c0-.06.044-.108.107-.108h1.607z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

InsertLeftIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(InsertLeftIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'InsertLeftIcon';
export default ForwardRef;