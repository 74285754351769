import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridRow,
  GridRowCell,
  Input,
  RangeInput,
  ColorInput,
  Switch,
  Label,
  Text
} from '../../ui';
import { Board } from './Board';
import { Hue } from './Hue';
import { Alpha } from './Alpha';
import { Color } from './Color';
import { GradientPicker } from './GradientPicker';
import { useColorPicker } from './ColorPickerContext';
import { ACTIONS } from './reducer';
import {
  generateCssBackground,
  normalizeChannel,
  toPercentage,
  hueBg,
  getSaturationBg,
  getBrightnessBg
} from './utils';

export const ColorPicker = props => {
  const { onChange, hasGradient, className } = props;
  const [picker, dispatch] = useColorPicker();
  const [hexValue, setHexValue] = useState(picker.color.hexString);

  const color = picker.activeStopColor || picker.color;
  const colorPath = picker.activeStopColor ? 'activeStopColor' : 'color';

  useEffect(() => {
    onChange({
      color,
      gradient: picker.gradient,
      background: generateCssBackground(picker.gradient)
    });
  }, [onChange, color, color.rgbString, picker.gradient]);

  useEffect(() => {
    setHexValue(picker.color.hexString);
  }, [picker.color.hexString]);

  const handleColorChannelChange = channel => maybeEvent => {
    if (typeof maybeEvent === 'object') {
      return dispatch({
        type: ACTIONS.setColorChannel,
        channel,
        value: normalizeChannel(channel, maybeEvent.target.value),
        colorPath
      });
    }
    const value = normalizeChannel(channel, maybeEvent);
    dispatch({ type: ACTIONS.setColorChannel, channel, value, colorPath });
  };

  const handleColorChange = useCallback(
    color => {
      if (!(color instanceof Color)) {
        color = new Color(color);
      }
      dispatch({ type: ACTIONS.setColor, color, colorPath });
    },
    [colorPath, dispatch]
  );

  const handleColorOnBlur = useCallback(() => {
    let newColor = new Color(hexValue);
    if (!newColor.isValid) {
      setHexValue(color.hexString);
      newColor = color;
    }
    dispatch({
      type: ACTIONS.setColor,
      color: newColor,
      colorPath
    });
  }, [color, colorPath, dispatch, hexValue]);

  return (
    <Grid maxWidth="5" className={className}>
      {hasGradient && <GradientPicker minStops={2} marginBottom="3" />}

      <Board marginBottom="3" color={color} onChange={handleColorChange} />
      <Hue
        value={color.hue}
        onChange={handleColorChannelChange('hue')}
        marginBottom="3"
      />
      <GridRow fluid alignItems="center" marginBottom="3">
        <Text m="0" marginRight="3">
          O
        </Text>
        <Alpha
          alpha={toPercentage(color.alpha)}
          color={color}
          onChange={alpha =>
            dispatch({ type: ACTIONS.setAlpha, alpha, colorPath })
          }
          marginRight="3"
        />
        <Input
          type="text"
          value={`${toPercentage(color.alpha)}%`}
          name="alpha"
          onChange={event =>
            dispatch({
              type: ACTIONS.setAlpha,
              alpha: event.target.value,
              colorPath
            })
          }
          width="2"
        />
      </GridRow>

      <GridRow
        fluid
        alignItems="center"
        justifyContent="space-between"
        marginBottom="3"
      >
        <GridRowCell flexBasis="50%">
          <Label htmlFor="color-picker-color" id="color-picker-color-label">
            Hex
          </Label>
          <ColorInput
            id="color-picker-color"
            aria-labelledby="color-picker-color-label"
            value={hexValue}
            onChange={event => setHexValue(event.target.value)}
            onBlur={handleColorOnBlur}
          />
        </GridRowCell>
        <GridRowCell>
          <Switch
            id="color-picker-mode"
            type="radio"
            value={picker.mode}
            onLabel="H"
            offLabel="R"
            offValue="RGB"
            onValue="HSL"
            onChange={mode =>
              dispatch({
                type: 'SET_COLOR_MODE',
                mode
              })
            }
            marginTop="4"
          />
        </GridRowCell>
      </GridRow>
      {picker.mode === 'RGB' && (
        <React.Fragment>
          <GridRow fluid alignItems="center" marginBottom="3">
            <Text m="0" marginRight="3">
              R
            </Text>
            <RangeInput
              trackBg="linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(255, 0, 0) 100%)"
              value={color.red}
              onChange={handleColorChannelChange('red')}
              id="color-picker-red"
              fluid
              min={0}
              max={255}
              marginRight="3"
            />
            <Input
              type="text"
              value={color.red}
              onChange={handleColorChannelChange('red')}
              name="colorPickerRed"
              width="2"
            />
          </GridRow>
          <GridRow fluid alignItems="center" marginBottom="3">
            <Text m="0" marginRight="3">
              G
            </Text>
            <RangeInput
              trackBg="linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 255, 0) 100%)"
              value={color.green}
              onChange={handleColorChannelChange('green')}
              id="color-picker-green"
              fluid
              min={0}
              max={255}
              marginRight="3"
            />
            <Input
              type="text"
              value={color.green}
              onChange={handleColorChannelChange('green')}
              name="colorPickerGreen"
              width="2"
            />
          </GridRow>
          <GridRow fluid alignItems="center">
            <Text m="0" marginRight="3">
              B
            </Text>
            <RangeInput
              trackBg="linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 255) 100%)"
              value={color.blue}
              onChange={handleColorChannelChange('blue')}
              id="color-picker-blue"
              fluid
              min={0}
              max={255}
              marginRight="3"
            />
            <Input
              type="text"
              value={color.blue}
              onChange={handleColorChannelChange('blue')}
              name="colorPickerBlue"
              width="2"
            />
          </GridRow>
        </React.Fragment>
      )}
      {picker.mode === 'HSL' && (
        <React.Fragment>
          <GridRow fluid alignItems="center" marginBottom="3">
            <Text m="0" marginRight="3">
              H
            </Text>
            <RangeInput
              value={color.hue.toFixed(0)}
              onChange={handleColorChannelChange('hue')}
              id="color-picker-hue"
              fluid
              min={0}
              max={360}
              marginRight="3"
              trackBg={hueBg}
            />
            <Input
              type="number"
              value={color.hue.toFixed(0)}
              onChange={handleColorChannelChange('hue')}
              name="colorPickerHue"
              width="2"
            />
          </GridRow>
          <GridRow fluid alignItems="center" marginBottom="3">
            <Text m="0" marginRight="3">
              S
            </Text>
            <RangeInput
              value={toPercentage(color.saturation)}
              onChange={handleColorChannelChange('saturation')}
              id="color-picker-saturation"
              fluid
              min={0}
              max={100}
              marginRight="3"
              trackBg={getSaturationBg(
                color.hue,
                toPercentage(color.brightness)
              )}
            />
            <Input
              type="number"
              value={toPercentage(color.saturation)}
              onChange={handleColorChannelChange('saturation')}
              name="colorPickerSaturation"
              width="2"
            />
          </GridRow>
          <GridRow fluid alignItems="center">
            <Text m="0" marginRight="3">
              B
            </Text>
            <RangeInput
              value={toPercentage(color.brightness)}
              onChange={handleColorChannelChange('brightness')}
              id="color-picker-brightness"
              fluid
              min={0}
              max={100}
              marginRight="3"
              trackBg={getBrightnessBg(
                color.hue,
                toPercentage(color.saturation)
              )}
            />
            <Input
              type="number"
              value={toPercentage(color.brightness)}
              onChange={handleColorChannelChange('brightness')}
              name="colorPickerBrightness"
              width="2"
            />
          </GridRow>
        </React.Fragment>
      )}
    </Grid>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.object,
  defaultColor: PropTypes.string,
  onChange: PropTypes.func,
  mode: PropTypes.oneOf(['RGB', 'HSB']),
  hasGradient: PropTypes.bool
};

ColorPicker.defaultProps = {
  defaultColor: '#ff0000',
  onChange: () => {},
  mode: 'RGB',
  hasGradient: true
};
