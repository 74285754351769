import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { em, padding, transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';
import { position, space, layout, flex } from 'styled-system';
import { Popup } from '../../components/Popup';
import { Icon } from '../Icon';
import { Menu, MenuItem, MenuItemLink } from '../Menu';

const noop = () => {};

const variant = props => ({
  boxSizing: 'border-box',
  backgroundColor: themeGet('colors.input.background')(props),
  borderColor: themeGet('colors.input.border')(props),
  boxShadow: `0 ${em(1)} 0 0 ${transparentize(
    0.85,
    themeGet('colors.white')(props)
  )}`,
  color: themeGet('colors.input.color')(props),

  '&:focus, &:active': {
    backgroundColor: transparentize(0.97, 'rgb(255, 255, 255)'),
    borderColor: 'rgb(0, 160, 255)',
    boxShadow: `0 ${em(1)} 0 0 ${transparentize(0.85, 'rgb(0, 0, 0)')}`
  },

  '&::placeholder': {
    color: themeGet('colors.input.placeholder')(props)
  },

  "&:required, &[aria-required='true']": {
    outlineColor: themeGet('colors.intent.success.0')(props)
  },

  "&:invalid, &[aria-invalid='true']": {
    outlineColor: themeGet('colors.intent.error.0')(props)
  },

  "&:disabled, &[aria-disabled='true']": {
    cursor: 'not-allowed',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderColor: transparentize(
      0.7,
      themeGet('colors.input.placeholder')(props)
    ),
    color: transparentize(0.7, themeGet('colors.input.placeholder')(props))
  }
});

const SelectInput = styled.select(
  props => ({
    ...padding(
      themeGet('space.2')(props) - 1.5,
      themeGet('space.5')(props),
      themeGet('space.2')(props) - 1.5,
      themeGet('space.3')(props)
    ),
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none',
    appearance: 'none',
    borderRadius: em(3),
    borderStyle: 'solid',
    borderWidth: '0.5px',
    boxSizing: 'border-box',
    display: props.fluid ? 'block' : 'inline-block',
    font: 'inherit',
    lineHeight: 24 / 16,
    verticalAlign: props.fluid ? undefined : 'middle'
  }),
  variant
);

const SelectWrap = styled.div(props => ({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  [`&:hover ${SelectInput}`]: {
    backgroundColor: transparentize(0.97, 'rgb(255, 255, 255)'),
    borderColor: 'rgb(0, 160, 255)',
    boxShadow: `0 ${em(1)} 0 0 ${transparentize(0.85, 'rgb(0, 0, 0)')}`
  },
  width: props.fluid ? '100%' : undefined,
  [`${Icon}`]: {
    position: 'absolute',
    right: 6,
    top: 'calc(50% - 6px)',
    pointerEvents: 'none'
  },
  '& > [data-select-option="value"]': {
    position: 'absolute',
    top: 1,
    bottom: 1,
    left: 1,
    right: 1
  },
  [`& > [data-select-option="value"] ${MenuItemLink}`]: {
    height: '100%',
    border: 'none'
  }
}));

export const Select = styled(props => {
  const {
    fluid,
    native,
    value,
    onChange,
    disabled,
    options,
    valueToString,
    renderOption,
    renderValue,
    placeholder,
    header,
    footer,
    InputProps,
    MenuProps,
    PopupProps,
    className,
    children,
    ...rest
  } = props;

  const valueNode = React.cloneElement(
    renderValue(value || placeholder, props),
    {
      'data-select-option': 'value'
    }
  );
  return native ? (
    <SelectWrap className={className} fluid={fluid} {...rest}>
      <SelectInput onChange={onChange} {...InputProps}>
        {children}
      </SelectInput>
      <Icon name="down" title="Select" size={12} color="action.default" />
    </SelectWrap>
  ) : (
    <Popup
      popup={({ setExpanded }) => {
        const optionsList = options.map(option =>
          React.cloneElement(renderOption({ option, value }), {
            key: valueToString(option),
            onClick: event => {
              event.target.value = option;
              onChange(event);
              setExpanded(false);
            },
            'aria-current': valueToString(option) === value,
            'data-select-option': 'option'
          })
        );
        return (
          <Menu marginTop="2" {...MenuProps}>
            {header && <MenuItem>{header}</MenuItem>}
            {optionsList}
            {footer && <MenuItem>{footer}</MenuItem>}
          </Menu>
        );
      }}
      placement="bottom-start"
      closeOnClickAway
      {...PopupProps}
    >
      {({ setExpanded }) => (
        <SelectWrap
          fluid={fluid}
          onClick={disabled ? noop : () => setExpanded(true)}
          className={className}
          {...rest}
        >
          <SelectInput disabled={disabled} {...InputProps} />
          <Icon name="down" title="Select" size={12} color="action.default" />
          {valueNode}
        </SelectWrap>
      )}
    </Popup>
  );
})(space, layout, flex, position);

Select.propTypes = {
  native: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  // Used to sort and identify each option (id)
  valueToString: PropTypes.func,
  // Custom rendering each option's menu item
  renderOption: PropTypes.func,
  renderValue: PropTypes.func
};

Select.defaultProps = {
  native: false,
  options: [],
  placeholder: 'Select option..',
  onChange: () => {},
  valueToString: v => String(v),
  renderOption: ({ option }) => (
    <MenuItem as="div">
      <MenuItemLink as="button">{option}</MenuItemLink>
    </MenuItem>
  ),
  renderValue: (value, props) => (
    <MenuItem as="div">
      <MenuItemLink as="span" aria-disabled={props.disabled}>
        {value}
      </MenuItemLink>
    </MenuItem>
  )
};
