import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SubgraphIcon = function SubgraphIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M3 9.429v2.892c0 .06-.048.108-.107.108H2.25a.107.107 0 01-.107-.108V8.68c0-.06.048-.108.107-.108h6.321V5.68c0-.06.048-.108.108-.108h.642c.06 0 .108.048.108.108V8.57h6.321c.06 0 .107.048.107.108v3.642c0 .06-.048.108-.107.108h-.643A.107.107 0 0115 12.32V9.43H9.429v2.892c0 .06-.048.108-.108.108H8.68a.107.107 0 01-.108-.108V9.43H3zM.107 12.857h4.929c.059 0 .107.048.107.107v4.929c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893v-4.929c0-.059.048-.107.107-.107zm6.429 0h4.928c.06 0 .107.048.107.107v4.929c0 .059-.048.107-.107.107H6.536a.107.107 0 01-.107-.107v-4.929c0-.059.048-.107.107-.107zM9 5.143A2.571 2.571 0 119 0a2.571 2.571 0 010 5.143zm3.964 7.714h4.929c.059 0 .107.048.107.107v4.929c0 .059-.048.107-.107.107h-4.929a.107.107 0 01-.107-.107v-4.929c0-.059.048-.107.107-.107z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SubgraphIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SubgraphIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SubgraphIcon';
export default ForwardRef;