import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var WebPageIcon = function WebPageIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 3h17.786c.059 0 .107.044.107.099V17.9c0 .055-.048.099-.107.099H.107C.048 18 0 17.956 0 17.901V3.1C0 3.044.048 3 .107 3zM1.11 5C1.05 5 1 5.047 1 5.104v4.792c0 .057.049.104.11.104h6.78c.061 0 .11-.047.11-.104V5.104A.107.107 0 007.89 5H1.11zm8.009 0C9.053 5 9 5.056 9 5.125v.75c0 .069.053.125.118.125h7.764c.065 0 .118-.056.118-.125v-.75c0-.069-.053-.125-.118-.125H9.118zm0 4C9.053 9 9 9.056 9 9.125v.75c0 .069.053.125.118.125h7.764c.065 0 .118-.056.118-.125v-.75c0-.069-.053-.125-.118-.125H9.118zm0-2C9.053 7 9 7.056 9 7.125v.75c0 .069.053.125.118.125h7.764c.065 0 .118-.056.118-.125v-.75c0-.069-.053-.125-.118-.125H9.118zM1.11 13c-.061 0-.111.056-.111.125v.75c0 .069.05.125.111.125H16.89c.061 0 .111-.056.111-.125v-.75c0-.069-.05-.125-.111-.125H1.11zm0 2c-.061 0-.111.056-.111.125v.75c0 .069.05.125.111.125H16.89c.061 0 .111-.056.111-.125v-.75c0-.069-.05-.125-.111-.125H1.11zm0-4c-.061 0-.111.056-.111.125v.75c0 .069.05.125.111.125H16.89c.061 0 .111-.056.111-.125v-.75c0-.069-.05-.125-.111-.125H1.11zM.107 0h17.786c.059 0 .107.075.107.167v1.666c0 .092-.048.167-.107.167H.107C.048 2 0 1.925 0 1.833V.167C0 .075.048 0 .107 0zM1.5 1.5a.5.5 0 100-1 .5.5 0 000 1zm2 0a.5.5 0 100-1 .5.5 0 000 1zm2 0a.5.5 0 100-1 .5.5 0 000 1z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

WebPageIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(WebPageIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'WebPageIcon';
export default ForwardRef;