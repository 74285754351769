import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var HierarchyIcon = function HierarchyIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M8.957.009a.11.11 0 01.086 0l8.89 3.744c.025.011.045.031.056.057a.107.107 0 01-.057.14L9.043 7.695a.11.11 0 01-.086 0L.067 3.95a.107.107 0 010-.197L8.958.01zM4.811 6.873a.11.11 0 01.085 0l4.062 1.68a.11.11 0 00.084 0l4.062-1.68a.11.11 0 01.085 0l4.745 2.028a.107.107 0 010 .197l-8.891 3.745a.11.11 0 01-.086 0L.067 9.098a.107.107 0 010-.197L4.81 6.873zm0 5.148a.11.11 0 01.085 0l4.062 1.68a.11.11 0 00.084 0l4.062-1.68a.11.11 0 01.085 0l4.745 2.028a.107.107 0 010 .197l-8.891 3.745a.11.11 0 01-.086 0l-8.89-3.745a.107.107 0 010-.197l4.744-2.028z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

HierarchyIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(HierarchyIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'HierarchyIcon';
export default ForwardRef;