import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var MaxIcon = function MaxIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M6 5.75V.25A.25.25 0 016.25 0h5.5a.25.25 0 01.25.25v5.5c0 .138.112.25.25.25h5.5a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25h-5.5a.25.25 0 00-.25.25v5.5a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-5.5a.25.25 0 00-.25-.25H.25a.25.25 0 01-.25-.25v-5.5A.25.25 0 01.25 6h5.5A.25.25 0 006 5.75z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

MaxIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(MaxIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'MaxIcon';
export default ForwardRef;