import styled from '@emotion/styled/macro';
import { color } from 'styled-system';

export const Backdrop = styled.div(
  {
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    // Remove grey highlight
    WebkitTapHighlightColor: 'transparent',
    // Disable scroll capabilities.
    touchAction: 'none'
  },
  color
);

Backdrop.defaultProps = {
  'aria-hidden': true
};
