import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ComponentsIcon = function ComponentsIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M13.989 7.998a.987.987 0 01-.301.71A1.04 1.04 0 0112.96 9 1.013 1.013 0 0112 8c0-.528.42-.965.96-1 .274-.003.538.1.734.287a.987.987 0 01.306.71h-.011zM17 9c-.528 0-.965-.437-1-1 .035-.563.472-1 1-1s.965.437 1 1c-.035.563-.472 1-1 1zm-.998 3a.923.923 0 01.479-.864c.319-.181.72-.181 1.038 0a.923.923 0 01.479.864.923.923 0 01-.479.864c-.319.181-.72.181-1.038 0a.923.923 0 01-.479-.864zm.479 3.136c.319-.181.72-.181 1.038 0a.923.923 0 01.479.864.923.923 0 01-.479.864c-.319.181-.72.181-1.038 0a.923.923 0 01-.479-.864.923.923 0 01.479-.864zM13 17c-.563-.035-1-.472-1-1s.437-.965 1-1c.563.035 1 .472 1 1s-.437.965-1 1zm-3-1c-.035.563-.472 1-1 1s-.965-.437-1-1c.035-.563.472-1 1-1s.965.437 1 1zm-.37-3.556l-1.432-1.48 2.627-2.12 4.305 3.488a.158.158 0 01.027.215 3.165 3.165 0 01-3.05 1.194 4.51 4.51 0 01-2.477-1.297zM2.563 9.63c.441-.044.886.018 1.3.181l1.601-1.64L3.862 6.53a2.808 2.808 0 01-3.141-.757 2.89 2.89 0 01-.395-3.244 2.822 2.822 0 012.866-1.505A2.824 2.824 0 015.18 2.262a2.89 2.89 0 01.39 2.329l1.896 1.533L9.644 3.9a4.515 4.515 0 012.469-1.297 3.163 3.163 0 013.044 1.203.158.158 0 01-.027.215l-9.56 7.726c.099.383.119.782.059 1.172-.226 1.47-1.528 2.521-2.995 2.416-1.467-.105-2.61-1.33-2.628-2.819-.019-1.488 1.093-2.742 2.557-2.884zM1.482 3.858a1.36 1.36 0 001.351 1.368c.359 0 .702-.145.956-.401.253-.256.396-.604.396-.967a1.36 1.36 0 00-1.352-1.367 1.36 1.36 0 00-1.351 1.367zm0 8.615a1.36 1.36 0 001.351 1.367 1.36 1.36 0 001.352-1.367 1.36 1.36 0 00-1.352-1.368 1.36 1.36 0 00-1.351 1.368z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ComponentsIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ComponentsIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ComponentsIcon';
export default ForwardRef;