export function normalizeChannel(channel, value) {
  if (['saturation', 'brightness'].includes(channel)) {
    return value / 100;
  }
  return value;
}

export function normalizeStops(stops) {
  return stops.map((s, index) => ({
    ...s,
    id: index + 1,
    offset: Number(s.offset)
  }));
}

export function generateCssBackground(gradient) {
  return `
    linear-gradient(${gradient
      .map(stop => `${stop.color} ${stop.offset * 100}%`)
      .join(',')})
  `;
}

export function toPercentage(decimal) {
  return (decimal.toPrecision(2) * 100).toFixed(0);
}

export const hueBg = `
  linear-gradient(to right,#ff0000 0%,#ff9900 10%,#cdff00 20%,#35ff00 30%,#00ff66 40%,#00fffd 50%,#0066ff 60%,#3200ff 70%,#cd00ff 80%,#ff0099 90%,#ff0000 100%)
`;

export const getSaturationBg = (hue, brightness) => {
  return `
    -webkit-linear-gradient(left, ${[0, 20, 40, 60, 80, 100]
      .map(step => {
        return `hsla(${hue}, ${step}%, ${brightness}%, 1)`;
      })
      .join(', ')})
  `;
};

export const getBrightnessBg = (hue, saturation) => {
  return `
    -webkit-linear-gradient(left, ${[0, 20, 40, 60, 80, 100]
      .map(step => {
        return `hsla(${hue}, ${saturation}%, ${step}%, 1)`;
      })
      .join(', ')})
  `;
};
