import { compile } from './compile';
import { registerDeps } from '../plugins/deps';
import { getRequireContext } from '../consumer';
import { join, walkSync } from '../../../utils';
import injector from '../../../injector';
import { getSelectedProjectId } from '../../git/queries';
import { createServiceToken } from '../../core/commands';
const inject = injector.inject;

const babelModuleMapToModuleNames = arr =>
  arr.map(a => (Array.isArray(a) ? a[0] : a));

// TODO look at how
// 1 we can potentially name the variables (AirPage)
// or
// 2 we can potentially use iframes
const getRemoteModuleRequireContext = async url => {
  const code = await fetch(url).then(response => response.text());
  const module = {
    exports: {}
  };
  (function anon(str) {
    return eval(str);
  }.call({ module }, code));
  return module.exports.AirPage;
};

const getTranspilerWithPresetsAndPlugins = ({ babelRequire, babelConfig }) => {
  const babelSlim = babelRequire.require('babel-slim');
  // presets
  babelModuleMapToModuleNames(babelConfig.presets).forEach(dep => {
    const preset = babelRequire.require(dep);
    babelSlim.registerPreset(dep, preset);
  });

  // plugins
  babelModuleMapToModuleNames(babelConfig.plugins).forEach(dep => {
    const plugin = babelRequire.require(dep);
    babelSlim.registerPlugin(dep, plugin);
  });
  return babelSlim;
};

const getBabelBundleContext = async ({ pkg, babelConfig }) => {
  // NOTE babel-slim transpiler requirements are already in @babel/core!
  babelModuleMapToModuleNames(babelConfig.presets).forEach(dep => {
    if (!pkg.devDependencies.hasOwnProperty(dep)) {
      throw new Error('babel presets requires missing module: ' + dep);
    }
  });
  babelModuleMapToModuleNames(babelConfig.plugins).forEach(dep => {
    if (!pkg.devDependencies.hasOwnProperty(dep)) {
      throw new Error('babel plugins requires missing module: ' + dep);
    }
  });

  const babel = Object.keys(pkg.devDependencies)
    .reduce((deps, key) => {
      return deps.concat([key, pkg.devDependencies[key]].join('@'));
    }, [])
    .join('+');

  const babelRequire = await getRemoteModuleRequireContext(
    `${process.env.REACT_APP_NPM_BUNDLER_URL}/b/${babel}/bundle.js?target=node`
  );
  return babelRequire;
};

const getAppBundleContext = async ({ pkg }) => {
  const keys = Object.keys(pkg.dependencies);
  if (!keys.length) {
    // no deps
    return {};
  }
  const app = keys
    .reduce((deps, key) => {
      return deps.concat([key, pkg.dependencies[key]].join('@'));
    }, [])
    .join('+');

  const TOKEN = await createServiceToken();
  const projectId = getSelectedProjectId();
  const appRequire = await getRemoteModuleRequireContext(
    `${
      process.env.REACT_APP_NPM_BUNDLER_URL
    }/b/${app}/bundle.js?token=${TOKEN}&projectId=${projectId}`
  );
  return appRequire;
};

const makeTransformer = ({ babelConfig, transform, template, types }) => {
  const error = template(`throw new Error(MESSAGE);`);
  return (filename, code) => {
    const deps = [];
    let result = null;

    try {
      result = transform(code, {
        filename,
        ast: true,
        presets: babelConfig.presets,
        plugins: babelConfig.plugins.concat(registerDeps(filename, deps))
      });
    } catch (e) {
      console.log(e);
      return {
        body: error({ MESSAGE: types.stringLiteral(e.message) }),
        deps: []
      };
    }
    return {
      body: result.ast.program.body,
      deps
    };
  };
};

const transpile = async ({ pkg, babelConfig, main, files }) => {
  babelConfig.presets = babelConfig.presets || [];
  babelConfig.plugins = babelConfig.plugins || [];

  // TODO work with Aaron to do this in an iframe portal
  const babelRequire = await getBabelBundleContext({ pkg, babelConfig });

  // NOTE these two babel plugins come inside of core, so we don't need to explicitly require
  const template = babelRequire.require('@babel/template').default;
  const generate = babelRequire.require('@babel/generator').default;
  const types = babelRequire.require('@babel/types');
  const babelSlim = getTranspilerWithPresetsAndPlugins({
    babelRequire,
    babelConfig
  });

  const transform = makeTransformer({
    babelConfig,
    transform: babelSlim.transform,
    template,
    types
  });

  // compile it
  return compile({
    path: main,
    transform,
    generate,
    types,
    template,
    files
  });
};

const getTranspiledContext = async ({ pkg, babelConfig, main, files }) => {
  const generated = await transpile({ pkg, babelConfig, main, files });
  const out = generated.code;

  // build bundle
  const normalRequire = await getAppBundleContext({ pkg });

  // merge local context with bundle
  const airpage = getRequireContext(normalRequire);
  (function anon(str) {
    return eval(str);
  }.call({ airpage }, out));

  return airpage;
};

const getBabelTranspilerArguments = inject(['fs'], function({ dir }) {
  const fs = this.fs;
  const pkgPath = join(dir, '/package.json');
  const pkg = JSON.parse(fs.readFileSync(pkgPath).toString());
  const babelPath = join(dir, '/.babelrc');
  const babelConfig = JSON.parse(fs.readFileSync(babelPath).toString());
  const main = join(dir, pkg.main);
  const patterns = (pkg.files || []).map(pattern => join(dir, pattern));
  const files = patterns.reduce((files, pattern) => {
    if (!fs.existsSync(pattern)) return files; // for now don't support globbing, sorry!
    if (fs.statSync(pattern).isDirectory()) {
      return files.concat(walkSync(pattern));
    }
    return files.concat(pattern);
  }, []);

  return {
    babelConfig,
    main,
    pkg,
    files
  };
});

export { getBabelTranspilerArguments, transpile, getTranspiledContext };
