import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ContactInfoIcon = function ContactInfoIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.281 0H17.72c.155 0 .281.126.281.281V2.25H0V.281C0 .126.126 0 .281 0zM0 16.875h18v.844a.281.281 0 01-.281.281H.28A.281.281 0 010 17.719v-.844zM0 2.25h1.125v14.625H0V2.25zm16.875 0H18v14.625h-1.125V2.25zM6 4c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4zm0 1.677a1.68 1.68 0 00-1.677 1.678A1.68 1.68 0 006 9.032a1.68 1.68 0 001.677-1.677A1.68 1.68 0 006 5.677zm0 6.065a3.728 3.728 0 002.61-1.065A3.008 3.008 0 006.065 9.29c-1.058 0-2.047.56-2.595 1.464a3.727 3.727 0 002.53.988zM11.25 4h4.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm0 2h4.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm0 2h4.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm0 2h4.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm0 2h4.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm0 2h4.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ContactInfoIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ContactInfoIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ContactInfoIcon';
export default ForwardRef;