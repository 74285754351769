import React, { memo, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { AuthForm, AuthHeading, Field, Checkbox, Button } from '../../ui';
import { propsAreEqual } from '../../utils/react';
import { AcceptTermsMutation } from '../../mutations';
import { TERMS_URL } from '../../urls';

const AgreeToTerms = memo(function AgreeToTerms(props) {
  const { step, setStep } = props;
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const changed = () => {
    setAgreeToTerms(!agreeToTerms);
  };
  const [acceptTerms] = useMutation(AcceptTermsMutation, {
    onCompleted: () => {
      setStep(step + 1);
    },
    onError: () => {
      // setNotification({text: ..., variant: 'danger'})
    }
    // refetchQueries: [
    //   query:
    //   variables:
    // ]
  });

  return (
    <AuthForm
      onSubmit={event => {
        event.preventDefault();
        console.log({ agreeToTerms });
        if (agreeToTerms) {
          acceptTerms().then(result => {
            console.log(result);
          });
        }
      }}
    >
      <AuthHeading>Terms of Service</AuthHeading>
      <Field fluid={true}>
        <Checkbox name="accept terms" value={agreeToTerms} onChange={changed} />{' '}
        Check the box to verify that you have read and agreed to the{' '}
        <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
      </Field>
      <Button
        fluid={true}
        disabled={!agreeToTerms}
        type="submit"
        variant="primary"
      >
        Agree
      </Button>
    </AuthForm>
  );
}, propsAreEqual);

export { AgreeToTerms };
