import styled from '@emotion/styled/macro';
import { em } from 'polished';

export const Tree = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: em(6),
  listStyle: 'none'
});

Tree.defaultProps = {
  role: 'tree'
};
