import injector from '../../injector';
import {
  CreateServiceTokenMutation,
  UpsertProjectSecretMutation
} from '../../../mutations';
import { getSelectedProjectId } from '../git/queries';

import { mkdirpSync, rimrafSync, walkSync } from '../../utils';
import {
  getProcess,
  getActiveProcessId,
  getProcessPath
} from '../process/queries';
import { getAvailableName, dirname } from '../../utils';

import {
  GIT_DIRECTORY_PATH,
  PROCESSES_DIRECTORY_PATH,
  REPOS_DIRECTORY_PATH,
  TERMINAL_DIRECTORY_PATH,
  WORKSPACES_DIRECTORY_PATH
} from '../../constants';
import { getSelectionPaths } from './queries';

const { inject } = injector;

const initializeFileSystem = inject(['fs'], function initializeFileSystem() {
  const { fs } = this;
  if (fs.existsSync('/etc')) return;
  // fs.getRootFS().rootFs.empty();
  mkdirpSync('/etc');
  mkdirpSync(WORKSPACES_DIRECTORY_PATH);
  mkdirpSync(TERMINAL_DIRECTORY_PATH);
  mkdirpSync(REPOS_DIRECTORY_PATH);
  mkdirpSync(GIT_DIRECTORY_PATH);
  mkdirpSync(PROCESSES_DIRECTORY_PATH);
});

const initializeProjectFolder = inject(
  ['path'],
  function initializeProjectFolder(projId) {
    const { path } = this;
    const REPO_DIRECTORY_PATH = path.join(REPOS_DIRECTORY_PATH, projId);
    mkdirpSync(REPO_DIRECTORY_PATH);
  }
);

const signOut = inject(['fs', 'apollo'], function signOut() {
  this.fs.getRootFS().rootFs.empty();
  this.apollo.writeData({ data: { isSignedIn: false } });
  window.localStorage.clear();
});

const signIn = inject(['fs', 'apollo'], function signIn(token) {
  localStorage.setItem('token', token);
  this.apollo.writeData({ data: { isSignedIn: true } });
  initializeFileSystem();
});

const createServiceToken = inject(
  ['apollo'],
  async function createServiceToken() {
    const client = this.apollo;
    const {
      data: {
        createServiceToken: {
          token: { accessToken }
        }
      }
    } = await client.mutate({
      mutation: CreateServiceTokenMutation
    });
    return accessToken;
  }
);

const upsertProjectSecret = inject(
  ['apollo'],
  async function upsertProjectSecret({
    projectId = getSelectedProjectId(),
    secretName,
    value
  } = {}) {
    const client = this.apollo;
    const {
      data: {
        upsertProjectSecret: { success }
      }
    } = await client.mutate({
      mutation: UpsertProjectSecretMutation,
      variables: {
        projectId,
        secretName,
        value
      }
    });
    console.log({ success });
    return [];
  }
);
upsertProjectSecret.description = 'Add/Update Project Secrets';
upsertProjectSecret.args = [
  {
    name: 'projectId',
    type: 'string'
  },
  {
    _: true,
    name: 'secretName',
    required: true
  },
  {
    _: true,
    name: 'value',
    required: true
  }
];

const writeState = inject(['fs'], function writeState(path, payload) {
  this.fs.writeFileSync(path, JSON.stringify(payload));
  return [path];
});

const writeFile = inject(['fs'], function writeFile({ path, content }) {
  this.fs.writeFileSync(path, content);
  return [path];
});

const createFile = inject(['fs'], function createFile({ paths }) {
  paths.forEach(path => {
    this.fs.writeFileSync(path, '');
  });
});

// TODO make sources an array
const copyFile = inject(['fs'], function copyFile({
  source,
  target,
  recursive
}) {
  const { fs } = this;
  if (fs.statSync(source).isDirectory()) {
    if (recursive) {
      if (source === '/') {
        throw new Error('cannot copy root');
      }
      if (source === target) {
        throw new Error('cannot copy self');
      }
      if (!recursive) {
        throw new Error('cannot copy directories without recursive option');
      }
      const files = walkSync(source);
      const regexp = new RegExp('^' + source);
      for (let src of files) {
        const tgt = src.replace(regexp, target);
        const dir = dirname(tgt);
        mkdirpSync(dir);
        const contents = fs.readFileSync(src).toString();
        fs.writeFileSync(tgt, contents);
      }
      return [];
    }
  } else {
    const contents = fs.readFileSync(source).toString();
    fs.writeFileSync(target, contents);
  }
  return [];
});

const symLink = ({ source, target }) => {
  // fs.linkSync(src, dst);
};

const rename = inject(['fs', 'path'], function rename({ sources, target }) {
  const { fs, path } = this;
  let isDir = false;
  if (fs.existsSync(target) && fs.statSync(target).isDirectory()) {
    isDir = true;
  }

  return sources.map(sourcePath => {
    if (sourcePath === '/') {
      throw new Error('cannot rename root!');
    }
    const targetPath = isDir
      ? path.join(target, path.basename(sourcePath))
      : target;
    fs.renameSync(sourcePath, targetPath);
    return targetPath;
  });
});

const safeRename = inject(['fs', 'path'], function safeRename({
  sources,
  target
}) {
  const { fs, path } = this;
  let isDir = false;
  if (fs.existsSync(target) && fs.statSync(target).isDirectory()) {
    isDir = true;
  }
  return sources.map(sourcePath => {
    if (sourcePath === '/') {
      throw new Error('cannot rename root!');
    }
    // TODO refactor this into an option on rename
    if (isDir) {
      const name = getAvailableName({
        root: target,
        name: path.basename(sourcePath)
      });
      fs.renameSync(sourcePath, path.join(target, name));
      return path.join(target, name);
    } else {
      fs.renameSync(sourcePath, target);
      return target;
    }
  });
});

const removeFile = inject(['fs'], function removeFile({ paths }) {
  paths.forEach(this.fs.unlinkSync);
});

const remove = ({ paths, recursive }) => {
  if (!recursive) return removeFile({ paths });
  return paths.map(path => {
    rimrafSync(path);
    return path;
  });
};

const createDirectory = inject(['fs'], function createDirectory({
  recursive,
  paths
}) {
  paths.forEach(path => {
    if (recursive) {
      mkdirpSync(path);
    } else {
      this.fs.mkdirSync(path);
    }
  });
});

const changeDirectory = inject(['fs', 'path'], function changeDirectory({
  pid = getActiveProcessId(),
  path
} = {}) {
  if (!path) {
    // NOTE this only gets hit from Command Palette
    const paths = getSelectionPaths({ pid });
    if (paths.length) {
      path = paths[0];
    }
  }

  if (!path) return [];

  const proc = getProcess(pid);
  path = this.path.resolve(proc.cwd, path);

  if (!this.fs.existsSync(path)) {
    throw new Error(`${path} no such file or directory.`);
  }

  if (!this.fs.statSync(path).isDirectory()) {
    return [];
  }

  proc.cwd = this.path.resolve(proc.cwd, path);
  proc.selection = [];
  const procPath = getProcessPath(proc.pid);
  writeState(procPath, proc);
  return [procPath];
});

changeDirectory.description = 'change directory';
changeDirectory.args = [
  {
    _: true,
    name: 'path',
    type: 'path',
    default: '/'
  }
];

const setSelection = ({ pid = getActiveProcessId(), selection = [] } = {}) => {
  const proc = getProcess(pid);
  if (!proc) throw new Error('cannot find process!');
  proc.selection = selection.filter(Boolean);
  const procPath = getProcessPath(pid);
  writeState(procPath, proc);
  return [procPath];
};
setSelection.description = 'select nodes';
setSelection.args = [
  {
    _: true,
    name: 'selection',
    type: 'array',
    items: {
      type: 'string'
    },
    required: true
  }
];

export {
  changeDirectory,
  copyFile,
  createDirectory,
  createServiceToken,
  createFile,
  initializeFileSystem,
  initializeProjectFolder,
  remove,
  removeFile,
  rename,
  safeRename,
  setSelection,
  signIn,
  signOut,
  symLink,
  upsertProjectSecret,
  writeFile,
  writeState
};
