import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var FilterIcon = function FilterIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 .11C0 .05.052 0 .106 0h17.788c.059 0 .106.049.106.11v1.066a.11.11 0 01-.106.11H.106A.108.108 0 010 1.176V.11zm.053 2.125c-.03-.051-.001-.092.053-.092h17.788c.059 0 .084.038.053.092l-7.608 13.102a.336.336 0 00-.036.196l.394 2.363a.087.087 0 01-.09.104H7.393c-.059 0-.099-.045-.089-.104l.394-2.363a.332.332 0 00-.036-.196L.053 2.235z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

FilterIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(FilterIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'FilterIcon';
export default ForwardRef;