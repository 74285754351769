import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { em, padding, transparentize } from 'polished';

const variant = () => {
  return css`
    background-color: ${transparentize(0.95, 'rgb(255, 255, 255)')};
    border-color: ${transparentize(0.2, 'rgb(0, 0, 0)')};
    box-shadow: 0 ${em(1)} 0 0 ${transparentize(0.85, 'rgb(255, 255, 255)')};
    color: rgb(250, 250, 250);

    &:hover,
    &:focus,
    &:active {
      background-color: ${transparentize(0.97, 'rgb(255, 255, 255)')};
      border-color: rgb(0, 160, 255);
      box-shadow: 0 ${em(1)} 0 0 ${transparentize(0.85, 'rgb(0, 0, 0)')};
    }

    &::placeholder {
      color: rgb(180, 180, 180);
    }

    &:required,
    &[aria-required='true'] {
      outline-color: rgb(29, 175, 124);
    }

    &:invalid,
    &[aria-invalid='true'] {
      outline-color: rgb(225, 36, 64);
    }

    &:disabled,
    $[aria-disabled='true'] {
      background-color: transparent;
      border-color: ${transparentize(0.7, 'rgb(180, 180, 180)')};
      color: ${transparentize(0.7, 'rgb(180, 180, 180)')};
    }
  `;
};

export const Radio = styled.input`
  ${padding(em(6 - 0.5), em(12 - 0.5))};
  border-radius: ${em(3)};
  border-style: solid;
  border-width: 0.5px;
  box-sizing: border-box;
  display: inline-block;
  font: inherit;
  line-height: ${24 / 16};
  vertical-align: middle;
  ${variant}
`;

Radio.defaultProps = {
  type: 'radio'
};
