import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var MobilePortraitIcon = function MobilePortraitIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.25 16h9.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-9.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25zM9 17.5a.5.5 0 100-1 .5.5 0 000 1zM4.25 0h9.5a.25.25 0 01.25.25v14.5a.25.25 0 01-.25.25h-9.5a.25.25 0 01-.25-.25V.25A.25.25 0 014.25 0zM5 1.129V3.87c0 .077.055.129.123.129H6V2.125C6 2.058 6.056 2 6.125 2H8v-.877A.125.125 0 007.871 1H5.13A.125.125 0 005 1.129z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

MobilePortraitIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(MobilePortraitIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'MobilePortraitIcon';
export default ForwardRef;