import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var TimeMachineIcon = function TimeMachineIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M7 7V1H6v6h1zm.85 3.1c-1.571.639-2.436 1.379-3.325 2.595a6.5 6.5 0 117.712-3.138c-1.642-.198-2.815-.096-4.386.543zM7 6v1h4V6H7zm4.25 4.125c-2.58 0-4.918 1.315-6.644 3.451a.471.471 0 000 .595c1.726 2.139 4.065 3.454 6.644 3.454 2.58 0 4.918-1.315 6.644-3.451a.471.471 0 000-.595c-1.726-2.139-4.065-3.454-6.644-3.454zm.196 6.744a3.002 3.002 0 01-3.19-3.19 3.007 3.007 0 012.798-2.798 3.002 3.002 0 013.19 3.19 3.016 3.016 0 01-2.798 2.798zm-.052-.749a2.246 2.246 0 01-2.389-2.39 2.26 2.26 0 012.1-2.1 2.246 2.246 0 012.39 2.39 2.26 2.26 0 01-2.1 2.1z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

TimeMachineIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(TimeMachineIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'TimeMachineIcon';
export default ForwardRef;