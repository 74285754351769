import React from 'react';
import PropTypes from 'prop-types';
import { ListLayout } from './ListLayout';
import { GridLayout } from './GridLayout';

const layoutComponent = {
  list: ListLayout,
  grid: GridLayout
};

export const LayoutList = ({ layout, ...rest }) => {
  const Component = layoutComponent[layout] || layoutComponent.list;
  return <Component {...rest} />;
};

LayoutList.propTypes = {
  data: PropTypes.array,
  layout: PropTypes.oneOf(['list', 'grid']),
  as: PropTypes.any,
  renderItem: PropTypes.func.isRequired
};

LayoutList.defaultProps = {
  data: [],
  as: 'div'
};

export * from './GridLayout';
export * from './ListLayout';
