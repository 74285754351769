import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var H5Icon = function H5Icon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zM7.93 6.086v3.096H4.371V6.086h-.977v7.2h.977v-3.158h3.56v3.158h.976v-7.2H7.93zm4.65 2.458c-.289 0-.525.051-.741.123l.329-1.635h2.211v-.946h-2.952l-.864 3.97.576.113c.309-.38.772-.679 1.368-.679.906 0 1.512.648 1.512 1.481 0 .823-.596 1.45-1.44 1.45-.75 0-1.316-.555-1.48-1.264l-.896.4c.258 1.04 1.163 1.832 2.376 1.832 1.492 0 2.438-1.142 2.438-2.418 0-1.244-.956-2.427-2.438-2.427z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

H5Icon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(H5Icon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'H5Icon';
export default ForwardRef;