import gql from 'graphql-tag';

export const UpsertProjectSecretMutation = gql`
  mutation UpsertProjectSecretMutation(
    $projectId: UUID!
    $secretName: String!
    $value: String!
  ) {
    upsertProjectSecret(
      input: { projId: $projectId, secretName: $secretName, value: $value }
    ) {
      success: boolean
    }
  }
`;
