import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var RoundCornerIcon = function RoundCornerIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.875 0C5.944 0 6 .056 6 .125V11.87c0 .069.056.125.125.125h11.75c.069 0 .125.056.125.125v5.756a.125.125 0 01-.125.125H4c-2.536 0-4-1.464-4-4.001V.125C0 .056.056 0 .125 0h5.75z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

RoundCornerIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(RoundCornerIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'RoundCornerIcon';
export default ForwardRef;