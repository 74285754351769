// TODO put these into the database, query them dynamically
const CODES = {
  EMAIL_NOT_VERIFIED: {
    en: 'Email not verified. Please verify your email.'
  },
  ACCOUNT_LOCKED_EXCEED_ATTEMPTS: {
    en: 'User account locked - too many login attempts.'
  },
  ACCOUNT_LOCKED_EXCEED_MFA_ATTEMPTS: {
    en: 'User account locked - too many MFA attempts.'
  },
  INVITE_WRONG_EMAIL: {
    en: 'This invite is associated with another email.'
  },
  PASSWORD_RESET_LOCKED_EXCEED_ATTEMPTS: {
    en: 'Password reset locked - too many reset attempts.'
  },
  CONTENT_REQUIRES_PROJECT: {
    en: 'Content requires a project.'
  },
  SECRETS_NO_KEY_EXISTS: {
    en: 'Key does not exist.'
  },
  GENERIC_NO_OBJECT_EXISTS: {
    en: 'Object does not exist'
  },
  PROJECT_TRANSFERS_ALREADY_TRANSFERRED: {
    en: 'Project already transferred.'
  },
  PROJECT_TRANSFERS_NOT_FOUND: {
    en: 'Project transfer not found.'
  },
  PROJECT_TRANSFERS_OWNER_NOT_FOUND: {
    en: 'Owner of project not found.'
  },
  PROJECT_TRANSFERS_NEW_OWNER_NOT_FOUND: {
    en: 'New owner of project not found.'
  },
  NOT_AUTHENTICATED: {
    en: 'Not authenticated'
  },
  MFA_NOT_ENABLED: {
    en: 'You do not have 2fa enabled.'
  },
  MFA_ENABLED_TWICE: {
    en: 'You cannot enable 2fa again.'
  },
  TOKENS_REQUIRE_ROLE: {
    en: 'Tokens require a role_id.'
  },
  TOKENS_REQUIRE_USER_ROLE: {
    en: 'Cannot create a token for non user roles.'
  },
  TOKENS_CANT_CREATE_TOTP: {
    en: 'Cannot explicitly create TOTP tokens.'
  },
  TOKENS_REFRESH_TOTP: {
    en: 'Cannot refresh TOTP tokens.'
  },
  TOKENS_REFRESH_SERVICE: {
    en: 'Cannot refresh service tokens.'
  },
  TOKENS_REFRESH_NON_AUTH: {
    en: 'Cannot refresh this non-auth token type.'
  },
  ROLES_ONLY_TEAMS_HAVE_PARENTS: {
    en: 'parent_id only works on Teams.'
  },
  ROLES_TEAM_CIRCULAR_REF: {
    en: 'Circular reference detected in team hierarchy.'
  },
  ROLES_ROLE_DOES_NOT_EXIST: {
    en: 'Role does not exist.'
  },
  ROLES_TEAM_PARENT_TYPE_MISMATCH: {
    en: 'Team parents can only be either a Team or Organization.'
  },
  ROLES_ORGANIZATION_NO_PARENT: {
    en: 'Organization cannot have parent_id.'
  },
  ROLES_USER_NO_PARENT: {
    en: 'User cannot have parent_id.'
  },
  IMMUTABLE_PROPERTIES: {
    en: 'Cannot edit immutable properties.'
  },
  TEAMS_REQUIRE_ORGANIZATION_ID: {
    en: 'Teams require organization_id.'
  },
  TEAMS_NO_USERNAME: {
    en: 'Usernames are for Users and Organizations only.'
  },
  MEMBERSHIPS_CANNOT_GRANT_ORG_TEAM: {
    en: 'Cannot grant an organization to a team.'
  },
  MEMBERSHIPS_CANNOT_GRANT_TEAM: {
    en: 'Cannot grant teams, use parent_id instead.'
  },
  MEMBERSHIPS_CANNOT_GRANT_USER: {
    en: 'Cannot grant to a user role.'
  },
  MEMBERSHIPS_ONLY_USERS: {
    en: 'Currently only supporting User grants.'
  },
  ORGANIZATIONS_REQUIRE_ONE_OWNER: {
    en: 'Organizations reqwuire at least one owner.'
  },
  ORGANIZATION_MISMASTCH: {
    en: 'Organization Mismatch.'
  },
  INVITES_ONLY_ACCEPT_AFTER_ACCOUNT: {
    en: 'Invite cannot accept until account is created.'
  },
  INVITES_ONLY_INVITEE_ACCEPT: {
    en: 'Only invitee can accept an invite.'
  },
  INVITES_NO_INVITEE: {
    en: 'No invitee specified.'
  },
  INVITES_GROUP_NOT_FOUND: {
    en: 'Group not found.'
  },
  INVITES_ONLY_OWNER_INVITE_OWNER: {
    en: 'Only an owner can invite another owner.'
  },
  INVITES_ONLY_ADMIN_INVITE_ADMIN: {
    en: 'Only an admin can invite another admin.'
  },
  INVITES_ONLY_ADMIN_APPROVE: {
    en: 'Only admin can approve an invite.'
  },
  INVITES_ONLY_MEMBERS_CHANGE_EXPIRY: {
    en: 'Only members can change expiration of an invite.'
  }
};

export default (code, lang = 'en') => {
  if (CODES.hasOwnProperty(code)) {
    if (CODES[code].hasOwnProperty(lang)) {
      return CODES[code][lang];
    } else {
      return CODES[code].en;
    }
  } else {
    return `Error: ${code}`;
  }
};
