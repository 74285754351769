import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var FolderIcon = function FolderIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 2.178c0-.059.051-.107.107-.107h11.286c.06 0 .14-.035.179-.077l.856-.917A.287.287 0 0112.611 1h4.278c.061 0 .144.036.183.077l.856.917c.04.043.072.123.072.184v.965H0v-.965zm0 1.393h18V16.43H.106c-.059 0-.106-.047-.106-.112V3.571z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

FolderIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(FolderIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'FolderIcon';
export default ForwardRef;