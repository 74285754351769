import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var UploadIcon = function UploadIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.305 14.461a.609.609 0 01-.607-.607v-3.419c0-.94.697-1.717 1.6-1.85-.052-.477-.08-.971-.08-1.481 0-3.818 3.408-6.85 3.553-6.977L8.916 0l.144.127c.145.127 3.553 3.159 3.553 6.977 0 .507-.027 1-.079 1.473.928.11 1.65.901 1.65 1.858v3.42a.606.606 0 01-.827.565l-2.724-1.064c-.51.498-1.094.78-1.717.78-.618 0-1.195-.277-1.702-.766l-2.688 1.05a.61.61 0 01-.22.041zM7.078 5.67c0 1.013.825 1.837 1.838 1.837a1.839 1.839 0 001.837-1.837 1.84 1.84 0 00-1.837-1.837 1.84 1.84 0 00-1.838 1.837zM8.941 18a.22.22 0 01-.208-.15 3.975 3.975 0 00-.272-.63 1.587 1.587 0 01-.304.114.22.22 0 01-.22-.07c-.044-.05-1.074-1.251-1.074-2.365 0-.24.029-.48.086-.713a.22.22 0 01.352-.117c.508.42 1.051.632 1.614.632.58 0 1.138-.224 1.656-.665a.218.218 0 01.354.112c.063.242.094.495.095.75 0 1.114-1.03 2.316-1.074 2.367a.219.219 0 01-.22.069 1.614 1.614 0 01-.304-.114 3.93 3.93 0 00-.272.629.22.22 0 01-.209.151zM1 9.125C1 9.056 1.054 9 1.123 9h.754c.068 0 .123.058.123.125v1.75c0 .069-.054.125-.123.125h-.754A.125.125 0 011 10.875v-1.75zm15 2.998c0-.068.054-.123.123-.123h.754c.068 0 .123.054.123.123v.754a.122.122 0 01-.123.123h-.754a.122.122 0 01-.123-.123v-.754zm-13 4c0-.068.054-.123.123-.123h.754c.068 0 .123.054.123.123v.754a.122.122 0 01-.123.123h-.754A.122.122 0 013 16.877v-.754zM15 1.107c0-.06.054-.107.123-.107h.754c.068 0 .123.052.123.107v2.786c0 .06-.054.107-.123.107h-.754C15.055 4 15 3.948 15 3.893V1.107zM3 3.097C3 3.042 3.054 3 3.123 3h.754c.068 0 .123.042.123.096v1.808C4 4.957 3.946 5 3.877 5h-.754C3.055 5 3 4.958 3 4.904V3.096z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

UploadIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(UploadIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'UploadIcon';
export default ForwardRef;