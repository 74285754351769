import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { em, margin, mix, padding } from 'polished';
import themeGet from '@styled-system/theme-get';

const variant = props => ({
  backgroundColor: themeGet('colors.background.default')(props),
  borderColor: 'transparent',
  color: themeGet('colors.action.default')(props),

  '&:hover, &:focus': {
    backgroundColor:
      props.theme.mode === 'dark'
        ? mix(
            0.15,
            themeGet('colors.white')(props),
            themeGet('colors.background.default')(props)
          )
        : themeGet('colors.blue.0')(props),
    color:
      props.theme.mode === 'dark'
        ? themeGet('colors.action.hover')(props)
        : themeGet('colors.blue.1')(props)
  },

  '&:active, &[aria-current="true"]': {
    backgroundColor:
      props.theme.mode === 'dark'
        ? mix(
            0.15,
            themeGet('colors.black')(props),
            themeGet('colors.background.default')(props)
          )
        : mix(
            0.05,
            themeGet('colors.black')(props),
            themeGet('colors.blue.0')(props)
          ),
    color:
      props.theme.mode === 'dark'
        ? themeGet('colors.action.active')(props)
        : themeGet('colors.blue.1')(props)
  }
});

export const PaginationListItemLink = styled.a(
  {
    ...margin(0),
    ...padding(em(6 - 0.5)),
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    borderRadius: em(3),
    borderStyle: 'solid',
    borderWidth: 0.5,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    justifyContent: 'center',
    lineHeight: 18 / 16,
    minWidth: em(24),
    textDecoration: 'none'
  },
  variant
);

PaginationListItemLink.propTypes = {
  'aria-current': PropTypes.bool,
  'aria-disabled': PropTypes.bool
};
