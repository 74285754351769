import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { em } from 'polished';

const variant = css({
  backgroundColor: 'rgb(12,12,12)',
  borderColor: 'rgba(0,0,0,0.8)',
  color: 'rgb(180,180,180)',
  fontFamily: '"Source Code Pro", monospace'
});

export const Terminal = styled.label(
  {
    borderStyle: 'solid',
    borderWidth: 0.5,
    boxSizing: 'border-box',
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    fontSize: 14,
    lineHeight: 18 / 14,
    padding: em(12, 14),
    height: '100%'
  },
  variant
);

Terminal.defaultProps = {
  tabIndex: -1
};
