import React from 'react';
import styled from '@emotion/styled/macro';
import { em, padding, transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';
import { space, layout } from 'styled-system';
import { useForkRef } from '../../utils/react';

const variant = props => ({
  backgroundColor: themeGet('colors.input.background')(props),
  borderColor: themeGet('colors.input.border')(props),
  boxShadow: `0 ${em(1)} 0 0 ${transparentize(
    0.85,
    themeGet('colors.white')(props)
  )}`,
  color: themeGet('colors.input.color')(props),
  transition: 'backgroundColor 0.15s ease-in-out',
  [`&:hover,
  &:focus,
  &:active`]: {
    backgroundColor: transparentize(
      0.5,
      themeGet('colors.input.background')(props)
    ),
    borderColor: themeGet('colors.blue.1')(props),
    boxShadow: `0 ${em(1)} 0 0 ${transparentize(
      0.85,
      themeGet('colors.black')(props)
    )}`
  },

  '&::placeholder': {
    color: themeGet('colors.input.placeholder')(props)
  },

  [`&:required,
  &[aria-required='true']`]: {
    outlineColor: themeGet('colors.intent.success.0')(props)
  },

  [`&:invalid,
  &[aria-invalid='true']`]: {
    outlineColor: themeGet('colors.intent.error.0')(props)
  },

  [`&:disabled,
  $[aria-disabled='true']`]: {
    backgroundColor: 'transparent',
    borderColor: transparentize(
      0.7,
      themeGet('colors.input.placeholder')(props)
    ),
    color: transparentize(0.7, themeGet('colors.input.placeholder')(props))
  },

  [`&[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button`]: {
    '-webkit-appearance': 'none',
    margin: 0
  },

  "&[type='number']": {
    '-moz-appearance': 'textfield'
  }
});

const StyledInput = styled.input(
  props => ({
    ...padding(em(6 - 0.5), em(12 - 0.5)),
    borderRadius: em(3),
    borderStyle: 'solid',
    borderWidth: 0.5,
    boxSizing: 'border-box',
    display: props.fluid ? 'block' : 'inline-block',
    font: 'inherit',
    lineHeight: 24 / 16,
    verticalAlign: props.fluid ? undefined : 'middle',
    width: props.fluid ? '100%' : undefined
  }),
  variant,
  space,
  layout
);

export const Input = React.forwardRef(function Input(
  { autoFocus, ...rest },
  ref
) {
  const inputRef = React.useRef(null);
  const handleRef = useForkRef(inputRef, ref);
  React.useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return <StyledInput ref={handleRef} {...rest} />;
});
