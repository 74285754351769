import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';

const variant = props => ({
  backgroundColor: themeGet('colors.background.default')(props)
});

export const SideNavPanel = styled.div(
  {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    '&[hidden]': {
      display: 'none'
    }
  },
  variant
);

SideNavPanel.defaultProps = {
  role: 'tabpanel',
  tabIndex: 0
};

SideNavPanel.propTypes = {
  'aria-labelledby': PropTypes.string,
  hidden: PropTypes.bool,
  id: PropTypes.string.isRequired,
  tabIndex: PropTypes.number
};
