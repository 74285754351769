import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var MathIcon = function MathIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.068.026C.095.009 2.818 0 8.238 0h8.128l.783 1.736c.531 1.183.815 1.808.851 1.877h-.54l-.095-.18a4.355 4.355 0 00-.756-1.003 5.683 5.683 0 00-1.499-.99 7.669 7.669 0 00-1.904-.553c-.72-.12-1.4-.193-2.039-.218a81.026 81.026 0 00-2.47-.052h-6.05l.108.142c4.168 5.425 6.26 8.164 6.279 8.215.009.017.009.043 0 .077-.027.026-1.216 1.32-3.565 3.883l-3.565 3.857c0 .009.95.013 2.85.013h1.903c.801 0 1.378.005 1.729.013 1.224 0 2.214-.021 2.97-.064a18.176 18.176 0 002.255-.27c.748-.137 1.382-.347 1.904-.63a5.01 5.01 0 001.391-1.119c.19-.223.374-.531.554-.925H18c0 .008-.032.098-.095.27-.063.171-.175.45-.337.835-.162.386-.302.746-.419 1.08l-.783 1.993L8.251 18c-5.42 0-8.143-.009-8.17-.026C.027 17.957 0 17.92 0 17.86a.19.19 0 01.027-.078L7.224 9.94c0-.009-.148-.202-.445-.579-.297-.377-.77-.994-1.418-1.851-.648-.858-1.233-1.62-1.756-2.289L.014.527 0 .334C0 .154.023.051.068.026z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

MathIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(MathIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'MathIcon';
export default ForwardRef;