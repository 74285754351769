import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PaneContainerIcon = function PaneContainerIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 .106C0 .047.052 0 .106 0h17.788c.059 0 .106.052.106.106v17.788a.109.109 0 01-.106.106H.106A.109.109 0 010 17.894V.106zM1.25 17h6.5a.25.25 0 00.25-.25V1.25A.25.25 0 007.75 1h-6.5a.25.25 0 00-.25.25v15.5c0 .138.112.25.25.25zm8-7h7.5a.25.25 0 00.25-.25v-8.5a.25.25 0 00-.25-.25h-7.5a.25.25 0 00-.25.25v8.5c0 .138.112.25.25.25zm0 7h7.5a.25.25 0 00.25-.25v-5.5a.25.25 0 00-.25-.25h-7.5a.25.25 0 00-.25.25v5.5c0 .138.112.25.25.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PaneContainerIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PaneContainerIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PaneContainerIcon';
export default ForwardRef;