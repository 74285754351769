import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { slugify } from '../utils/string';
import { Input, Field, Label, Tooltip } from '../ui';
import { Popup } from './Popup';

export const UpdatePostForm = props => {
  const { onChange, updatePost } = props;
  const [name, setName] = useState(props.name);

  useEffect(() => {
    if (typeof onChange !== 'function') return;
    onChange({ name });
  }, [onChange, name]);

  return (
    <ProjectPostHeaderForm
      onSubmit={event => {
        event.preventDefault();
        updatePost({
          variables: {
            id: props.id,
            patch: { title: name, slug: slugify(name) }
          }
        });
      }}
    >
      <Popup
        placement="bottom-start"
        popup={
          <Tooltip>
            Your new post will be created as&nbsp;
            <strong>{slugify(name)}</strong>
          </Tooltip>
        }
      >
        {({ expanded, setExpanded }) => (
          <div>
            <Field fluid={true}>
              <Label fluid={true} htmlFor="post-name" id="post-name-label">
                Post Name
              </Label>
              <Input
                aria-label="Post name"
                autoFocus={true}
                fluid={true}
                name="name"
                placeholder="Post name"
                type="text"
                value={name}
                onChange={event => {
                  setName(event.target.value);
                  if (
                    !expanded &&
                    event.target.value !== slugify(event.target.value)
                  ) {
                    setExpanded(true);
                  } else if (
                    expanded &&
                    event.target.value === slugify(event.target.value)
                  ) {
                    setExpanded(false);
                  }
                }}
              />
            </Field>
            <input
              type="submit"
              style={{ display: 'none' }}
              role="presentation"
            />
          </div>
        )}
      </Popup>
    </ProjectPostHeaderForm>
  );
};

UpdatePostForm.propTypes = {
  updatePage: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

const ProjectPostHeaderForm = styled.form({ flex: '1 0 auto' });
