import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var LibraryIcon = function LibraryIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.094 6.75h4.312c.052 0 .094.048.094.108v11.034c0 .06-.042.108-.094.108H.094C.042 18 0 17.952 0 17.892V6.858c0-.06.042-.108.094-.108zm1.125 9c-.052 0-.094.063-.094.14v.844c0 .078.042.141.094.141H3.28c.052 0 .094-.063.094-.14v-.844c0-.078-.042-.141-.094-.141H1.22zm0-2.25c-.052 0-.094.063-.094.14v.844c0 .078.042.141.094.141H3.28c.052 0 .094-.063.094-.14v-.844c0-.078-.042-.141-.094-.141H1.22zM.094 2.25h4.312c.052 0 .094.063.094.14v3.094c0 .078-.042.141-.094.141H.094c-.052 0-.094-.063-.094-.14V2.39c0-.078.042-.141.094-.141zm6.75 2.25h4.312c.052 0 .094.046.094.102v13.296c0 .056-.042.102-.094.102H6.844c-.052 0-.094-.046-.094-.102V4.602c0-.056.042-.102.094-.102zm1.125 9a.094.094 0 00-.094.094v2.062c0 .052.042.094.094.094h2.062a.094.094 0 00.094-.094v-2.062a.094.094 0 00-.094-.094H7.97zM6.844 0h4.312c.052 0 .094.063.094.14v3.094c0 .078-.042.141-.094.141H6.844c-.052 0-.094-.063-.094-.14V.14c0-.078.042-.141.094-.141zm6.75 7.875h4.312c.052 0 .094.05.094.11v9.905c0 .06-.042.11-.094.11h-4.312c-.052 0-.094-.05-.094-.11V7.985c0-.06.042-.11.094-.11zm0-4.5h4.312c.052 0 .094.063.094.14V6.61c0 .078-.042.141-.094.141h-4.312c-.052 0-.094-.063-.094-.14V3.515c0-.078.042-.141.094-.141z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

LibraryIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(LibraryIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'LibraryIcon';
export default ForwardRef;