import gql from 'graphql-tag';

export const SignInMutation = gql`
  mutation SignInMutation($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password }) {
      token {
        accessToken
        roleId
      }
    }
  }
`;
