import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var NumberIcon = function NumberIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 0h18v18H0V0zm4.31 5.973L2.047 7.392l.442.802.957-.586v5.678h.967V5.973H4.31zm1.388 7.313h4.444v-.947H7.509c.493-.617.997-1.234 1.48-1.851.577-.73 1.07-1.44 1.07-2.345 0-1.132-.894-2.191-2.273-2.191-1.388 0-2.242 1.07-2.314 2.222l.905.257c.052-.864.607-1.512 1.41-1.512a1.26 1.26 0 011.285 1.255c0 .771-.504 1.378-1.05 2.057-.791 1.008-1.542 1.975-2.324 2.942v.113zm7.961-4.629l1.667-1.841v-.73h-4.022v.967h2.613L11.87 9.357l.452.483c.32-.175.617-.298 1.029-.298.833 0 1.47.607 1.47 1.43 0 .812-.606 1.45-1.45 1.45-.792 0-1.337-.576-1.512-1.265l-.915.4c.267.947 1.07 1.832 2.427 1.832 1.482 0 2.448-1.163 2.448-2.418 0-1.172-.822-2.18-2.16-2.314z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

NumberIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(NumberIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'NumberIcon';
export default ForwardRef;