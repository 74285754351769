import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var GraphicIcon = function GraphicIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M13 7a3.593 3.593 0 00-1 0c-2.732.44-4.604 2.265-5 5a3.777 3.777 0 000 1H.281A.281.281 0 010 12.719V.28C0 .126.126 0 .281 0H12.72c.155 0 .281.126.281.281V7zm0 10.996A4.999 4.999 0 1113 8a4.999 4.999 0 110 9.996z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

GraphicIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(GraphicIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'GraphicIcon';
export default ForwardRef;