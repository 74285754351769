import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ThumbnailViewIcon = function ThumbnailViewIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 14.681c0-.06.049-.11.111-.11h3.635a.11.11 0 01.111.11v1.067a.11.11 0 01-.111.11H.11a.11.11 0 01-.111-.11V14.68zm0 2.143c0-.06.052-.11.104-.11h5.792c.057 0 .104.05.104.11v1.066c0 .06-.052.11-.104.11H.104A.107.107 0 010 17.89v-1.066zm5.143-2.143c0-.06.046-.11.111-.11h12.635a.11.11 0 01.111.11v1.067c0 .06-.047.11-.111.11H5.254a.11.11 0 01-.111-.11V14.68zm2.143 2.143c0-.06.052-.11.106-.11h2.787c.059 0 .107.05.107.11v1.066c0 .06-.052.11-.107.11H7.392a.108.108 0 01-.106-.11v-1.066zm4.285 0c0-.06.048-.11.107-.11h6.216c.058 0 .106.05.106.11v1.066c0 .06-.048.11-.106.11h-6.216a.108.108 0 01-.107-.11v-1.066zM2.68 0H15.32c.06 0 .108.048.108.107V12.75c0 .06-.048.107-.108.107H2.68a.107.107 0 01-.108-.107V.107C2.571.048 2.62 0 2.68 0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ThumbnailViewIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ThumbnailViewIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ThumbnailViewIcon';
export default ForwardRef;