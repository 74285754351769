import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CircleAddIcon = function CircleAddIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M9 18A9 9 0 119 0a9 9 0 010 18zM7.875 7.875H3.656a.281.281 0 00-.281.281v1.688c0 .155.126.281.281.281h4.219v4.219c0 .155.126.281.281.281h1.688a.281.281 0 00.281-.281v-4.219h4.219a.281.281 0 00.281-.281V8.156a.281.281 0 00-.281-.281h-4.219V3.656a.281.281 0 00-.281-.281H8.16a.281.281 0 00-.28.281l-.005 4.219z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CircleAddIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CircleAddIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CircleAddIcon';
export default ForwardRef;