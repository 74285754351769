import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var EmailIcon = function EmailIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.111 5.892l8.75-5.85a.25.25 0 01.278 0l8.75 5.85A.25.25 0 0118 6.1v11.65a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25V6.1a.25.25 0 01.111-.208zm9.049 7.057l7.733-5.494a.244.244 0 00.054-.354.272.272 0 00-.214-.101H1.267A.26.26 0 001 7.253c0 .08.04.154.107.202l7.733 5.494a.279.279 0 00.32 0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

EmailIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(EmailIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'EmailIcon';
export default ForwardRef;