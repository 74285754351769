import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PlansIcon = function PlansIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.83 9.064A4.534 4.534 0 011.3 4.53a4.53 4.53 0 119.06.004 4.53 4.53 0 01-4.53 4.53zM4.247 6.322c-.052.2-.033.248.15.334.244.12.503.18.77.222.21.033.213.045.217.258.003.098.003.195.003.293 0 .123.06.195.188.199.143.003.289.003.431 0 .12-.004.18-.068.18-.188 0-.135.008-.266 0-.401-.007-.135.053-.203.184-.24.304-.083.559-.244.758-.484.547-.664.337-1.639-.44-2.07-.247-.135-.505-.24-.76-.345a1.561 1.561 0 01-.417-.232c-.247-.2-.199-.518.09-.646a.66.66 0 01.255-.052c.338-.019.657.045.96.191.15.071.199.049.252-.108.056-.165.1-.334.15-.503.03-.112-.008-.188-.117-.236a2.434 2.434 0 00-.604-.18c-.273-.042-.273-.045-.273-.319 0-.39 0-.39-.39-.39h-.169c-.18.008-.214.037-.218.221v.248c-.003.244-.003.24-.236.326-.562.202-.911.589-.949 1.204-.033.543.252.915.698 1.181.277.165.581.262.874.39.112.049.221.109.319.188.28.232.228.618-.105.764a1.054 1.054 0 01-.56.075 2.533 2.533 0 01-.854-.255c-.158-.082-.207-.063-.259.11-.045.15-.086.296-.128.446zm11.745 3.12c.398 0 .72.323.728.72v1.774a.72.72 0 01-.72.72h-.499c-.311 1.013-.915 1.92-1.732 2.648l-.023.018-.079.068c-.735.664-.802 1.44-.806 1.635v.585a.39.39 0 01-.39.39h-2.377a.39.39 0 01-.39-.39v-.559c-.274.03-.548.045-.829.045-.356 0-.709-.026-1.054-.071v.585a.39.39 0 01-.39.39h-2.37a.393.393 0 01-.39-.364v-.202c-.019-.413-.146-1.365-.892-2.134-.518-.529-.893-1.181-1.151-1.74a5.413 5.413 0 01-.45-1.196c-.004-.012-.004-.019-.004-.019a5.303 5.303 0 01.341-3.48A5.423 5.423 0 005.83 9.99a5.461 5.461 0 005.303-4.174c.082-.341.27-.648.532-.877.383-.334.938-.63 1.744-.732a.43.43 0 01.416.657c-.274.439-.51 1.05-.319 1.721a.506.506 0 01.015.105c.911.735 1.594 1.684 1.943 2.753h.528zm-2.966.128a.587.587 0 00.589-.589.587.587 0 00-.589-.588.587.587 0 00-.588.588c0 .326.262.589.588.589z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PlansIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PlansIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PlansIcon';
export default ForwardRef;