import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { em } from 'polished';
import { Box } from '../Box';

export const Avatar = styled(Box)(({ size, url }) => {
  const borderWidth = 0.5;
  const sizeWithBorder = size - borderWidth * 2;

  return {
    backgroundImage: `url(${url})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderColor: 'rgba(0,0,0,0.8)',
    borderRadius: em(0.5, 1),
    borderStyle: 'solid',
    borderWidth,
    fontSize: em(sizeWithBorder),
    height: em(sizeWithBorder, sizeWithBorder),
    width: em(sizeWithBorder, sizeWithBorder)
  };
});

Avatar.defaultProps = {
  as: 'img',
  alt: 'Avatar image',
  src:
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
};

Avatar.propTypes = {
  size: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired
};
