import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import { Query } from '@apollo/react-components';

import { client } from './apollo';
import { UserContext } from './contexts/UserContext';
import { DraggableProvider } from './contexts/DraggableContext';
import { AppProvider } from './contexts/AppContext';
import { IsSignedInQuery, UserAchievedQuery } from './queries';
import { InviteFriends } from './screens/InviteFriends';
import { SimpleWithFooter } from './layouts/SimpleWithFooter';
import { ErrorPage } from './screens/ErrorPage';

import { Loader, Alert, globalStyles, AuthLoader } from './ui';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { Global } from '@emotion/core';
import ErrorBoundary from 'react-error-boundary';
import React, { lazy, Suspense, memo } from 'react';
import { useTheme } from './ui/Theme';
import { propsAreEqual } from './utils/react';
import { SignIn } from './screens/SignIn';
import { SignUp } from './screens/SignUp';
import { NotificationPortal } from './components/NotificationPortal';
import { CompleteProfile } from './screens/CompleteProfile/index';

const Dashboard = lazy(() => import('./screens/Dashboard'));
const VerifyEmail = lazy(() => import('./screens/VerifyEmail'));
const Project = lazy(() => import('./screens/Project'));
const Workspace = lazy(() => import('./screens/Workspace'));

const ErrorFallback = memo(function ErrorFallback(props) {
  return (
    <NotificationPortal>
      <Alert variant="danger">
        There was an error
        <pre style={{ maxWidth: 700 }}>
          {JSON.stringify(props.error, null, 2)}
        </pre>
      </Alert>
    </NotificationPortal>
  );
}, propsAreEqual);

const FullAccess = () => (
  <Router>
    <Switch>
      <Route path="/workspace/:projectId" component={Workspace} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/invites" component={InviteFriends} />
      <Route path="/verify-email/:id/:token" component={VerifyEmail} />
      <Route path="/project/:projectId" component={Project} />
      <Redirect from="/signin" to="/dashboard" />
      <Route render={() => <Redirect to="/dashboard" />} />
    </Switch>
  </Router>
);

const AuthenticatedProfileCompletion = props => (
  <Router>
    <Switch>
      <Route path="/verify-email/:id/:token" component={VerifyEmail} />
      <Route path="/invites" component={InviteFriends} />
      <Route render={() => <CompleteProfile {...props} />} />
    </Switch>
  </Router>
);

const Authenticated = () => {
  const { data, loading, error } = useQuery(UserAchievedQuery, {
    variables: { achievement: 'profile_complete' }
  });
  if (loading)
    return (
      <ErrorPage>
        <Loader />
      </ErrorPage>
    );
  if (error) {
    console.log(error.message);

    if (error.message.match(/Network error: Failed to fetch/)) {
      return (
        <ErrorPage>
          Looks like we have a slight issue with our network. We are working on
          it ASAP. Sorry for any inconvenience! Please contact{' '}
          <a href="mailto:support@airpage.com">support</a> if this continues.
        </ErrorPage>
      );
    }

    return (
      <ErrorPage>
        Something went wrong. Sorry for any inconvenience! Please contact{' '}
        <a href="mailto:support@airpage.com">support</a> if this continues.
      </ErrorPage>
    );
  }
  if (data.complete) {
    return <FullAccess />;
  }
  return <AuthenticatedProfileCompletion />;
};

const Anonymous = () => (
  <Router>
    <Switch>
      <Route path="/signup" component={SignUp} />
      <Route path="/verify-email/:id/:token" component={VerifyEmail} />
      <Route component={SignIn} />
    </Switch>
  </Router>
);

export const App = () => {
  const [theme, setTheme] = useTheme('light');
  return (
    <AppProvider theme={theme} setTheme={setTheme}>
      <DraggableProvider>
        <ApolloProvider client={client}>
          <Global styles={globalStyles} />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<Loader />}>
              <Query query={IsSignedInQuery}>
                {({ loading, error, data }) => {
                  if (loading) {
                    return <Loader />;
                  }
                  return data.isSignedIn ? (
                    <UserContext.Provider value={{ signedIn: true }}>
                      <Authenticated />
                    </UserContext.Provider>
                  ) : (
                    <Anonymous />
                  );
                }}
              </Query>
            </Suspense>
          </ErrorBoundary>
        </ApolloProvider>
      </DraggableProvider>
    </AppProvider>
  );
};
