import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var InsertRightIcon = function InsertRightIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M11.871 10.31l-4.51-4.789a.108.108 0 01.003-.151l1.083-1.002a.107.107 0 01.151.005l6.333 6.723a.108.108 0 010 .148l-6.333 6.722a.107.107 0 01-.15.006L7.364 16.97a.108.108 0 01-.006-.152l4.512-4.79H.111A.11.11 0 010 11.922v-1.504c0-.06.047-.107.111-.107h11.76zm2.7-8.592V.108c0-.06.05-.108.108-.108h1.5c.059 0 .107.045.107.108v1.61h1.607c.059 0 .107.05.107.108v1.503c0 .06-.044.108-.107.108h-1.607v1.61c0 .06-.05.108-.108.108h-1.5a.105.105 0 01-.107-.107V3.437h-1.606a.108.108 0 01-.108-.108V1.826c0-.06.045-.108.108-.108h1.606z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

InsertRightIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(InsertRightIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'InsertRightIcon';
export default ForwardRef;