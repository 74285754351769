import ReactDOM from 'react-dom';
import { memo } from 'react';
import { propsAreEqual } from '../utils/react';

export const NotificationPortal = memo(function NotificationPortal(props) {
  return ReactDOM.createPortal(
    props.children,
    document.getElementById('notifications')
  );
}, propsAreEqual);
