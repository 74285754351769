import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Grid, GridRow, GridRowCell } from '../../ui';

export const ListItemMeta = ({
  avatarSrc,
  avatar,
  title,
  description,
  className
}) => {
  const content = (
    <Grid flexDirection="column" className={className}>
      {title && (
        <GridRow aria-rowindex={1} marginBottom="2">
          {title}
        </GridRow>
      )}
      {description && <GridRow aria-rowindex={2}>{description}</GridRow>}
    </Grid>
  );
  return (
    <Box flexGrow="1">
      <Grid>
        <GridRow alignItems="center">
          <GridRowCell marginRight="3">
            {avatar}
            {avatarSrc && <Avatar url={avatarSrc} size={40} />}
          </GridRowCell>
          <GridRowCell>{content}</GridRowCell>
        </GridRow>
      </Grid>
    </Box>
  );
};

ListItemMeta.propTypes = {
  avatar: PropTypes.node,
  avatarSrc: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.node
};
