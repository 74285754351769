import { camel } from 'case';
import keycode from 'keycode';
import stringify from 'json-stringify-simple';
import injector from './injector';
import v4 from 'uuid/v4';
import { getCurrentWorkingDirectory } from './app/core/queries';

import { commands as emitter } from './events';
import { mod } from '../utils/math';

const { inject } = injector;

const join = inject(['path'], function join(...args) {
  return this.path.join(...args);
});

const dirname = inject(['path'], function dirname(path) {
  return this.path.dirname(path);
});

const basename = inject(['path'], function basename(...args) {
  return this.path.basename(...args);
});

const extname = inject(['path'], function extname(path) {
  return this.path.extname(path);
});

const relative = inject(['path'], function relative(from, to) {
  return this.path.relative(from, to);
});

const isDirectory = inject(['fs'], function isDirectory(path) {
  return this.fs.existsSync(path) && this.fs.statSync(path).isDirectory();
});

let c = 1; // cant use this style in prod unless we serialized this number in fs
const getShortId =
  process.env.NODE_ENV === 'test'
    ? () => {
        return c++ + '';
      }
    : () => {
        const [a, b, c] = v4().split('-');
        return a.slice(0, 3) + b.slice(1, 2) + c.slice(2, 4);
      };

// TODO check this nameRegExp, does it encompass all names we support?
const nameRegExp = /^([.a-zA-Z]+[\w]*)([0-9]+)$/;
const actualBasename = n => basename(n, extname(n));
const getBaseNumExtTripletPlusOne = n => {
  const base = actualBasename(n);
  const ext = extname(n);
  const match = base.match(nameRegExp);
  if (match) {
    return [match[1], Number(match[2]) + 1, ext].join('');
  }
  return [base, 1, ext].join('');
};
const getUniqueName = (names, name) => {
  if (!names.includes(name)) return name;
  return getUniqueName(names, getBaseNumExtTripletPlusOne(name));
};

const findNameFromNameOrType = ({ names, name, type, ext }) => {
  if (!name) {
    name = getBaseNumExtTripletPlusOne(ext ? camel(type) + ext : camel(type));
  }
  if (ext && extname(name).length < 2) {
    name = name + ext;
  }
  return getUniqueName(names, name);
};

const getAvailableName = inject(['fs'], function getAvailableName({
  root,
  name,
  type,
  ext
}) {
  const children = this.fs.readdirSync(root);
  return findNameFromNameOrType({ names: children, name, type, ext });
});

const getAllPaths = inject(['path'], function getAllPaths(
  fullPath = '/',
  { includeRoot = true } = {}
) {
  const { path } = this;
  return fullPath === '/'
    ? includeRoot
      ? [fullPath]
      : []
    : fullPath.split('/').reduce(
        (m, v, i) => {
          return i === 0 ? m : m.concat(path.dirname(m[i - 1]));
        },
        [fullPath]
      );
});

const orderedPaths = paths =>
  paths.sort((a, b) => {
    if (a === '/') return -1;
    if (b === '/') return 1;
    const A = a.split('/').length;
    const B = b.split('/').length;
    if (A < B) return -1;
    return 1;
  });

const aggregateAllPaths = paths =>
  orderedPaths(
    paths.reduce((m, v) => {
      const all = getAllPaths(v);
      for (let p of all) {
        if (m.includes(p)) continue;
        m.push(p);
      }
      return m;
    }, [])
  );
const rimrafSync = inject(['fs', 'path'], function(dir) {
  const { fs, path } = this;
  const isDirectory = fs.statSync(dir).isDirectory();
  if (!isDirectory) return fs.unlinkSync(dir);
  fs.readdirSync(dir).forEach(file => rimrafSync(path.join(dir, file)));
  fs.rmdirSync(dir);
});

const mkdirpSync = inject(['fs'], function mkdirpSync(fullPath) {
  const { fs } = this;
  const paths = getAllPaths(fullPath, {
    includeRoot: false
  }).reverse();

  for (let cur of paths) {
    try {
      fs.statSync(cur);
    } catch (e) {
      fs.mkdirSync(cur);
    }
  }
});

const assertFileExists = inject(['fs'], function assertFileExists(file, error) {
  const { fs } = this;
  try {
    fs.statSync(file);
  } catch (e) {
    throw new Error(error);
  }
});

const walkSync = inject(['fs', 'path'], function(dir) {
  const { fs, path } = this;
  return fs
    .readdirSync(dir)
    .reduce(
      (files, file) =>
        fs.statSync(path.join(dir, file)).isDirectory()
          ? files.concat(walkSync(path.join(dir, file)))
          : files.concat(path.join(dir, file)),
      []
    );
});

const findSync = inject(['fs'], function({ dir, name, type }) {
  const { fs } = this;
  return fs.readdirSync(dir).reduce((files, file) => {
    const isDirectory = fs.statSync(join(dir, file)).isDirectory();
    const isName = file === name;
    if (isName) {
      if (isDirectory && type === 'd') {
        files.push(join(dir, file));
      } else if (!isDirectory && type === 'f') {
        files.push(join(dir, file));
      } else if (!type) {
        files.push(join(dir, file));
      }
    }
    if (isDirectory) {
      return files.concat(findSync({ dir: join(dir, file), name, type }));
    } else {
      return files;
    }
  }, []);
});

const wget = inject(['fs'], async function({
  uri,
  cwd = getCurrentWorkingDirectory(),
  script
}) {
  const response = await fetch(uri);

  if (response.status !== 200) {
    console.log(
      'Looks like there was a problem. Status Code: ' + response.status
    );
    return;
  }

  const value = await response.text();
  const name = join(cwd, basename(uri));
  this.fs.writeFileSync(name, value);
  if (script) {
    sourceFile({ path: name });
  }
});

const sourceFile = inject(['fs'], function({ path }) {
  emitter.emit('terminal:parse', {
    buffer: this.fs.readFileSync(path).toString()
  });
});

const readJSONSync = inject(['fs'], function readJSONSync(
  file,
  defaultJSON = {}
) {
  try {
    return JSON.parse(this.fs.readFileSync(file).toString());
  } catch (e) {
    return defaultJSON;
  }
});

const writeJSONSync = inject(['fs'], function writeJSONSync(file, data) {
  this.fs.writeFileSync(file, stringify(data));
});

// pass in the length BEFORE removal
const getNewIndexAfterRemoval = (
  previousSelectedIndex,
  removedIndex,
  length
) => {
  const newLen = length - 1;
  if (newLen <= 0) {
    return -1;
  } else if (previousSelectedIndex === removedIndex) {
    return mod(removedIndex, newLen);
  } else if (previousSelectedIndex < removedIndex) {
    return previousSelectedIndex;
  } else {
    return previousSelectedIndex - 1;
  }
};

const swapArrayElements = (order, sourceIndex, targetIndex) => {
  if (sourceIndex === targetIndex) return order;
  const i = sourceIndex < targetIndex ? sourceIndex : targetIndex;
  const j = sourceIndex < targetIndex ? targetIndex : sourceIndex;
  const begin = order.slice(0, i);
  const middle = order.slice(i + 1, j);
  const end = order.slice(j + 1, order.length);
  return begin
    .concat(order[j])
    .concat(middle)
    .concat(order[i])
    .concat(end);
};

const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  a = a.sort();
  b = b.sort();

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }

  return true;
};

const getHotkeysFromText = keybinding =>
  keybinding.split('+').map(key => {
    switch (key) {
      case 'shiftKey':
        return '⇧';
      case 'ctrlKey':
        return '⌥';
      case 'metaKey':
        return '⌘';
      case 'esc':
        return '⎋';
      case 'up':
        return '↑';
      case 'down':
        return '↓';
      case 'left':
        return '←';
      case 'right':
        return '→';
      default:
        return key;
    }
  });

const getKeyDownKeysPressed = ({ keyCode, shiftKey, metaKey, ctrlKey }) => {
  const keysPressed = [];
  if (metaKey) keysPressed.push('metaKey');
  if (shiftKey) keysPressed.push('shiftKey');
  if (ctrlKey) keysPressed.push('ctrlKey');

  if (keyCode && keyCode !== 16 && keyCode !== 91 && keyCode !== 17)
    keysPressed.push(keycode(keyCode));
  return keysPressed;
};

const moveArrayElements = (order, sourceIndex, targetIndex) => {
  if (sourceIndex === targetIndex) return order;
  if (order.length === 2)
    return swapArrayElements(order, sourceIndex, targetIndex);
  const l = sourceIndex < targetIndex;
  const i = l ? sourceIndex : targetIndex;
  const j = l ? targetIndex : sourceIndex;
  const begin = order.slice(0, i);
  const middle = order.slice(i + 1, j);
  const end = order.slice(j + 1, order.length);
  return l
    ? begin
        .concat(middle)
        .concat(order[i])
        .concat(order[j])
        .concat(end)
    : begin
        .concat(order[j])
        .concat(order[i])
        .concat(middle)
        .concat(end);
};

export {
  aggregateAllPaths,
  arraysEqual,
  assertFileExists,
  basename,
  dirname,
  findSync,
  isDirectory,
  getAllPaths,
  getAvailableName,
  getHotkeysFromText,
  getKeyDownKeysPressed,
  extname,
  getNewIndexAfterRemoval,
  getShortId,
  join,
  relative,
  mkdirpSync,
  moveArrayElements,
  orderedPaths,
  readJSONSync,
  rimrafSync,
  sourceFile,
  swapArrayElements,
  walkSync,
  wget,
  writeJSONSync
};
