const base = {
  borders: [
    0,
    '0.5px solid',
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid'
  ],
  radii: [0, 3, 6, 12, 9999, '100%'],
  space: [0, 3, 6, 12, 18, 24, 36, 60],
  sizes: [18, 36, 54, 66, 126, 240, 252, 510, 768],
  // typography
  fontSizes: [12, 16, 18],
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    heavy: 900
  },
  lineHeights: {
    tight: 18 / 16,
    normal: 24 / 16,
    large: 30 / 16
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em'
  },
  zIndices: {
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  },
  // Alias
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  },
  breakpoints: ['32em', '48em', '64em', '80em', '106em'],
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195
  },
  easing: {
    easeOutQuart: `cubic-bezier(0.165, 0.84, 0.44, 1)`
  }
};

base.mediaQueries = base.breakpoints.map(
  bp => `@media screen and (min-width: ${bp})`
);

base.mq = base.mediaQueries;

// Aliases
base.breakpoints.sm = base.breakpoints[0];
base.breakpoints.md = base.breakpoints[1];
base.breakpoints.lg = base.breakpoints[2];
base.breakpoints.xl = base.breakpoints[3];
base.breakpoints.xxl = base.breakpoints[3];

export { base };
