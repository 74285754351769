import React from 'react';
import PropTypes from 'prop-types';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled/macro';

const percentage = fraction => `${fraction * 100}%`;

const Svg = styled.svg(props => {
  const frameSize = props.width / 8;
  const frame = index => `translate3d(${frameSize * -index}px,0,0)`;

  const loading = keyframes({
    [`${percentage(0 / 8)}, ${percentage(8 / 8)}`]: {
      transform: frame(0)
    },
    [percentage(1 / 8)]: {
      transform: frame(1)
    },
    [percentage(2 / 8)]: {
      transform: frame(2)
    },
    [percentage(3 / 8)]: {
      transform: frame(3)
    },
    [percentage(4 / 8)]: {
      transform: frame(4)
    },
    [percentage(5 / 8)]: {
      transform: frame(5)
    },
    [percentage(6 / 8)]: {
      transform: frame(6)
    },
    [percentage(7 / 8)]: {
      transform: frame(7)
    }
  });

  return {
    animation: `${loading} 0.7s steps(1) infinite`
  };
});

Svg.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

const Wrapper = styled.div(({ width, height }) => ({
  overflow: 'hidden',
  width,
  height
}));

Wrapper.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export const Loader = React.forwardRef(
  ({ width, height, forgroundColor, backgroundColor, ...props }, ref) => {
    return (
      <Wrapper width={width} height={height} {...props} ref={ref}>
        <Svg
          width={width * 8}
          height={height}
          viewBox="0 0 336 42"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g transform="translate(294, 0)">
            <rect fill={forgroundColor} x="5" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="5" width="8" height="8" />
            <rect fill={backgroundColor} x="29" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="17" width="8" height="8" />
            <rect fill={backgroundColor} x="29" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="29" width="8" height="8" />
            <rect fill={backgroundColor} x="29" y="29" width="8" height="8" />
          </g>
          <g transform="translate(252, 0)">
            <rect fill={forgroundColor} x="5" y="5" width="8" height="8" />
            <rect fill={backgroundColor} x="17" y="5" width="8" height="8" />
            <rect fill={backgroundColor} x="29" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="17" width="8" height="8" />
            <rect fill={backgroundColor} x="29" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="29" width="8" height="8" />
          </g>
          <g transform="translate(210, 0)">
            <rect fill={backgroundColor} x="5" y="5" width="8" height="8" />
            <rect fill={backgroundColor} x="17" y="5" width="8" height="8" />
            <rect fill={backgroundColor} x="29" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="29" width="8" height="8" />
          </g>
          <g transform="translate(168, 0)">
            <rect fill={backgroundColor} x="5" y="5" width="8" height="8" />
            <rect fill={backgroundColor} x="17" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="5" width="8" height="8" />
            <rect fill={backgroundColor} x="5" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="29" width="8" height="8" />
          </g>
          <g transform="translate(126, 0)">
            <rect fill={backgroundColor} x="5" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="5" width="8" height="8" />
            <rect fill={backgroundColor} x="5" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="17" width="8" height="8" />
            <rect fill={backgroundColor} x="5" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="29" width="8" height="8" />
          </g>
          <g transform="translate(84, 0)">
            <rect fill={forgroundColor} x="5" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="5" width="8" height="8" />
            <rect fill={backgroundColor} x="5" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="17" width="8" height="8" />
            <rect fill={backgroundColor} x="5" y="29" width="8" height="8" />
            <rect fill={backgroundColor} x="17" y="29" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="29" width="8" height="8" />
          </g>
          <g transform="translate(42, 0)">
            <rect fill={forgroundColor} x="5" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="17" width="8" height="8" />
            <rect fill={backgroundColor} x="5" y="29" width="8" height="8" />
            <rect fill={backgroundColor} x="17" y="29" width="8" height="8" />
            <rect fill={backgroundColor} x="29" y="29" width="8" height="8" />
          </g>
          <g>
            <rect fill={forgroundColor} x="5" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="29" y="5" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="17" y="17" width="8" height="8" />
            <rect fill={backgroundColor} x="29" y="17" width="8" height="8" />
            <rect fill={forgroundColor} x="5" y="29" width="8" height="8" />
            <rect fill={backgroundColor} x="17" y="29" width="8" height="8" />
            <rect fill={backgroundColor} x="29" y="29" width="8" height="8" />
          </g>
        </Svg>
      </Wrapper>
    );
  }
);

Loader.displayName = 'Loader';

Loader.defaultProps = {
  height: 42,
  width: 42,
  forgroundColor: '#33CCFF',
  backgroundColor: '#FFFFFF'
};

Loader.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  forgroundColor: PropTypes.string,
  backgroundColor: PropTypes.string
};
