import styled from '@emotion/styled/macro';

export const TabsNavList = styled.ol({
  display: 'flex',
  flexBasis: 'auto',
  flexGrow: 1,
  flexShrink: 0,
  listStyle: 'none',
  margin: 0,
  padding: 0,
  overflowX: 'hidden',
  '&:hover, &:focus': {
    overflowX: 'auto'
  }
});
