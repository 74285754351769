import styled from '@emotion/styled/macro';

export const ProjectFeedItemHeaderBackground = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});
