import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AttachmentIcon = function AttachmentIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M12.897 0a.25.25 0 01.176.073l2.854 2.854a.25.25 0 01.073.177V17.75a.25.25 0 01-.248.25l-13.495-.002a.25.25 0 01-.252-.248L2 .25A.25.25 0 012.25 0h10.647zM6.47 14c.633 0 1.265-.24 1.747-.722l5.06-5.06A2.473 2.473 0 0011.53 4c-.66 0-1.28.257-1.747.724l-.586.586a.353.353 0 10.5.499l.585-.586a1.754 1.754 0 011.248-.517 1.767 1.767 0 011.248 3.013l-5.06 5.06a1.767 1.767 0 01-2.496 0 1.767 1.767 0 010-2.496L7.98 7.525c.2-.2.466-.31.75-.31.282 0 .548.11.748.31.2.2.31.466.31.749 0 .282-.11.548-.31.748L7.27 11.23a.353.353 0 10.499.5L9.977 9.52c.333-.333.517-.776.517-1.247 0-.472-.184-.915-.517-1.248a1.754 1.754 0 00-1.248-.517c-.471 0-.915.183-1.248.517L4.723 9.784a2.473 2.473 0 000 3.494A2.463 2.463 0 006.47 14z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AttachmentIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AttachmentIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AttachmentIcon';
export default ForwardRef;