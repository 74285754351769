import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { em } from 'polished';

const variant = css({
  backgroundColor: 'rgb(12,12,12)',
  boxShadow: `rgba(0,0,0,0.15) 0 ${em(6)} ${em(16)}`
});

export const Popover = styled.div(variant);
