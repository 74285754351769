import React from 'react';
import { Link } from 'react-router-dom';
import {
  MenuBar,
  MenuBarList,
  MenuBarListItem,
  MenuBarListItemLink,
  Icon,
  Menu,
  MenuItem,
  MenuItemLink,
  Button,
  Select
} from '../ui';
import { CurrentAvatar } from './CurrentAvatar';
import { SignOutLink } from './SignOutLink';

import { saveProject } from '../state/commands';

import { Popup } from './Popup';

export const EditorMenu = props => {
  return (
    <MenuBar aria-label="Main menu">
      <MenuBarList>
        <MenuBarListItem>
          <MenuBarListItemLink as={Link} to="/">
            <Icon
              name="airpage-logo"
              size={18}
              title="Goto dashboard"
              fill="rgb(0,128,255)"
            />
          </MenuBarListItemLink>
        </MenuBarListItem>
        <MenuBarListItem>
          <MenuBarListItemLink
            href="#go-back"
            tabIndex={-1}
            onClick={event => {
              event.preventDefault();
              window.history.back();
            }}
          >
            <Icon name="routing-left" title="Go Back" size={18} />
          </MenuBarListItemLink>
          <MenuBarListItemLink
            href="#go-forward"
            tabIndex={-1}
            onClick={event => {
              event.preventDefault();
              window.history.forward();
            }}
          >
            <Icon name="routing-right" title="Go Back" size={18} />
          </MenuBarListItemLink>
        </MenuBarListItem>
        <MenuBarListItem fluid={true}>
          <div>
            <Button
              onClick={async () =>
                await saveProject({ projId: props.projectId })
              }
            >
              Save
            </Button>
          </div>
        </MenuBarListItem>
        <MenuBarListItem>
          <div>
            <Select native aria-label="Current workspace">
              <option value="editor">Design Studio</option>
            </Select>
          </div>
        </MenuBarListItem>
        <MenuBarListItem>
          <MenuBarListItemLink>
            <Icon name="window-manager" size={18} title="Window manager" />
          </MenuBarListItemLink>
          <MenuBarListItemLink>
            <Icon name="customize-field" size={18} title="Settings" />
          </MenuBarListItemLink>
        </MenuBarListItem>
        <MenuBarListItem>
          <Popup
            placement="bottom-end"
            modifiers={{ shift: { enabled: true } }}
            popup={
              <Menu aria-label="User" style={{ zIndex: 10 }}>
                <MenuItem>
                  <MenuItemLink as={SignOutLink}>Sign out</MenuItemLink>
                </MenuItem>
              </Menu>
            }
          >
            {({ setExpanded }) => (
              <MenuBarListItemLink
                tabIndex={-1}
                href="#"
                id="avatar-link"
                aria-controls="avatar-menu"
                onClick={event => {
                  event.preventDefault();
                  setExpanded(expanded => !expanded);
                }}
              >
                <CurrentAvatar size={24} />
              </MenuBarListItemLink>
            )}
          </Popup>
        </MenuBarListItem>
      </MenuBarList>
    </MenuBar>
  );
};
