import styled from '@emotion/styled/macro';
import { em, margin, padding, transparentize } from 'polished';
import { space, layout, position, border, shadow, color } from 'styled-system';
import themeGet from '@styled-system/theme-get';
import { ListItem } from './ListItem';

const variant = props => ({
  backgroundColor: themeGet('colors.background.default')(props),
  borderColor: 'transparent'
});

const bordered = props =>
  props.bordered && {
    borderColor:
      props.theme.mode === 'dark'
        ? transparentize(0.2, themeGet('colors.black')(props))
        : transparentize(0.8, themeGet('colors.black')(props)),
    padding: 0,
    [`${ListItem}`]: {
      marginTop: 0,
      boxShadow:
        props.theme.mode === 'dark'
          ? `inset 0px -0.5px 0px 0px ${transparentize(
              0.2,
              themeGet('colors.black')(props)
            )}`
          : `inset 0px -0.5px 0px 0px ${transparentize(
              0.8,
              themeGet('colors.black')(props)
            )}`
    }
  };

export const List = styled.ul(
  {
    ...margin(0),
    ...padding(em(12)),
    listStyle: 'none',
    borderRadius: em(3),
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap'
  },
  variant,
  bordered,
  space,
  layout,
  position,
  border,
  shadow,
  color
);
