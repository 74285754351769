import React, { memo, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CreateInviteMutation } from '../mutations';
import { getFormVariables } from '../utils/form';
import { AuthLayout } from '../layouts/AuthLayout';
import { EMAIL_REGEX } from '../utils/regex';

import {
  AuthForm,
  AuthHeading,
  AuthParagraph,
  Button,
  Field,
  Input,
  Icon
} from '../ui';
import { propsAreEqual } from '../utils/react';

export const InviteFriends = memo(function InviteFriends(props) {
  const [disabled, setDisabled] = useState(true);
  const [invites, setInvites] = useState({
    invite1: null,
    invite2: null,
    invite3: null
  });
  const [invite] = useMutation(CreateInviteMutation, {
    onCompleted: d => {
      console.log({ d });
      setInvites({
        invite1: null,
        invite2: null,
        invite3: null
      });
      setDisabled(true);
    },
    onError: e => {
      console.error({ e });
      setInvites({
        invite1: null,
        invite2: null,
        invite3: null
      });
      setDisabled(true);
    }
  });
  const sendInvites = async emails => {
    for (let email of emails) {
      await invite({ variables: { email } });
    }
  };
  return (
    <AuthLayout linkLocal={true}>
      <AuthForm
        onSubmit={async event => {
          event.preventDefault();
          const variables = getFormVariables(event);
          const inviteEmails = Object.keys(invites).reduce((m, v) => {
            if (EMAIL_REGEX.test(variables[v])) {
              return m.concat(variables[v]);
            }
            return m;
          }, []);
          await sendInvites(inviteEmails);
        }}
      >
        <AuthHeading>
          <Icon name="collaborator" size={18} color="inherit" marginRight="2" />
        </AuthHeading>
        <AuthHeading>Invite some friends</AuthHeading>
        <AuthParagraph>Invite friends and move up the waitlist!</AuthParagraph>
        {Object.keys(invites).map(fieldName => (
          <Field fluid={true}>
            <Input
              aria-labelledby={`${fieldName}-label`}
              fluid={true}
              id={fieldName}
              name={fieldName}
              key={fieldName}
              value={invites[fieldName]}
              type="input"
              onChange={event => {
                event.preventDefault();
                const value = event.currentTarget.value;
                if (value && EMAIL_REGEX.test(value)) {
                  setDisabled(false);
                }
                setInvites({ ...invites, [fieldName]: value });
              }}
            />
          </Field>
        ))}

        <Button
          fluid={true}
          disabled={disabled}
          type="submit"
          variant="primary"
        >
          Invite
        </Button>
      </AuthForm>
    </AuthLayout>
  );
}, propsAreEqual);
