import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PrinterIcon = function PrinterIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 5.252c0-.06.052-.11.106-.11h17.788c.059 0 .106.05.106.11v7.496a.11.11 0 01-.106.11H.106a.108.108 0 01-.106-.11V5.252zm2.143 2.462a.857.857 0 100-1.714.857.857 0 000 1.714zm2.571 0a.857.857 0 100-1.714.857.857 0 000 1.714zm-2.035 6H15.32c.06 0 .108.048.108.107v4.072c0 .059-.048.107-.108.107H2.68a.107.107 0 01-.108-.107V13.82c0-.059.048-.107.108-.107zM3.429.857v3H2.57V.43C2.571.192 2.763 0 3 0h12c.237 0 .429.192.429.429v3.428h-.858v-3H3.43z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PrinterIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PrinterIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PrinterIcon';
export default ForwardRef;