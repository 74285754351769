import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ResponsiveIcon = function ResponsiveIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M16.132 17H1.868v1L.102 16.7a.247.247 0 010-.4L1.868 15v1h14.264v-1l1.766 1.3a.247.247 0 010 .4L16.132 18v-1zm-12.85-6h6.437c.155 0 .281.126.281.281v.438a.281.281 0 01-.281.281H3.28A.281.281 0 013 11.719v-.438c0-.155.126-.281.281-.281zm-3-11h13.437c.155 0 .281.126.281.281V3.72a.281.281 0 01-.281.281H12.28a.281.281 0 00-.281.281V9.72a.281.281 0 01-.281.281H.28A.281.281 0 010 9.719V.28C0 .126.126 0 .281 0zM18 5.281v8.438a.281.281 0 01-.281.281H13.28a.281.281 0 01-.281-.281V5.28c0-.155.126-.281.281-.281h4.438c.155 0 .281.126.281.281zM15.5 13a.5.5 0 100-1 .5.5 0 000 1z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ResponsiveIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ResponsiveIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ResponsiveIcon';
export default ForwardRef;