import gql from 'graphql-tag';
import { ProjectFeedProjectFragment } from '../fragments';

export const ProjectQuery = gql`
  query ProjectQuery($id: UUID!) {
    project(id: $id) {
      ...ProjectFeedProjectFragment
    }
  }
  ${ProjectFeedProjectFragment}
`;
