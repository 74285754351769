import styled from '@emotion/styled/macro';
import { em, margin } from 'polished';
import themeGet from '@styled-system/theme-get';
import { layout, space } from 'styled-system';

const variant = props => ({
  color: themeGet('textColors.secondary')(props),

  '&:hover, &:focus': {
    color: themeGet('textColors.active')(props)
  },

  '&[aria-disabled="true"]': {
    color: themeGet('textColors.disabled')(props)
  },
  'button[aria-current="false"]&': {
    border: 'none',
    backgroundColor: 'transparent'
  }
});

export const MenuBarListItemLink = styled.a(
  {
    ...margin(0, em(6)),
    display: 'flex'
  },
  variant,
  layout,
  space
);

MenuBarListItemLink.defaultProps = {
  role: 'menuitem'
};
