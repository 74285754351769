import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var StrikeThroughIcon = function StrikeThroughIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M11.134 11.576h-1.29v-.857h2.809c.233-.053.475-.093.721-.133a8.605 8.605 0 001.251-.274v-.26c0-.656-.366-1.549-.984-1.549-.619 0-.985.893-.985 1.548a.422.422 0 01-.422.423H11.11a.422.422 0 01-.421-.423 2.394 2.394 0 012.39-2.392h1.125a2.394 2.394 0 012.39 2.392v.668H18v.857h-1.406v1.853c0 .077.063.14.14.14h.282a.422.422 0 010 .845h-1.407a.979.979 0 01-.77-.38c-.508.26-.979.38-1.48.38h-.562c-.923 0-1.629-.374-1.937-1.026-.271-.573-.166-1.266.274-1.812zm2.574 0c-.315.146-.595.32-.79.537a.996.996 0 00-.175 1.053c.067.151.236.404.616.404.413 0 .81-.118 1.266-.38v-1.614h-.917zm-11.623 0H0v-.857h2.437L5.094 4.26a.422.422 0 01.78 0l2.658 6.458h1.312v.857h-.96l.82 1.994h.28a.422.422 0 010 .844h-2.53a.422.422 0 01-.066-.838L6.575 11.6H2.988l-.814 1.977a.421.421 0 01-.065.838H.984a.422.422 0 010-.844h.28l.82-1.994zm1.265-.857h2.863L4.78 7.24 3.35 10.719z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

StrikeThroughIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(StrikeThroughIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'StrikeThroughIcon';
export default ForwardRef;