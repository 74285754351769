import styled from '@emotion/styled/macro';

import { Icon } from '../Icon';
import { Link } from 'react-router-dom';
import React from 'react';
import { padding, em } from 'polished';

export const ProjectCardHeader = ({ projectsPath, children, ...props }) => (
  <Container {...props}>
    <Content>
      <StyledLink as={Link} to="..">
        <Icon name="delete" title="Close" size={18} />
      </StyledLink>
      <PlaceholderIcon
        as={Icon}
        role="presentation"
        name="image"
        width={60}
        height={60}
      />
    </Content>
    {children}
  </Container>
);

const Container = styled.div({
  ...padding(em(24)),
  color: 'rgb(180,180,180)',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
});

const Content = styled.div({
  position: 'relative',
  height: '162px'
});

const StyledLink = styled.a({
  position: 'absolute',
  top: '-12px',
  left: '-12px'
});

const PlaceholderIcon = styled.svg({
  position: 'absolute',
  left: '50%',
  top: '50%',
  marginLeft: '-30px',
  marginTop: '-30px'
});
