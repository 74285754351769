import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var OrderedMenuIcon = function OrderedMenuIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M0 0h18v18H0V0zm5.143 2.143v1.714h10.286V2.143H5.143zm-2.572 0v1.714h1.715V2.143H2.57zm0 3v1.714h1.715V5.143H2.57zm0 3v1.714h1.715V8.143H2.57zm0 3v1.714h1.715v-1.714H2.57zm0 3v1.714h1.715v-1.714H2.57zm2.572-9v1.714h10.286V5.143H5.143zm0 3v1.714h10.286V8.143H5.143zm0 3v1.714h10.286v-1.714H5.143zm0 3v1.714h10.286v-1.714H5.143z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

OrderedMenuIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(OrderedMenuIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'OrderedMenuIcon';
export default ForwardRef;