import styled from '@emotion/styled/macro';
import { em } from 'polished';

export const Field = styled.div({
  marginBottom: em(24),
  position: 'relative'
});

// Alias to FormControl since Field is
// a bit confusing with a stateful Field (TextField)
export const FormControl = Field;
