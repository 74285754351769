import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var H6Icon = function H6Icon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm7.87 6.086v3.096h-3.56V6.086h-.976v7.2h.977v-3.158h3.559v3.158h.977v-7.2h-.977zm4.577 2.489c-.185 0-.36.02-.525.051l1.389-2.54h-1.1c-.412.802-.772 1.44-1.194 2.263-.411.771-.967 1.666-.977 2.602v.02c0 1.348.977 2.418 2.407 2.418 1.45 0 2.417-1.07 2.417-2.418 0-1.337-.977-2.396-2.417-2.396zm0 3.847c-.833 0-1.43-.659-1.43-1.44 0-.792.586-1.46 1.43-1.46.853 0 1.44.668 1.44 1.46 0 .781-.597 1.44-1.44 1.44z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

H6Icon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(H6Icon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'H6Icon';
export default ForwardRef;