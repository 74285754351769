import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var GoBackIcon = function GoBackIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M7 7h6v4H7V7zm10 0h1v4h-1V7zm-3 0h2v4h-2V7zm-7.154 6.815a.507.507 0 01-.38.185.613.613 0 01-.285-.109L.245 9.472A.625.625 0 010 8.978a.67.67 0 01.245-.502L6.18 4.095a.476.476 0 01.542-.02A.63.63 0 017 4.61v8.767a.665.665 0 01-.154.44z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

GoBackIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(GoBackIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'GoBackIcon';
export default ForwardRef;