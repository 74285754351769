import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var StringIcon = function StringIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm7.9 13.286h1.07L5.89 5.983h-.144l-3.179 7.303h1.08l.638-1.533H7.37l.637 1.533zm4.619-5.215c-.761 0-1.368.37-1.77.905V5.057H9.89v8.229h.967v-.844c.4.545 1.008.916 1.769.916 1.43 0 2.407-1.163 2.407-2.644 0-1.48-.978-2.643-2.407-2.643zm-7.951 2.736L5.827 8.03l1.152 2.777H4.675zm7.786 1.604c-.926 0-1.594-.792-1.594-1.697s.668-1.707 1.594-1.707 1.574.761 1.574 1.707c0 .936-.648 1.697-1.574 1.697z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

StringIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(StringIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'StringIcon';
export default ForwardRef;