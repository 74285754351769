import gql from 'graphql-tag';

export const CreateContentMutation = gql`
  mutation CreateContentMutation(
    $title: String!
    $projectId: UUID!
    $slug: String!
  ) {
    createContent(
      input: { content: { title: $title, projectId: $projectId, slug: $slug } }
    ) {
      content {
        createdAt
        createdBy
        id
        title
        slug
      }
    }
  }
`;
