import gql from 'graphql-tag';

export const TasksRequiredForQuery = gql`
  query TasksRequiredForQuery($achievement: String!) {
    tasksRequiredFor(achievement: $achievement) {
      nodes {
        name
      }
    }
  }
`;
