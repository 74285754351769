import styled from '@emotion/styled/macro';
import { padding, em } from 'polished';

export const AuthHeading = styled.h3(props => ({
  ...padding(em(6, 18), 0),
  color: props.theme.mode === 'dark' ? 'rgb(250,250,250)' : 'rgb(77, 77, 77)',
  fontSize: em(18),
  fontWeight: 600,
  lineHeight: 18 / 18,
  margin: 0,
  textAlign: 'center'
}));
