import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CodeIcon = function CodeIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M12.667 4.864l.909-.89L18 9l-4.424 5.027-.91-.891L16.307 9l-3.64-4.136zm-5.864 9.79L9.91 3l1.288.347L8.09 15l-1.288-.347zm-1.47-1.518l-.909.89L0 9l4.424-5.027.91.891L1.693 9l3.64 4.136z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CodeIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CodeIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CodeIcon';
export default ForwardRef;