import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ApiKeysIcon = function ApiKeysIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.281 0H17.72c.155 0 .281.126.281.281V2.25H0V.281C0 .126.126 0 .281 0zM0 16.875h18v.844a.281.281 0 01-.281.281H.28A.281.281 0 010 17.719v-.844zM0 2.25h1.125v14.625H0V2.25zm16.875 0H18v14.625h-1.125V2.25zM3.85 10.49h2.16l-.11-.34c-.253-.787-.46-1.438-.62-1.955-.16-.517-.257-.838-.29-.965l-.05-.19c-.073.313-.4 1.35-.98 3.11l-.11.34zM6.82 13l-.53-1.65H3.57L3.03 13H1.94l2.33-6.74h1.35L7.92 13h-1.1zm3.17-3.24h.88c.487 0 .858-.12 1.115-.36s.385-.557.385-.95c0-.413-.13-.74-.39-.98s-.637-.36-1.13-.36h-.86v2.65zm-1.03-3.5h1.93c.773 0 1.387.205 1.84.615.453.41.68.935.68 1.575 0 .64-.228 1.16-.685 1.56-.457.4-1.048.6-1.775.6h-.96V13H8.96V6.26zM14.54 13V6.26h1.02V13h-1.02z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ApiKeysIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ApiKeysIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ApiKeysIcon';
export default ForwardRef;