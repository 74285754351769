import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ExpandIcon = function ExpandIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.04 2.205l1.277-1.172a.127.127 0 01.178.006l7.47 7.875a.125.125 0 010 .172l-7.47 7.875a.127.127 0 01-.178.006L.041 15.795a.125.125 0 01-.008-.178l.001-.001 6.195-6.53a.125.125 0 000-.172L.034 2.384a.125.125 0 01.006-.178zm9 0l1.277-1.172a.127.127 0 01.178.006l7.47 7.875a.125.125 0 010 .172l-7.47 7.875a.127.127 0 01-.178.006l-1.276-1.172a.125.125 0 01-.008-.178l.001-.001 6.195-6.53a.125.125 0 000-.172l-6.195-6.53a.125.125 0 01.006-.178z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ExpandIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ExpandIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ExpandIcon';
export default ForwardRef;