import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled/macro';
import icons from '@airpage/icons';
import { em } from 'polished';
import { color, space, flexbox, position } from 'styled-system';

const Svg = React.forwardRef(function Icon({ name, size, ...props }, ref) {
  return React.createElement(
    name in icons ? icons[name] : icons.null,
    Object.assign({ ref }, props)
  );
});

export const Icon = styled(Svg)(
  ({ size }) => ({
    fontSize: em(size)
  }),
  color,
  space,
  flexbox,
  position
);

Icon.defaultProps = {
  fill: 'currentColor'
};

Icon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number.isRequired
};
