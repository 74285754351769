import gql from 'graphql-tag';
import { ProjectFeedProjectFragment } from '../fragments';

export const UpdateProjectMutation = gql`
  mutation UpdateProjectMutation($id: UUID!, $patch: ProjectPatch!) {
    updateProject(input: { id: $id, patch: $patch }) {
      project {
        ...ProjectFeedProjectFragment
      }
    }
  }
  ${ProjectFeedProjectFragment}
`;
