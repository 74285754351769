import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var RightIcon = function RightIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M3.872.066l10.687 8.737a.23.23 0 010 .367L3.873 17.934a.307.307 0 01-.397-.014.235.235 0 01-.075-.17V.25c0-.138.126-.25.281-.25.07 0 .139.024.19.066z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

RightIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(RightIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'RightIcon';
export default ForwardRef;