import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var BlockedUsersIcon = function BlockedUsersIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M8.548 7.34A6.49 6.49 0 006.018 13H0l.344-1.894A5 5 0 015.264 7h1.472c.636 0 1.248.12 1.812.34zM6 6a3 3 0 110-6 3 3 0 010 6zm10.016 3.691l-6.325 6.325a4.5 4.5 0 006.325-6.325zm-.707-.707a4.5 4.5 0 00-6.325 6.325l6.325-6.325zm1.08 7.405a5.5 5.5 0 11-7.778-7.778 5.5 5.5 0 017.778 7.778z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

BlockedUsersIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(BlockedUsersIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'BlockedUsersIcon';
export default ForwardRef;