import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { transitions } from 'polished';
import { Text } from '../Text';
import { CardAction } from './CardAction';
import { Box } from '../Box';

const StyledCardMeta = styled.div(props => ({
  [`${CardAction}`]: {
    ...transitions('opacity', '.2s cubic-bezier(.4,1,.75,.9)'),
    position: 'absolute',
    pointerEvents: 'none'
  }
}));

export const CardMeta = ({ title, description, action, className }) => (
  <StyledCardMeta className={className}>
    {title && (
      <Box position="relative">
        <Text fontSize="2" my="0">
          {title}
        </Text>
        {action}
      </Box>
    )}
    {description && (
      <Text fontSize="0" my="0">
        {description}
      </Text>
    )}
  </StyledCardMeta>
);

CardMeta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
