import React, { memo } from 'react';

import { AuthForm } from './AuthForm';
import { AuthHeading } from './AuthHeading';
import { Loader } from '../Loader';
import { propsAreEqual } from '../../utils/react';

export const AuthLoader = memo(function AuthLoader({ title }) {
  return (
    <AuthForm>
      {title && <AuthHeading>{title}</AuthHeading>}
      <Loader />
    </AuthForm>
  );
}, propsAreEqual);
