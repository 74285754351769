import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { padding } from 'polished';
import { ListItemLink } from '../List';

export const CardAction = styled(ListItemLink)(props => ({
  ...padding(
    themeGet('space.2')(props) - 0.5 * 2,
    themeGet('space.2')(props) - 0.5 * 2
  ),
  display: 'inline-block',
  width: themeGet('space.4')(props),
  height: themeGet('space.4')(props),
  color:
    props.theme.mode === 'dark'
      ? themeGet('colors.white')(props)
      : themeGet('colors.action.default')(props),
  top: themeGet('space.1')(props),
  right: themeGet('space.2')(props)
}));
