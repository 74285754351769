import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var RenameIcon = function RenameIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M12.864.031a.107.107 0 01.15 0l2.881 2.87a.104.104 0 010 .15l-8.637 8.603a.406.406 0 01-.181.093l-3.532.587.589-3.519a.379.379 0 01.093-.18L12.864.031zM.25 15h17.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25v-.5A.25.25 0 01.25 15zm3 2h11.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H3.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

RenameIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(RenameIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'RenameIcon';
export default ForwardRef;