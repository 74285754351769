import gql from 'graphql-tag';

export const UpdateRoleMutation = gql`
  mutation UpdateRoleMutation($id: UUID!, $patch: RolePatch!) {
    updateRole(input: { id: $id, patch: $patch }) {
      role {
        id
      }
    }
  }
`;
