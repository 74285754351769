import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

const variant = css({
  color: 'rgb(180,180,180)',
  caretColor: 'rgb(0, 160, 255)'
});

export const TerminalScreenPromptInput = styled.input(
  {
    margin: 0,
    padding: 0,
    font: 'inherit',
    appearance: 'none',
    backgroundColor: 'inherit',
    border: 0,
    outline: 0,
    flex: '1 0 auto',
    cursor: 'inherit',
    width: 0
  },
  variant
);

TerminalScreenPromptInput.defaultProps = {
  autoCapitalize: 'off'
};
