export const baseColors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  gray: [
    'rgba(58, 58, 58, 1)',
    'rgba(51, 51, 51, 1)',
    'rgba(46, 46, 46, 1)',
    'rgba(40, 40, 40, 1)',
    'rgba(30, 30, 30, 1)',
    'rgba(26, 26, 26, 1)',
    'rgba(10, 10, 10, 1)'
  ],
  intent: {
    primary: [
      'rgba(0, 160, 255, 1)',
      'rgba(32, 143, 223, 1)',
      'rgba(26, 114, 178, 1)',
      'rgba(19, 86, 134, 1)',
      'rgba(13, 57, 89, 1)'
    ],
    success: [
      'rgba(25, 179, 102, 1)',
      'rgba(19, 134, 77, 1)',
      'rgba(13, 90, 51, 1)',
      'rgba(6, 45, 26, 1)'
    ],
    error: [
      'rgba(223, 32, 48, 1)',
      'rgba(178, 26, 38, 1)',
      'rgba(134, 19, 29, 1)',
      'rgba(89, 13, 19, 1)'
    ],
    danger: [
      'rgba(223, 32, 48, 1)',
      'rgba(178, 26, 38, 1)',
      'rgba(134, 19, 29, 1)',
      'rgba(89, 13, 19, 1)'
    ],
    warning: [
      'rgba(223, 143, 32, 1)',
      'rgba(178, 114, 26, 1)',
      'rgba(134, 86, 19, 1)',
      'rgba(89, 57, 13, 1)'
    ]
  },
  // extended colors
  green: [
    'rgba(26, 230, 196, 1)',
    'rgba(20, 184, 156, 1)',
    'rgba(15, 138, 117, 1)',
    'rgba(10, 92, 78, 1)'
  ],
  blue: [
    'rgba(235, 247, 255, 1)',
    'rgba(0, 160, 255, 1)',
    'rgba(32, 143, 223, 1)',
    'rgba(20, 174, 184, 1)',
    'rgba(15, 56, 138, 1)',
    'rgba(6, 45, 99, 1)',
    'rgba(10, 37, 92, 1)'
  ],
  orange: [
    'rgba(26, 179, 102, 1)',
    'rgba(184, 47, 20, 1)',
    'rgba(138, 36, 15, 1)',
    'rgba(92, 24, 10, 1)'
  ],
  yellow: [
    'rgba(223, 143, 32, 1)',
    'rgba(184, 170, 20, 1)',
    'rgba(138, 128, 15, 1)',
    'rgba(92, 85, 10, 1)'
  ]
};

export default function createPalette(mode) {
  const dark = {
    background: {
      default: 'rgba(30, 30, 30, 1)',
      bar: 'rgba(40, 40, 40, 1)'
    },
    action: {
      default: 'rgba(186, 186, 186, 1)',
      active: 'rgba(255, 255, 255, 1)',
      hover: 'rgba(255, 255, 255, 1)',
      hoverOpacity: 0.09,
      disabled: 'rgba(186, 186, 186, 0.3)'
    },
    input: {
      background: 'rgba(52, 52, 52, 1)',
      placeholder: 'rgba(186, 186, 186, 1)',
      color: 'rgba(255, 255, 255, 1)',
      border: 'rgba(0,0,0,0.8)',
      adornmentBg: baseColors.gray[0]
    },
    border: 'rgba(0,0,0,0.8)',
    divider: 'rgba(0, 0, 0, 0.5)'
  };

  const light = {
    // TODO: replace with real light theme palette later
    background: {
      default: 'rgba(250, 250, 250, 1)',
      bar: 'rgba(250, 250, 250, 1)'
    },
    action: {
      default: 'rgba(128, 128, 128, 1)',
      active: 'rgba(255, 255, 255, 1)',
      hover: 'rgba(255, 255, 255, 1)',
      hoverOpacity: 0.09,
      disabled: 'rgba(186, 186, 186, 0.3)'
    },
    input: {
      background: 'rgba(0, 0, 0, 0.02)',
      placeholder: 'rgba(186, 186, 186, 1)',
      color: 'rgba(77, 77, 77, 1)',
      border: 'rgba(0,0,0,0.2)',
      adornmentBg: 'rgba(0, 0, 0, 0.02)'
    },
    border: 'rgba(0,0,0,0.2)',
    divider: 'rgba(0, 0, 0, 0.2)'
  };

  return {
    colors: {
      ...baseColors,
      ...({ dark, light }[mode] || dark)
    }
  };
}
