import gql from 'graphql-tag';

export const ProjectFeedProjectFragment = gql`
  fragment ProjectFeedProjectFragment on Project {
    createdAt
    id
    name
    updatedAt
  }
`;
