import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var StampIcon = function StampIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M4.425 8.288l.235-1.265 2.216.372-.679-6.202S7.006-.411 10.055.1c3.05.511 3.233 2.282 3.233 2.282L10.42 7.99l2.216.372-.235 1.265 2.66.446-.47 2.53-13.294-2.23.468-2.53 2.66.445zM9 18c-4.97 0-9-.959-9-2.141 0-1.183 4.03-2.142 9-2.142s9 .96 9 2.142S13.97 18 9 18z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

StampIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(StampIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'StampIcon';
export default ForwardRef;