import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';

const variant = props => ({
  backgroundColor: themeGet('colors.background.default')(props),
  borderColor: themeGet('colors.border')(props),
  color: themeGet('colors.action.default')(props)
});

export const BreadcrumbListItem = styled.li(
  {
    display: 'inline'
  },
  variant
);
