import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var AccessIcon = function AccessIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M16.438 7.119H7.5C6.783 5.85 5.625 5 4 5 1.637 5 0 6.782 0 9s1.637 4 4 4c1.773 0 2.867-1.01 3.5-2.455h1.236l.781-.476.844.614.76-.812.696.317.675-.574 1.013.614.654-.416.802.257.697-.435.675.376L18 8.644l-1.562-1.525zM3 10c-.549 0-1-.451-1-1 0-.549.451-1 1-1 .549 0 1 .451 1 1 0 .549-.451 1-1 1z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

AccessIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(AccessIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'AccessIcon';
export default ForwardRef;