import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var StylesheetIcon = function StylesheetIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.107 0h17.786c.059 0 .107.048.107.107v17.786c0 .059-.048.107-.107.107H.107A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0zm11.981 5.132l-.309 1.885a.236.236 0 00.088.225.212.212 0 00.23.017l1.617-.89 1.617.89a.21.21 0 00.23-.017.236.236 0 00.088-.225l-.309-1.885 1.308-1.336a.238.238 0 00.056-.235.222.222 0 00-.177-.157l-1.808-.275-.808-1.715a.219.219 0 00-.197-.128c-.083 0-.16.05-.196.128l-.809 1.715-1.807.275a.222.222 0 00-.177.157.238.238 0 00.055.235l1.308 1.336zm-2.856 1.97a.107.107 0 00-.152 0L1.96 14.223a.107.107 0 000 .152l1.666 1.666c.042.042.11.042.152 0l7.121-7.121a.107.107 0 000-.152L9.232 7.102z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

StylesheetIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(StylesheetIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'StylesheetIcon';
export default ForwardRef;