import React, { createContext, useContext, useReducer } from 'react';

export const ColorPickerContext = createContext();

export const ColorPickerProvider = ({ reducer, initialState, children }) => (
  <ColorPickerContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </ColorPickerContext.Provider>
);

export const useColorPicker = () => useContext(ColorPickerContext);
