import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PaddingsIcon = function PaddingsIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M17 1H1v16h16V1zm1-1v18H0V0h18zM5 13h8V5H5v8zm-3 3V2h14v14H2z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PaddingsIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PaddingsIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PaddingsIcon';
export default ForwardRef;