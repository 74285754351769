import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { typeDefs, resolvers } from './resolvers';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { signOut } from '../state/commands';

const setAuthorizationLink = setContext(() => {
  const headers = {
    'client-name': 'AirPage',
    'client-version': '1.0.0'
  };

  const token = window.localStorage.getItem('token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return { headers };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err.extensions) {
          switch (err.extensions.code) {
            case 'UNAUTHENTICATED':
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: null
                }
              });
              // refactor this stuff to signout
              signOut();
              window.location.reload();
              break;
            default:
              console.log(err);
              break;
          }
        }
      }
    } else if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }

    return forward(operation);
  }
);

const httpLink = new HttpLink({
  credentials: 'include',
  uri: process.env.REACT_APP_GRAPHQL_URL
});

const cacheRedirects = {
  Query: {
    project: (_parent, args, { getCacheKey }) =>
      getCacheKey({ __typename: 'Project', id: args.id })
  }
};

const dataIdFromObject = object => {
  switch (object.__typename) {
    case 'RoleProfile':
      return object.roleId;
    default:
      return defaultDataIdFromObject(object);
  }
};

const cache = new InMemoryCache({ cacheRedirects, dataIdFromObject });
const link = ApolloLink.from([setAuthorizationLink, errorLink, httpLink]);

const client = new ApolloClient({
  cache,
  link,
  resolvers,
  typeDefs
});

cache.writeData({
  data: {
    'projects({"first":10,"offset":0,"orderBy":"CREATED_AT_DESC"})': {
      edges: [],
      __typename: 'ProjectsConnection',
      totalCount: 0,
      pageInfo: {
        __typename: 'PageInfo',
        hasNextPage: false,
        hasPreviousPage: false
      }
    }
  }
});

export { client };
