import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var CodeForkIcon = function CodeForkIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M15 3.75c0 .406-.102.783-.305 1.13-.203.349-.476.62-.82.815-.016 2.242-.898 3.86-2.648 4.852-.532.297-1.325.613-2.38.95-1 .312-1.661.589-1.986.831-.324.242-.486.633-.486 1.172v.305c.344.195.617.466.82.814.203.348.305.725.305 1.131a2.17 2.17 0 01-.656 1.594A2.17 2.17 0 015.25 18a2.17 2.17 0 01-1.594-.656A2.17 2.17 0 013 15.75c0-.406.102-.783.305-1.13.203-.349.476-.62.82-.815v-9.61a2.189 2.189 0 01-.82-.814A2.203 2.203 0 013 2.25c0-.625.219-1.156.656-1.594A2.17 2.17 0 015.25 0a2.17 2.17 0 011.594.656c.437.438.656.969.656 1.594 0 .406-.102.783-.305 1.13-.203.349-.476.62-.82.815v5.825c.422-.204 1.023-.426 1.805-.668.43-.133.771-.248 1.025-.346.254-.098.53-.219.826-.363.297-.145.528-.3.692-.463.164-.164.322-.364.474-.598a2.41 2.41 0 00.328-.814c.067-.309.1-.666.1-1.073a2.189 2.189 0 01-.82-.814A2.203 2.203 0 0110.5 3.75c0-.625.219-1.156.656-1.594A2.17 2.17 0 0112.75 1.5a2.17 2.17 0 011.594.656c.437.438.656.969.656 1.594zm-8.625 12c0-.313-.11-.578-.328-.797a1.085 1.085 0 00-.797-.328c-.313 0-.578.11-.797.328a1.085 1.085 0 00-.328.797c0 .313.11.578.328.797.219.219.484.328.797.328.313 0 .578-.11.797-.328.219-.219.328-.485.328-.797zm0-13.5c0-.313-.11-.578-.328-.797a1.085 1.085 0 00-.797-.328c-.313 0-.578.11-.797.328a1.085 1.085 0 00-.328.797c0 .313.11.578.328.797.219.219.484.328.797.328.313 0 .578-.11.797-.328.219-.219.328-.485.328-.797zm7.5 1.5c0-.313-.11-.578-.328-.797a1.085 1.085 0 00-.797-.328c-.313 0-.578.11-.797.328a1.085 1.085 0 00-.328.797c0 .313.11.578.328.797.219.219.485.328.797.328.313 0 .578-.11.797-.328.219-.219.328-.484.328-.797z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

CodeForkIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(CodeForkIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'CodeForkIcon';
export default ForwardRef;