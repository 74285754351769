import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import themeGet from '@styled-system/theme-get';
import { padding, em } from 'polished';

const variant = props => {
  switch (props.variant) {
    case 'info':
      return {
        backgroundColor: 'rgb(179,210,255)',
        borderColor: 'rgb(25,68,128)',
        color: 'rgb(25,68,128)'
      };
    case 'success':
      return {
        backgroundColor: 'rgb(179,255,236)',
        borderColor: 'rgb(20,102,82)',
        color: 'rgb(20,102,82)'
      };
    case 'warning':
      return {
        backgroundColor: 'rgb(255,249,179)',
        borderColor: 'rgb(102,95,20)',
        color: 'rgb(102,95,20)'
      };
    case 'danger':
      return {
        backgroundColor: 'rgb(255,179,185)',
        borderColor: 'rgb(128,26,34)',
        color: 'rgb(128,26,34)'
      };
    default:
      return null;
  }
};

export const Alert = styled.div(
  props => ({
    ...padding(em(12 - 0.5), em(42 - 0.5), em(12 - 0.5), em(12 - 0.5)),
    borderRadius: em(3),
    borderStyle: 'solid',
    borderWidth: 0.5,
    boxSizing: 'border-box',
    display: 'inline-flex',
    flexDirection: 'column',
    lineHeight: 18 / 16,
    position: 'relative',
    pre: {
      margin: 0
    },
    zIndex: themeGet('zIndex.snackbar')(props)
  }),
  variant
);

Alert.propTypes = {
  variant:
    (PropTypes.oneOf < 'info') |
    'success' |
    'warning' |
    ('danger' > ['info', 'success', 'warning', 'danger'].isRequired)
};
