import React, { useMemo, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { GenericPortal as Portal } from '../GenericPortal';
import { FocusTrap } from '../FocusTrap';
import { ModalRoot, Backdrop as DefaultBackdrop, Fade } from '../../ui';
import { uniqueId } from '../../utils/uniqueId';
import { getOwnerDocument } from '../../utils/dom';
import { useForkRef } from '../../utils/react';
import { chainFunctions } from '../../utils/function';
import ModalManager from './ModalManager';

const manager = new ModalManager();

export const Modal = React.forwardRef(function Modal(props, ref) {
  const {
    id,
    open,
    onClose,
    onRendered,
    backdrop,
    BackdropComponent = DefaultBackdrop,
    disableBackdropClick,
    disableScrollLock,
    disableAutoFocus = false,
    disableRestoreFocus = false,
    keyboard,
    container,
    children,
    style
  } = props;
  const modalId = useMemo(() => {
    if (id) return id;
    return uniqueId('modal-');
  }, [id]);

  const [exited, setExited] = React.useState(!open);
  const modal = React.useRef({});
  const mountNodeRef = React.useRef(null);
  const modalRef = React.useRef(null);
  const handleRef = useForkRef(modalRef, ref);
  const hasTransition = getHasTransition(props);

  const handleKeyDown = event => {
    // ESC key
    if (keyboard && event.keyCode === 27) {
      event.stopPropagation();
      return onClose(event);
    }
  };

  const handleMounted = useCallback(() => {
    if (!modalRef.current) return;
    manager.mount(getModal(modal, mountNodeRef, modalRef));
    modalRef.current.scrollTop = 0;
  }, []);

  const handleOpen = useCallback(() => {
    const getContainer = container => {
      container = typeof container === 'function' ? container() : container;
      return ReactDOM.findDOMNode(container);
    };
    const resolvedContainer =
      getContainer(container) || getOwnerDocument().body;

    manager.add(getModal(modal, mountNodeRef, modalRef), resolvedContainer);

    // The element was already mounted.
    if (modalRef.current) {
      handleMounted();
    }
  }, [container, handleMounted]);

  const handleClose = useCallback(() => {
    manager.remove(getModal(modal, mountNodeRef, modalRef));
  }, []);

  const handleRendered = useCallback(() => {
    if (onRendered) onRendered();
    if (open) {
      handleMounted();
    } else {
      if (!modalRef.current) return;
      modalRef.current.setAttribute('aria-hidden', true);
    }
  }, [handleMounted, open, onRendered]);

  const isTopModal = useCallback(
    () => manager.isTopModal(getModal(modal, mountNodeRef, modalRef)),
    []
  );

  React.useEffect(() => {
    if (open) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [open, handleClose, handleOpen]);

  useEffect(() => {
    if (disableScrollLock) return;
    if (open) document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, [open, disableScrollLock]);

  const childProps = {
    id: modalId + '-body',
    role: children.role || 'document',
    tabIndex: children.tabIndex || '-1'
  };

  if (!open && (!hasTransition || exited)) return null;

  const handleEnter = () => {
    setExited(false);
  };

  const handleExited = () => {
    setExited(true);
  };

  if (hasTransition) {
    childProps.onEnter = chainFunctions(handleEnter, children.props.onEnter);
    childProps.onExited = chainFunctions(handleExited, children.props.onExited);
  }

  return (
    <Portal
      ref={mountNodeRef}
      open={open}
      container={container}
      onRendered={handleRendered}
    >
      <ModalRoot
        id={`${modalId}`}
        role="presentation"
        tabIndex={-1}
        onKeyDown={handleKeyDown}
        ref={handleRef}
        style={style}
      >
        {backdrop && (
          <Fade in={open}>
            <BackdropComponent
              onClick={event => {
                if (disableBackdropClick) return;
                onClose(event);
              }}
            />
          </Fade>
        )}
        <FocusTrap
          open={open}
          enabled={isTopModal}
          disableAutoFocus={disableAutoFocus}
          disableRestoreFocus={disableRestoreFocus}
        >
          {React.cloneElement(children, childProps)}
        </FocusTrap>
      </ModalRoot>
    </Portal>
  );
});

Modal.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  backdrop: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableScrollLock: PropTypes.bool,
  onClose: PropTypes.func,
  onBackdropClick: PropTypes.func,
  // The mounting container of the modal, if not provided, default to document.body
  container: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  keyboard: PropTypes.bool
};

Modal.defaultProps = {
  keyboard: true,
  backdrop: true,
  disableBackdropClick: false,
  disableScrollLock: false
};

function getModal(modal, mountNodeRef, modalRef) {
  modal.current.modalRef = modalRef.current;
  modal.current.mountNode = mountNodeRef.current;
  return modal.current;
}

// Is the children a Transition component?
function getHasTransition(props) {
  return props.children ? props.children.props.hasOwnProperty('in') : false;
}
