import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var SnapshotIcon = function SnapshotIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M18 2.25v11.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25V2.25A.25.25 0 01.25 2h1.5A.25.25 0 002 1.75v-.5A.25.25 0 012.25 1h.5a.25.25 0 01.25.25v.5c0 .138.112.25.25.25h1.625a.25.25 0 00.2-.1L6.425.1a.25.25 0 01.2-.1h4.75a.25.25 0 01.2.1l1.35 1.8a.25.25 0 00.2.1h4.625a.25.25 0 01.25.25zm-8 14v1.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 00-.25-.25h-4.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h11.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-4.5a.25.25 0 00-.25.25zm1.5-8v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 01-.25-.25v-2A.25.25 0 009.25 5h-.5a.25.25 0 00-.25.25v3c0 .138.112.25.25.25h2.5a.25.25 0 00.25-.25zM9 12a4 4 0 110-8 4 4 0 010 8zm0 1A5 5 0 109 3a5 5 0 000 10z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

SnapshotIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SnapshotIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'SnapshotIcon';
export default ForwardRef;