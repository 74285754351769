import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { margin, padding, em, mix } from 'polished';
import { Icon } from '../Icon';

const variant = css({
  backgroundColor: 'rgb(50,50,50)',
  borderColor: 'rgba(0,0,0,0.8)',
  color: 'rgb(180,180,180)',

  '&:hover, &:focus': {
    backgroundColor: mix(0.15, 'rgb(255,255,255)', 'rgb(50,50,50)'),
    borderColor: 'rgba(0,0,0,0.8)',
    color: 'rgb(250,250,250)'
  },

  '&:active': {
    backgroundColor: mix(0.15, 'rgb(0,0,0)', 'rgb(50,50,50)'),
    borderColor: 'rgba(0,0,0,0.8)',
    color: 'rgb(250,250,250)'
  }
});

export const AccordionHeadingButton = styled.button(
  {
    ...margin(0),
    ...padding(em(12 - 0.5)),
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 0.5,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 18 / 16,
    position: 'relative',
    textAlign: 'left',
    textDecoration: 'none',
    width: '100%',
    [`${Icon}`]: {
      marginRight: em(12)
    }
  },
  variant
);

AccordionHeadingButton.propTypes = {
  'aria-controls': PropTypes.string.isRequired,
  'aria-disabled': PropTypes.bool,
  'aria-expanded': PropTypes.bool
};
