import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var ColorSchemeIcon = function ColorSchemeIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M5.143 9.3l7.51-7.51a.107.107 0 01.15 0l3.486 3.485a.107.107 0 010 .152l-7.43 7.43h9.034c.059 0 .107.048.107.107v4.929c0 .059-.048.107-.107.107H3.715l-.003.003a.107.107 0 01-.151 0L3.558 18H.108A.107.107 0 010 17.893V.107C0 .048.048 0 .107 0h4.929c.059 0 .107.048.107.107V9.3zm-1.714 6.129a.857.857 0 100-1.715.857.857 0 000 1.715z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

ColorSchemeIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(ColorSchemeIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'ColorSchemeIcon';
export default ForwardRef;