import styled from '@emotion/styled/macro';
import { layout, flexbox, space } from 'styled-system';

export const Grid = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto'
  },
  layout,
  flexbox,
  space
);
