import styled from '@emotion/styled/macro';
import { Icon } from '../Icon';
import { margin, padding, em } from 'polished';
import css from '@emotion/css';

const variant = props =>
  css({
    color: 'rgb(180,180,180)',
    [`& > ${Icon}`]: props.isSelected
      ? {
          backgroundColor: 'rgb(24,24,24)',
          borderColor: 'rgba(0,0,0,0.8)',
          boxShadow: 'rgba(255,255,255,0.15) 0 1px 0 0'
        }
      : undefined
  });

export const ToolbarNavListItem = styled.button([
  {
    ...margin(0),
    ...padding(0),
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    borderWidth: 0,
    cursor: 'pointer',
    display: 'flex',
    flexShrink: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    justifyContent: 'center',
    lineHeight: 18 / 16,
    textDecoration: 'none',
    [`& > ${Icon}`]: {
      ...padding(em(6)),
      borderColor: 'transparent',
      borderRadius: em(3),
      borderStyle: 'solid',
      borderWidth: 0.5
    }
  },
  variant
]);

ToolbarNavListItem.defaultProps = {
  role: 'tab'
};
