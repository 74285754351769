import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var PowerButtonIcon = function PowerButtonIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M12 2.582A8.003 8.003 0 019 18 8 8 0 016 2.582v1.092a7 7 0 106 0V2.582zM8.75 0h.5a.25.25 0 01.25.25v7.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25V.25A.25.25 0 018.75 0z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

PowerButtonIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(PowerButtonIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'PowerButtonIcon';
export default ForwardRef;