import { em, transparentize } from 'polished';
import { baseColors } from './createPalette';

// Create a variant for button https://styled-system.com/variants
export default function createButton(theme) {
  const dark = {
    buttons: {
      primary: {
        backgroundColor: baseColors.blue[1],
        borderColor: 'rgba(0, 0, 0, 0.8)',
        boxShadow: `inset 0 ${em(1)} 0 0
          ${transparentize(0.85, baseColors.white)}`,
        color: baseColors.blue[4],
        '&:hover, &:focus': {
          backgroundColor: transparentize(0.15, baseColors.blue[1]),
          boxShadow: `inset 0 ${em(1)} 0 0 rgba(255, 255, 255, 0.15)`,
          color: baseColors.blue[4]
        },
        "&:active, &[aria-pressed='true']": {
          backgroundColor: transparentize(0.5, baseColors.blue[1]),
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
          borderColor: baseColors.blue[1],
          color: baseColors.white
        },
        "&:disabled, &[aria-disabled='true']": {
          backgroundColor: 'rgba(255, 255, 255, 0)',
          borderColor: 'rgba(186, 186, 186, 0.3)',
          color: 'rgba(186, 186, 186, 0.3)'
        }
      },
      minimal: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: '4px',
        color: 'rgba(128, 128, 128, 1)',
        '&:hover, &:focus': {
          backgroundColor: baseColors.blue[0],
          color: baseColors.blue[1],
          borderColor: 'rgba(0, 0, 0, 0.2)'
        },
        "&:active, &[aria-pressed='true'], &[aria-current='true']": {
          backgroundColor: baseColors.blue[0],
          color: baseColors.blue[1],
          boxShadow: `inset 0 ${em(1)} 0 0
            ${transparentize(0.2, baseColors.white)}`,
          borderColor: 'rgba(0, 0, 0, 0.2)'
        },
        "&:disabled, &[aria-disabled='true']": {
          backgroundColor: `${transparentize(1, baseColors.black)}`,
          borderColor: 'rgba(128, 128, 128, 0.2)',
          color: 'rgba(128, 128, 128, 0.2)'
        }
      },
      default: {
        backgroundColor: `${transparentize(0.97, baseColors.white)}`,
        borderColor: `${transparentize(0.2, baseColors.black)}`,
        boxShadow: `inset 0 ${em(1)} 0 0 ${transparentize(
          0.85,
          baseColors.white
        )}`,
        color: 'rgba(186, 186, 186, 1)',
        '&:hover, &:focus': {
          backgroundColor: `${transparentize(0.91, baseColors.white)}`,
          color: baseColors.white
        },
        "&:active, &[aria-pressed='true']": {
          backgroundColor: `${transparentize(0.86, baseColors.white)}`,
          boxShadow: `inset 0 ${em(1)} 0 0
            ${transparentize(0.85, baseColors.black)}`,
          color: baseColors.white
        },
        "&:disabled, &[aria-disabled='true']": {
          backgroundColor: 'transparent',
          borderColor: `${transparentize(0.7, baseColors.black)}`,
          color: 'rgba(186, 186, 186, 0.3)'
        }
      }
    }
  };

  const light = {
    buttons: {
      primary: {
        backgroundColor: baseColors.blue[1],
        borderColor: 'rgb(0, 129, 204)',
        boxShadow: `inset 0 ${em(1)} 0 0
          ${transparentize(0.4, baseColors.white)}`,
        color: baseColors.white,
        '&:hover, &:focus': {
          backgroundColor: 'rgb(51, 180, 255)',
          boxShadow: `inset 0 ${em(1)} 0 0 rgba(255, 255, 255, 0.6)`
        },
        "&:active, &[aria-pressed='true'], &[aria-current='true']": {
          backgroundColor: 'rgba(235, 247, 255, 0.5)',
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.8)',
          borderColor: baseColors.blue[1],
          color: baseColors.blue[1]
        },
        "&:disabled, &[aria-disabled='true']": {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(128, 128, 128, 0.2)',
          color: 'rgba(128, 128, 128, 0.2)'
        }
      },
      minimal: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: '4px',
        color: 'rgba(128, 128, 128, 1)',
        '&:hover, &:focus': {
          backgroundColor: baseColors.blue[0],
          color: baseColors.blue[1],
          borderColor: 'rgba(0, 0, 0, 0.2)'
        },
        "&:active, &[aria-pressed='true'], &[aria-current='true']": {
          backgroundColor: baseColors.blue[0],
          color: baseColors.blue[1],
          boxShadow: `inset 0 ${em(1)} 0 0
            ${transparentize(0.2, baseColors.white)}`,
          borderColor: 'rgba(0, 0, 0, 0.2)'
        },
        "&:disabled, &[aria-disabled='true']": {
          backgroundColor: `${transparentize(1, baseColors.black)}`,
          borderColor: 'rgba(128, 128, 128, 0.2)',
          color: 'rgba(128, 128, 128, 0.2)'
        }
      },
      default: {
        backgroundColor: `${transparentize(0.98, baseColors.black)}`,
        borderColor: `${transparentize(0.8, baseColors.black)}`,
        boxShadow: `inset 0 ${em(1)} 0 0 ${transparentize(
          0.4,
          baseColors.white
        )}`,
        color: 'rgba(128, 128, 128, 1)',
        '&:hover, &:focus': {
          backgroundColor: `${transparentize(0.96, baseColors.white)}`,
          color: 'rgb(77, 77, 77)'
        },
        "&:active, &[aria-pressed='true'], &[aria-current='true']": {
          backgroundColor: `${transparentize(0.92, baseColors.black)}`,
          boxShadow: `inset 0 ${em(1)} 0 0
            ${transparentize(0.2, baseColors.white)}`,
          color: 'rgb(77, 77, 77)'
        },
        "&:disabled, &[aria-disabled='true']": {
          backgroundColor: `${transparentize(1, baseColors.black)}`,
          borderColor: 'rgba(128, 128, 128, 0.2)',
          color: 'rgba(128, 128, 128, 0.2)'
        }
      }
    }
  };

  return {
    ...({ dark, light }[theme] || dark)
  };
}
