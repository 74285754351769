import React from 'react';
import { camelCase } from 'change-case';
import PropTypes from 'prop-types';

const styleString2JSON = str =>
  str.split(';').reduce((props, value) => {
    let [name, val] = value.split(':');
    name = camelCase(name.trim());
    val = val.trim();
    props[name] = val;
    return props;
  }, {});

function xml2json(xml) {
  // Create the return object
  var obj = {
    type: xml.nodeName,
    props: {},
    children: []
  };

  if (xml.nodeType === 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        if (camelCase(attribute.nodeName) === 'style') {
          obj.props[camelCase(attribute.nodeName)] = styleString2JSON(
            attribute.nodeValue
          );
        } else {
          obj.props[camelCase(attribute.nodeName)] = attribute.nodeValue;
        }
      }
    }
  }

  // do children
  if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);
      const child = xml2json(item);
      if (child) obj.children.push(child);
    }
  }
  return obj;
}

const svgJson2react = (json, props, svgRef) => {
  switch (json.type) {
    case '#text':
    case '#comment':
      return;
    case '#document':
      return ({ svgRef, ...allProps }) =>
        svgJson2react(json.children[0], allProps, svgRef);
    case 'svg': {
      let {
        width,
        height,
        size = 16,
        fill = 'currentColor',
        style = {},
        ...rest
      } = { ...json.props, ...props };

      style = {
        ...style,
        width: '1em',
        height: '1em',
        fontSize: size
      };
      return React.createElement(
        json.type,
        {
          ...rest,
          style,
          fill,
          ref: svgRef,
          width: '1em',
          height: '1em'
        },
        json.children.map(svgJson2react)
      );
    }
    default:
      return React.createElement(
        json.type,
        json.props,
        json.children && json.children.length
          ? json.children
              .map((child, i) => svgJson2react(child, { key: i }))
              .filter(Boolean)
          : undefined
      );
  }
};

export const getDefinitionIcon = iconText => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(iconText, 'text/xml');
  const json = xml2json(xmlDoc);
  const Icon = svgJson2react(json);
  Icon.propTypes = {
    svgRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  };
  const IconComponent = React.forwardRef((props, ref) => (
    <Icon svgRef={ref} {...props} />
  ));
  IconComponent.displayName = 'IconComponent';
  return IconComponent;
};
