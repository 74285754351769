import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

export const AccordionHeading = styled.dt();

AccordionHeading.defaultProps = {
  role: 'heading'
};

AccordionHeading.propTypes = {
  'aria-level': PropTypes.number.isRequired
};
