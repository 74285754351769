import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';

var UpIcon = function UpIcon(_ref) {
  var svgRef = _ref.svgRef,
      props = _objectWithoutProperties(_ref, ["svgRef"]);

  return React.createElement("svg", _extends({
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 18",
    ref: svgRef
  }, props), React.createElement("path", {
    d: "M.066 13.775l8.742-10.68a.243.243 0 01.384 0l8.742 10.68a.305.305 0 01-.02.402.246.246 0 01-.173.073H.259c-.143 0-.259-.127-.259-.285 0-.07.024-.138.066-.19z",
    fill: "inherit",
    fillRule: "nonzero"
  }));
};

UpIcon.propTypes = {
  svgRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })])
};
var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(UpIcon, _extends({
    svgRef: ref
  }, props));
});
ForwardRef.displayName = 'UpIcon';
export default ForwardRef;